import React, { useCallback } from "react";
import "./Spot.scss";
interface Props {
    image: {
        src: string;
        srcSet?: string;
        sizes?: string;
        alt: string;
    };
    category?: string;
    headline: string;
    body?: string;
    imageHeight: string;
    link?: string;
    openInNewTab?: boolean;
}

export default function Spot({ image, category, headline, body, link, openInNewTab }: Props) {
    const onClickHandler = useCallback(
        () => {
            if (link) {
                window.location.href = link;
            }
        },
        [link]
    );

    let linkTarget = undefined;
    let rel = undefined;
    if (openInNewTab) {
        linkTarget = "_blank";
        rel = "noopener noreferrer";
    }

    const bodyClassNames = ['bwp-spot__body'];
    if (!link) {
        bodyClassNames.push("bwp-no-link");
    }

    return (
        <div
            className="bwp-spot"
            style={{ cursor: link ? "pointer" : undefined }}
            onClick={onClickHandler}
        >
            {image && <img {...image} loading="lazy" />}
            {category && <h4>{category}</h4>}
            {headline && <h3>{link ? <a target={linkTarget} rel={rel} href={link}>{headline}</a> : headline}</h3>}
            {body && (
                <div className={bodyClassNames.join(' ')} dangerouslySetInnerHTML={{ __html: body }}></div>
            )}
        </div>
    );
}
