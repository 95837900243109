import favoriteHelper from "./FavoriteHelper";
import { useCallback, useEffect, useState } from "react";

export default function useFavorites() {
    let [favorites, setFavorites] = useState(favoriteHelper.getFavorites().map((f) => f.id));

    useEffect(() => {
        return favoriteHelper.subscribe(() => {
            setFavorites(favoriteHelper.getFavorites().map((f) => f.id));
        });
    }, []);

    const setFavorite = useCallback((id: number) => {
        favoriteHelper.handleFavouriteToggle(id);
    }, []);

    return [favorites, setFavorite] as [number[], (id: number) => void];
}
