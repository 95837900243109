import React from "react";
import "./Grid.scss";

export interface Row {
    className: string;
    cells: React.ReactNode[];
}

export interface Props {
    rows: Row[];
    autoShade: boolean;
}

export default function Grid({ rows, autoShade }: Props) {
    const cellClassNames = (index) => {
        if (!autoShade) {
            return "bwp-sb-cell";
        }

        const shades = ["lightest", "lighter", "light", "base", "dark", "darker", "darkest"];
        const shade = shades[index % shades.length];
        return ["bwp-sb-cell", `bwp-sb-cell-${shade}`].join(" ");
    };

    const createCell = (content: React.ReactNode, rowNumber: number, index: number) => {
        if (typeof content === "string") {
            return (
                <div className={cellClassNames(index)} key={`row${rowNumber}_cell${index}`}>
                    {content}
                </div>
            );
        }
        return content;
    };

    return (
        <React.Fragment>
            {rows.map((row, index) => (
                <div className={row.className} key={`row${index}`}>
                    {row.cells.map((cell, cellIndex) => createCell(cell, index, cellIndex))}
                </div>
            ))}
        </React.Fragment>
    );
}
