import React from "react";
import "./SearchResultListHeader.scss";
import { processPluralizedTemplateReact } from "./shared/templates";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import SelectFilterBox from "./SelectFilterBox";
import Button from "./Button";
import ListIcon from "./ListIcon";
import MapIcon from "./MapIcon";
import { SearchResultListStyle } from "./shared/types";

interface Props {
    numberOfResults: number;
    sorting: string;
    onSortingChange: (value: string) => void;
    translations: SearchResultListTranslations;
    listStyle: SearchResultListStyle;
    mapUnavailable?: boolean;
    onListStyleChange: (style: SearchResultListStyle) => void;
}

export default function SearchResultListHeader({
    numberOfResults,
    sorting,
    onSortingChange,
    translations,
    listStyle,
    mapUnavailable,
    onListStyleChange,
}: Props) {
    const mapAvailable = !mapUnavailable;
    
    return (
        <div className="bwp-search-result-list-header">
            <div>
                {numberOfResults !== null && (
                    <span>
                        {processPluralizedTemplateReact(
                            numberOfResults,
                            <strong key="1">{numberOfResults}</strong>,
                            translations.numberOfResultsFound
                        )}
                    </span>
                )}
            </div>
            <div className="bwp-search-result-list-header__toolbar">
                <SelectFilterBox
                    value={sorting === null ? "" : sorting}
                    onChange={(e) =>
                        onSortingChange(e.currentTarget.value === "" ? null : e.currentTarget.value)
                    }
                >
                    <option value="">{translations.chooseSorting}</option>
                    <option value="PRICE_LOW_TO_HIGH">{translations.sortingPrice}</option>
                </SelectFilterBox>
                {mapAvailable && (
                    <>
                        {listStyle != "Grid" && (
                            <Button type="filter" icon={ListIcon} onClick={() => onListStyleChange("Grid")}>
                                {translations.showAsList}
                            </Button>
                        )}
                        {listStyle != "Map" && (
                            <Button type="filter" icon={MapIcon} onClick={() => onListStyleChange("Map")}>
                                {translations.showOnMap}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
