import React from "react";
import "./Breadcrumb.scss";
interface Props {
    items: BreadcrumbItem[];
}

export interface BreadcrumbItem {
    name: string;
    url?: string;
}

export default function Breadcrumb({ items }: Props) {
    return (
        <ol className="bwp-breadcrumb">
            {items.map((i, index) => (
                <li key={index}>
                    {i.url ? <a href={i.url}>{i.name}</a> : <span>{i.name}</span>}
                    <svg
                        width={7}
                        height={8}
                        viewBox="0 0 7 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.98438 4.14286C3.98438 4.08929 3.95759 4.02902 3.91741 3.98884L0.796875 0.868303C0.756696 0.828125 0.696429 0.801339 0.642857 0.801339C0.589286 0.801339 0.529018 0.828125 0.488839 0.868303L0.154018 1.20312C0.113839 1.2433 0.0870536 1.30357 0.0870536 1.35714C0.0870536 1.41071 0.113839 1.47098 0.154018 1.51116L2.78571 4.14286L0.154018 6.77455C0.113839 6.81473 0.0870536 6.875 0.0870536 6.92857C0.0870536 6.98214 0.113839 7.04241 0.154018 7.08259L0.488839 7.41741C0.529018 7.45759 0.589286 7.48437 0.642857 7.48437C0.696429 7.48437 0.756696 7.45759 0.796875 7.41741L3.91741 4.29688C3.95759 4.2567 3.98438 4.19643 3.98438 4.14286ZM6.5558 4.14286C6.5558 4.08929 6.52902 4.02902 6.48884 3.98884L3.3683 0.868303C3.32812 0.828125 3.26786 0.801339 3.21429 0.801339C3.16071 0.801339 3.10045 0.828125 3.06027 0.868303L2.72545 1.20312C2.68527 1.2433 2.65848 1.30357 2.65848 1.35714C2.65848 1.41071 2.68527 1.47098 2.72545 1.51116L5.35714 4.14286L2.72545 6.77455C2.68527 6.81473 2.65848 6.875 2.65848 6.92857C2.65848 6.98214 2.68527 7.04241 2.72545 7.08259L3.06027 7.41741C3.10045 7.45759 3.16071 7.48437 3.21429 7.48437C3.26786 7.48437 3.32812 7.45759 3.3683 7.41741L6.48884 4.29688C6.52902 4.2567 6.5558 4.19643 6.5558 4.14286Z"
                            fill="#555555"
                        />
                    </svg>
                </li>
            ))}
        </ol>
    );
}
