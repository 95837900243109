import React, { useEffect, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { getSearchSorting } from "@bwp/shared/helpers";
import { formatISO } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLodgingListQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import LodgingList from "@bwp/LodgingList";
import {CleaningBannerType, DiscountBannerType, LodgingListSetting} from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";
import { FavouritesTranslations } from "@bwp/translations/FavouritesTranslations";
import {SearchContext, SearchContextProps} from "@bwp/shared/searchcontext";

export interface LodgingListWrapperProps {
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    searchResultListTranslations: SearchResultListTranslations;
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    year: number;
    lodgingListSetting: LodgingListSetting;
    onlyShowFavorites: boolean;
    emptyResultHtmlContent: string;
    markerIconUrl: string;
    mapHeight: number;
    googleMapsApiKey: string;
    maxMapZoomLevel: number;
    showStars?: boolean;
    starsExtraIcon?: string;
    extraFacilities?: number[];
    defaultOrderBy?: string;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    cleaningIncludedFacilityId?: number;
    presets?: SearchContextProps;
    defaultValues?: SearchContextProps;
    showProbabilityWithPriceAsBookingOption?: boolean;
}

export default function LodgingListWrapper({
    lodgingCardTranslations,
    favouritesTranslations,
    lodgingUrlPattern,
    includeLodgingIdInUrl,
    lodgingListSetting,
    year,
    onlyShowFavorites,
    emptyResultHtmlContent,
    searchResultListTranslations,
    markerIconUrl,
    mapHeight,
    googleMapsApiKey,
    maxMapZoomLevel,
    showStars,
    starsExtraIcon,
    extraFacilities,
    defaultOrderBy,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    cleaningIncludedFacilityId,
    presets,
    defaultValues,
    showProbabilityWithPriceAsBookingOption
}: LodgingListWrapperProps) {
    const [headerElement] = React.useState(document.getElementById("bwp-search-result-header"));
    const [sorting, setSorting] = useQueryString("ord", null);

    const [searchContext] = useGlobalSearchContext({ presets, defaultValues, lodgingListSetting });
    const query = useLodgingListQuery({
        variables: {
            query: searchContext.toServerQuery(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: (sorting as any) ?? getSearchSorting(defaultOrderBy),
            year
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(6);

    useEffect(() => {
        if (query.data != null) {
            setNumberOfSeletons(query.data.lodgingList.results.length);
        }
    }, [query.data]);

    if (query.error) {
        return (
            <div className="bwp-query-errors">
                <div className="bwp-error"><strong>Error message:</strong> {query.error.message}</div>
                <div className="bwp-error"><strong>Error stack:</strong><pre>{query.error.stack}</pre></div>
            </div>
        );
    }

    if (query.loading) {
        return <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />;
    }
    
    const alwaysIgnored = [
        "context:lodging-location",
        "context:lodging-address1",
        "context:lodging-name"
    ];
    
    const ignoreParams = includeLodgingIdInUrl 
        ? alwaysIgnored
        : alwaysIgnored.concat(["lodgingId"]);

    return (
        <>
            <LodgingList
                lodgingCardTranslations={lodgingCardTranslations}
                favouritesTranslations={favouritesTranslations}
                {...query.data}
                lodgingUrlBuilder={(l) => {
                    const baseUrl = lodgingUrl(lodgingUrlPattern, l);
                    const sc = new SearchContext();
                    const qs = sc.changeLodging(l.id).toQueryString(ignoreParams);
                    let separator = baseUrl.indexOf('?') === -1 ? "?" : "&";
                    if (qs === "") {
                        separator = "";
                    }
                    return `${baseUrl}${separator}${qs}`;
                }}
                extraFacilities={extraFacilities}
                onlyShowFavorites={onlyShowFavorites}
                emptyResultHtmlContent={emptyResultHtmlContent}
                searchResultListTranslations={searchResultListTranslations}
                headerElement={headerElement}
                markerIconUrl={markerIconUrl}
                mapHeight={mapHeight}
                maxMapZoomLevel={maxMapZoomLevel}
                googleMapsApiKey={googleMapsApiKey}
                sorting={sorting}
                setSorting={setSorting}
                showStars={showStars}
                starsExtraIcon={starsExtraIcon}
                discountBanner={discountBanner}
                cleaningBanner={cleaningBanner}
                hideLodgingAddress={hideLodgingAddress}
                cleaningIncludedFacilityId={cleaningIncludedFacilityId}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
            />
        </>
    );
}
