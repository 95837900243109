import Button from "./Button";
import React, { useState } from "react";
import SelectWithLabel from "./SelectWithLabel";
import useLocalSearchContext from "./shared/useLocalSearchContext";
import { SearchBoxProps } from "./shared/types";
import DatePickerWithLabel from "./DatePickerWithLabel";
import { createDayManipulatorWithHighlightDays, SelectionMode } from "./DatePicker";
import { DurationSelectWithLabel } from "./DurationSelectWithLabel";
import { NumberSelectWithLabel } from "./NumberSelectWithLabel";
import PeopleSelectWithLabel from "./PeopleSelectWithLabel";
import "./HeroSearchSearchBox.scss";
import { IconHouseSmall, IconSearchSmall } from "./shared/icons";
import AutoCompleteFieldWithLabel from "./AutoCompleteFieldWithLabel";
import getLodgingAutoComplete from "./shared/autocompletehelpers/LodgingAutoCompleteHelper";
import { LodgingsQuery } from "./operations.generated";
import { lodgingUrl } from "./shared/lodgingurl";
import {SearchContextProps, StandardDefaultSearchContext} from "./shared/searchcontext";

type Lodging = LodgingsQuery["lodgings"][0];

export type HeroSearchSearchBoxProps = {
    searchBox: SearchBoxProps;
    requestLodgings: () => LodgingsQuery;
    lodgings?: LodgingsQuery["lodgings"];
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    culture: string;
    defaultValues?: SearchContextProps;
};

const TabSearch = "tabSearch";
const TabLodging = "tabLodging";

export function registerTranslations() {
    return {
        "searchBox.translations": "SearchBox",
        "searchBox.datePickerTranslations": "DatePicker"
    };
}

export default function HeroSearchSearchBox({
    searchBox,
    requestLodgings,
    lodgings,
    lodgingUrlPattern,
    includeLodgingIdInUrl,
    culture,
    defaultValues
}: HeroSearchSearchBoxProps) {
    
    let {
        translations,
        locations,
        durations,
        maxPeople,
        maxPets,
        onSubmit,
        datePickerTranslations,
        highlightDays,
    } = searchBox;
    
    if (!culture && window && window["BookingStudio_culture"]) {
        culture = window["BookingStudio_culture"];
    }
    
    const [currentTab, setCurrentTab] = useState(TabSearch);
    const [hasRequestedLodgings, setHasRequestedLodgings] = useState(false);

    const contextDefaults = Object.assign(StandardDefaultSearchContext, defaultValues);
    
    const [searchContext, setSearchContext] = useLocalSearchContext({
        defaultValue: contextDefaults
    });

    function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();

        if (onSubmit) {
            onSubmit(searchContext);
        }
    }

    function handleLoadLodgings() {
        if (!hasRequestedLodgings) {
            requestLodgings();
            setHasRequestedLodgings(true);
        }
    }

    function handleLodgingSearchChange(lodging: Lodging) {
        if (lodging) {
            gotoLodging(lodging);
        }
    };

    const gotoLodging = (lodging: Lodging) => {
        if (lodgingUrlPattern) {
            if (lodging) {
                let url = lodgingUrl(lodgingUrlPattern, lodging);
                if (includeLodgingIdInUrl) {
                    url += url.indexOf('?') > -1  ? "&" : "?";
                    url += `lod=${lodging.id}`;
                }
                window.location.href = url;
            }
        } else {
            console.error("HeroSearchSearchBox component is missing lodgingUrlPattern prop");
        }
    };

    function setupTabClicked(tabName: string) {
        return (e: React.SyntheticEvent) => {
            e.preventDefault();
            setCurrentTab(tabName);
        }
    }

    function tabClassNames(isActive) {
        const names = ['bwp-hero-searchbox__tab'];
        if (isActive) {
            names.push('bwp-active');
        }
        return names.join(' ');
    }

    return (
        <div className="bwp-hero-searchbox">
            <div className="bwp-hero-searchbox__tabs">
                <div className={tabClassNames(currentTab === TabSearch)}>
                    <a href={`#${TabSearch}`} onClick={setupTabClicked(TabSearch)}>
                        {IconSearchSmall}
                        <span>{translations.searchAvailableLodgings}</span>
                    </a>
                </div>
                <div className={tabClassNames(currentTab === "tabLodging")}>
                    <a href={`#${TabLodging}`} onClick={setupTabClicked(TabLodging)}>
                        {IconHouseSmall}
                        <span>{translations.searchLodging}</span>
                    </a>
                </div>
            </div>
            <div className="bwp-hero-searchbox__panels">
                {currentTab === TabSearch && (
                    <div className="bwp-hero-searchbox__panel">
                        <form onSubmit={handleSubmit} className="bwp-hero-searchbox__cols-6">
                            <SelectWithLabel
                                id="location"
                                label={translations.locationLabel}
                                value={
                                    searchContext.locationIds == null || searchContext.locationIds.length == 0
                                        ? null
                                        : searchContext.locationIds[0].toString()
                                }
                                onChange={(v) =>
                                    setSearchContext(searchContext.changeLocationId(v == null ? null : parseInt(v)))
                                }
                                items={locations}
                            />
                            <DatePickerWithLabel
                                label={translations.arrivalLabel}
                                value={searchContext.parsedArrival}
                                onChange={(v) => setSearchContext(searchContext.changeArrival(v))}
                                selectionMode={SelectionMode.Future}
                                culture={culture}
                                translations={datePickerTranslations}
                                dayManipulator={createDayManipulatorWithHighlightDays(highlightDays ?? [])}
                            />
                            <DurationSelectWithLabel
                                id="duration"
                                label={translations.durationLabel}
                                value={searchContext.duration}
                                onChange={(v) => setSearchContext(searchContext.changeDuration(v))}
                                translations={translations}
                                durations={durations}
                            />
                            <PeopleSelectWithLabel
                                label={translations.personsLabel}
                                maxPersons={maxPeople}
                                value={{ adults: searchContext.adults, children: searchContext.children, infants: searchContext.infants }}
                                onChange={(v) =>
                                    setSearchContext(
                                        searchContext.changePersons(v.adults, v.children, v.infants, searchContext.pets)
                                    )
                                }
                                translations={translations}
                                showLabelAsPersons
                            />
                            <NumberSelectWithLabel
                                id="pets"
                                label={translations.petsLabel}
                                itemTranslation={translations.pet}
                                value={searchContext.pets}
                                min={1}
                                max={maxPets}
                                nullValueText={translations.petsNotImportant}
                                onChange={(v) => setSearchContext(searchContext.changePets(v))}
                            />
                            <Button type="primary" submitButton noWrap>
                                {translations.searchButtonText}
                            </Button>
                        </form>
                    </div>
                )}
                {currentTab === TabLodging && (
                    <div className="bwp-hero-searchbox__panel">
                        <form className="bwp-hero-searchbox__cols-1">
                            <AutoCompleteFieldWithLabel
                                label={translations.enterLodgingId}
                                focus={true}
                                onChange={handleLodgingSearchChange}
                                items={lodgings}
                                onLoadItems={handleLoadLodgings}
                                noLodgingsMatch={translations.noLodgingsMatch}
                                loadingData={translations.loadingData}
                                {...getLodgingAutoComplete()}
                            />
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}
