import React from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LastMinuteQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./CompactDiscountLodgingList.scss";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import { FavouritesTranslations } from "./translations/FavouritesTranslations";
import {CleaningBannerType, DiscountBannerType} from "./shared/types";

export function registerTranslations() {
    return {
        "lodgingCardTranslations": "LodgingCard",
        "favouritesTranslations": "Favourites"
    };
}

export default function CompactDiscountLodgingList({
    lodgingCardTranslations,
    favouritesTranslations,
    lastMinute: { results },
    lodgingUrlBuilder,
    extraFacilities,
    emptyResultHtmlContent,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: {
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingUrlBuilder: (l: LastMinuteQuery["lastMinute"]["results"][0]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
} & LastMinuteQuery) {
    let [favorites, setFavorite] = useFavorites();
    
    if (results.length == 0) {
        return <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />;
    }

    return (
        <div className="bwp-compact-lodging-list">
            <div className="bwp-lodging-list">
                {results.map((result) => (
                    <LodgingCard2
                        key={
                            result.lodging.name +
                            " " +
                            result.selectedBookingOption?.arrivalDisplayValue +
                            " " +
                            result.selectedBookingOption?.departureDisplayValue +
                            " " +
                            result.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                        }
                        lodging={result.lodging}
                        translations={lodgingCardTranslations}
                        favouritesTranslations={favouritesTranslations}
                        extraFacilities={extraFacilities}
                        nextUrl={lodgingUrlBuilder(result)}
                        selectedBookingOption={result.selectedBookingOption}
                        isFavorite={favorites.includes(result.lodging.id)}
                        onSetFavorite={setFavorite}
                        showCleaningBanner={result.selectedBookingOption.cleaningIncludedInPriceWithMandatoryItems}
                        showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                        showDepositSeparately={showDepositSeparately}
                        showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                        discountBanner={discountBanner}
                        cleaningBanner={cleaningBanner}
                        hideAddress={hideLodgingAddress}
                        showItems={showItems}
                        itemsToShow={itemsToShow}
                        maxItemsToShow={maxItemsToShow}
                    />
                ))}
            </div>
        </div>
    );
}
