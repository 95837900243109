import React from "react";
import "./TextAreaWithLabel.scss";

export interface TextAreaWithLabelProps {
    label: string;
    value: string;
    onChange: (newValue) => void;
    required?: boolean;
}

export default function TextAreaWithLabel({
    label,
    value,
    onChange,
    required,
}: TextAreaWithLabelProps) {
    const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange(e.currentTarget.value);
        }
    };

    return (
        <div className="bwp-textarea-with-label">
            <label>
                <span>{label}</span>
                <textarea
                    className="bwp-textarea-with-label__value-container"
                    required={required}
                    value={value}
                    onChange={handleOnChange}
                >
                    {value}
                </textarea>
            </label>
        </div>
    );
}
