import React, {useCallback} from "react";
import {SearchContext} from "@bwp/shared/searchcontext";

export default function withSearchBoxWrapper(WrappedComponent) {
    return function SearchBoxComponentWrapper(props) {
        const urlReader = useCallback((location: Location) => {
            // So when we are server side rendering we don't have a location. So we will look for the special fallback url prop and use that
            if (location === null) {
                if (props.searchBox.fallbackUrl) {
                    const tokens = props.searchBox.fallbackUrl.split("?");
                    if (tokens.length > 1) {
                        const search = props.searchBox.fallbackUrl.split("?")[1];
                        return SearchContext.createFromQueryString(search);
                    } else {
                        return new SearchContext();
                    }
                } else {
                    return new SearchContext();
                }
            } else {
                return SearchContext.createFromQueryString(location.search);
            }
        }, []);

        props.searchBox.urlReader = urlReader;
        props.searchBox.onSubmit = (value: SearchContext) => {
            window.location.href = props.searchBox.searchUrl + "?" + value.toQueryString();
        };

        return <WrappedComponent {...props} />;
    };
}
