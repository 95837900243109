import React from "react";

export function getImageUrl(image: { url: string }, width: number, height?: number) {
    let url = image.url.replace("/default", "");
    url = url.replace("/cl/", "/v2/");
    url = url + "?width=" + width;
    if (height) {
        url = url + "&height=" + height;
    }
    url = url + "&mode=crop";
    return url;
}

export function createImg(src: string | (() => string), options: { width?: string, height?: string, alt?: string }) {
    const imgSrc = typeof src === "function"
        ? src()
        : src;

    return <img src={imgSrc} {...options} />;
}
