import React, { useEffect, useState } from "react";
import "./Spinner.scss";

interface Props {
    showDelay?: number;
}

export default function Spinner({ showDelay = 1000 }: Props) {
    const [show, setShow] = useState(showDelay == 0);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(true), showDelay);

        return () => {
            clearTimeout(timeout);
        };
    }, [showDelay]);

    if (show) {
        return (
            <div className="loadingio-spinner-spinner-zdpqxc6sfwd">
                <div className="ldio-w43l78isysm">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
