import React from "react";
import "./Form.scss";
import CheckBoxWithLabel from "./CheckBoxWithLabel";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";

export interface NewsletterProps {
    id: number;
    name: string;
}

export interface CommunicationFormProps {
    catalogue: boolean;
    newsletters: NewsletterProps[];
    onCatalogueChange: (newValue: boolean) => void;
    onNewslettersChange: (newsletters: number[]) => void;
    translations: CheckoutTranslations;
    selectedNewsletters: number[];
    catalogueLabel: string;
}

export default function CommunicationForm({
    catalogue,
    newsletters,
    onCatalogueChange,
    onNewslettersChange,
    translations,
    selectedNewsletters,
    catalogueLabel,
}: CommunicationFormProps) {
    const handlerForNewslettersChange = (id: number) => {
        return (newValue: boolean) => {
            if (newValue == true) {
                onNewslettersChange([...selectedNewsletters, id]);
            } else {
                onNewslettersChange(selectedNewsletters.filter((i) => i != id));
            }
        };
    };

    if (!newsletters?.length && !catalogueLabel) {
        return <div data-no-catalogues-or-newsletters={true} />;
    }

    return (
        <section className="bwp-form-section">
            <h2>{translations.communicationFormTitle}</h2>
            {catalogueLabel && (
                <div className="bwp-form-section__group">
                    <p className="explanation">{translations.catalogueDescription}</p>
                    <CheckBoxWithLabel
                        label={catalogueLabel}
                        value={catalogue}
                        onChange={onCatalogueChange}
                    />
                </div>
            )}
            {newsletters && newsletters.length > 0 && (
                <div className="bwp-form-section__group">
                    <p className="explanation">{translations.newslettersDescription}</p>
                    <div className="bwp-form-section__list">
                        {newsletters.map((newsletter) => (
                            <CheckBoxWithLabel
                                key={`newsletter${newsletter.id}`}
                                label={newsletter.name}
                                onChange={handlerForNewslettersChange(newsletter.id)}
                                value={selectedNewsletters.includes(newsletter.id)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
}
