import React from "react";

interface Props {
    favourite: boolean;
    onClick?: () => void;
}

export default function HeartIcon({ favourite, onClick }: Props) {
    return (
        <div onClick={onClick}>
            {!favourite && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height={23}
                    viewBox="0 0 27 23"
                    fill="none"
                >
                    <path
                        d="M1.50411 7.78749C1.8454 12.1866 13.7905 22 13.7905 22C13.7905 22 26.4181 11.5098 26.0768 7.78749C25.7355 4.06519 24.7117 1.69641 19.9336 1.01964C16.1795 1.35801 15.9952 1.97453 13.7905 3.72678C11.2056 1.45456 9.86336 0.880013 7.64729 1.01964C3.63024 2.14323 1.16282 3.3884 1.50411 7.78749Z"
                        stroke="#555"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
            {favourite && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="23"
                    viewBox="0 0 27 23"
                    fill="none"
                    className="bwp-is-favourite"
                >
                    <path
                        d="M1.50411 7.78749C1.8454 12.1866 13.7905 22 13.7905 22C13.7905 22 26.4181 11.5098 26.0768 7.78749C25.7355 4.06519 24.7117 1.69641 19.9336 1.01964C16.1795 1.35801 15.9952 1.97453 13.7905 3.72678C11.2056 1.45456 9.86336 0.880013 7.64729 1.01964C3.63024 2.14323 1.16282 3.3884 1.50411 7.78749Z"
                        fill="#ED303A"
                        stroke="#8C3439"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </div>
    );
}
