import {useCallback, useState} from "react";
import {startOfDay} from "date-fns";
import {SearchContext} from "./searchcontext";
import {useLodgingCalendarDataQuery} from "../operations.generated";
import {DatePickerCalendarDay} from "../DatePickerCalendar";

export default function useCalendarDayManipulator({ searchContext }: { searchContext: SearchContext }) {
    let [monthAndYear, setMonthAndYear] = useState<{ month: number; year: number }>({
        month: searchContext.arrival.getMonth() + 1,
        year: searchContext.arrival.getFullYear(),
    });

    let numberOfMonths = 4;
    let { data, loading, error } = useLodgingCalendarDataQuery({
        variables: {
            month: monthAndYear.month,
            year: monthAndYear.year,
            numberOfMonths: numberOfMonths,
            query: searchContext.toQueryString(),
        },
    });

    let days = data?.calendar?.days;

    return useCallback(
        (
            currentYear: number,
            currentMonth: number,
            day: DatePickerCalendarDay,
            dayManipulatorLastReturnValue: boolean
        ) => {
            if (loading || error) {
                return dayManipulatorLastReturnValue;
            }

            if (day.parsedDate < startOfDay(new Date())) {
                day.classNames.push("bwp-calendar__cell-past-date");
                return dayManipulatorLastReturnValue;
            }

            let dayData = days?.find((d) => d.date == day.date);

            if (dayData == null) {
                if (dayManipulatorLastReturnValue !== false) {
                    // We need to make sure we will only call the event once - so we use the return value as a marker boolean
                    setMonthAndYear({ month: currentMonth, year: currentYear });
                }
                return false;
            }

            if (dayData.isChangeDay && dayData.isAvailable) {
                day.classNames.push("bwp-calendar__cell-arrival-date");
            }
            
            if (!dayData.isAvailable) {
                day.selectable = false;
                day.unSelectableReason = "Unavailable";
                day.classNames.push("bwp-calendar__cell-unavailable");
            } else {
                day.classNames.push("bwp-calendar__cell-available");
                if (dayData.selectedBookingOption) {
                    
                    day.suggestionRange = {
                        from: dayData.selectedBookingOption.arrival,
                        to: dayData.selectedBookingOption.departure,
                    };
                    day.overrideValue = dayData.selectedBookingOption.arrival;
                    day.title = dayData.selectedBookingOption.priceDisplayValue;
                }
            }

            return dayManipulatorLastReturnValue;
        },
        [days, error, loading]
    );
}
