// This function takes a component...
import React, {useEffect, useState} from "react";
import {isBrowser} from "@bwp/shared/helpers";
import {ApolloProvider} from "@apollo/client";

export default function onlyClientWrapper(WrappedComponent, client) {
    return function OnlyClientWrapper(props) {
        let [mounted, setMounted] = useState(false);

        useEffect(() => {
            if (isBrowser()) {
                setMounted(true);
            }
        }, []);

        if (!mounted) {
            return null;
        }

        return (
            <ApolloProvider client={client}>
                <WrappedComponent {...props} />
            </ApolloProvider>
        );
    };
}
