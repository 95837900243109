import React from "react";
import HeartIcon from "./HeartIcon";
import "./FavoriteButton.scss";

interface Props {
    text: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

export default function FavoriteButton({ text, value, onChange }: Props) {
    return (
        <div
            className="bwp-favorite-button"
            onClick={() => {
                onChange(!value);
            }}
        >
            <span>{text}</span> <HeartIcon favourite={value} />
        </div>
    );
}
