import { SearchContext, SearchContextProps } from "./searchcontext";
import { useCallback, useEffect, useState } from "react";
import {isBrowser, isValidDate} from "./helpers";
import * as queryString from "querystring";
import { LodgingListSetting } from "./types";
import { lodgingListSettingToSearchContext } from "./lodgingListUtils";

function getSearchFromGlobal() {
    let tokens = globalUrl.split("?");
    if (tokens.length > 1) {
        return globalUrl.split("?")[1];
    } else {
        return "";
    }
}

function getSearchFromLocal() {
    let url = window["BookingStudio_originalUrl"] || window.location.search;
    let tokens = url.split("?");
    if (tokens.length > 1) {
        return url.split("?")[1];
    } else {
        return "";
    }
}

function generateDefaultSearchContext(
    presetValues: SearchContextProps,
    defaultValue: SearchContextProps,
    lodgingListSetting: LodgingListSetting
) {
    if (lodgingListSetting != null) {
        const presetContext = presetValues
            ? SearchContext.createFromSearchContextProps(presetValues).merge(lodgingListSettingToSearchContext(lodgingListSetting))
            : lodgingListSettingToSearchContext(lodgingListSetting);
        
        return isBrowser()
            ? SearchContext.createFromQueryString(getSearchFromLocal()).usePreset(presetContext)
            : SearchContext.createFromQueryString(getSearchFromGlobal()).usePreset(presetContext);
    }

    const result = isBrowser()
        ? SearchContext.createFromQueryStringWithPresetsAndDefaults(getSearchFromLocal(), presetValues, defaultValue)
        : SearchContext.createFromQueryStringWithPresetsAndDefaults(getSearchFromGlobal(), presetValues, defaultValue);
    
    return result;
}

/**
 * A hook that supplies access to a SearchContext instance.
 */
export default function useGlobalSearchContext({
    presets,
    defaultValues,
    lodgingListSetting,
}: {
    presets?: SearchContextProps;
    defaultValues?: SearchContextProps;
    lodgingListSetting?: LodgingListSetting;
}) {
    let [searchContext, setSearchContext] = useState(
        generateDefaultSearchContext(presets, (defaultValues || {}), lodgingListSetting)
    );

    useEffect(() => {
        if (isBrowser()) {
            const listener = () => {
                const newSearchContext = SearchContext.createFromQueryStringWithPresetsAndDefaults(
                    window.location.search,
                    presets,
                    defaultValues
                );
                setSearchContext(newSearchContext);
            };
            const passiveListener = (e: { detail: SearchContext }) => {
                if (e.detail != searchContext) {
                    setSearchContext(e.detail);
                    replaceState(e.detail);
                }
            };
            const updatedListener = (e: { detail: SearchContext }) => {
                if (e.detail != searchContext) {
                    setSearchContext(e.detail);
                }
            };

            window.addEventListener("popstate", listener);
            window.addEventListener("passiveSearchContextUpdated", passiveListener as any);
            window.addEventListener("searchContextUpdated", updatedListener as any);

            return () => {
                window.removeEventListener("popstate", listener);
                window.addEventListener("passiveSearchContextUpdated", passiveListener as any);
                window.addEventListener("searchContextUpdated", updatedListener as any);
            };
        }
    }, [defaultValues, presets, searchContext]);

    useEffect(() => {
        window.dispatchEvent(new CustomEvent("searchContextUpdated", { detail: searchContext }));
    }, [searchContext]);

    const handleSetSearchContext = useCallback((searchContext: SearchContext) => {
        if (isBrowser()) {
            setSearchContext(searchContext);
            replaceState(searchContext);
        } else {
            throw "Global Search Context does not supporting setting the value outside of a browser context";
        }
    }, []);

    return [searchContext, handleSetSearchContext] as [
        SearchContext,
        (searchContext: SearchContext) => void
    ];
}

export function replaceState(searchContext: SearchContext) {
    let queryStringValuesToKeep = {};
    if (window.location.search.startsWith("?")) {
        let qs = queryString.parse(window.location.search.substring(1));

        Object.keys(qs).forEach((key) => {
            if (!SearchContext.isSearchContextQueryStringKeyName(key)) {
                queryStringValuesToKeep[key] = qs[key];
            }
        });
    }

    let searchContextQueryString = searchContext.toQueryString(
        [],
        true
    );
    let parsedSearchContextQueryString = queryString.parse(searchContextQueryString);
    Object.keys(parsedSearchContextQueryString).forEach((key) => {
        queryStringValuesToKeep[key] = parsedSearchContextQueryString[key];
    });

    let url = window.location.href.split("?")[0];

    let qs = queryString.stringify(queryStringValuesToKeep);

    if (qs != "") {
        url = "?" + qs;
    }

    window.history.replaceState(null, null, url);
}
