import React from "react";
import "./Form.scss";
import TextAreaWithLabel from "./TextAreaWithLabel";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";

export interface NotesFormProps {
    value: string;
    onChange: (newValue: string) => void;
    translations: CheckoutTranslations;
}

export default function NotesForm({ value, onChange, translations }: NotesFormProps) {
    return (
        <section className="bwp-form-section">
            <h2>{translations.notesFormTitle}</h2>
            <TextAreaWithLabel
                label={translations.notes}
                value={value}
                onChange={(newValue) => onChange(newValue)}
            />
        </section>
    );
}
