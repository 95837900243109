import React from "react";

export function CloseIcon() {
    return (
        <svg
            width={26}
            height={25}
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="3.12132"
                y1={3}
                x2={23}
                y2="22.8787"
                stroke="#2F4A7A"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="1.5"
                y1="-1.5"
                x2="29.6127"
                y2="-1.5"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 25 3)"
                stroke="#2F4A7A"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}