import React from "react";
import "./SelectFilterBox.scss";

interface Props {
    children?: React.ReactNode;
    value: HTMLSelectElement["value"];
    onChange: (e: React.SyntheticEvent<HTMLSelectElement>) => void;
}

export default function SelectFilterBox({ children, value, onChange }: Props) {
    return (
        <select className="bwp-select-filter-box" value={value} onChange={onChange}>
            {children}
        </select>
    );
}
