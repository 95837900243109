import React from "react";
import "./LocationReviews.scss";
import { LocationReviewsFragment } from "./operations.generated";
import { CommentIcon } from "./shared/icons";

interface Props {
    reviews: LocationReviewsFragment["reviews"];
}

export default function LocationReviews({ reviews }: Props) {
    return (
        <div className="bwp-area-reviews">
            {reviews.map((review, index) => (
                <div key={index}>
                    <CommentIcon />
                    <p>{review.reviewOfAreaText}</p>
                </div>
            ))}
        </div>
    );
}
