import * as Types from "./shared/graphql-types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type SearchResultsPageQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
    onlyFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    onlyMasterFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    displayDate: Types.Scalars["Date"];
    sorting?: Types.InputMaybe<Types.SearchSorting>;
}>;

export type SearchResultsPageQuery = {
    __typename?: "Query";
    search?: {
        __typename?: "SearchResult";
        numberOfResults: number;
        results?: Array<{
            __typename?: "SearchResultLodging";
            lodging: {
                __typename?: "Lodging";
                id: number;
                name: string;
                nameSlug: string;
                address1: string;
                address1Slug: string;
                latitude: string;
                longitude: string;
                maxPeople?: number | null;
                petsAllowed?: boolean | null;
                stars?: number | null;
                starsPostText: string;
                activeDescription?: {
                    __typename?: "LodgingDescription";
                    title?: string | null;
                    summary?: string | null;
                } | null;
                images: Array<{ __typename?: "Image"; url: string }>;
                location: { __typename?: "Location"; id: number; name: string; slug: string };
                facilityValues: Array<{
                    __typename?: "FacilityValue";
                    facilityId: number;
                    masterFacilityId?: number | null;
                    displayValue: string;
                    displayValueAndName: string;
                    value: string;
                    facility: { __typename?: "Facility"; name: string };
                }>;
            };
            selectedBookingOption: {
                __typename?: "BookingOption";
                status: string;
                currency: string;
                price: any;
                priceDisplayValue: string;
                priceWithMandatoryItems: any;
                priceWithMandatoryItemsDisplayValue: string;
                priceWithMandatoryItemsWithoutDeposits: any;
                priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                arrivalDisplayValue: string;
                departureDisplayValue: string;
                normalPrice: any;
                normalPriceDisplayValue: string;
                normalPriceWithMandatoryItems: any;
                normalPriceWithMandatoryItemsDisplayValue: string;
                normalPriceWithMandatoryItemsWithoutDeposits: any;
                normalPriceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                duration: number;
                adults: number;
                children: number;
                infants: number;
                pets: number;
                discountName?: string | null;
                hasDiscount: boolean;
                arrival?: any | null;
                cleaningIncludedInPriceWithMandatoryItems: boolean;
                arrivalWeekDayName: string;
                itemPrices?: Array<{
                    __typename?: "ItemPrice";
                    isMandatory: boolean;
                    item: {
                        __typename?: "Item";
                        id: number;
                        itemTypeId: number;
                        name: string;
                        sortIndex: number;
                    };
                } | null> | null;
            };
        } | null> | null;
    } | null;
};

export type LastMinuteQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
    onlyFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    onlyMasterFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    displayDate: Types.Scalars["Date"];
    sorting?: Types.InputMaybe<Types.SearchSorting>;
    daysAhead?: Types.InputMaybe<Types.Scalars["Int"]>;
    tagFilter?: Types.InputMaybe<Types.Scalars["String"]>;
    take?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type LastMinuteQuery = {
    __typename?: "Query";
    lastMinute?: {
        __typename?: "SearchResult";
        numberOfResults: number;
        results?: Array<{
            __typename?: "SearchResultLodging";
            lodging: {
                __typename?: "Lodging";
                id: number;
                name: string;
                nameSlug: string;
                address1: string;
                address1Slug: string;
                latitude: string;
                longitude: string;
                maxPeople?: number | null;
                petsAllowed?: boolean | null;
                stars?: number | null;
                starsPostText: string;
                activeDescription?: {
                    __typename?: "LodgingDescription";
                    title?: string | null;
                    summary?: string | null;
                } | null;
                images: Array<{ __typename?: "Image"; url: string }>;
                location: { __typename?: "Location"; id: number; name: string; slug: string };
                facilityValues: Array<{
                    __typename?: "FacilityValue";
                    facilityId: number;
                    masterFacilityId?: number | null;
                    displayValue: string;
                    displayValueAndName: string;
                    value: string;
                    facility: { __typename?: "Facility"; name: string };
                }>;
            };
            selectedBookingOption: {
                __typename?: "BookingOption";
                status: string;
                currency: string;
                price: any;
                priceDisplayValue: string;
                priceWithMandatoryItems: any;
                priceWithMandatoryItemsDisplayValue: string;
                priceWithMandatoryItemsWithoutDeposits: any;
                priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                arrivalDisplayValue: string;
                departureDisplayValue: string;
                normalPrice: any;
                normalPriceDisplayValue: string;
                normalPriceWithMandatoryItems: any;
                normalPriceWithMandatoryItemsDisplayValue: string;
                normalPriceWithMandatoryItemsWithoutDeposits: any;
                normalPriceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                duration: number;
                adults: number;
                children: number;
                infants: number;
                pets: number;
                discountName?: string | null;
                hasDiscount: boolean;
                arrival?: any | null;
                cleaningIncludedInPriceWithMandatoryItems: boolean;
                arrivalWeekDayName: string;
                itemPrices?: Array<{
                    __typename?: "ItemPrice";
                    isMandatory: boolean;
                    item: {
                        __typename?: "Item";
                        id: number;
                        itemTypeId: number;
                        name: string;
                        sortIndex: number;
                    };
                } | null> | null;
            };
        } | null> | null;
    } | null;
};

export type LodgingListQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
    year: Types.Scalars["Int"];
    onlyFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    onlyMasterFacilities?: Types.InputMaybe<Array<Types.Scalars["Int"]> | Types.Scalars["Int"]>;
    displayDate: Types.Scalars["Date"];
    sorting?: Types.InputMaybe<Types.SearchSorting>;
}>;

export type LodgingListQuery = {
    __typename?: "Query";
    lodgingList?: {
        __typename?: "LodgingListView";
        results?: Array<{
            __typename?: "ExtendedLodging";
            lodging: {
                __typename?: "Lodging";
                id: number;
                latitude: string;
                longitude: string;
                name: string;
                nameSlug: string;
                address1: string;
                address1Slug: string;
                maxPeople?: number | null;
                petsAllowed?: boolean | null;
                stars?: number | null;
                starsPostText: string;
                activeDescription?: {
                    __typename?: "LodgingDescription";
                    title?: string | null;
                } | null;
                images: Array<{ __typename?: "Image"; url: string }>;
                location: { __typename?: "Location"; id: number; name: string; slug: string };
                facilityValues: Array<{
                    __typename?: "FacilityValue";
                    facilityId: number;
                    masterFacilityId?: number | null;
                    displayValue: string;
                    displayValueAndName: string;
                    value: string;
                    facility: { __typename?: "Facility"; name: string };
                }>;
            };
            selectedBookingOption?: {
                __typename?: "BookingOption";
                currency: string;
                price: any;
                priceDisplayValue: string;
                priceWithMandatoryItems: any;
                priceWithMandatoryItemsDisplayValue: string;
                priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                arrivalDisplayValue: string;
                departureDisplayValue: string;
                normalPrice: any;
                normalPriceDisplayValue: string;
                normalPriceWithMandatoryItems: any;
                normalPriceWithMandatoryItemsDisplayValue: string;
                normalPriceWithMandatoryItemsWithoutDeposits: any;
                normalPriceWithMandatoryItemsWithoutDepositsDisplayValue: string;
                discountName?: string | null;
                hasDiscount: boolean;
                cleaningIncludedInPriceWithMandatoryItems: boolean;
                status: string;
                arrivalWeekDayName: string;
                itemPrices?: Array<{
                    __typename?: "ItemPrice";
                    isMandatory: boolean;
                    item: {
                        __typename?: "Item";
                        id: number;
                        itemTypeId: number;
                        name: string;
                        sortIndex: number;
                    };
                } | null> | null;
            } | null;
            seasonPrices: Array<{
                __typename?: "SeasonPrice";
                price: any;
                currency: string;
                seasonCode: string;
                weeklyPriceDisplayValue: string;
            }>;
        } | null> | null;
    } | null;
};

export type LodgingPresentationQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
    seasonPricesYear: Types.Scalars["Int"];
    displayDate: Types.Scalars["Date"];
    numberOfReviews: Types.Scalars["Int"];
}>;

export type LodgingPresentationQuery = {
    __typename?: "Query";
    lodgingPresentation?: {
        __typename?: "LodgingPresentationView";
        lodging?: {
            __typename?: "Lodging";
            id: number;
            name: string;
            maxPeople?: number | null;
            petsAllowed?: boolean | null;
            maxPets?: number | null;
            latitude: string;
            longitude: string;
            stars?: number | null;
            starsPostText: string;
            activeDescription?: {
                __typename?: "LodgingDescription";
                title?: string | null;
                body?: string | null;
                longHtmlDescription?: string | null;
                embeddedVideoHtml?: string | null;
            } | null;
            location: {
                __typename?: "Location";
                id: number;
                name: string;
                shortDescription: string;
                reviews: Array<{ __typename?: "CustomerReview"; reviewOfAreaText: string }>;
            };
            facilityValues: Array<{
                __typename?: "FacilityValue";
                facilityId: number;
                masterFacilityId?: number | null;
                displayValue: string;
                displayValueAndName: string;
                value: string;
                facility: { __typename?: "Facility"; name: string };
            }>;
            images: Array<{
                __typename?: "Image";
                url: string;
                tagIds: Array<number>;
                originalHeight: number;
                originalWidth: number;
            }>;
            reviews: Array<{
                __typename?: "CustomerReview";
                reviewerName: string;
                reviewOfLodgingText: string;
                month: string;
            }>;
        } | null;
        selectedBookingOption?: {
            __typename?: "BookingOption";
            arrivalDisplayValue: string;
            departureDisplayValue: string;
            normalPriceDisplayValue: string;
            priceWithMandatoryItemsDisplayValue: string;
            priceWithMandatoryItems: any;
            priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
            priceDepositItems?: any | null;
            priceDepositItemsDisplayValue?: string | null;
            currency: string;
            discountName?: string | null;
            hasDiscount: boolean;
            discountReductionDisplayValue: string;
            status: string;
            itemPrices?: Array<{
                __typename?: "ItemPrice";
                isMandatory: boolean;
                priceWithQuantityDisplayValue: string;
                item: { __typename?: "Item"; name: string; itemType?: Types.ItemTypes | null };
            } | null> | null;
        } | null;
    } | null;
};

export type LodgingBookingOptionsQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
}>;

export type LodgingBookingOptionsQuery = {
    __typename?: "Query";
    lodgingBookingOptions?: {
        __typename?: "LodgingBookingOptions";
        lodging: {
            __typename?: "Lodging";
            id: number;
            name: string;
            nameSlug: string;
            internalName: string;
            address1: string;
            address1Slug: string;
            location: { __typename?: "Location"; id: number; name: string; slug: string };
            images: Array<{ __typename?: "Image"; url: string }>;
        };
        bookingOptions: Array<{
            __typename?: "BookingOption";
            status: string;
            priceWithMandatoryItems: any;
            priceWithMandatoryItemsDisplayValue: string;
            priceWithMandatoryItemsWithoutDeposits: any;
            priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
            arrivalDisplayValue: string;
            departureDisplayValue: string;
            normalPriceWithMandatoryItems: any;
            duration: number;
            adults: number;
            children: number;
            infants: number;
            pets: number;
            discountName?: string | null;
            hasDiscount: boolean;
            arrival?: any | null;
            cleaningIncludedInPriceWithMandatoryItems: boolean;
            arrivalWeekDayName: string;
        }>;
    } | null;
};

export type LodgingsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LodgingsQuery = {
    __typename?: "Query";
    lodgings?: Array<{
        __typename?: "Lodging";
        id: number;
        name: string;
        nameSlug: string;
        internalName: string;
        address1: string;
        address1Slug: string;
        location: { __typename?: "Location"; id: number; name: string; slug: string };
    } | null> | null;
};

export type LodgingWithImagesFragment = {
    __typename?: "Lodging";
    id: number;
    name: string;
    nameSlug: string;
    internalName: string;
    address1: string;
    address1Slug: string;
    location: { __typename?: "Location"; id: number; name: string; slug: string };
    images: Array<{ __typename?: "Image"; url: string }>;
};

export type DisplayBookingOptionFragment = {
    __typename?: "BookingOption";
    status: string;
    priceWithMandatoryItems: any;
    priceWithMandatoryItemsDisplayValue: string;
    priceWithMandatoryItemsWithoutDeposits: any;
    priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
    arrivalDisplayValue: string;
    departureDisplayValue: string;
    normalPriceWithMandatoryItems: any;
    duration: number;
    adults: number;
    children: number;
    infants: number;
    pets: number;
    discountName?: string | null;
    hasDiscount: boolean;
    arrival?: any | null;
    cleaningIncludedInPriceWithMandatoryItems: boolean;
    arrivalWeekDayName: string;
};

export type LodgingFacilitySummaryFragment = {
    __typename?: "Lodging";
    maxPeople?: number | null;
    maxPets?: number | null;
    petsAllowed?: boolean | null;
    bedroomFacility?: { __typename?: "FacilityValue"; displayValueAndName: string } | null;
    internetFacility?: {
        __typename?: "FacilityValue";
        displayValue: string;
        facility: { __typename?: "Facility"; name: string };
    } | null;
    bathroomFacility?: { __typename?: "FacilityValue"; displayValueAndName: string } | null;
    lodgingAreaFacility?: {
        __typename?: "FacilityValue";
        displayValue: string;
        facility: { __typename?: "Facility"; name: string };
    } | null;
};

export type BookingSummaryLodgingFragment = {
    __typename?: "Lodging";
    id: number;
    name: string;
    address1: string;
    location: { __typename?: "Location"; id: number; name: string };
    primaryImage?: { __typename?: "Image"; url: string } | null;
};

export type BookingSummaryBookingOptionFragment = {
    __typename?: "BookingOption";
    status: string;
    arrivalDisplayValue: string;
    departureDisplayValue: string;
    adults: number;
    children: number;
    infants: number;
    pets: number;
};

export type ItemsSummaryLodgingFragment = { __typename?: "Lodging"; name: string };

export type ItemsSummaryCheckoutPresentationFragment = {
    __typename?: "CheckoutPresentationView";
    totalPriceDisplayValue?: string | null;
    discountReductionDisplayValue?: string | null;
    hasDiscount: boolean;
    discountName?: string | null;
    normalBookingPriceDisplayValue?: string | null;
    itemLines?: Array<{
        __typename?: "CheckoutPresentationViewItemLine";
        quantity: number;
        totalPrice?: any | null;
        totalPriceDisplayValue: string;
        showInSummary?: boolean | null;
        itemPrice: { __typename?: "ItemPrice"; unitPrice: any; maxQuantity: number };
        item: { __typename?: "Item"; id: number; name: string };
    }> | null;
};

export type ItemSelectorCheckoutPresentationFragment = {
    __typename?: "CheckoutPresentationView";
    itemLinesTotalDisplayValue?: string | null;
    totalPriceDisplayValue?: string | null;
    itemLines?: Array<{
        __typename?: "CheckoutPresentationViewItemLine";
        quantity: number;
        totalPriceDisplayValue: string;
        item: {
            __typename?: "Item";
            id: number;
            name: string;
            shortDescription: string;
            description?: string | null;
        };
        itemPrice: {
            __typename?: "ItemPrice";
            minQuantity: number;
            maxQuantity: number;
            unitPriceDisplayValue: string;
            isMandatory: boolean;
        };
    }> | null;
};

export type CheckoutTrackingPresentationFragment = {
    __typename?: "CheckoutPresentationView";
    totalPrice?: any | null;
    bookingPrice: any;
    discountReduction?: any | null;
    currency: string;
    itemLines?: Array<{
        __typename?: "CheckoutPresentationViewItemLine";
        quantity: number;
        item: { __typename?: "Item"; id: number; name: string };
        itemPrice: { __typename?: "ItemPrice"; unitPrice: any };
    }> | null;
};

export type CheckoutQueryVariables = Types.Exact<{
    request: Types.BookingRequestInput;
    displayDate: Types.Scalars["Date"];
    bedroomFacilityId: Types.Scalars["Int"];
    internetFacilityId: Types.Scalars["Int"];
    bathroomFacilityId: Types.Scalars["Int"];
    houseAreaFacilityId: Types.Scalars["Int"];
}>;

export type CheckoutQuery = {
    __typename?: "Query";
    lodgingPresentation?: {
        __typename?: "LodgingPresentationView";
        lodging?: {
            __typename?: "Lodging";
            id: number;
            name: string;
            address1: string;
            maxPeople?: number | null;
            maxPets?: number | null;
            petsAllowed?: boolean | null;
            location: { __typename?: "Location"; id: number; name: string };
            primaryImage?: { __typename?: "Image"; url: string } | null;
            bedroomFacility?: { __typename?: "FacilityValue"; displayValueAndName: string } | null;
            internetFacility?: {
                __typename?: "FacilityValue";
                displayValue: string;
                facility: { __typename?: "Facility"; name: string };
            } | null;
            bathroomFacility?: { __typename?: "FacilityValue"; displayValueAndName: string } | null;
            lodgingAreaFacility?: {
                __typename?: "FacilityValue";
                displayValue: string;
                facility: { __typename?: "Facility"; name: string };
            } | null;
        } | null;
        selectedBookingOption?: {
            __typename?: "BookingOption";
            status: string;
            arrivalDisplayValue: string;
            departureDisplayValue: string;
            adults: number;
            children: number;
            infants: number;
            pets: number;
        } | null;
    } | null;
    checkoutPresentation?: {
        __typename?: "CheckoutPresentationView";
        available: boolean;
        totalPriceDisplayValue?: string | null;
        discountReductionDisplayValue?: string | null;
        hasDiscount: boolean;
        discountName?: string | null;
        normalBookingPriceDisplayValue?: string | null;
        itemLinesTotalDisplayValue?: string | null;
        totalPrice?: any | null;
        bookingPrice: any;
        discountReduction?: any | null;
        currency: string;
        itemLines?: Array<{
            __typename?: "CheckoutPresentationViewItemLine";
            quantity: number;
            totalPrice?: any | null;
            totalPriceDisplayValue: string;
            showInSummary?: boolean | null;
            itemPrice: {
                __typename?: "ItemPrice";
                unitPrice: any;
                maxQuantity: number;
                minQuantity: number;
                unitPriceDisplayValue: string;
                isMandatory: boolean;
            };
            item: {
                __typename?: "Item";
                id: number;
                name: string;
                shortDescription: string;
                description?: string | null;
            };
        }> | null;
    } | null;
    countries: Array<{ __typename?: "Country"; id: string; name: string }>;
    newsletters: Array<{ __typename?: "Newsletter"; id: number; name: string }>;
};

export type AvailabilityCalendarsQueryVariables = Types.Exact<{
    month: Types.Scalars["Int"];
    year: Types.Scalars["Int"];
    displayDate: Types.Scalars["Date"];
}>;

export type AvailabilityCalendarsQuery = {
    __typename?: "Query";
    availabilityCalendars: Array<{
        __typename?: "AvailabilityCalendar";
        days: Array<{
            __typename?: "AvailabilityCalendarDay";
            date?: any | null;
            isAvailable: boolean;
            seasonCode?: string | null;
            hasCalendarData: boolean;
            freeNightsAhead: number;
        }>;
        lodging: {
            __typename?: "Lodging";
            id: number;
            name: string;
            nameSlug: string;
            internalName: string;
            address1: string;
            address1Slug: string;
            maxPeople?: number | null;
            maxPets?: number | null;
            petsAllowed?: boolean | null;
            location: { __typename?: "Location"; name: string; slug: string };
        };
    }>;
};

export type AvailabilityCalendarLodgingFragment = {
    __typename?: "AvailabilityCalendar";
    lodging: {
        __typename?: "Lodging";
        id: number;
        name: string;
        nameSlug: string;
        internalName: string;
        address1: string;
        address1Slug: string;
        maxPeople?: number | null;
        maxPets?: number | null;
        petsAllowed?: boolean | null;
        location: { __typename?: "Location"; name: string; slug: string };
    };
};

export type PayRatesQueryVariables = Types.Exact<{
    request: Types.BookingRequestInput;
}>;

export type PayRatesQuery = {
    __typename?: "Query";
    payRates?: {
        __typename?: "PayRates";
        rate1?: {
            __typename?: "PayRate";
            payDate?: any | null;
            amountDisplayValue: string;
            payDateDisplayValue: string;
        } | null;
        rate2?: {
            __typename?: "PayRate";
            amountDisplayValue: string;
            payDateDisplayValue: string;
        } | null;
    } | null;
};

export type TermsQueryVariables = Types.Exact<{
    lodgingId: Types.Scalars["Int"];
    arrivalDate: Types.Scalars["DateTime"];
}>;

export type TermsQuery = {
    __typename?: "Query";
    terms?: { __typename?: "TermsAndConditions"; description: string } | null;
};

export type BookingQueryVariables = Types.Exact<{
    basketId: Types.Scalars["String"];
    dateFormat: Types.Scalars["String"];
}>;

export type BookingQuery = {
    __typename?: "Query";
    booking?: {
        __typename?: "Booking";
        reservationId?: number | null;
        totalPrice?: any | null;
        totalPriceDisplayValue?: string | null;
        bookingsTotalPrice?: any | null;
        bookingsTotalPriceDisplayValue?: string | null;
        bookingsTotalDiscount?: any | null;
        bookingsTotalDiscountDisplayValue?: string | null;
        currency: string;
        balance?: any | null;
        balanceDisplayValue?: string | null;
        externalNote?: string | null;
        payment?: {
            __typename?: "Payment";
            amount: any;
            currency: string;
            fee: any;
            transact: string;
            status?: Types.PaymentStatus | null;
        } | null;
        bookingLines: Array<{
            __typename?: "BookingLine";
            lodgingId?: number | null;
            lodgingUnitTypeId?: number | null;
            arrivalDate?: any | null;
            duration?: number | null;
            adults?: number | null;
            children?: number | null;
            infants?: number | null;
            pets: number;
            calculatedLineTotal?: any | null;
            description: string;
            lodging?: {
                __typename?: "Lodging";
                name: string;
                location: { __typename?: "Location"; id: number; name: string };
            } | null;
        }>;
        itemLines?: Array<{
            __typename?: "ItemLine";
            itemId?: number | null;
            quantity?: any | null;
            calculatedUnitPrice?: any | null;
            calculatedLineTotal?: any | null;
            description: string;
        } | null> | null;
        payRates: {
            __typename?: "PayRates";
            rate1?: {
                __typename?: "PayRate";
                amount: any;
                amountDisplayValue: string;
                currency: string;
                payDate?: any | null;
                payDateDisplayValue: string;
            } | null;
            rate2?: {
                __typename?: "PayRate";
                amount: any;
                amountDisplayValue: string;
                currency: string;
                payDate?: any | null;
                payDateDisplayValue: string;
            } | null;
        };
        customer?: {
            __typename?: "Customer";
            email?: string | null;
            postalCode?: string | null;
            city?: string | null;
            country?: string | null;
        } | null;
    } | null;
};

export type LodgingMapDataFragment = {
    __typename?: "SearchResultLodging";
    lodging: { __typename?: "Lodging"; latitude: string; longitude: string };
};

export type LodgingCardFragment = {
    __typename?: "SearchResultLodging";
    lodging: {
        __typename?: "Lodging";
        id: number;
        name: string;
        nameSlug: string;
        address1: string;
        address1Slug: string;
        latitude: string;
        longitude: string;
        maxPeople?: number | null;
        petsAllowed?: boolean | null;
        stars?: number | null;
        starsPostText: string;
        activeDescription?: {
            __typename?: "LodgingDescription";
            title?: string | null;
            summary?: string | null;
        } | null;
        images: Array<{ __typename?: "Image"; url: string }>;
        location: { __typename?: "Location"; id: number; name: string; slug: string };
        facilityValues: Array<{
            __typename?: "FacilityValue";
            facilityId: number;
            masterFacilityId?: number | null;
            displayValue: string;
            displayValueAndName: string;
            value: string;
            facility: { __typename?: "Facility"; name: string };
        }>;
    };
    selectedBookingOption: {
        __typename?: "BookingOption";
        status: string;
        currency: string;
        price: any;
        priceDisplayValue: string;
        priceWithMandatoryItems: any;
        priceWithMandatoryItemsDisplayValue: string;
        priceWithMandatoryItemsWithoutDeposits: any;
        priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
        arrivalDisplayValue: string;
        departureDisplayValue: string;
        normalPrice: any;
        normalPriceDisplayValue: string;
        normalPriceWithMandatoryItems: any;
        normalPriceWithMandatoryItemsDisplayValue: string;
        normalPriceWithMandatoryItemsWithoutDeposits: any;
        normalPriceWithMandatoryItemsWithoutDepositsDisplayValue: string;
        duration: number;
        adults: number;
        children: number;
        infants: number;
        pets: number;
        discountName?: string | null;
        hasDiscount: boolean;
        arrival?: any | null;
        cleaningIncludedInPriceWithMandatoryItems: boolean;
        arrivalWeekDayName: string;
        itemPrices?: Array<{
            __typename?: "ItemPrice";
            isMandatory: boolean;
            item: {
                __typename?: "Item";
                id: number;
                itemTypeId: number;
                name: string;
                sortIndex: number;
            };
        } | null> | null;
    };
};

export type ExtendedLodgingLodgingCardFragment = {
    __typename?: "ExtendedLodging";
    lodging: {
        __typename?: "Lodging";
        id: number;
        name: string;
        nameSlug: string;
        address1: string;
        address1Slug: string;
        maxPeople?: number | null;
        petsAllowed?: boolean | null;
        stars?: number | null;
        starsPostText: string;
        activeDescription?: { __typename?: "LodgingDescription"; title?: string | null } | null;
        images: Array<{ __typename?: "Image"; url: string }>;
        location: { __typename?: "Location"; id: number; name: string; slug: string };
        facilityValues: Array<{
            __typename?: "FacilityValue";
            facilityId: number;
            masterFacilityId?: number | null;
            displayValue: string;
            displayValueAndName: string;
            value: string;
            facility: { __typename?: "Facility"; name: string };
        }>;
    };
    selectedBookingOption?: {
        __typename?: "BookingOption";
        currency: string;
        price: any;
        priceDisplayValue: string;
        priceWithMandatoryItems: any;
        priceWithMandatoryItemsDisplayValue: string;
        priceWithMandatoryItemsWithoutDepositsDisplayValue: string;
        arrivalDisplayValue: string;
        departureDisplayValue: string;
        normalPrice: any;
        normalPriceDisplayValue: string;
        normalPriceWithMandatoryItems: any;
        normalPriceWithMandatoryItemsDisplayValue: string;
        normalPriceWithMandatoryItemsWithoutDeposits: any;
        normalPriceWithMandatoryItemsWithoutDepositsDisplayValue: string;
        discountName?: string | null;
        hasDiscount: boolean;
        cleaningIncludedInPriceWithMandatoryItems: boolean;
        status: string;
        arrivalWeekDayName: string;
        itemPrices?: Array<{
            __typename?: "ItemPrice";
            isMandatory: boolean;
            item: {
                __typename?: "Item";
                id: number;
                itemTypeId: number;
                name: string;
                sortIndex: number;
            };
        } | null> | null;
    } | null;
    seasonPrices: Array<{
        __typename?: "SeasonPrice";
        price: any;
        currency: string;
        seasonCode: string;
        weeklyPriceDisplayValue: string;
    }>;
};

export type LodgingCalendarDataQueryVariables = Types.Exact<{
    query: Types.Scalars["String"];
    year: Types.Scalars["Int"];
    month: Types.Scalars["Int"];
    numberOfMonths: Types.Scalars["Int"];
}>;

export type LodgingCalendarDataQuery = {
    __typename?: "Query";
    calendar?: {
        __typename?: "Calendar";
        days: Array<{
            __typename?: "CalendarDay";
            date?: any | null;
            isAvailable: boolean;
            isChangeDay: boolean;
            selectedBookingOption?: {
                __typename?: "BookingOption";
                arrival?: any | null;
                departure?: any | null;
                priceDisplayValue: string;
            } | null;
        }>;
    } | null;
};

export type LocationDescriptionFragment = {
    __typename?: "Location";
    name: string;
    shortDescription: string;
};

export type LocationReviewsFragment = {
    __typename?: "Location";
    reviews: Array<{ __typename?: "CustomerReview"; reviewOfAreaText: string }>;
};

export type CreateBookingMutationVariables = Types.Exact<{
    request: Types.BookingRequestInput;
    payMethod?: Types.InputMaybe<Types.PaymentOption>;
}>;

export type CreateBookingMutation = {
    __typename?: "RootMutation";
    createBooking?: {
        __typename?: "MutationResult_CreateBookingResult";
        errorMessage?: string | null;
        success: boolean;
        result?: {
            __typename?: "CreateBookingResult";
            resultType?: Types.CreateBookingResultType | null;
            basketId?: any | null;
            bookingOptionNoLongerAvailable: boolean;
            formForGateway?: string | null;
            orderItem?: {
                __typename?: "OrderItem";
                id: number;
                reservationId: number;
                type: string;
            } | null;
        } | null;
    } | null;
};

export type NewsletterSignupMutationVariables = Types.Exact<{
    firstName: Types.Scalars["String"];
    lastName: Types.Scalars["String"];
    email: Types.Scalars["String"];
    newsletterCategories: Array<Types.Scalars["Int"]> | Types.Scalars["Int"];
    mailConfigurationId: Types.Scalars["String"];
}>;

export type NewsletterSignupMutation = {
    __typename?: "RootMutation";
    newsletterSignup?: {
        __typename?: "MutationResult_Boolean";
        errorMessage?: string | null;
        success: boolean;
    } | null;
};

export type BureauReviewsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BureauReviewsQuery = {
    __typename?: "Query";
    bureauReviews?: Array<{
        __typename?: "CustomerReview";
        reviewOfBureauText: string;
        reviewerName: string;
    } | null> | null;
};

export const LodgingWithImagesFragmentDoc = gql`
    fragment LodgingWithImages on Lodging {
        id
        name
        nameSlug
        internalName
        address1
        address1Slug
        location {
            id
            name
            slug
        }
        images {
            url
        }
    }
`;
export const DisplayBookingOptionFragmentDoc = gql`
    fragment DisplayBookingOption on BookingOption {
        status
        priceWithMandatoryItems
        priceWithMandatoryItemsDisplayValue
        priceWithMandatoryItemsWithoutDeposits
        priceWithMandatoryItemsWithoutDepositsDisplayValue
        arrivalWeekDayName: arrivalDisplayValue(format: "dddd")
        arrivalDisplayValue(format: "dd-MM-yyyy")
        departureDisplayValue(format: "dd-MM-yyyy")
        normalPriceWithMandatoryItems
        duration
        adults
        children
        infants
        pets
        discountName
        hasDiscount
        arrival
        cleaningIncludedInPriceWithMandatoryItems
    }
`;
export const LodgingFacilitySummaryFragmentDoc = gql`
    fragment LodgingFacilitySummary on Lodging {
        maxPeople(displayDate: $displayDate)
        maxPets(displayDate: $displayDate)
        petsAllowed(displayDate: $displayDate)
        bedroomFacility: facilityValue(facilityId: $bedroomFacilityId, displayDate: $displayDate) {
            displayValueAndName
        }
        internetFacility: facilityValue(
            facilityId: $internetFacilityId
            displayDate: $displayDate
        ) {
            facility {
                name
            }
            displayValue
        }
        bathroomFacility: facilityValue(
            facilityId: $bathroomFacilityId
            displayDate: $displayDate
        ) {
            displayValueAndName
        }
        lodgingAreaFacility: facilityValue(
            facilityId: $houseAreaFacilityId
            displayDate: $displayDate
        ) {
            facility {
                name
            }
            displayValue
        }
    }
`;
export const BookingSummaryLodgingFragmentDoc = gql`
    fragment BookingSummaryLodging on Lodging {
        id
        name
        address1
        location {
            id
            name
        }
        primaryImage {
            url
        }
    }
`;
export const BookingSummaryBookingOptionFragmentDoc = gql`
    fragment BookingSummaryBookingOption on BookingOption {
        status
        arrivalDisplayValue(format: "dd-MM-yyyy")
        departureDisplayValue(format: "dd-MM-yyyy")
        adults
        children
        infants
        pets
    }
`;
export const ItemsSummaryLodgingFragmentDoc = gql`
    fragment ItemsSummaryLodging on Lodging {
        name
    }
`;
export const ItemsSummaryCheckoutPresentationFragmentDoc = gql`
    fragment ItemsSummaryCheckoutPresentation on CheckoutPresentationView {
        itemLines {
            itemPrice {
                unitPrice
                maxQuantity
            }
            item {
                id
                name
            }
            quantity
            totalPrice
            totalPriceDisplayValue
            showInSummary
        }
        totalPriceDisplayValue
        discountReductionDisplayValue
        hasDiscount
        discountName
        normalBookingPriceDisplayValue
    }
`;
export const ItemSelectorCheckoutPresentationFragmentDoc = gql`
    fragment ItemSelectorCheckoutPresentation on CheckoutPresentationView {
        itemLines {
            item {
                id
                name
                shortDescription
                description
            }
            itemPrice {
                minQuantity
                maxQuantity
                unitPriceDisplayValue
                isMandatory
            }
            quantity
            totalPriceDisplayValue
        }
        itemLinesTotalDisplayValue(includeMandatory: false)
        totalPriceDisplayValue
    }
`;
export const CheckoutTrackingPresentationFragmentDoc = gql`
    fragment CheckoutTrackingPresentation on CheckoutPresentationView {
        totalPrice
        bookingPrice
        discountReduction
        currency
        itemLines {
            item {
                id
                name
            }
            quantity
            itemPrice {
                unitPrice
            }
        }
    }
`;
export const AvailabilityCalendarLodgingFragmentDoc = gql`
    fragment availabilityCalendarLodging on AvailabilityCalendar {
        lodging {
            id
            name
            nameSlug
            internalName
            address1
            address1Slug
            location {
                name
                slug
            }
            maxPeople(displayDate: $displayDate)
            maxPets(displayDate: $displayDate)
            petsAllowed(displayDate: $displayDate)
        }
    }
`;
export const LodgingMapDataFragmentDoc = gql`
    fragment lodgingMapData on SearchResultLodging {
        lodging {
            latitude
            longitude
        }
    }
`;
export const LodgingCardFragmentDoc = gql`
    fragment lodgingCard on SearchResultLodging {
        lodging {
            id
            activeDescription(displayDate: $displayDate) {
                title
                summary
            }
            name
            nameSlug
            address1
            address1Slug
            latitude
            longitude
            images {
                url
            }
            maxPeople(displayDate: $displayDate)
            petsAllowed(displayDate: $displayDate)
            location {
                id
                name
                slug
            }
            facilityValues(
                displayDate: $displayDate
                onlyFacilities: $onlyFacilities
                onlyMasterFacilities: $onlyMasterFacilities
            ) {
                facilityId
                masterFacilityId
                facility {
                    name
                }
                displayValue
                displayValueAndName
                value
            }
            stars
            starsPostText
        }
        selectedBookingOption {
            status
            currency
            price
            priceDisplayValue
            priceWithMandatoryItems
            priceWithMandatoryItemsDisplayValue
            priceWithMandatoryItemsWithoutDeposits
            priceWithMandatoryItemsWithoutDepositsDisplayValue
            arrivalWeekDayName: arrivalDisplayValue(format: "dddd")
            arrivalDisplayValue(format: "dd-MM")
            departureDisplayValue(format: "dd-MM")
            normalPrice
            normalPriceDisplayValue
            normalPriceWithMandatoryItems
            normalPriceWithMandatoryItemsDisplayValue
            normalPriceWithMandatoryItemsWithoutDeposits
            normalPriceWithMandatoryItemsWithoutDepositsDisplayValue
            duration
            adults
            children
            infants
            pets
            discountName
            hasDiscount
            arrival
            cleaningIncludedInPriceWithMandatoryItems
            itemPrices {
                isMandatory
                item {
                    id
                    itemTypeId
                    name
                    sortIndex
                }
            }
        }
    }
`;
export const ExtendedLodgingLodgingCardFragmentDoc = gql`
    fragment extendedLodgingLodgingCard on ExtendedLodging {
        lodging {
            id
            activeDescription(displayDate: $displayDate) {
                title
            }
            name
            nameSlug
            address1
            address1Slug
            images {
                url
            }
            maxPeople(displayDate: $displayDate)
            petsAllowed(displayDate: $displayDate)
            location {
                id
                name
                slug
            }
            facilityValues(
                displayDate: $displayDate
                onlyFacilities: $onlyFacilities
                onlyMasterFacilities: $onlyMasterFacilities
            ) {
                facilityId
                masterFacilityId
                facility {
                    name
                }
                displayValue
                displayValueAndName
                value
            }
            stars
            starsPostText
        }
        selectedBookingOption {
            currency
            price
            priceDisplayValue
            priceWithMandatoryItems
            priceWithMandatoryItemsDisplayValue
            priceWithMandatoryItemsWithoutDepositsDisplayValue
            arrivalWeekDayName: arrivalDisplayValue(format: "dddd")
            arrivalDisplayValue(format: "dd-MM")
            departureDisplayValue(format: "dd-MM")
            normalPrice
            normalPriceDisplayValue
            normalPriceWithMandatoryItems
            normalPriceWithMandatoryItemsDisplayValue
            normalPriceWithMandatoryItemsWithoutDeposits
            normalPriceWithMandatoryItemsWithoutDepositsDisplayValue
            discountName
            hasDiscount
            cleaningIncludedInPriceWithMandatoryItems
            status
            itemPrices {
                isMandatory
                item {
                    id
                    itemTypeId
                    name
                    sortIndex
                }
            }
        }
        seasonPrices {
            price
            currency
            seasonCode
            weeklyPriceDisplayValue
        }
    }
`;
export const LocationDescriptionFragmentDoc = gql`
    fragment locationDescription on Location {
        name
        shortDescription
    }
`;
export const LocationReviewsFragmentDoc = gql`
    fragment locationReviews on Location {
        reviews(limit: 5) {
            reviewOfAreaText
        }
    }
`;
export const SearchResultsPageDocument = gql`
    query searchResultsPage(
        $query: String!
        $onlyFacilities: [Int!]
        $onlyMasterFacilities: [Int!]
        $displayDate: Date!
        $sorting: SearchSorting
    ) {
        search(query: $query, sorting: $sorting) {
            results {
                ...lodgingCard
                ...lodgingMapData
            }
            numberOfResults
        }
    }
    ${LodgingCardFragmentDoc}
    ${LodgingMapDataFragmentDoc}
`;

/**
 * __useSearchResultsPageQuery__
 *
 * To run a query within a React component, call `useSearchResultsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchResultsPageQuery({
 *   variables: {
 *      query: // value for 'query'
 *      onlyFacilities: // value for 'onlyFacilities'
 *      onlyMasterFacilities: // value for 'onlyMasterFacilities'
 *      displayDate: // value for 'displayDate'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSearchResultsPageQuery(
    baseOptions: Apollo.QueryHookOptions<SearchResultsPageQuery, SearchResultsPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchResultsPageQuery, SearchResultsPageQueryVariables>(
        SearchResultsPageDocument,
        options
    );
}
export function useSearchResultsPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchResultsPageQuery,
        SearchResultsPageQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchResultsPageQuery, SearchResultsPageQueryVariables>(
        SearchResultsPageDocument,
        options
    );
}
export type SearchResultsPageQueryHookResult = ReturnType<typeof useSearchResultsPageQuery>;
export type SearchResultsPageLazyQueryHookResult = ReturnType<typeof useSearchResultsPageLazyQuery>;
export type SearchResultsPageQueryResult = Apollo.QueryResult<
    SearchResultsPageQuery,
    SearchResultsPageQueryVariables
>;
export const LastMinuteDocument = gql`
    query lastMinute(
        $query: String!
        $onlyFacilities: [Int!]
        $onlyMasterFacilities: [Int!]
        $displayDate: Date!
        $sorting: SearchSorting
        $daysAhead: Int
        $tagFilter: String
        $take: Int
    ) {
        lastMinute(
            query: $query
            sorting: $sorting
            daysAhead: $daysAhead
            tagFilter: $tagFilter
            take: $take
        ) {
            results {
                ...lodgingCard
            }
            numberOfResults
        }
    }
    ${LodgingCardFragmentDoc}
`;

/**
 * __useLastMinuteQuery__
 *
 * To run a query within a React component, call `useLastMinuteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastMinuteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastMinuteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      onlyFacilities: // value for 'onlyFacilities'
 *      onlyMasterFacilities: // value for 'onlyMasterFacilities'
 *      displayDate: // value for 'displayDate'
 *      sorting: // value for 'sorting'
 *      daysAhead: // value for 'daysAhead'
 *      tagFilter: // value for 'tagFilter'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useLastMinuteQuery(
    baseOptions: Apollo.QueryHookOptions<LastMinuteQuery, LastMinuteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LastMinuteQuery, LastMinuteQueryVariables>(LastMinuteDocument, options);
}
export function useLastMinuteLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LastMinuteQuery, LastMinuteQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LastMinuteQuery, LastMinuteQueryVariables>(
        LastMinuteDocument,
        options
    );
}
export type LastMinuteQueryHookResult = ReturnType<typeof useLastMinuteQuery>;
export type LastMinuteLazyQueryHookResult = ReturnType<typeof useLastMinuteLazyQuery>;
export type LastMinuteQueryResult = Apollo.QueryResult<LastMinuteQuery, LastMinuteQueryVariables>;
export const LodgingListDocument = gql`
    query lodgingList(
        $query: String!
        $year: Int!
        $onlyFacilities: [Int!]
        $onlyMasterFacilities: [Int!]
        $displayDate: Date!
        $sorting: SearchSorting
    ) {
        lodgingList(query: $query, year: $year, sorting: $sorting) {
            results {
                ...extendedLodgingLodgingCard
                lodging {
                    id
                    latitude
                    longitude
                }
            }
        }
    }
    ${ExtendedLodgingLodgingCardFragmentDoc}
`;

/**
 * __useLodgingListQuery__
 *
 * To run a query within a React component, call `useLodgingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingListQuery({
 *   variables: {
 *      query: // value for 'query'
 *      year: // value for 'year'
 *      onlyFacilities: // value for 'onlyFacilities'
 *      onlyMasterFacilities: // value for 'onlyMasterFacilities'
 *      displayDate: // value for 'displayDate'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useLodgingListQuery(
    baseOptions: Apollo.QueryHookOptions<LodgingListQuery, LodgingListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingListQuery, LodgingListQueryVariables>(
        LodgingListDocument,
        options
    );
}
export function useLodgingListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LodgingListQuery, LodgingListQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingListQuery, LodgingListQueryVariables>(
        LodgingListDocument,
        options
    );
}
export type LodgingListQueryHookResult = ReturnType<typeof useLodgingListQuery>;
export type LodgingListLazyQueryHookResult = ReturnType<typeof useLodgingListLazyQuery>;
export type LodgingListQueryResult = Apollo.QueryResult<
    LodgingListQuery,
    LodgingListQueryVariables
>;
export const LodgingPresentationDocument = gql`
    query lodgingPresentation(
        $query: String!
        $seasonPricesYear: Int!
        $displayDate: Date!
        $numberOfReviews: Int!
    ) {
        lodgingPresentation(query: $query, seasonPricesYear: $seasonPricesYear) {
            lodging {
                id
                name
                maxPeople(displayDate: $displayDate)
                petsAllowed(displayDate: $displayDate)
                maxPets(displayDate: $displayDate)
                activeDescription(displayDate: $displayDate) {
                    title
                    body
                    longHtmlDescription
                    embeddedVideoHtml
                }
                location {
                    id
                    name
                }
                facilityValues(displayDate: $displayDate) {
                    facilityId
                    masterFacilityId
                    facility {
                        name
                    }
                    displayValue
                    displayValueAndName
                    value
                }
                images {
                    url
                    tagIds
                    originalHeight
                    originalWidth
                }
                reviews(take: $numberOfReviews) {
                    month: fromDisplayValue(format: "MMMM yyyy")
                    reviewerName
                    reviewOfLodgingText
                }
                latitude
                longitude
                location {
                    ...locationDescription
                    ...locationReviews
                }
                stars
                starsPostText
            }
            selectedBookingOption {
                arrivalDisplayValue(format: "dd-MM")
                departureDisplayValue(format: "dd-MM")
                itemPrices {
                    item {
                        name
                        itemType
                    }
                    isMandatory
                    priceWithQuantityDisplayValue
                }
                normalPriceDisplayValue
                priceWithMandatoryItemsDisplayValue
                priceWithMandatoryItems
                priceWithMandatoryItemsWithoutDepositsDisplayValue
                priceDepositItems
                priceDepositItemsDisplayValue
                currency
                discountName
                hasDiscount
                discountReductionDisplayValue
                status
            }
        }
    }
    ${LocationDescriptionFragmentDoc}
    ${LocationReviewsFragmentDoc}
`;

/**
 * __useLodgingPresentationQuery__
 *
 * To run a query within a React component, call `useLodgingPresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingPresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingPresentationQuery({
 *   variables: {
 *      query: // value for 'query'
 *      seasonPricesYear: // value for 'seasonPricesYear'
 *      displayDate: // value for 'displayDate'
 *      numberOfReviews: // value for 'numberOfReviews'
 *   },
 * });
 */
export function useLodgingPresentationQuery(
    baseOptions: Apollo.QueryHookOptions<
        LodgingPresentationQuery,
        LodgingPresentationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingPresentationQuery, LodgingPresentationQueryVariables>(
        LodgingPresentationDocument,
        options
    );
}
export function useLodgingPresentationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        LodgingPresentationQuery,
        LodgingPresentationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingPresentationQuery, LodgingPresentationQueryVariables>(
        LodgingPresentationDocument,
        options
    );
}
export type LodgingPresentationQueryHookResult = ReturnType<typeof useLodgingPresentationQuery>;
export type LodgingPresentationLazyQueryHookResult = ReturnType<
    typeof useLodgingPresentationLazyQuery
>;
export type LodgingPresentationQueryResult = Apollo.QueryResult<
    LodgingPresentationQuery,
    LodgingPresentationQueryVariables
>;
export const LodgingBookingOptionsDocument = gql`
    query lodgingBookingOptions($query: String!) {
        lodgingBookingOptions(query: $query) {
            lodging {
                ...LodgingWithImages
            }
            bookingOptions {
                ...DisplayBookingOption
            }
        }
    }
    ${LodgingWithImagesFragmentDoc}
    ${DisplayBookingOptionFragmentDoc}
`;

/**
 * __useLodgingBookingOptionsQuery__
 *
 * To run a query within a React component, call `useLodgingBookingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingBookingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingBookingOptionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useLodgingBookingOptionsQuery(
    baseOptions: Apollo.QueryHookOptions<
        LodgingBookingOptionsQuery,
        LodgingBookingOptionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingBookingOptionsQuery, LodgingBookingOptionsQueryVariables>(
        LodgingBookingOptionsDocument,
        options
    );
}
export function useLodgingBookingOptionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        LodgingBookingOptionsQuery,
        LodgingBookingOptionsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingBookingOptionsQuery, LodgingBookingOptionsQueryVariables>(
        LodgingBookingOptionsDocument,
        options
    );
}
export type LodgingBookingOptionsQueryHookResult = ReturnType<typeof useLodgingBookingOptionsQuery>;
export type LodgingBookingOptionsLazyQueryHookResult = ReturnType<
    typeof useLodgingBookingOptionsLazyQuery
>;
export type LodgingBookingOptionsQueryResult = Apollo.QueryResult<
    LodgingBookingOptionsQuery,
    LodgingBookingOptionsQueryVariables
>;
export const LodgingsDocument = gql`
    query lodgings {
        lodgings {
            id
            name
            nameSlug
            internalName
            address1
            address1Slug
            location {
                id
                name
                slug
            }
        }
    }
`;

/**
 * __useLodgingsQuery__
 *
 * To run a query within a React component, call `useLodgingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLodgingsQuery(
    baseOptions?: Apollo.QueryHookOptions<LodgingsQuery, LodgingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingsQuery, LodgingsQueryVariables>(LodgingsDocument, options);
}
export function useLodgingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LodgingsQuery, LodgingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingsQuery, LodgingsQueryVariables>(LodgingsDocument, options);
}
export type LodgingsQueryHookResult = ReturnType<typeof useLodgingsQuery>;
export type LodgingsLazyQueryHookResult = ReturnType<typeof useLodgingsLazyQuery>;
export type LodgingsQueryResult = Apollo.QueryResult<LodgingsQuery, LodgingsQueryVariables>;
export const CheckoutDocument = gql`
    query checkout(
        $request: BookingRequestInput!
        $displayDate: Date!
        $bedroomFacilityId: Int!
        $internetFacilityId: Int!
        $bathroomFacilityId: Int!
        $houseAreaFacilityId: Int!
    ) {
        lodgingPresentation(request: $request) {
            lodging {
                ...BookingSummaryLodging
                ...ItemsSummaryLodging
                ...LodgingFacilitySummary
            }
            selectedBookingOption {
                ...BookingSummaryBookingOption
            }
        }
        checkoutPresentation(request: $request) {
            available
            ...ItemsSummaryCheckoutPresentation
            ...ItemSelectorCheckoutPresentation
            ...CheckoutTrackingPresentation
        }
        countries {
            id
            name
        }
        newsletters {
            id
            name
        }
    }
    ${BookingSummaryLodgingFragmentDoc}
    ${ItemsSummaryLodgingFragmentDoc}
    ${LodgingFacilitySummaryFragmentDoc}
    ${BookingSummaryBookingOptionFragmentDoc}
    ${ItemsSummaryCheckoutPresentationFragmentDoc}
    ${ItemSelectorCheckoutPresentationFragmentDoc}
    ${CheckoutTrackingPresentationFragmentDoc}
`;

/**
 * __useCheckoutQuery__
 *
 * To run a query within a React component, call `useCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutQuery({
 *   variables: {
 *      request: // value for 'request'
 *      displayDate: // value for 'displayDate'
 *      bedroomFacilityId: // value for 'bedroomFacilityId'
 *      internetFacilityId: // value for 'internetFacilityId'
 *      bathroomFacilityId: // value for 'bathroomFacilityId'
 *      houseAreaFacilityId: // value for 'houseAreaFacilityId'
 *   },
 * });
 */
export function useCheckoutQuery(
    baseOptions: Apollo.QueryHookOptions<CheckoutQuery, CheckoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
}
export function useCheckoutLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CheckoutQuery, CheckoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CheckoutQuery, CheckoutQueryVariables>(CheckoutDocument, options);
}
export type CheckoutQueryHookResult = ReturnType<typeof useCheckoutQuery>;
export type CheckoutLazyQueryHookResult = ReturnType<typeof useCheckoutLazyQuery>;
export type CheckoutQueryResult = Apollo.QueryResult<CheckoutQuery, CheckoutQueryVariables>;
export const AvailabilityCalendarsDocument = gql`
    query availabilityCalendars($month: Int!, $year: Int!, $displayDate: Date!) {
        availabilityCalendars(month: $month, year: $year, displayDate: $displayDate) {
            ...availabilityCalendarLodging
            days {
                date
                isAvailable
                seasonCode
                hasCalendarData
                freeNightsAhead
            }
        }
    }
    ${AvailabilityCalendarLodgingFragmentDoc}
`;

/**
 * __useAvailabilityCalendarsQuery__
 *
 * To run a query within a React component, call `useAvailabilityCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityCalendarsQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      displayDate: // value for 'displayDate'
 *   },
 * });
 */
export function useAvailabilityCalendarsQuery(
    baseOptions: Apollo.QueryHookOptions<
        AvailabilityCalendarsQuery,
        AvailabilityCalendarsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AvailabilityCalendarsQuery, AvailabilityCalendarsQueryVariables>(
        AvailabilityCalendarsDocument,
        options
    );
}
export function useAvailabilityCalendarsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AvailabilityCalendarsQuery,
        AvailabilityCalendarsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AvailabilityCalendarsQuery, AvailabilityCalendarsQueryVariables>(
        AvailabilityCalendarsDocument,
        options
    );
}
export type AvailabilityCalendarsQueryHookResult = ReturnType<typeof useAvailabilityCalendarsQuery>;
export type AvailabilityCalendarsLazyQueryHookResult = ReturnType<
    typeof useAvailabilityCalendarsLazyQuery
>;
export type AvailabilityCalendarsQueryResult = Apollo.QueryResult<
    AvailabilityCalendarsQuery,
    AvailabilityCalendarsQueryVariables
>;
export const PayRatesDocument = gql`
    query PayRates($request: BookingRequestInput!) {
        payRates(request: $request) {
            rate1 {
                payDate
                amountDisplayValue
                payDateDisplayValue(format: "dd-MM-yyyy")
            }
            rate2 {
                amountDisplayValue
                payDateDisplayValue(format: "dd-MM-yyyy")
            }
        }
    }
`;

/**
 * __usePayRatesQuery__
 *
 * To run a query within a React component, call `usePayRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayRatesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePayRatesQuery(
    baseOptions: Apollo.QueryHookOptions<PayRatesQuery, PayRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PayRatesQuery, PayRatesQueryVariables>(PayRatesDocument, options);
}
export function usePayRatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PayRatesQuery, PayRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PayRatesQuery, PayRatesQueryVariables>(PayRatesDocument, options);
}
export type PayRatesQueryHookResult = ReturnType<typeof usePayRatesQuery>;
export type PayRatesLazyQueryHookResult = ReturnType<typeof usePayRatesLazyQuery>;
export type PayRatesQueryResult = Apollo.QueryResult<PayRatesQuery, PayRatesQueryVariables>;
export const TermsDocument = gql`
    query Terms($lodgingId: Int!, $arrivalDate: DateTime!) {
        terms(lodgingId: $lodgingId, arrivalDate: $arrivalDate) {
            description
        }
    }
`;

/**
 * __useTermsQuery__
 *
 * To run a query within a React component, call `useTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsQuery({
 *   variables: {
 *      lodgingId: // value for 'lodgingId'
 *      arrivalDate: // value for 'arrivalDate'
 *   },
 * });
 */
export function useTermsQuery(
    baseOptions: Apollo.QueryHookOptions<TermsQuery, TermsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<TermsQuery, TermsQueryVariables>(TermsDocument, options);
}
export function useTermsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<TermsQuery, TermsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<TermsQuery, TermsQueryVariables>(TermsDocument, options);
}
export type TermsQueryHookResult = ReturnType<typeof useTermsQuery>;
export type TermsLazyQueryHookResult = ReturnType<typeof useTermsLazyQuery>;
export type TermsQueryResult = Apollo.QueryResult<TermsQuery, TermsQueryVariables>;
export const BookingDocument = gql`
    query booking($basketId: String!, $dateFormat: String!) {
        booking(basketId: $basketId) {
            reservationId
            totalPrice
            totalPriceDisplayValue
            bookingsTotalPrice
            bookingsTotalPriceDisplayValue
            bookingsTotalDiscount
            bookingsTotalDiscountDisplayValue
            currency
            balance
            balanceDisplayValue
            externalNote
            payment {
                amount
                currency
                fee
                transact
                status
            }
            bookingLines {
                lodgingId
                lodging {
                    name
                    location {
                        id
                        name
                    }
                }
                lodgingUnitTypeId
                arrivalDate
                duration
                adults
                children
                infants
                pets
                calculatedLineTotal
                description
            }
            itemLines {
                itemId
                quantity
                calculatedUnitPrice
                calculatedLineTotal
                description
            }
            payRates {
                rate1 {
                    amount
                    amountDisplayValue
                    currency
                    payDate
                    payDateDisplayValue(format: $dateFormat)
                }
                rate2 {
                    amount
                    amountDisplayValue
                    currency
                    payDate
                    payDateDisplayValue(format: $dateFormat)
                }
            }
            customer {
                email
                postalCode
                city
                country
            }
        }
    }
`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      basketId: // value for 'basketId'
 *      dateFormat: // value for 'dateFormat'
 *   },
 * });
 */
export function useBookingQuery(
    baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export function useBookingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const LodgingCalendarDataDocument = gql`
    query lodgingCalendarData($query: String!, $year: Int!, $month: Int!, $numberOfMonths: Int!) {
        calendar(query: $query, year: $year, month: $month, numberOfMonths: $numberOfMonths) {
            days {
                date
                isAvailable
                isChangeDay
                selectedBookingOption {
                    arrival
                    departure
                    priceDisplayValue
                }
            }
        }
    }
`;

/**
 * __useLodgingCalendarDataQuery__
 *
 * To run a query within a React component, call `useLodgingCalendarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLodgingCalendarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLodgingCalendarDataQuery({
 *   variables: {
 *      query: // value for 'query'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      numberOfMonths: // value for 'numberOfMonths'
 *   },
 * });
 */
export function useLodgingCalendarDataQuery(
    baseOptions: Apollo.QueryHookOptions<
        LodgingCalendarDataQuery,
        LodgingCalendarDataQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LodgingCalendarDataQuery, LodgingCalendarDataQueryVariables>(
        LodgingCalendarDataDocument,
        options
    );
}
export function useLodgingCalendarDataLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        LodgingCalendarDataQuery,
        LodgingCalendarDataQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LodgingCalendarDataQuery, LodgingCalendarDataQueryVariables>(
        LodgingCalendarDataDocument,
        options
    );
}
export type LodgingCalendarDataQueryHookResult = ReturnType<typeof useLodgingCalendarDataQuery>;
export type LodgingCalendarDataLazyQueryHookResult = ReturnType<
    typeof useLodgingCalendarDataLazyQuery
>;
export type LodgingCalendarDataQueryResult = Apollo.QueryResult<
    LodgingCalendarDataQuery,
    LodgingCalendarDataQueryVariables
>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($request: BookingRequestInput!, $payMethod: PaymentOption) {
        createBooking(request: $request, payMethod: $payMethod) {
            errorMessage
            success
            result {
                resultType
                basketId
                bookingOptionNoLongerAvailable
                orderItem {
                    id
                    reservationId
                    type
                }
                formForGateway
            }
        }
    }
`;
export type CreateBookingMutationFn = Apollo.MutationFunction<
    CreateBookingMutation,
    CreateBookingMutationVariables
>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      request: // value for 'request'
 *      payMethod: // value for 'payMethod'
 *   },
 * });
 */
export function useCreateBookingMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
        CreateBookingDocument,
        options
    );
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
>;
export const NewsletterSignupDocument = gql`
    mutation NewsletterSignup(
        $firstName: String!
        $lastName: String!
        $email: String!
        $newsletterCategories: [Int!]!
        $mailConfigurationId: String!
    ) {
        newsletterSignup(
            firstName: $firstName
            lastName: $lastName
            email: $email
            newsletterCategories: $newsletterCategories
            mailConfigurationId: $mailConfigurationId
        ) {
            errorMessage
            success
        }
    }
`;
export type NewsletterSignupMutationFn = Apollo.MutationFunction<
    NewsletterSignupMutation,
    NewsletterSignupMutationVariables
>;

/**
 * __useNewsletterSignupMutation__
 *
 * To run a mutation, you first call `useNewsletterSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterSignupMutation, { data, loading, error }] = useNewsletterSignupMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      newsletterCategories: // value for 'newsletterCategories'
 *      mailConfigurationId: // value for 'mailConfigurationId'
 *   },
 * });
 */
export function useNewsletterSignupMutation(
    baseOptions?: Apollo.MutationHookOptions<
        NewsletterSignupMutation,
        NewsletterSignupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<NewsletterSignupMutation, NewsletterSignupMutationVariables>(
        NewsletterSignupDocument,
        options
    );
}
export type NewsletterSignupMutationHookResult = ReturnType<typeof useNewsletterSignupMutation>;
export type NewsletterSignupMutationResult = Apollo.MutationResult<NewsletterSignupMutation>;
export type NewsletterSignupMutationOptions = Apollo.BaseMutationOptions<
    NewsletterSignupMutation,
    NewsletterSignupMutationVariables
>;
export const BureauReviewsDocument = gql`
    query BureauReviews {
        bureauReviews(limit: 11) {
            reviewOfBureauText
            reviewerName
        }
    }
`;

/**
 * __useBureauReviewsQuery__
 *
 * To run a query within a React component, call `useBureauReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBureauReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBureauReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBureauReviewsQuery(
    baseOptions?: Apollo.QueryHookOptions<BureauReviewsQuery, BureauReviewsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<BureauReviewsQuery, BureauReviewsQueryVariables>(
        BureauReviewsDocument,
        options
    );
}
export function useBureauReviewsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<BureauReviewsQuery, BureauReviewsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<BureauReviewsQuery, BureauReviewsQueryVariables>(
        BureauReviewsDocument,
        options
    );
}
export type BureauReviewsQueryHookResult = ReturnType<typeof useBureauReviewsQuery>;
export type BureauReviewsLazyQueryHookResult = ReturnType<typeof useBureauReviewsLazyQuery>;
export type BureauReviewsQueryResult = Apollo.QueryResult<
    BureauReviewsQuery,
    BureauReviewsQueryVariables
>;
