import React, { useState, useRef, useEffect } from "react";
import useOnClickOutside from "./shared/useOnClickOutside";
import IconButton from "./IconButton";
import "./MenuBar.scss";
import { Language, NavNode, Brand } from "./shared/types";
import { IconMenu, IconClose } from "./shared/icons";
import { setBoundsFromRef } from "./shared/dimensions";
import useIsomorphicLayoutEffect from "./shared/useIsomorphicLayoutEffect";
import { createImg } from "./shared/images";
import { MenuBarTranslations } from "./translations/MenuBarTranslations";

export interface MenuBarProps {
    brand: Brand;
    languages: Language[];
    menus: NavNode[];
    overlapsContent?: boolean;
    backgroundColor?: string;
    showLabelWithMenuIcon?: boolean;
    favourites?: NavNode;
    serverPath: string;
    testPath?: string;
    translations?: MenuBarTranslations;
}

export default function MenuBar({
    brand,
    languages,
    menus,
    overlapsContent,
    backgroundColor,
    showLabelWithMenuIcon,
    favourites,
    serverPath,
    testPath,
    translations
}: MenuBarProps) {
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const [path, setPath] = useState(null);
    const menuListRef = useRef(null);
    const menuListMobileRef = useRef(null);
    const [navigationPanelOpen, setNavigationPanelOpen] = useState(false);

    useEffect(() => {
        if (typeof testPath !== "undefined") {
            setPath(testPath);
        } else {
            if (typeof window !== "undefined") {
                setPath(window.location.pathname);
            } else {
                setPath(serverPath);
            }
        }
    }, [testPath, serverPath]);

    useOnClickOutside([menuListRef], () => {
        if (!navigationPanelOpen) {
            setActiveSubMenu(null);
        }
    });

    useOnClickOutside([menuListMobileRef], () => {
        if (navigationPanelOpen) {
            setActiveSubMenu(null);
        }
    });

    const handleMenuClick = (nodes: NavNode[]) => {
        if (activeSubMenu === nodes) {
            setActiveSubMenu(null);
        } else {
            setActiveSubMenu(nodes);
        }
    };

    const handleMenuIconClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setNavigationPanelOpen(true);
    };

    const handleCloseIconClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setNavigationPanelOpen(false);
    };

    const isMenuActive = (menu: NavNode) => {
        if (!path) {
            return false;
        }

        return (
            path === menu.url ||
            (menu.nodes && menu.nodes.length && menu.nodes.some((node) => node.url === path))
        );
    };

    const getMenuItem = (menu: NavNode) => {
        if (menu.nodes && menu.nodes.length) {
            return (
                <MenuWithSubMenu
                    menu={menu}
                    isOpen={activeSubMenu === menu.nodes}
                    onClick={handleMenuClick}
                    isActive={isMenuActive(menu)}
                    key={`menuwithsubmenu${menu.name}`}
                />
            );
        }
        return <Menu menu={menu} isActive={isMenuActive(menu)} key={`menu${menu.url}`} />;
    };

    const classNames = ["bwp-menu-bar"];
    if (backgroundColor === "ffffff") {
        classNames.push("bwp-menu-bar--light");
    } else if (backgroundColor === "eeeeee") {
        classNames.push("bwp-menu-bar--light-transparent");
    } else if (backgroundColor === "666666") {
        classNames.push("bwp-menu-bar--dark-transparent");
    } else if (backgroundColor === "000000") {
        classNames.push("bwp-menu-bar--dark");
    }
    if (overlapsContent) {
        classNames.push("bwp-menu-bar--overlaps-content");
    }

    const panelClassNames = ["bwp-menu-bar__panel"];
    if (navigationPanelOpen) {
        panelClassNames.push("bwp-open");
    }

    const logoUrl = brand.logoUrl || brand.logoLightBgUrl || brand.logoDarkBgUrl;
    let logo: React.ReactNode = createImg(logoUrl, {
        width: brand.logoWidth,
        height: brand.logoHeight,
        alt: brand.name
    });

    const menuButtonContent = showLabelWithMenuIcon
        ? <><div className="bwp-menu-hamburger__text">{translations.menu}</div>{IconMenu}</>
        : <>{IconMenu}</>;

    return (
        <React.Fragment>
            <nav className={classNames.join(" ")}>
                <div className="bwp-menu-bar__body">
                    <div className="bwp-menu-bar__body-centered">
                        <div className="bwp-menu-bar__brand">
                            <a href={brand.url || "/"}>
                                {logo}
                            </a>
                        </div>
                        <ul className="bwp-menu-bar__list" ref={menuListRef}>
                            {menus.map((menu) => getMenuItem(menu))}
                        </ul>
                        <div className="bwp-menu-bar__tools">
                            <IconButton
                                type="button"
                                onClick={handleMenuIconClick}
                                className="bwp-menu-hamburger"
                                padding="13px 9px 10px 9px"
                            >
                                {menuButtonContent}
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className="bwp-menu-bar__container">
                    <div className={panelClassNames.join(" ")}>
                        <div className="bwp-menu-bar__panel-body">
                            <button className="bwp-menu-bar__close" onClick={handleCloseIconClick}>
                                {IconClose}
                            </button>
                            <div className="bwp-menu-bar__brand">
                                <a href={brand.url || "/"}>{brand.name}</a>
                            </div>
                            <ul className="bwp-menu-bar__list" ref={menuListMobileRef}>
                                {menus.map((menu) => getMenuItem(menu))}
                                {(favourites?.name && favourites?.url) && (getMenuItem(favourites))}
                            </ul>
                        </div>
                        <div className="bwp-menu-bar__panel-footer">
                            <div className="bwp-menu-bar__language-list">
                                <ul>
                                    {languages.map((language) => (
                                        <li
                                            key={language.isoCode}
                                            className={`bwp-flag--${language.isoCode}`}
                                        >
                                            <a href={language.url}>{language.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );
}

export interface MenuWithSubMenuProps {
    menu: NavNode;
    isOpen: boolean;
    onClick: (subMenu: NavNode[]) => void;
    isActive: boolean;
}

export function MenuWithSubMenu({ menu, isOpen, onClick, isActive }: MenuWithSubMenuProps) {
    const [menuBounds, setMenuBounds] = useState(null);
    const menuRef: React.RefObject<HTMLLIElement> = useRef(null);

    const [subMenuBounds, setSubMenuBounds] = useState(null);
    const subMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

    const handleMenuClick = (e: React.SyntheticEvent, subMenu: NavNode[]) => {
        e.preventDefault();
        onClick(subMenu);
    };

    useIsomorphicLayoutEffect(() => {
        setBoundsFromRef(menuRef, menuBounds, setMenuBounds);
        setBoundsFromRef(subMenuRef, subMenuBounds, setSubMenuBounds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const subMenuStyle =
        menuBounds && subMenuBounds
            ? { left: Math.round((menuBounds.width - subMenuBounds.width) / 2) }
            : {};

    const classNames: string[] = [];
    if (isActive) {
        classNames.push("bwp-active");
    }
    if (isOpen) {
        classNames.push("bwp-open");
    }

    return (
        <li ref={menuRef} className={classNames.join(" ")}>
            <button
                type="button"
                onClick={(e) => handleMenuClick(e, menu.nodes)}
                aria-expanded={isOpen}
            >
                {menu.name}
            </button>
            {isOpen && (
                <div className="bwp-menu-bar__submenu" ref={subMenuRef} style={subMenuStyle}>
                    <ul>
                        {menu.nodes.map((submenu) => (
                            <li key={`menu${submenu.url}_${submenu.name}`}>
                                <a href={submenu.url}>{submenu.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

export interface MenuProps {
    menu: NavNode;
    isActive: boolean;
}

export function Menu({ menu, isActive }: MenuProps) {
    return (
        <li className={isActive ? "bwp-active" : ""}>
            <a href={menu.url || "#"}>{menu.name}</a>
        </li>
    );
}

function thisOrParentElement(element: HTMLElement, matcher: (e: HTMLElement) => boolean) {
    if (matcher(element)) {
        return true;
    } else {
        if (element.parentElement != null) {
            return thisOrParentElement(element.parentElement, matcher);
        }
    }

    return false;
}
