import React from "react";
import {useLodgingsLazyQuery} from "@bwp/operations.generated";
import {LodgingsMapper} from "./LodgingPresentationWrapper";

export default function lazyLoadLodgingsWrapper(WrappedComponent, mapper: LodgingsMapper) {
    return function LazyLoadLodgingsComponentWrapper(props) {
        const [requestLodgings, { data }] = useLodgingsLazyQuery({ ssr: false });

        return (
            <WrappedComponent
                {...props}
                requestLodgings={requestLodgings}
                lodgings={data ? mapper(data) : []}
            />
        );
    };
}