import { LodgingListSetting } from "./types";
import { SearchContext } from "./searchcontext";

export function lodgingListSettingToSearchContext(settings: LodgingListSetting) {
    let context = new SearchContext();
    if (!settings) {
        return context;
    }

    if (settings.locations.length > 0) {
        context = context.changeLocationIds(settings.locations);
    }

    if (settings.facilities.length > 0) {
        for (let facility of settings.facilities) {
            if (facility.value !== null) {
                context = context.changeFacilityValue(facility.facilityId, facility.value, "equal");
            }
            if (facility.minValue !== null || facility.maxValue !== null) {
                let from = facility.minValue;
                let to = facility.maxValue;

                if (from.length == 0 && to.length > 0) {
                    context = context.changeFacilityValue(facility.facilityId, to, "max");
                }
                if (to.length == 0 && from.length > 0) {
                    context = context.changeFacilityValue(facility.facilityId, from, "min");
                }
                if (from.length > 0 && to.length > 0) {
                    context = context.changeFacilityRange(facility.facilityId, {
                        min: parseInt(from),
                        max: parseInt(to),
                    });
                }
            }
        }
    }

    return context;
}
