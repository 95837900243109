import * as React from "react";
import format from "date-fns/format";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import subDays from "date-fns/subDays";
import * as queryString from "query-string";
import { CalendarValue, FacilityValueComparison } from "../types";
import {isValidDate, facilityKey, defaultDateValue} from "../helpers";
import slugify from "slugify";
import { isDate, parse } from "date-fns";

type Dictionary = { [key: string]: string };
const contextPrefix = "context:";
export const IgnoredContextNames = ["lodging-address1", "lodging-name", "lodging-location"];

function parseQueryStringDate(date: string | string[]) {
    if (!date) {
        return null;
    }
    const dateStr = Array.isArray(date) ? date[0] : date;
    const tokens = dateStr.split("-");
    return new Date(Date.parse(tokens[2] + "-" + tokens[0] + "-" + tokens[1]));
}

export interface SearchContextProps {
    adults?: number;
    allItemPrices?: boolean;
    arrival?: string;
    arrivalDensity?: number;
    children?: number;
    duration?: number;
    durations?: number[];
    facilityValues?: Dictionary;
    infants?: number;
    locationIds?: number[];
    lodgingId?: number;
    lodgingIds?: number[];
    page?: number;
    pageSize?: number;
    pets?: number;
    priceFrom?: number;
    priceTo?: number;
}

export interface SearchContextValues {
    adults?: string;
    allItemPrices?: string;
    arrival?: string;
    arrivalDensity?: number;
    children?: string;
    duration?: string;
    durations?: string[];
    facilityValues?: Dictionary;
    infants?: string;
    locationIds?: string[];
    lodgingId?: string;
    lodgingIds?: string[];
    page?: string;
    pageSize?: string;
    pets?: string;
    priceFrom?: string;
    priceTo?: string;
}

export interface SearchContextRouterOptions {
    getPathname?: () => string;
    getSearch?: () => string;
}

export type RoutePropertyMap = { [key: string]: string };

export type RouteValueMap = {
    [key: string]: RoutePropertyMap;
};

export interface SearchContextRouterConfig {
    url: string;
    map: RouteValueMap;
}

export function serializeNumber(value: number) {
    if (value === null || value === undefined) {
        throw new Error("Argument value for serializeNumber is not valid");
    }

    return value.toString();
}

export function deserializeNumber(value: string) {
    if (value === null || value === undefined) {
        return undefined;
    }

    return parseInt(value, 10);
}

export function serializeString(value: string) {
    if (value === null || value === undefined) {
        throw new Error("Argument value for serializeString is not valid");
    }

    return slugify(value).toLowerCase();
}

export function serializeDate(date: Date) {
    if (!isValidDate(date)) {
        throw new Error("Invalid date given to serializeDate");
    }

    return format(date, "MM-dd-yyyy");
}

export function deserializeDate(value: string) {
    return parseQueryStringDate(value);
}

export function serializeNumberArray(numbers: number[]) {
    if (numbers === null || numbers === undefined) {
        throw new Error("Invalid numbers array given to serializeNumbersArray");
    }

    return numbers.join(",");
}

export function deserializeNumberArray(value: string) {
    if (value === null || value === undefined) {
        return undefined;
    }

    return value
        .split(",")
        .map((p) => parseInt(p, 10))
        .filter((n) => !isNaN(n));
}

/**
 * Parses the query object and returns all the keys that start with fac, as a object with the facility id and the value
 */
function queryStringToFacilityValues(query: any): Dictionary {
    const facilityValues: Dictionary = {};
    Object.keys(query)
        .filter((name) => name.startsWith("fac"))
        .forEach((name) => {
            facilityValues[name] = query[name];
        });

    return facilityValues;
}

/**
 * Parses the query object and returns all the keys that start with context:, as a object with the key and the value
 */
function queryStringToContextValues(query: any): Dictionary {
    const contextValues: Dictionary = {};
    Object.keys(query)
        .filter((name) => name.startsWith(contextPrefix))
        .forEach((name) => {
            contextValues[name.substring(contextPrefix.length)] = query[name];
        });

    return contextValues;
}

/**
 * The SearchContext is a object that contains information about a search in BookingStudio.
 * It can convert to and from a querystring. That querystring can then be converted to a C# SearchContext on the backend and used in the API
 */
export class SearchContext {
    private _adults = null as number;
    private _allItemPrices = false;
    private _arrival = null as Date;
    private _arrivalDensity = null as number;
    private _children = null as number;
    private _contextValues = {} as Dictionary;
    private _duration = null as number;
    private _durations = null as number[];
    private _facilityValues = {} as Dictionary;
    private _infants = null as number;
    private _locationIds = null as number[];
    private _lodgingId = null as number;
    private _lodgingIds = null as number[];
    private _page = null as number;
    private _pageSize = null as number;
    private _pets = null as number;
    private _preset = null as SearchContext;
    private _priceFrom = null as number;
    private _priceTo = null as number;

    get pageSize() {
        return this._pageSize;
    }

    get page() {
        return this._page;
    }

    get adults() {
        return this._adults;
    }
    get children() {
        return this._children;
    }
    get infants() {
        return this._infants;
    }
    get pets() {
        return this._pets;
    }
    get duration() {
        return this._duration;
    }
    get durations() {
        return this._durations;
    }
    get lodgingId() {
        return this._lodgingId;
    }
    get lodgingIds() {
        return this._lodgingIds;
    }
    get arrival() {
        return this._arrival;
    }
    get arrivalDensity() {
        return this._arrivalDensity;
    }
    get parsedArrival() {
        if (!isValidDate(this._arrival)) {
            return null;
        }

        return {
            day: this._arrival.getDate(),
            month: this._arrival.getMonth() + 1,
            year: this._arrival.getFullYear(),
        };
    }
    get priceFrom() {
        return this._priceFrom;
    }
    get priceTo() {
        return this._priceTo;
    }
    get locationIds() {
        return this._locationIds;
    }
    get facilityValues() {
        return this._facilityValues;
    }
    get allItemPrices() {
        return this._allItemPrices;
    }

    get contextValues() {
        return this._contextValues;
    }

    get preset() {
        return this._preset;
    }

    changeLodging = (lodgingId: number) => {
        const clone = this.clone();
        clone._lodgingId = lodgingId;
        return clone;
    };

    changeLodgings = (lodgingIds: number[]) => {
        const clone = this.clone();
        clone._lodgingIds = lodgingIds;
        return clone;
    };

    changeDuration = (value: number) => {
        const clone = this.clone();
        clone._duration = value;
        return clone;
    };
    
    changeDurations = (values: number[]) => {
        const clone = this.clone();
        clone._durations = values;
        return clone;
    }

    changeAdults = (adults: number) => {
        const clone = this.clone();
        clone._adults = adults;
        return clone;
    };

    changeChildren = (children: number) => {
        const clone = this.clone();
        clone._children = children;
        return clone;
    };

    changeInfants = (infants: number) => {
        const clone = this.clone();
        clone._infants = infants;
        return clone;
    };

    changePets = (pets: number) => {
        const clone = this.clone();
        clone._pets = pets;
        return clone;
    };

    changePersons = (adults: number, children: number, infants: number, pets: number) => {
        const clone = this.clone();
        clone._adults = adults;
        clone._children = children;
        clone._infants = infants;
        clone._pets = pets;
        return clone;
    };

    changeArrival = (arrival: Date | CalendarValue) => {
        let value: Date;
        if (isDate(arrival)) {
            value = arrival as Date;
        } else {
            let cArrival = arrival as CalendarValue;
            value = new Date(cArrival.year, cArrival.month - 1, cArrival.day, 0, 0, 0);
        }

        const clone = this.clone();
        clone._arrival = value;
        return clone;
    };

    changeArrivalDensity = (value: number) => {
        const clone = this.clone();
        clone._arrivalDensity = value;
        return clone;
    };

    changeDeparture = (departure: Date) => {
        let arrival = this.arrival;
        let duration = this.duration;

        // Handle where arrival hasn't been set.
        // Set arrival to departure minus duration (default default is one week)
        if (!arrival) {
            if (duration) {
                duration = 7;
            }
            arrival = subDays(departure, duration);
        }

        // Handle where departure is before arrival.
        // Move arrival to be same amount before departure
        if (differenceInCalendarDays(departure, arrival) < 0) {
            arrival = subDays(departure, duration);
        }

        const diffInDays = differenceInCalendarDays(departure, arrival);

        let clone = this.changeDuration(diffInDays);
        if (arrival !== this.arrival) {
            clone = clone.changeArrival(arrival);
        }
        return clone;
    };

    changePage = (value: number) => {
        const clone = this.clone();
        clone._page = value;
        return clone;
    };

    changePageSize = (value: number) => {
        const clone = this.clone();
        clone._pageSize = value;
        return clone;
    };

    changeLocationId = (locationId: number) => {
        const clone = this.clone();
        if (locationId !== null) {
            clone._locationIds = [locationId];
        } else {
            clone._locationIds = [];
        }
        return clone;
    };

    changeLocationIds = (locationIds: number[]) => {
        const clone = this.clone();
        clone._locationIds = locationIds;
        return clone;
    };

    changeAllItemPrices = (allItemPrices: boolean) => {
        const clone = this.clone();
        clone._allItemPrices = allItemPrices;
        return clone;
    };

    changeFacilityValue = (
        id: number,
        value: string,
        comparison: FacilityValueComparison = "equal"
    ) => {
        const clone = this.clone();
        clone._facilityValues[facilityKey(id, comparison)] = value;
        return clone;
    };

    changeContextValue = (name: string, value: string) => {
        const clone = this.clone();
        clone._contextValues[name] = value;
        return clone;
    };

    changeFacilityRange = (id: number, range: { min: number; max: number | null }) => {
        const clone = this.clone();
        clone._facilityValues[facilityKey(id, "min")] = range.min?.toString();
        clone._facilityValues[facilityKey(id, "max")] = range.max?.toString();
        return clone;
    };

    getFacilityRange = (id: number) => {
        if (typeof id === "undefined") {
            console.error("Facility id is undefined");
            return null;
        }
        
        let minRaw = this._facilityValues[facilityKey(id, "min")];
        let maxRaw = this._facilityValues[facilityKey(id, "max")];

        let min: number = null;
        let max: number = null;

        if (minRaw !== undefined && minRaw !== null) {
            min = parseInt(minRaw);
        }
        if (maxRaw !== undefined && maxRaw !== null) {
            max = parseInt(maxRaw);
        }

        return { min, max };
    };

    getFacilityEqualValue = (id: number) => {
        return this.facilityValues[facilityKey(id, "equal")];
    };

    canSearchBookingOptions = () => {
        return (
            (this._adults || 0) + (this._children || 0) > 0 &&
            (
                (
                    this._duration &&
                    this._duration > 0
                ) || (
                    this._durations &&
                    this._durations.length &&
                    this._durations.every(dur => dur > 0)
                )
            ) &&
            this._arrival &&
            isValidDate(this._arrival)
        );
    };

    canChangeFacilityValue(facilityId: number) {
        if (this.preset == null) {
            return true;
        } else {
            let strFacilityId = "fac" + facilityId.toString();
            return (
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId) &&
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId + "_min") &&
                Object.keys(this.preset.facilityValues).every((f) => f != strFacilityId + "_max")
            );
        }
    }

    static createFromQueryStringWithPresetsAndDefaults = (qs: string, presets: SearchContextProps, defaults: SearchContextProps) => {
        let fromPresets = presets
            ? SearchContext.createFromSearchContextProps(presets)
            : null;
        let fromQueryString = SearchContext.createFromQueryString(qs);
        
        if (fromPresets) {
            let context = SearchContext.createFromSearchContextProps(defaults).merge(fromQueryString);
            context.usePreset(fromPresets);
            return context;
        }
        
        return SearchContext.createFromSearchContextProps(defaults).merge(fromQueryString);
    };
    
    /**
     * Parses a querystring and returns an instance of the SearchContext
     */
    static createFromQueryString = (qs: string) => {
        const query = queryString.parse(qs);
        const instance = new SearchContext();
        const parseIntValue = (value: string | string[]) => {
            const parsedValue = Array.isArray(value) ? parseInt(value[0], 10) : parseInt(value, 10);

            if (isNaN(parsedValue)) {
                return null;
            }

            return parsedValue;
        };

        const parseFloatValue = (value: string | string[]) => {
            const parsedValue = Array.isArray(value) ? parseFloat(value[0]) : parseFloat(value);

            if (isNaN(parsedValue)) {
                return null;
            }

            return parsedValue;
        };

        const parseBooleanValue = (value: string | string[]) => {
            return Array.isArray(value)
                ? value[0]?.toLowerCase() === "true"
                : value?.toLowerCase() === "true";
        };

        instance._page = parseIntValue(query.pge) || 0;
        instance._pageSize = parseIntValue(query.psz) || null;
        instance._adults = parseIntValue(query.adu);
        instance._children = parseIntValue(query.chi);
        instance._infants = parseIntValue(query.inf);
        instance._pets = parseIntValue(query.pet);
        instance._duration = parseIntValue(query.dur);
        instance._lodgingId = parseIntValue(query.lod);
        instance._lodgingIds = query.lods
            ? (query.lods as string).split(",").map((x) => parseInt(x, 10))
            : [];
        instance._arrival = parseQueryStringDate(query.ari);
        instance._priceFrom = parseFloatValue(query.prfro);
        instance._priceTo = parseFloatValue(query.prto);
        instance._locationIds = query.loc
            ? (query.loc as string).split(",").map((x) => parseInt(x, 10))
            : [];
        instance._allItemPrices = parseBooleanValue(query.itp);
        instance._facilityValues = queryStringToFacilityValues(query);
        instance._contextValues = queryStringToContextValues(query);
        return instance;
    };

    static createFromSearchContextProps = (props: SearchContextProps) => {
        const searchContext = new SearchContext();
        searchContext._duration = props.duration;
        searchContext._durations = props.durations;
        searchContext._arrival = parse(props.arrival, "MM-dd-yyyy", new Date());
        searchContext._adults = props.adults;
        searchContext._children = props.children;
        searchContext._infants = props.infants;
        searchContext._pets = props.pets;
        searchContext._locationIds = props.locationIds;
        searchContext._facilityValues = props.facilityValues || {};
        searchContext._lodgingId = props.lodgingId;
        searchContext._lodgingIds = props.lodgingIds;
        searchContext._page = props.page || 0;
        searchContext._pageSize = props.pageSize || null;
        searchContext._allItemPrices = props.allItemPrices || false;
        return searchContext;
    };

    private buildQuery = (ignored: string[], ignorePresets: boolean) => {
        const result = [];
        
        // We add a element to the querystring and omit empty values
        const add = (propertyName: string, qsName: string, value: string) => {
            const isIgnored = ignored.indexOf(propertyName) > -1;
            if (value && !isIgnored) {
                result.push(qsName + "=" + encodeURIComponent(value.toString()));
            }
        };
        
        const addPart = (propertyName: string, qsName: string, value: string | number, preset: string | number) => {
            if (preset && !ignorePresets) {
                add(propertyName, qsName, preset.toString());
            } else if (value) {
                add(propertyName, qsName, value.toString());
            }
        };

        const addBooleanPart = (propertyName: string, qsName: string, value: boolean, preset: boolean) => {
            if (typeof preset === "boolean" && !ignorePresets) {
                add(propertyName, qsName, preset ? "true" : "");
            } else if (typeof value === "boolean") {
                add(propertyName, qsName, value ? "true" : "");
            }
        };        
        
        const addMultiPart = (propertyName: string, qsName: string, value: string[] | number[], preset: string[] | number[]) => {
            if (preset && preset.length && !ignorePresets) {
                const sorted = [...preset];
                sorted.sort();
                add(propertyName, qsName, sorted.join(","));
            } else if (value && value.length) {
                const sorted = [...value];
                sorted.sort();
                add(propertyName, qsName, sorted.join(","));
            }
        };
        
        const addDatePart = (propertyName: string, qsName: string, value: Date, preset: Date) => {
            if (preset && isValidDate(preset) && !ignorePresets) {
                add(propertyName, qsName, format(preset, "MM-dd-yyyy"));
            } else if (value && isValidDate(value)) {
                add(propertyName, qsName, format(value, "MM-dd-yyyy"));
            }
        };
        
        const addKeyedValues = (value: Dictionary, preset: Dictionary, prefix: string = "") => {
            const valueKeys = Object.keys(value ?? {});
            const presetKeys = ignorePresets
                ? []
                : Object.keys(preset ?? {});
            
            const keys = presetKeys.concat(valueKeys.filter(k => presetKeys.indexOf(k) === -1));
            keys.sort();
            
            keys.forEach(key => addPart(key, prefix + key, value?.[key], preset?.[key]));
        }
        
        addPart("adults", "adu", this.adults, this.preset?.adults);
        addPart("children", "chi", this.children, this.preset?.children);
        addPart("infants", "inf", this.infants, this.preset?.infants);
        addPart("pets", "pet", this.pets, this.preset?.pets);
        if (typeof this.duration === "number" && this.duration > 0) {
            addPart("duration", "dur", this.duration, this.preset?.duration);    
        } else if (Array.isArray(this.durations) && this.durations.length) {
            addMultiPart("durations", "dur", this.durations, this.preset?.durations);
        }
        addPart("lodgingId", "lod", this.lodgingId, this.preset?.lodgingId);
        addMultiPart("lodgingIds", "lods", this.lodgingIds, this.preset?.lodgingIds);
        addDatePart("arrival", "ari", this.arrival, this.preset?.arrival);
        addPart("arrivalDensity", "ari_density", this.arrivalDensity, this.preset?.arrivalDensity);
        addPart("priceFrom", "prfro", this.priceFrom, this.preset?.priceFrom);
        addPart("priceTo", "prto", this.priceTo, this.preset?.priceTo);
        addPart("page", "pge", this.page, this.preset?.page);
        addPart("pageSize", "psz", this.pageSize, this.preset?.pageSize);
        addMultiPart("locationIds", "loc", this.locationIds, this.preset?.locationIds);
        addBooleanPart("allItemPrices", "itp", this.allItemPrices, this.preset?.allItemPrices);
        addKeyedValues(this.facilityValues, this.preset?.facilityValues);
        addKeyedValues(this.contextValues, null, contextPrefix);

        // We combine the parts and returns the result
        return result.join("&");
    };

    /**
     * Converts the SearchContext to a querystring that can be used in the backend. Is not prefixed by "?"
     */
    public toQueryString = (ignored: string[] = [], ignorePresets: boolean = false) => {
        const ignoredParams = [...IgnoredContextNames].concat(ignored);
        return this.buildQuery(ignoredParams, ignorePresets);
    }
    
    public toServerQuery = () => {
        return this.buildQuery([], false);
    }

    public toUrl(router: SearchContextRouter) {
        return router.makeUrl(this);
    }

    toProps = (): SearchContextProps => {
        let merged: SearchContext = this.clone();
        if (this.preset) {
            merged.merge(this.preset);
        }
        
        const props: SearchContextProps = {};
        props.adults = merged.adults;
        props.children = merged.children;
        props.infants = merged.infants;
        props.pets = merged.pets;
        props.duration = merged.duration;
        props.durations = merged.durations;
        props.arrival = isValidDate(merged.arrival)
            ? format(merged.arrival, "MM-dd-yyyy")
            : undefined;
        props.page = merged.page;
        props.pageSize = merged.pageSize;
        props.allItemPrices = merged.allItemPrices;
        props.lodgingId = merged.lodgingId;
        props.lodgingIds = merged.lodgingIds;
        props.facilityValues = merged.facilityValues;
        props.locationIds = merged.locationIds;
        props.arrivalDensity = merged.arrivalDensity;
        props.priceFrom = merged.priceFrom;
        props.priceTo = merged.priceTo;
        return props;
    };

    values = (): SearchContextValues => {
        return {
            arrival: (this.arrival && format(this.arrival, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")) || "",
            duration: (this.duration && this.duration.toString()) || "",
            durations: (Array.isArray(this.durations) && this.durations.length)
                ? this.durations.map(d => d.toString())
                : [],
            adults: this.adults !== null ? this.adults.toString() : "",
            children: this.children !== null ? this.children.toString() : "",
            infants: this.infants !== null ? this.infants.toString() : "",
            pets: this.pets !== null ? this.pets.toString() : "",
            lodgingId: (this.lodgingId && this.lodgingId.toString()) || "",
            lodgingIds: this.lodgingIds !== null ? this.lodgingIds.map((id) => id.toString()) : [],
            locationIds:
                this.locationIds !== null ? this.locationIds.map((id) => id.toString()) : [],
            facilityValues: this.facilityValues !== null ? this.facilityValues : {},
            page: this.page !== null ? this.page.toString() : "",
            pageSize: this.pageSize !== null ? this.pageSize.toString() : "",
            priceFrom: this.priceFrom !== null ? this.priceFrom.toString() : "",
            priceTo: this.priceTo !== null ? this.priceTo.toString() : "",
            allItemPrices: this.allItemPrices ? "true" : "false",
        };
    };

    clone = () => {
        const context = new SearchContext();
        context._adults = this._adults;
        context._children = this._children;
        context._infants = this._infants;
        context._pets = this._pets;
        context._arrival = this._arrival;
        if (typeof this._arrivalDensity === "number") context._arrivalDensity = this._arrivalDensity;
        context._duration = this._duration;
        if (this._durations) context._durations = [ ...this._durations ];
        context._lodgingId = this._lodgingId;
        if (this._lodgingIds) context._lodgingIds = [ ...this._lodgingIds ];
        context._allItemPrices = this._allItemPrices;
        if (this._contextValues) context._contextValues = { ...this._contextValues };
        if (this._locationIds) context._locationIds = [ ...this._locationIds ];
        if (this._facilityValues) context._facilityValues = { ...this._facilityValues };
        if (this._locationIds) context._locationIds = [ ...this._locationIds ];
        context._page = this._page;
        context._pageSize = this._pageSize;
        context._priceFrom = this._priceFrom;
        context._priceTo = this._priceTo;
        if (this._preset) context._preset = this._preset.clone();
        return context;
    };

    merge(other: SearchContext) {
        
        if (typeof other._adults === "number") this._adults = other._adults;
        if (typeof other._children === "number") this._children = other._children;
        if (typeof other._infants === "number") this._infants = other._infants;
        if (typeof other._pets === "number") this._pets = other._pets;
        if (isValidDate(other._arrival)) this._arrival = other._arrival;
        if (typeof other._arrivalDensity === "number") this._arrivalDensity = other._arrivalDensity;
        if (typeof other._duration === "number") this._duration = other._duration;
        if (Array.isArray(other.durations) && other.durations.length) this._durations = other._durations;
        if (other._lodgingId) this._lodgingId = other._lodgingId;
        if (other._lodgingIds) this._lodgingIds = this._lodgingIds
            ? this._lodgingIds.concat(other._lodgingIds.filter(lid => this._lodgingIds.indexOf(lid) === -1))
            : [ ...other._lodgingIds ];
        if (other._allItemPrices) this._allItemPrices = other._allItemPrices;
        if (other._contextValues) this._contextValues = this._contextValues 
            ? { ...this._contextValues, ...other._contextValues }
            : { ...other._contextValues };
        if (other._locationIds) this._locationIds = other._locationIds;
        if (other._facilityValues) this._facilityValues = this._facilityValues
            ? { ...this.facilityValues, ...other._facilityValues }
            : { ...other._facilityValues };
        if (other._locationIds) this._locationIds = this._locationIds
            ? this._locationIds.concat(other._locationIds.filter(lid => this._locationIds.indexOf(lid) === -1))
            : [ ... other._locationIds ];
        if (typeof other._page === "number") this._page = other._page;
        if (other._pageSize) this._pageSize = other._pageSize;
        if (other._priceFrom) this._priceFrom = other._priceFrom;
        if (other._priceTo) this._priceTo = other._priceTo;
        if (other._preset) this._preset = this._preset
            ? this._preset.merge(other._preset)
            : other._preset.clone();
        
        return this;
    }

    usePreset(preset: SearchContext) {
        this._preset = preset;
        return this;
    }

    static isSearchContextQueryStringKeyName(name: string) {
        if (
            name == "adu" ||
            name == "chi" ||
            name == "inf" ||
            name == "pet" ||
            name == "dur" ||
            name == "lod" ||
            name == "lods" ||
            name == "ari" ||
            name == "ari_density" ||
            name == "prfro" ||
            name == "prto" ||
            name == "pge" ||
            name == "psz" ||
            name == "loc" ||
            name == "itp"
        ) {
            return true;
        }

        if (/^fac\d+(_min|_max)?$/.test(name)) {
            return true;
        }

        return name.startsWith(contextPrefix);
    }
}

export class SearchContextRouter {
    private readonly url: string;
    private readonly routeValueMap: RouteValueMap;
    private readonly options: SearchContextRouterOptions;

    constructor(
        url: string,
        routeValueMap: RouteValueMap = null,
        options: SearchContextRouterOptions = null
    ) {
        if (!url) {
            throw new Error("SearchContextRouter constructor parameter url is missing");
        }

        this.url = url.replace(/\/+$/, "");
        this.routeValueMap = routeValueMap;
        this.options = options;
    }

    static createFromConfig = (config: SearchContextRouterConfig) => {
        return new SearchContextRouter(config.url, config.map);
    };

    matchesUrl = (url: string = null) => {
        const patternUrlPath = this.splitPathSeqments(this.url);
        const currentUrlPath = url ? this.splitPathSeqments(url) : this.getValues();

        for (let i = 0; i < patternUrlPath.length; i++) {
            if (i < currentUrlPath.length) {
                if (!SearchContextRouter.pathSegmentIsParameterKey(patternUrlPath[i]) && patternUrlPath[i] !== currentUrlPath[i]) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    };

    getFieldConfig = () => {
        return {
            arrival: {
                read: (value) => deserializeDate(value),
                write: (value) => serializeDate(value),
            },
            duration: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            adults: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            children: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            infants: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            pets: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            locationIds: {
                read: (value) => deserializeNumberArray(value),
                write: (value) => serializeNumberArray(value),
            },
            lodgingId: {
                read: (value) => deserializeNumber(value),
                write: (value) => serializeNumber(value),
            },
            lodgingIds: {
                read: (value) => deserializeNumberArray(value),
                write: (value) => serializeNumberArray(value),
            },
        };
    };

    getSearchContext = (searchContext: SearchContext = null) => {
        let newSearchContext =
            searchContext || SearchContext.createFromQueryString(this.getSearch());
        const parameters = this.getParameters();
        const fieldConfig = this.getFieldConfig();

        if (parameters === null) {
            return newSearchContext;
        }

        // Override with path parameters (if not set in query string)
        Object.keys(parameters).forEach((key) => {
            if (Object.keys(fieldConfig).indexOf(key) > -1) {
                const value = fieldConfig[key].read(parameters[key]);
                if (key === "arrival" && newSearchContext.arrival === null) {
                    newSearchContext = newSearchContext.changeArrival(value);
                } else if (key === "duration" && newSearchContext.duration === null) {
                    newSearchContext = newSearchContext.changeDuration(value);
                } else if (key === "adults" && newSearchContext.adults === null) {
                    newSearchContext = newSearchContext.changePersons(
                        value,
                        newSearchContext.children,
                        newSearchContext.infants,
                        newSearchContext.pets
                    );
                } else if (key === "children" && newSearchContext.children === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        value,
                        newSearchContext.infants,
                        newSearchContext.pets
                    );
                } else if (key === "infants" && newSearchContext.infants === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        newSearchContext.children,
                        value,
                        newSearchContext.pets
                    );
                } else if (key === "pets" && newSearchContext.pets === null) {
                    newSearchContext = newSearchContext.changePersons(
                        newSearchContext.adults,
                        newSearchContext.children,
                        newSearchContext.infants,
                        value
                    );
                } else if (key === "locationIds" && newSearchContext.locationIds === null) {
                    newSearchContext = newSearchContext.changeLocationIds(value);
                } else if (key === "lodgingId" && newSearchContext.lodgingId === null) {
                    newSearchContext = newSearchContext.changeLodging(value);
                } else if (key === "lodgingIds" && newSearchContext.lodgingIds === null) {
                    newSearchContext = newSearchContext.changeLodgings(value);
                }
            }
        });

        return newSearchContext;
    };

    makeUrl = (searchContext: SearchContext) => {
        const parameters = this.getParameters();
        const fieldConfig = this.getFieldConfig();
        const ignored = Object.keys(parameters);
        const lodgingId = searchContext.lodgingId;
        const routeValueProperties =
            this.routeValueMap !== null && this.routeValueMap !== undefined
                ? Object.keys(this.routeValueMap)
                : [];

        // Replace keywords with values in url
        let processedUrl = this.url;
        Object.keys(parameters).forEach((key) => {
            // Is it a normal field (and the search context has a value)
            if (Object.keys(fieldConfig).indexOf(key) > -1 && searchContext[key]) {
                processedUrl = processedUrl.replace(
                    "{" + key + "}",
                    fieldConfig[key].write(searchContext[key])
                );
                // Is it route value property (and we know the lodging id)
            } else if (routeValueProperties.indexOf(key) > -1 && lodgingId) {
                const value = this.routeValueMap[key][lodgingId.toString()];
                processedUrl = processedUrl.replace("{" + key + "}", serializeString(value));
            }
        });
        if (!processedUrl.endsWith("/")) {
            processedUrl += "/";
        }

        // Append querystring with keywords ignored
        const queryString = searchContext.toQueryString(ignored);
        return queryString ? processedUrl + "?" + queryString : processedUrl;
    };

    getParameters() {
        const keys = this.getKeys();
        const values = this.getValues();
        const isRouterUrl = this.matchesUrl();

        const parameters: any = {};
        for (let i = 0; i < keys.length; i++) {
            if (SearchContextRouter.pathSegmentIsParameterKey(keys[i])) {
                if (isRouterUrl) {
                    parameters[keys[i].replace(/^{/, "").replace(/}$/, "")] =
                        values.length > i ? values[i] : undefined;
                } else {
                    parameters[keys[i].replace(/^{/, "").replace(/}$/, "")] = undefined;
                }
            }
        }

        return parameters;
    }

    static pathSegmentIsParameterKey(segment: string) {
        if (!segment) {
            return false;
        }

        return segment.startsWith("{") && segment.endsWith("}");
    }

    private getPathname = () => {
        if (
            this.options &&
            this.options.getPathname &&
            typeof this.options.getPathname === "function"
        ) {
            return this.options.getPathname();
        }
        return window.location.pathname;
    };

    private getSearch = () => {
        if (
            this.options &&
            this.options.getSearch &&
            typeof this.options.getSearch === "function"
        ) {
            return this.options.getSearch();
        }
        return window.location.search;
    };

    private getKeys = () => {
        return this.splitPathSeqments(this.url);
    };

    private getValues = () => {
        return this.splitPathSeqments(this.getPathname());
    };

    private splitPathSeqments = (path: string) => {
        if (!path) {
            return [];
        }

        return path.split("/").filter((part) => part !== "");
    };
}

export interface BookingOptionSearchContextProps {
    arrival: Date;
    duration: number;
    lodgingId: number;
    adults: number;
    children: number;
    infants: number;
    pets: number;
}

export function getSearchContextPropsFromBookingOption({ arrival, duration, lodgingId, adults, children, infants, pets }: BookingOptionSearchContextProps) {
    let props = {} as SearchContextProps;

    if (isValidDate(arrival)) {
        props.arrival = serializeDate(arrival);
    }
    props.duration = duration;
    props.lodgingId = lodgingId;
    props.adults = adults;
    if (0 <= children) {
        props.children = children;
    }
    if (0 <= infants) {
        props.infants = infants;
    }
    if (0 <= pets) {
        props.pets = pets;
    }
    return props;
}

export interface WithSearchContextProps {
    onSearchContextChanged?: (searchContext: SearchContext) => void;
    searchContext?: SearchContext;
}

interface ComponentWithSearchContextState {
    searchContext: SearchContext;
}

export const withSearchContext = <P extends WithSearchContextProps>(
    WrappedComponent: React.ComponentType<P>
) =>
    class ComponentWithSearchContext extends React.Component<
        P & WithSearchContextProps,
        ComponentWithSearchContextState
    > {
        state = {
            searchContext: undefined,
        };

        handleSearchContextChanged = (searchContext: SearchContext) => {
            const { onSearchContextChanged } = this.props;
            this.setState({ searchContext });
            if (typeof onSearchContextChanged === "function") {
                onSearchContextChanged(searchContext);
            }
        };

        render() {
            return (
                <WrappedComponent
                    searchContext={this.state ? this.state.searchContext : null}
                    onSearchContextChanged={this.handleSearchContextChanged}
                    {...(this.props as P)}
                />
            );
        }
    };

export const StandardDefaultSearchContext = {
    adults: 2,
    children: 0,
    infants: 0,
    pets: null,
    arrival: defaultDateValue,
    duration: 7,
} as SearchContextProps;