import React, { useEffect, useRef, useState } from "react";
import { debounce } from "ts-debounce";
import { isBrowser } from "./helpers";
import { Position } from "./types";

export function usePositioned() {

    const ref: React.RefObject<any> = useRef(null);
    const [rect, setRect] = useState(null);
     
    const updateRect = () => {
        if (ref && ref.current) {
            const clientRect = ref.current.getBoundingClientRect();
            const newRect: Position = {
                width: clientRect.width,
                height: clientRect.height,
                x: clientRect.x + (isBrowser() ? window.scrollX : 0),
                y: clientRect.y + (isBrowser() ? window.scrollY : 0)
            };
            if (!rect || (
                (rect.width !== newRect.width) ||
                (rect.height !== newRect.height) ||
                (rect.x !== newRect.x) ||
                (rect.y !== newRect.y)
            )) {
                setRect(newRect);
            }
        }
    };

    useEffect(updateRect, [ref, rect, updateRect]);

    useEffect(() => {
        const resizeHandler = debounce(updateRect, 200);

        if (isBrowser()) {
            window.addEventListener("resize", resizeHandler);
        }

        return () => {
            if (isBrowser()) {
                window.removeEventListener("resize", resizeHandler);
            }
        };
    }, [ref, rect, updateRect]);

    return [ref, rect];
}