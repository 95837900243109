import {LodgingPresentationQuery, LodgingsQuery, useLodgingPresentationQuery} from "@bwp/operations.generated";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import useFavorites from "@bwp/shared/useFavorites";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import {StandardDefaultSearchContext} from "@bwp/shared/searchcontext";
import {isValidDate} from "@bwp/shared/helpers";
import {formatISO, parseISO} from "date-fns";

export type LodgingPresentationMapper = (input: LodgingPresentationQuery) => any;
export type LodgingsMapper = (input: LodgingsQuery) => any;

export default function LodgingPresentationWrapper(WrappedComponent, mapper: LodgingPresentationMapper) {
    return function Wrapper(props) {
        const defaults = props?.defaultValues || StandardDefaultSearchContext;
        const presets = props?.presets;
        let [searchContext] = useGlobalSearchContext({ presets: presets, defaultValues: defaults });
        
        let [cachedData, setCachedData] = useState<LodgingPresentationQuery>(null);
        
        let { data, loading, error } = useLodgingPresentationQuery({
            variables: {
                seasonPricesYear: searchContext.arrival.getFullYear(),
                query: searchContext.toServerQuery(),
                displayDate:  formatISO(searchContext.arrival, { representation: "date" }),
                numberOfReviews: 3,
            },
        });

        let [favorites, setFavorite] = useFavorites();

        let favorite = useMemo(() => {
            if (!data || !data.lodgingPresentation) {
                return false;
            }
            return favorites.includes(data.lodgingPresentation.lodging.id);
        }, [data, favorites]);

        useEffect(() => {
            if (data != null) {
                setCachedData(data);
            }
        }, [data]);

        const handleFavoriteChanged = useCallback(() => {
            if (data && data.lodgingPresentation) {
                setFavorite(data.lodgingPresentation.lodging.id);
            }
        }, [data]);

        const handleOnBook = useCallback(() => {
            if (props.nextUrl == null) {
                alert("Unable to goto booking. No Next Url");
            } else {
                if (data && data.lodgingPresentation) {
                    window.location.href =
                        props.nextUrl +
                        "?lod=" +
                        data.lodgingPresentation.lodging.id +
                        "&" +
                        searchContext.toQueryString();
                }
            }
        }, [data, searchContext, props.nextUrl]);

        if (error) {
            return <GraphQLErrorPanel error={error} />;
        }

        if (loading && !cachedData) {
            return <div />;
        }

        return (
            <WrappedComponent
                {...mapper(data || cachedData)}
                {...props}
                breadcrumb={props.breadcrumb || []}
                favorite={favorite}
                onFavoriteChanged={handleFavoriteChanged}
                onBook={handleOnBook}
            />
        );
    };
}
