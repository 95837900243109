import React, {useCallback, useEffect, useMemo, useState} from "react";
import Checkout from "@bwp/Checkout";
import {
    CheckoutQueryVariables,
    CreateBookingMutation,
    useCheckoutQuery,
    useCreateBookingMutation,
    usePayRatesQuery,
    useTermsQuery,
} from "@bwp/operations.generated";
import {
    BookingRequestInput,
    CreateBookingResultType,
    PaymentOption as GraphQLPaymentOption
} from "@bwp/shared/graphql-types.generated";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import {CheckoutTranslations} from "@bwp/translations/CheckoutTranslations";
import {SearchBoxTranslations} from "@bwp/translations/SearchBoxTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import Spinner from "@bwp/Spinner";
import {BrowserRouter} from "react-router-dom";
import {FormFieldType, PaymentOption} from "@bwp/shared/types";
import {BookingSummaryBookingOptionFragment} from "@bwp/operations.generated";
import {
    allowBankTransfer,
    allowCreditCard,
    hasTwoCreditCardPaymentOptions,
    isLateMinute
} from "@bwp/shared/paymentoptions";
import {PeopleValue} from "@bwp/PeopleSelectWithLabel";
import {LodgingPageTranslations} from "@bwp/translations/LodgingPageTranslations";


export default function CheckoutWrapper({
    languageId,
    translations,
    lodgingTranslations,
    searchBoxTranslations,
    bathroomFacilityId,
    bedroomFacilityId,
    bookingOption,
    lodgingAreaFacilityId,
    internetFacilityId,
    defaultCountry,
    infoBoxContent,
    guestsFormAlertMessage,
    catalogueLabel,
    doneContactInformation,
    doneText,
    basePath,
    guestsRequired,
    hideGuests,
    address2FormFieldType,
    privateTelephoneFormFieldType,
    privateMobilephoneFormFieldType,
    workTelephoneFormFieldType,
    paymentOptions,
    allowCreditCardFullAmountWhenTwoRates,
    lateBookingCreditCardRequired,
    showInfants,
    showProbabilityWithPriceAsBookingOption,
    hideLodgingAddress
}: {
    languageId: number;
    translations: CheckoutTranslations;
    lodgingTranslations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    bathroomFacilityId: number;
    bedroomFacilityId: number;
    bookingOption: BookingSummaryBookingOptionFragment;
    lodgingAreaFacilityId: number;
    internetFacilityId: number;
    defaultCountry: string;
    infoBoxContent: string;
    guestsFormAlertMessage: string;
    catalogueLabel: string;        
    doneText: string;
    doneContactInformation: string;
    basePath: string;
    guestsRequired?: boolean;
    hideGuests?: boolean;
    address2FormFieldType?: FormFieldType;
    privateTelephoneFormFieldType?: FormFieldType;
    privateMobilephoneFormFieldType?: FormFieldType;
    workTelephoneFormFieldType?: FormFieldType;
    paymentOptions: PaymentOption[];
    allowCreditCardFullAmountWhenTwoRates: boolean;
    lateBookingCreditCardRequired: boolean;
    showInfants?: boolean;
    showProbabilityWithPriceAsBookingOption?: boolean;
    hideLodgingAddress?: boolean;
}) {
    const [searchContext, setSearchContext] = useGlobalSearchContext({ presets: {}, defaultValues: {} });
    
    const [request, setRequest] = useState<BookingRequestInput>({
        bookingOption: {
            adults: searchContext.adults,
            children: searchContext.children ?? 0,
            arrival: searchContext.arrival,
            duration: searchContext.duration,
            infants: searchContext.infants ?? 0,
            languageId: languageId,
            lodgingId: searchContext.lodgingId,
            pets: searchContext.pets ?? 0,
        },
        items: []
    });

    const handlePeopleChange = (value: PeopleValue) => {
        const newSearchContext = searchContext.changePersons(
            value.adults,
            value.children,
            value.infants,
            value.pets
        );

        setSearchContext(newSearchContext);
        const numberOfGuests = (value.adults || 0) + (value.children || 0);
        const guests = (request.guests && Array.isArray(request.guests))
            ? request.guests.length > numberOfGuests
                ? [...request.guests].slice(0, numberOfGuests)
                : [...request.guests]
            : [];
        
        if (guests.length < numberOfGuests) {
            const blankGuest: BookingRequestInput["guests"][0] = {
                firstName: "",
                lastName: "",
                birthYear: null,
            };
            
            while (guests.length < numberOfGuests) {
                guests.push({ ...blankGuest });
            }
        }
        
        setRequest({
            ...request,
            bookingOption: {
                ...request.bookingOption,
                adults: newSearchContext.adults,
                children: newSearchContext.children,
                infants: newSearchContext.infants,
                pets: newSearchContext.pets
            },
            guests
        });
    }
    
    const [createBookingResult, setCreateBookingResult] = useState<CreateBookingMutation>(null);

    const checkoutQueryParameters = useMemo(() => {
        return {
            bookingOption: request.bookingOption,
            items: request.items,
        } as CheckoutQueryVariables["request"];
    }, [request]);

    const { data, loading, error } = useCheckoutQuery({
        variables: {
            request: checkoutQueryParameters,
            displayDate: request.bookingOption.arrival,
            bathroomFacilityId: bathroomFacilityId,
            bedroomFacilityId: bedroomFacilityId,
            houseAreaFacilityId: lodgingAreaFacilityId,
            internetFacilityId: internetFacilityId,
        },
    });

    const isProbability = showProbabilityWithPriceAsBookingOption
        ? data?.lodgingPresentation?.selectedBookingOption?.status === "Probability"
        : (data?.lodgingPresentation?.selectedBookingOption?.status === "Probability" || data?.lodgingPresentation?.selectedBookingOption?.status === "ProbabilityAsBooking");

    const payRatesQuery = usePayRatesQuery({
        variables: {
            request: {
                bookingOption: request.bookingOption,
                items: data?.checkoutPresentation?.itemLines
                    ? data.checkoutPresentation.itemLines.filter(il => il.quantity > 0).map(il => ({ quantity: il.quantity, itemId: il.item.id }))
                    : []
            },
        },
        skip: (isProbability || data?.checkoutPresentation?.itemLines === undefined),
    });
    
    const [payMethod, setPayMethod] = useState<PaymentOption>(null);
    
    useEffect(() => {
        const payRates = payRatesQuery?.data?.payRates;
        if (payRates && paymentOptions && paymentOptions.length) {
            const hasTwoRates = payRates.rate1 && payRates.rate2;
            const isLastMinute = isLateMinute(payRates.rate1);
            
            const filteredPaymentOptions = allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired)
                ? paymentOptions
                : paymentOptions.filter(po => po != PaymentOption.BankTransfer);
            
            const showPaymentOptions = (
                (allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) && allowCreditCard(paymentOptions)) ||
                (
                    allowCreditCard(paymentOptions) &&
                    hasTwoRates &&
                    hasTwoCreditCardPaymentOptions(filteredPaymentOptions, allowCreditCardFullAmountWhenTwoRates)
                )
            );
            
            const isPayMethodSet = (
                payMethod === PaymentOption.BankTransfer ||
                payMethod === PaymentOption.CreditCardOneRate ||
                payMethod === PaymentOption.CreditCardTwoRates
            );
            
            if (!showPaymentOptions && !isPayMethodSet) {
                if (allowCreditCard(paymentOptions)) {
                    setPayMethod(hasTwoRates ? PaymentOption.CreditCardTwoRates : PaymentOption.CreditCardOneRate);
                } else {
                    setPayMethod(PaymentOption.BankTransfer);
                }
            }
        }
    }, [payRatesQuery, paymentOptions, lateBookingCreditCardRequired, allowCreditCardFullAmountWhenTwoRates]);
    
    const termsQuery = useTermsQuery({
        variables: {
            lodgingId: searchContext.lodgingId,
            arrivalDate: searchContext.arrival
        },
    });

    const [cachedData, setCachedData] = useState(data);

    useEffect(() => {
        if (!loading && data) {
            setCachedData(data);
        }
    }, [loading, data]);

    const [createBooking] = useCreateBookingMutation();

    const handleBook = useCallback(() => {
        setCreateBookingResult(null);
        if (window.location.search.includes("test=1")) {
            setCreateBookingResult({
                createBooking: {
                    success: true,
                    result: {
                        resultType: CreateBookingResultType.OrderItem,
                        bookingOptionNoLongerAvailable: false,
                        orderItem: {
                            id: 1,
                            reservationId: 1337,
                            type: "invoice",
                        },
                    },
                },
            });
        } else {
            let graphQLPayMethod: GraphQLPaymentOption | null;
            if (payMethod === PaymentOption.CreditCardOneRate) {
                graphQLPayMethod = GraphQLPaymentOption.CreditCardWithOneRate;
            } else if (payMethod === PaymentOption.CreditCardTwoRates) {
                graphQLPayMethod = GraphQLPaymentOption.CreditCardWithTwoRates;
            } else {
                graphQLPayMethod = GraphQLPaymentOption.BankTransfer;
            }
            
            createBooking({
                variables: {
                    request,
                    payMethod: graphQLPayMethod
                },
            }).then(result => {
                setCreateBookingResult(result.data);
            });
        }
    }, [createBooking, payMethod, request]);

    if (loading && !cachedData) {
        return (
            <div style={{ display: "grid", justifyContent: "center", marginTop: "64px" }}>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }

    if (data?.checkoutPresentation.available === false) {
        return <div>Booking Not Avilable</div>;
    }

    return (
        <BrowserRouter basename={basePath}>
            <Checkout
                languageId={languageId}
                value={request}
                onChange={setRequest}
                onPeopleChange={handlePeopleChange}
                onPayMethodChange={setPayMethod}
                data={data || cachedData}
                translations={translations}
                lodgingTranslations={lodgingTranslations}
                searchBoxTranslations={searchBoxTranslations}
                onBook={handleBook}
                defaultCountry={defaultCountry}
                infoBoxContent={infoBoxContent}
                guestsFormAlertMessage={guestsFormAlertMessage}
                catalogueLabel={catalogueLabel}
                payRates={payRatesQuery.data}
                guestsRequired={guestsRequired}
                hideGuests={hideGuests}
                terms={termsQuery.data}
                createBookingResult={createBookingResult}
                doneText={doneText}
                doneContactInformation={doneContactInformation}
                address2FormFieldType={address2FormFieldType}
                privateTelephoneFormFieldType={privateTelephoneFormFieldType}
                privateMobilephoneFormFieldType={privateMobilephoneFormFieldType}
                workTelephoneFormFieldType={workTelephoneFormFieldType}
                payMethod={payMethod}
                paymentOptions={paymentOptions}
                allowCreditCardFullAmountWhenTwoRates={allowCreditCardFullAmountWhenTwoRates}
                lateBookingCreditCardRequired={lateBookingCreditCardRequired}
                showInfants={showInfants}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                hideLodgingAddress={hideLodgingAddress}
                searchContext={searchContext}/>
        </BrowserRouter>
    );
}
