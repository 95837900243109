import React, { useState } from "react";
import { ApolloError } from "@apollo/client";

export default function GraphQLErrorPanel({ error }: { error: ApolloError }) {
    const [showMoreInformation, setShowMoreInformation] = useState(false);

    return (
        <div
            style={{
                color: "#000",
                backgroundColor: "#fafafa",
                border: "2px solid rgb(255 152 152)",
                borderRadius: "5px",
                padding: "1em",
                overflow: "auto",
            }}
        >
            <div>
                <strong>Error</strong>
            </div>
            <div style={{ margin: "0.5em 0" }}>{error.message}</div>
            {!showMoreInformation && (
                <div>
                    <a
                        style={{ fontSize: "80%", color: "rgb(255 152 152)" }}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowMoreInformation(true);
                        }}
                    >
                        Show more information
                    </a>
                </div>
            )}
            {showMoreInformation && <div>{JSON.stringify(error)}</div>}
        </div>
    );
}
