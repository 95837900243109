import React from "react";

export default function KeyIcon() {
    return (
        <svg
            width={21}
            height={23}
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21 8.33305C21 12.4059 17.7681 15.7075 13.7812 15.7075C13.3211 15.7075 12.8711 15.663 12.4348 15.5789L11.45 16.7108C11.3576 16.817 11.2443 16.9019 11.1175 16.9601C10.9907 17.0183 10.8532 17.0483 10.7142 17.0483H9.1875V18.7244C9.1875 19.2798 8.74679 19.73 8.20312 19.73H6.5625V21.406C6.5625 21.9614 6.12179 22.4116 5.57812 22.4116H0.984375C0.440713 22.4116 0 21.9614 0 21.406V18.1353C0 17.8686 0.103729 17.6128 0.288299 17.4242L6.92471 10.6446C6.68998 9.91744 6.5625 9.14048 6.5625 8.33301C6.5625 4.26018 9.79441 0.958538 13.7812 0.958496C17.7798 0.958454 21 4.24816 21 8.33305ZM13.7812 6.32178C13.7812 7.43256 14.6627 8.33301 15.75 8.33301C16.8373 8.33301 17.7188 7.43256 17.7188 6.32178C17.7188 5.21099 16.8373 4.31055 15.75 4.31055C14.6627 4.31055 13.7812 5.21099 13.7812 6.32178Z"
                fill="black"
            />
        </svg>
    );
}
