import React, { useCallback } from "react";
import "./CheckBoxWithLabel.scss";

interface Props {
    label: React.ReactNode;
    value: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    required?: boolean;
    className?: string;
}

export default function CheckBoxWithLabel({
    label,
    value,
    onChange,
    disabled,
    required,
    className,
}: Props) {
    const handleOnChange = useCallback(
        (e: React.SyntheticEvent<HTMLInputElement>) => {
            onChange(e.currentTarget.checked);
        },
        [onChange]
    );

    const classNames = ["bwp-checkbox-with-label"];
    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(" ")}>
            <label>
                <input
                    type="checkbox"
                    checked={value}
                    onChange={handleOnChange}
                    disabled={disabled}
                    required={required}
                />{" "}
                <span>{label}</span>
            </label>
        </div>
    );
}
