import React, { useEffect, useMemo, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import { FavouritesTranslations } from "@bwp/translations/FavouritesTranslations";
import useLocalSearchContext from "@bwp/shared/useLocalSearchContext";
import { defaultValue } from "@bwp/shared/helpers";
import {formatISO, parse, parseISO} from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLastMinuteQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import {CleaningBannerType, DiscountBannerType, LodgingListSetting} from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import CompactDiscountLodgingList from "@bwp/CompactDiscountLodgingList";
import format from "date-fns/format";
import {getSearchContextPropsFromBookingOption, SearchContext} from "@bwp/shared/searchcontext";

export interface CompactDiscountLodgingListWrapperProps {
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    daysAhead: number;
    take?: number;
    lodgingListSetting: LodgingListSetting;
    tagFilter: string;
    emptyResultHtmlContent: string;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

export default function CompactDiscountLodgingListWrapper({
    lodgingCardTranslations,
    favouritesTranslations,
    lodgingUrlPattern,
    includeLodgingIdInUrl,
    lodgingListSetting,
    daysAhead,
    take,
    tagFilter,
    emptyResultHtmlContent,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: CompactDiscountLodgingListWrapperProps) {
    const [sorting] = useQueryString("ord", null);

    // TODO: These needs to be set somewhere
    const numberOfBedroomsFacilityId = 27;
    const numberOfBathroomsFacilityId = 35;
    const extraFacilities = [numberOfBedroomsFacilityId, numberOfBathroomsFacilityId];

    const [searchContext] = useLocalSearchContext({ defaultValue, lodgingListSetting });
    const query = useLastMinuteQuery({
        variables: {
            query: searchContext.toServerQuery(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: sorting as any,
            take,
            daysAhead,
            tagFilter,
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(4);

    useEffect(() => {
        if (query.data) {
            setNumberOfSeletons(query.data.lastMinute.results.length);
        }
    }, [query.data]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return (
            <div className="bwp-compact-lodging-list">
                <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />
            </div>
        );
    }

    const ignoredParams = includeLodgingIdInUrl
        ? []
        : ["lodgingId"];
    
    return (
        <>
            <CompactDiscountLodgingList
                lodgingCardTranslations={lodgingCardTranslations}
                favouritesTranslations={favouritesTranslations}
                {...query.data}
                lodgingUrlBuilder={(result) => {
                    const baseUrl = lodgingUrl(lodgingUrlPattern, result.lodging);
                    const separator = baseUrl.indexOf('?') === -1 ? "?" : "&";
                    const boProps = getSearchContextPropsFromBookingOption({
                        lodgingId: result.lodging.id,
                        arrival: parseISO(result.selectedBookingOption.arrival),
                        duration: result.selectedBookingOption.duration,
                        adults: result.selectedBookingOption.adults,
                        children: result.selectedBookingOption.children,
                        infants: result.selectedBookingOption.infants,
                        pets: result.selectedBookingOption.pets
                    });
                    const qs = SearchContext.createFromSearchContextProps(boProps).toQueryString(ignoredParams);
                    return `${baseUrl}${separator}${qs}`;
                }}
                extraFacilities={extraFacilities}
                emptyResultHtmlContent={emptyResultHtmlContent}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                showDepositSeparately={showDepositSeparately}
                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                discountBanner={discountBanner}
                cleaningBanner={cleaningBanner}
                hideLodgingAddress={hideLodgingAddress}
                showItems={showItems}
                itemsToShow={itemsToShow}
                maxItemsToShow={maxItemsToShow}
            />
        </>
    );
}
