import React, { useEffect, useMemo } from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LastMinuteQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import { parseISO } from "date-fns";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import { processTemplate } from "./shared/templates";
import { FavouritesTranslations } from "./translations/FavouritesTranslations";
import {isBrowser} from "./shared/helpers";
import {TrackLodgingListItem, trackViewList} from "./shared/tracking";
import {CleaningBannerType, DiscountBannerType} from "./shared/types";

export function registerTranslations() {
    return {
        "translations": "SearchResultList",
        "lodgingCardTranslations": "LodgingCard",
        "favouritesTranslations": "Favourites"
    };
}

export default function DiscountLodgingList({
    translations,
    favouritesTranslations,
    lodgingCardTranslations,
    lastMinute: { results },
    lodgingUrlBuilder,
    extraFacilities,
    emptyResultHtmlContent,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingUrlBuilder: (l: LastMinuteQuery["lastMinute"]["results"][0]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
} & LastMinuteQuery) {
    let [favorites, setFavorite] = useFavorites();

    let groupedResults = useMemo(() => {
        // First we group all the results into groups of "ticks" to be able to sort the dates and group them
        let byArrivalDate = results
            .filter((r) => r.selectedBookingOption != null)
            .reduce((list, cur) => {
                let ticks = parseISO(cur.selectedBookingOption.arrival).getTime();
                list = { ...list, [ticks]: [...(list[ticks] ?? []), cur] };
                return list;
            }, {} as { [key: string]: typeof results[0][] });

        // We take all the dates (represented by their ticks)
        // 1. We map the keys to integers - as objects' keys is string
        // 2. We sort the keys
        // 3. We map the items in the group out to a group where we give the group a title
        return Object.keys(byArrivalDate)
            .map((k) => parseInt(k))
            .sort((a, b) => (a > b ? 1 : -1))
            .map((key) => {
                return {
                    key,
                    title:
                        byArrivalDate[key.toString()][0].selectedBookingOption.arrivalDisplayValue,
                    items: byArrivalDate[key.toString()],
                };
            });
    }, [results]);

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: results.filter((r) => r.selectedBookingOption != null).length,
            })
        );
    }, [results]);

    useEffect(() => {
        if (isBrowser()) {
            const items = results.map(r => {
                return {
                    lodging: {
                        id: r.lodging.id,
                        name: r.lodging.name,
                        location: {
                            id: r.lodging.location.id,
                            name: r.lodging.location.name
                        }
                    },
                    value: r.selectedBookingOption
                        ? {
                            amount: r.selectedBookingOption.priceWithMandatoryItems,
                            currency: r.selectedBookingOption.currency
                        }
                        : null
                } as TrackLodgingListItem;
            });

            trackViewList(items, { onlyOnce: false });
        }
    }, [results]);

    if (groupedResults.length == 0) {
        return <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />;
    }
    return (
        <div className="bwp-lodging-list-grouped-container">
            {groupedResults.map((g) => (
                <div key={g.key}>
                    <h2>{processTemplate(translations.discountsWithArrival, { date: g.title })}</h2>
                    <div className="bwp-lodging-list">
                        {g.items.map((d) => (
                            <LodgingCard2
                                key={
                                    d.lodging.name +
                                    " " +
                                    d.selectedBookingOption?.arrivalDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.departureDisplayValue +
                                    " " +
                                    d.selectedBookingOption?.priceWithMandatoryItemsDisplayValue
                                }
                                lodging={d.lodging}
                                translations={lodgingCardTranslations}
                                favouritesTranslations={favouritesTranslations}
                                extraFacilities={extraFacilities}
                                nextUrl={lodgingUrlBuilder(d)}
                                selectedBookingOption={d.selectedBookingOption}
                                isFavorite={favorites.includes(d.lodging.id)}
                                onSetFavorite={setFavorite}
                                showCleaningBanner={d.selectedBookingOption.cleaningIncludedInPriceWithMandatoryItems}
                                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                                showDepositSeparately={showDepositSeparately}
                                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                                discountBanner={discountBanner}
                                cleaningBanner={cleaningBanner}
                                hideAddress={hideLodgingAddress}
                                showItems={showItems}
                                itemsToShow={itemsToShow}
                                maxItemsToShow={maxItemsToShow}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
