import {isBrowser} from "./helpers";

let hasFired = [];

export function trackViewList(lodgings: TrackLodgingListItem[], options: TrackingOptions) {
    if (isBrowser()) {
        const BwpViewList = "bwp:view-list";
        
        if (options.onlyOnce && hasFired.indexOf(BwpViewList) > -1) {
            return;
        }
        
        const event = new CustomEvent(BwpViewList, {
            detail: [...lodgings]
        });
        
        if (hasFired.indexOf(BwpViewList) === -1) {
            hasFired.push(BwpViewList);
        }
        window.dispatchEvent(event);
    }
}

export interface TrackViewListArgs {
    detail: TrackLodgingListItem[];
}

export interface TrackValue {
    amount: number;
    currency: string;
}

export function trackViewLodging(
    lodging: TrackLodging,
    value: TrackValue,
    options: TrackingOptions
) {
    if (isBrowser()) {
        const BwpViewLodging = "bwp:view-lodging";
        
        if (options.onlyOnce && hasFired.indexOf(BwpViewLodging) > -1) {
            return;
        }
        
        const detail = {
            lodging: {
                id: lodging.id,
                name: lodging.name,
                location: { ...lodging.location }
            },
            value: value ? { ...value } : null
        };
        
        const event = new CustomEvent(BwpViewLodging, { detail } as TrackViewLodgingArgs);
        
        if (hasFired.indexOf(BwpViewLodging) === -1) {
            hasFired.push(BwpViewLodging);
        }
        
        window.dispatchEvent(event);
    }
}

export interface TrackLodging {
    id: number;
    name: string;
    location: {
        id: number;
        name: string;
    }
}

export interface TrackViewLodgingArgs {
    detail: {
        lodging: TrackLodging;
    }
}

export interface TrackCustomer {
    postalCode: string;
    city: string;
    country: string;
}

export interface TrackLocation {
    id: number;
    name: string;
}

export interface TrackItemLine {
    itemId: number;
    description: string;
    quantity: number;
    unitPrice: number;
    lineTotalPrice: number;
}

export interface TrackBooking {
    reservationId: number;
    currency: string;
    totalPrice: number;
    bookingsTotalPrice: number;
    discountBookingsTotalPrice: number;
    lodging: TrackLodging;
    location: TrackLocation;
    customer: TrackCustomer;
    itemLines: TrackItemLine[];
}

export function trackBooking(
    booking: TrackBooking,
    options?: TrackingOptions
) {
    if (isBrowser()) {
        const BwpBooking = "bwp:booking";
        
        if (options?.onlyOnce && hasFired.indexOf(BwpBooking) > -1) {
            return;
        }
        
        const event = new CustomEvent(BwpBooking, {
            detail: {
                booking
            }
        } as TrackBookingArgs);
        
        if (hasFired.indexOf(BwpBooking) === -1) {
            hasFired.push(BwpBooking);
        }
        
        window.dispatchEvent(event);
    }
}

export interface TrackBookingArgs {
    detail: {
        booking: TrackBooking
    }
}

export interface TrackLodgingListItem {
    lodging: TrackLodging;
    value: TrackValue;
}

export interface TrackingOptions {
    onlyOnce?: boolean;
}

/***
 * Imports the required tracking utility from window.
 * If it is available it is returned right away.
 * If not and CookieBot is available it will listen to the CookieBot "CookiebotOnTagsExecuted" event and look again
 * The promise will only resolve if the service is unavailable if not it will just never return.
 * @param name
 */
export function trackingRequire<T = any>(name: "ga" | "raptor" | "gtag"): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        try {
            let service = window[name];

            console.debug("trackingRequire", name, service != null);

            if (service) {
                resolve(service);
                return;
            }

            let CookieBot = window["Cookiebot"];

            if (CookieBot) {
                let handler = () => {
                    window.removeEventListener("CookiebotOnTagsExecuted", handler);
                    setTimeout(() => {
                        service = window[name];
                        console.debug(
                            "trackingRequire",
                            "CookiebotOnTagsExecuted",
                            name,
                            service != null
                        );
                        if (service) {
                            resolve(service);
                        }
                    }, 1000);
                };
                console.debug("trackingRequire", "Listen CookiebotOnTagsExecuted", name);
                window.addEventListener("CookiebotOnTagsExecuted", handler);
            }
        } catch (err) {
            reject(err);
        }
    });
}
