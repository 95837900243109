import React, { useEffect, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { isBrowser } from "./helpers";
import Modal, { ModalProps } from "../Modal";

export default function useModal() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [portal, setPortal] = useState<HTMLDivElement>(null);

    useEffect(() => {
        if (!portal && isBrowser()) {
            const element = document.createElement("div");
            document.body.appendChild(element);
            setPortal(element);
        }
        
        return () => {
            if (portal && isBrowser()) {
                document.body.removeChild(portal);
                setPortal(null);
            }
        }
    }, [portal]);

    const handleShow = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const ModalWrapper = useCallback(
        (props: ModalProps) => {
            if (portal != null) {
                return createPortal((
                    <>
                        {isOpen && <Modal {...props} onClose={handleClose} />}
                    </>
                ), portal);
            } else {
                return null;
            }
        },
        [handleClose, portal, isOpen]
    );

    return {
        Modal: ModalWrapper,
        showModal: handleShow,
        hideModal: handleClose,
    };
}
