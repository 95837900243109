import React, { useEffect, useRef, useState } from "react";
import { EmbedKitOptions, embedSingle, getEmbedController } from "./shared/EmbedKit";

interface Props {
    url: string;
    autoPlay?: boolean;
}

export default function Video({ url, embedElementManipulator, autoPlay }: Props & EmbedKitOptions) {
    const divRef = useRef<HTMLDivElement>();
    const [embedFrame, setEmbedFrame] = useState<HTMLIFrameElement>();

    useEffect(() => {
        let element = embedSingle(divRef.current, { embedElementManipulator, autoPlay });
        setEmbedFrame(element);
    }, [embedElementManipulator, url]);

    return <div ref={divRef} data-url={url} />;
}
