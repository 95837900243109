import React, { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./ReviewSpot.scss";
import { prefixed } from "./shared/helpers";
import { BureauReviewsQuery } from "./operations.generated";
import Button from "./Button";

export interface ReviewSpotProps {
    headline?: string;
    buttonText?: string;
    animationDelay?: number;
    buttonUrl?: string;
    data: BureauReviewsQuery;
}

export default function ReviewSpot({ headline, buttonText, buttonUrl, animationDelay, data }: ReviewSpotProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [reviewWidth, setReviewWidth] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        if (listRef && listRef.current) {
            setReviewWidth(listRef.current.getBoundingClientRect().width);
        }
    }, [data]);

    useEffect(() => {
        const maxIndex = data.bureauReviews.length;
        let timer;
        if (animationDelay) {
            timer = setTimeout(() => {
                setCurrentIndex(currentIndex + 1 < maxIndex ? currentIndex + 1 : 0);
                if (listRef && listRef.current) {
                    setReviewWidth(listRef.current.getBoundingClientRect().width);
                }
            }, animationDelay);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [data, animationDelay, currentIndex]);

    let reviews = data.bureauReviews;

    let randomized = useMemo(() => {
        return shuffle(reviews);
    }, [reviews]);

    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (window && buttonUrl) {
            window.location.href = buttonUrl;
        }
    }

    const classNames = ["bwp-review-spot"];
    if (!headline) {
        classNames.push("bwp-review-spot--no-headline");
    }

    return (
        <div className={classNames.join(" ")}>
            {headline && (
                <div className="bwp-review-spot__headline">{headline}</div>
            )}

            <div className="bwp-review-spot__viewport-container">
                <div className="bwp-review-spot__viewport">

                    <motion.div
                        className="bwp-review-spot__list"
                        animate={{
                            translateX: currentIndex * (-reviewWidth)
                        }}
                        transition={{ ease: "easeOut", duration: 0.4 }}
                        ref={listRef}
                    >
                        {(randomized && randomized.length) && randomized.map((review, index) => (
                            <div className="bwp-review-spot__review" key={`review${index}`}>
                                <div className="bwp-review-spot__review-text">
                                    {review.reviewOfBureauText}
                                </div>
                                <div className="bwp-review-spot__review-byline">
                                    {prefixed("- ", review.reviewerName)}
                                </div>
                            </div>
                        ))}
                    </motion.div>

                </div>
            </div>

            {(buttonText && buttonUrl) && (
                <div className="bwp-review-spot__nav">
                    <Button type="default" onClick={handleClick}>
                        {buttonText}
                    </Button>
                </div>
            )}
        </div>
    );
}

function shuffle<T>(array: T[]) {
    let returnValue = [...array];

    let currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = returnValue[currentIndex];
        returnValue[currentIndex] = returnValue[randomIndex];
        returnValue[randomIndex] = temporaryValue;
    }

    return returnValue as T[];
}
