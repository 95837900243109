export type LodgingUrlData = {
    id: number;
    nameSlug: string;
    address1Slug: string;
    location: {
        slug: string;
    }    
};

export function lodgingUrl(pattern: string, lodging: LodgingUrlData) {
    const values = [
        { key: "lodging:id", value: lodging.id.toString() },
        { key: "lodging:name", value: lodging.nameSlug },
        { key: "lodging:area", value: lodging.location.slug },
        { key: "lodging:address", value: lodging.address1Slug }
    ];

    let url = pattern;
    for (const value of values) {
        url = url.replace(`{${value.key}}`, value.value);
    }

    return url;
}
