import React from "react";
import "./IconHeadline.scss";

export interface Props {
    headline: string;
    icon: "video" | "comment" | "envelope" | "";
}

export default function IconHeadline({ headline, icon }: Props) {
    const names = ['bwp-icon-headline'];
    if (icon) {
        names.push(`bwp-icon-headline--${icon}`);
    }

    return (
        <h4 className={names.join(' ')}>{headline}</h4>
    );
}