import React from "react";
import "./IconButton.scss";

export interface IconButtonProps {
    type?: "button" | "submit" | "reset";
    onClick: (e: React.SyntheticEvent) => void;
    children: React.ReactNode;
    padding?: string;
    className?: string;
}


export default React.forwardRef(({ type, onClick, className, padding, children }: IconButtonProps, ref: React.RefObject<HTMLButtonElement>) => {
    const classNames = ["bwp-iconbutton"];
    if (className) {
        classNames.push(className);
    }

    const style = padding
        ? { 'padding': padding }
        : {};

    return (
        <button ref={ref} className={classNames.join(' ')} type={type || "button"} onClick={onClick} style={style}>
            {children}
        </button>
    );
});