import React from "react";

export default function MapIcon() {
    return (
        <svg
            width={16}
            height={17}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.57143 0.285713C4.52679 0.285713 4.48214 0.294642 4.4375 0.321428L0.151786 2.60714C0.0625 2.65178 0 2.75 0 2.85714V16C0 16.1518 0.133929 16.2857 0.285714 16.2857C0.330357 16.2857 0.375 16.2768 0.419643 16.25L4.70536 13.9643C4.79464 13.9196 4.85714 13.8214 4.85714 13.7143V0.571428C4.85714 0.419642 4.72321 0.285713 4.57143 0.285713ZM15.7143 0.285713C15.6696 0.285713 15.625 0.294642 15.5804 0.321428L11.2946 2.60714C11.2054 2.65178 11.1429 2.75 11.1429 2.85714V16C11.1429 16.1518 11.2768 16.2857 11.4286 16.2857C11.4732 16.2857 11.5179 16.2768 11.5625 16.25L15.8482 13.9643C15.9375 13.9196 16 13.8214 16 13.7143V0.571428C16 0.419642 15.8661 0.285713 15.7143 0.285713ZM5.71429 0.285713C5.5625 0.285713 5.42857 0.419642 5.42857 0.571428V13.7143C5.42857 13.8214 5.49107 13.9196 5.58929 13.9732L10.1607 16.2589C10.1964 16.2768 10.2411 16.2857 10.2857 16.2857C10.4375 16.2857 10.5714 16.1518 10.5714 16V2.85714C10.5714 2.75 10.5089 2.65178 10.4107 2.59821L5.83929 0.312499C5.80357 0.294642 5.75893 0.285713 5.71429 0.285713Z"
                fill="#333333"
            />
        </svg>
    );
}
