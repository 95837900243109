import React, { useEffect, useRef } from "react";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { LodgingPresentationQuery } from "./operations.generated";
import { stringFormat } from "./shared/helpers";
import "./LodgingReviews.scss";

interface Props {
    translations: LodgingPageTranslations;
    lodging: LodgingPresentationQuery["lodgingPresentation"]["lodging"];
}

export default function LodgingReviews({ translations, lodging }: Props) {

    const containerRef = useRef(null);

    useEffect(() => {
        if (lodging && lodging.reviews && lodging.reviews.length && containerRef.current) {
            const rowElement = containerRef.current.closest('.bwp-row');
            if (rowElement) {
                rowElement.classList.remove('bwp-optional');
            }
        }
    }, [lodging]);

    return (
        <div className="bwp-lodging-reviews" ref={containerRef}>
            <h2>{translations.reviews}</h2>

            {lodging.reviews.map((r, index) => (
                <LodgingReview key={index} review={r} translations={translations} />
            ))}
        </div>
    );
}

function LodgingReview({
    review,
    translations,
}: {
    review: Props["lodging"]["reviews"][0];
    translations: LodgingPageTranslations;
}) {
    return (
        <div className="bwp-lodging-reviews__review">
            <div className="bwp-lodging-reviews__review__name">
                <span>{review.reviewerName}</span> <CommentIcon />
            </div>
            <div className="bwp-lodging-reviews__review__text">{review.reviewOfLodgingText}</div>
            <div className="bwp-lodging-reviews__review__period">
                {stringFormat(translations.reviewPeriod, review.month)}
            </div>
        </div>
    );
}

function CommentIcon() {
    return (
        <svg
            width={21}
            height={20}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 0C4.70039 0 0 4.15625 0 9.28571C0 11.5 0.877735 13.5268 2.33789 15.1205C1.8252 17.3705 0.110742 19.375 0.0902344 19.3973C0 19.5 -0.0246094 19.6518 0.0287109 19.7857C0.0820312 19.9196 0.196875 20 0.328125 20C3.04746 20 5.08594 18.5804 6.09492 17.7054C7.43613 18.2545 8.925 18.5714 10.5 18.5714C16.2996 18.5714 21 14.4152 21 9.28571C21 4.15625 16.2996 0 10.5 0Z"
                fill="white"
            />
        </svg>
    );
}
