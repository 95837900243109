import React, { ComponentProps } from "react";
import { PluralizedTranslation } from "./shared/types";
import SelectWithLabel from "./SelectWithLabel";
import useArrayPluralizeOptions from "./shared/useArrayPluralizeOptions";

interface Props extends Omit<ComponentProps<typeof SelectWithLabel>, "value" | "onChange"> {
    itemTranslation: PluralizedTranslation;
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
    nullValueText?: string;
    minValueText?: string;
    maxValueText?: string;
}

export function NumberSelectWithLabel(props: Props) {
    const { value, onChange } = props;
    const items = useArrayPluralizeOptions(
        props.itemTranslation,
        props.min,
        props.max,
        props.nullValueText,
        props.minValueText,
        props.maxValueText
    );

    const selectProps = { ...props };
    delete selectProps.min;
    delete selectProps.max;
    delete selectProps.itemTranslation;
    delete selectProps.nullValueText;
    delete selectProps.minValueText;
    delete selectProps.maxValueText;

    return (
        <SelectWithLabel
            {...selectProps}
            items={items}
            onChange={(value) => onChange(value === "" ? null : parseInt(value))}
            value={value == null ? "" : value.toString()}
        />
    );
}
