import React, { ComponentProps, useMemo } from "react";
import useDurationOptions from "./shared/useDurationOptions";
import {Dictionary, PluralizedTranslation} from "./shared/types";
import SelectWithLabel from "./SelectWithLabel";

interface DurationSelectWithLabelTranslations {
    day: PluralizedTranslation;
    week: PluralizedTranslation;
}

interface Props extends Omit<ComponentProps<typeof SelectWithLabel>, "value" | "onChange"> {
    translations: DurationSelectWithLabelTranslations;
    durations: number[];
    value: number;
    onChange: (value: number) => void;
    durationsAndPrices?: Dictionary;
}

export function DurationSelectWithLabel(props: Props) {
    const { translations, durations, value, onChange, durationsAndPrices } = props;
    const durationOptions = useDurationOptions(translations.day, translations.week, durations, durationsAndPrices);
    
    const selectProps = { ...props };
    delete selectProps.translations;
    delete selectProps.durations;
    
    return (
        <SelectWithLabel
            {...selectProps}
            items={durationOptions}
            onChange={(value) => onChange(parseInt(value))}
            value={value?.toString()}
        />
    );
}
