import React, { useCallback, useState } from "react";
import NewsletterSpot, { SubmitValue } from "@bwp/NewsletterSpot";
import { NewsletterFormTranslations } from "@bwp/translations/NewsletterFormTranslations";
import { useNewsletterSignupMutation } from "@bwp/operations.generated";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import { ApolloError } from "@apollo/client";

interface Props {
    translations: NewsletterFormTranslations;
    headline: string;
    intro: string;
    termsHtml: string;
    imageUrl: string;
    mailConfigurationId: string;
    successMessage: string;
}

export default function NewsletterSpotWrapper({
    translations,
    headline,
    intro,
    termsHtml,
    imageUrl,
    mailConfigurationId,
    successMessage,
}: Props) {
    const [newsletterSignupMutation] = useNewsletterSignupMutation();
    const [error, setError] = useState<ApolloError>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [resetKey, setResetKey] = useState(0);

    const handleSubmit = useCallback(({ firstName, lastName, email }: SubmitValue) => {
        setShowSuccessMessage(false);
        newsletterSignupMutation({
            variables: {
                firstName,
                lastName,
                email,
                mailConfigurationId,
                newsletterCategories: [1],
            },
        })
            .then(() => {
                setShowSuccessMessage(true);
                setResetKey((resetKey) => resetKey + 1);
            })
            .catch((err) => setError(err));
    }, []);

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }

    return (
        <NewsletterSpot
            key={resetKey}
            onSubmit={handleSubmit}
            translations={translations}
            headline={headline}
            intro={intro}
            termsHtml={termsHtml}
            imageUrl={imageUrl}
            successMessage={successMessage}
            showSuccessMessage={showSuccessMessage}
        />
    );
}
