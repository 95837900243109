import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { isBrowser } from "./shared/helpers";
import { Position } from "./shared/types";

export interface Adjustments {
    width?: number;
    x?: number;
    y?: number;
}

export interface CalloutProps {
    className?: string;
    isOpen: boolean;
    rect: Position;
    zIndex?: number;
    adjustments?: Adjustments;
    children: React.ReactNode;
}

export default function Callout({ className, isOpen, rect, zIndex, adjustments, children }: CalloutProps) {

    const [portalContainer, setPortalContainer] = useState<HTMLDivElement>(null);

    useEffect(() => {
        if (isBrowser() && portalContainer === null) {
            const newPortalContainer = document.createElement("div");
            document.body.appendChild(newPortalContainer);
            setPortalContainer(newPortalContainer);
        }

        return () => {
            if (isBrowser() && portalContainer) {
                window.document.body.removeChild(portalContainer);
                setPortalContainer(null);
            }
        }
    }, [portalContainer]);

    const adj = adjustments || {
        width: 0,
        x: 0,
        y: 0
    };

    let styles = rect
        ? {
            display: isOpen ? "block" : "none",
            top: `${rect.y + rect.height + (adj.y || 0)}px`,
            left: `${rect.x + (adj.x || 0)}px`,
            minWidth: `${rect.width + (adj.width || 0)}px`
        }
        : { display: isOpen ? "block" : "none", zIndex: 1 };

    if (typeof zIndex === 'number') {
        Object.assign(styles, { zIndex: zIndex });
    }

    if (isBrowser() && portalContainer) {
        return ReactDOM.createPortal((
            <div className={className} style={styles}>
                {children}
            </div>
        ), portalContainer);
    }

    return (
        <div className={className} style={styles}>
            {children}
        </div>
    );
}
