import ReactDOM from "react-dom";
import React from "react";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import Footer from "@bwp/Footer";
import MenuBar from "@bwp/MenuBar";
import Spot from "@bwp/Spot";
import GallerySpot from "@bwp/GallerySpot";
import SearchBox from "@bwp/SearchBox";
import Grid from "@bwp/Grid";
import Article from "@bwp/Article";
import HeroSearch from "@bwp/HeroSearch";
import HeroSearchSearchBox from "@bwp/HeroSearchSearchBox";
import LodgingTitle from "@bwp/LodgingTitle";
import LodgingImages from "@bwp/LodgingImages";
import LodgingFacilityRow from "@bwp/LodgingFacilityRow";
import LodgingDescription from "@bwp/LodgingDescription";
import IconHeadline from "@bwp/IconHeadline";
import LodgingFacilityGroup from "@bwp/LodgingFacilityGroup";
import LodgingReviews from "@bwp/LodgingReviews";
import LodgingMap from "@bwp/LodgingMap";
import LocationDescription from "@bwp/LocationDescription";
import LocationReviews from "@bwp/LocationReviews";
import NavButton from "@bwp/NavButton";
import BreakoutImage from "@bwp/BreakoutImage";
import LodgingListFilter from "@bwp/LodgingListFilter";
import LodgingGeneralInformation from "@bwp/LodgingGeneralInfo";
import SearchResultListWrapper from "../../wrappers/data/SearchResultListWrapper";
import LodgingListWrapper from "../../wrappers/data/LodgingListWrapper";
import DiscountLodgingListWrapper from "../../wrappers/data/DiscountLodgingListWrapper";
import CheckoutWrapper from "../../wrappers/data/CheckoutWrapper";
import CompactDiscountLodgingListWrapper from "../../wrappers/data/CompactDiscountLodgingListWrapper";
import NewsletterSpotWrapper from "../../wrappers/data/NewsletterSpotWrapper";
import ReviewSpotWrapper from "../../wrappers/data/ReviewSpotWrapper";
import BureauReviewsWrapper from "../../wrappers/data/BureauReviewsWrapper";
import LodgingPresentationWrapper from "../../wrappers/data/LodgingPresentationWrapper";
import LodgingBookingBoxWrapper from "../../wrappers/data/LodgingBookingBoxWrapper";
import lazyLoadLodgingsWrapper from "../../wrappers/data/lazyLoadLodgingsWrapper";
import onlyClientWrapper from "../../wrappers/onlyClientWrapper";
import withSearchBoxWrapper from "../../wrappers/withSearchBoxWrapper";
import PurchaseTrackerWrapper from "../../wrappers/data/PurchaseTrackerWrapper";

export function setExports(globalObject: object, client: ApolloClient<NormalizedCacheObject>) {
    globalObject["Footer"] = Footer;
    globalObject["HeroSearch"] = onlyClientWrapper(
        lazyLoadLodgingsWrapper(
            withSearchBoxWrapper(HeroSearch),
            (x) => x.lodgings
        ),
        client
    );
    globalObject["HeroSearchSearchBox"] = onlyClientWrapper(
        lazyLoadLodgingsWrapper(
            withSearchBoxWrapper(HeroSearchSearchBox),
            (x) => x.lodgings
        ),
        client
    );
    globalObject["MenuBar"] = MenuBar;
    globalObject["NewsletterSpot"] = onlyClientWrapper(NewsletterSpotWrapper, client);
    globalObject["ReviewSpot"] = onlyClientWrapper(ReviewSpotWrapper, client);
    globalObject["Spot"] = Spot;
    globalObject["GallerySpot"] = GallerySpot;
    globalObject["SearchBox"] = SearchBox;
    globalObject["LodgingListFilter"] = LodgingListFilter;
    globalObject["React"] = React;
    globalObject["ReactDOM"] = ReactDOM;
    globalObject["NavButton"] = NavButton;
    globalObject["SearchResultList"] = onlyClientWrapper(SearchResultListWrapper, client);
    globalObject["LodgingList"] = onlyClientWrapper(LodgingListWrapper, client);
    globalObject["DiscountLodgingList"] = onlyClientWrapper(DiscountLodgingListWrapper, client);
    globalObject["CompactDiscountLodgingList"] = onlyClientWrapper(
        CompactDiscountLodgingListWrapper,
        client
    );
    globalObject["LodgingTitle"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingTitle, (x) => x.lodgingPresentation?.lodging),
        client
    );
    globalObject["LodgingImages"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingImages, (x) => x.lodgingPresentation?.lodging),
        client
    );
    globalObject["LodgingFacilityRow"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingFacilityRow, (x) => x.lodgingPresentation?.lodging),
        client
    );
    globalObject["LodgingDescription"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingDescription, (x) => x.lodgingPresentation?.lodging),
        client
    );
    globalObject["LodgingFacilityGroup"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingFacilityGroup, (x) => x.lodgingPresentation?.lodging),
        client
    );
    globalObject["LodgingReviews"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingReviews, (x) => ({
            lodging: x.lodgingPresentation?.lodging,
        })),
        client
    );
    globalObject["LodgingBookingBox"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingBookingBoxWrapper, (x) => ({
            lodgingPresentation: x.lodgingPresentation,
        })),
        client
    );
    globalObject["LodgingMap"] = onlyClientWrapper(
        LodgingPresentationWrapper(LodgingMap, (x) => ({
            lodging: x.lodgingPresentation?.lodging,
        })),
        client
    );
    globalObject["LodgingLocationDescription"] = onlyClientWrapper(
        LodgingPresentationWrapper(LocationDescription, (x) => ({
            location: x.lodgingPresentation?.lodging.location,
        })),
        client
    );
    globalObject["LodgingLocationReviews"] = onlyClientWrapper(
        LodgingPresentationWrapper(LocationReviews, (x) => ({
            reviews: x.lodgingPresentation?.lodging.location.reviews,
        })),
        client
    );
    globalObject["IconHeadline"] = IconHeadline;
    globalObject["Grid"] = Grid;
    globalObject["Article"] = Article;
    globalObject["Checkout"] = onlyClientWrapper(CheckoutWrapper, client);
    globalObject["BreakoutImage"] = onlyClientWrapper(BreakoutImage, client);
    globalObject["LodgingGeneralInfo"] = onlyClientWrapper(LodgingGeneralInformation, client);
    globalObject["BureauReviews"] = onlyClientWrapper(BureauReviewsWrapper, client);
    globalObject["PurchaseTracker"] = onlyClientWrapper(PurchaseTrackerWrapper, client);
}
