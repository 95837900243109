import { CalendarValue, FacilityValueComparison, PluralizedTranslation } from "./types";
import { addDays, format, getDay, getISODay, getMonth, getYear } from "date-fns";
import { processTemplate } from "./templates";
import { sv, da, de, enUS } from "date-fns/locale";
import { Facility, SearchSorting } from "./graphql-types.generated";
import {ItemSelectorCheckoutPresentationFragment} from "../operations.generated";

export function getLocale(culture: string): Locale {
    let locale = enUS;
    if (culture.indexOf("da") === 0) {
        locale = da;
    }
    if (culture.indexOf("de") === 0) {
        locale = de;
    }
    if (culture.indexOf("en") === 0) {
        locale = enUS;
    }
    if (culture.indexOf("sv") === 0) {
        locale = sv;
    }
    return locale;
}

export function prefixed(prefix, text) {
    if (!text) return "";
    if (!prefix) return text;
    return `${prefix}${text}`;
}

export function getSearchSorting(value: string) {
    if (value === "Random") {
        return SearchSorting.Random;
    }
    if (value === "PriceLowToHigh") {
        return SearchSorting.PriceLowToHigh;
    }
    if (value === "PriceHighToLow") {
        return SearchSorting.PriceHighToLow;
    }
    if (value === "Standard") {
        return SearchSorting.Standard;
    }
    return null;
}

export function isValidDate(date: Date) {
    return (
        Object.prototype.toString.call(date) === "[object Date]" && isNaN(date.getTime()) === false
    );
}

export function facilityKey(
    facility: Facility | number,
    comparison: FacilityValueComparison = "equal"
) {
    const id = typeof facility === "number" ? facility : facility.id;

    const postfix = comparison === "equal" ? "" : `_${comparison}`;

    return `fac${id}${postfix}`;
}

export function isBrowser() {
    return typeof window === "object";
}

export function getPluralizedValue(num: number, translation: PluralizedTranslation) {
    if (num == 0 && translation.zero) {
        return translation.zero;
    }
    if (num == 1 && translation.one) {
        return translation.one;
    }

    return translation.other;
}

export function pluralize(num: number, translation: PluralizedTranslation) 
{
    return stringFormat(getPluralizedValue(num, translation), (num ?? 0).toString());
}

export function stringFormat(str: string, ...tokens: string[]) {
    let result = str;
    tokens.forEach((token, index) => {
        result = replaceAll(result, "{" + index + "}", token);
    });
    return result;
}

export function replaceAll(str: string, search: string, replacement: string) {
    if (str["replaceAll"] !== undefined) {
        return str["replaceAll"](search, replacement);
    } else {
        return str.split(search).join(replacement);
    }
}

export function telLinkHref(telephone: string) {
    if (!telephone) {
        return "#";
    }

    const onlyNumbers = telephone.replace(/\s+/g, "").replace(/^\+/, "00");

    return `tel:${onlyNumbers}`;
}

export function emailLinkHref(email: string) {
    if (!email) {
        return "#";
    }

    return `mailto:${email}`;
}

export function formattedShortDate(date: Date, culture: string) {
    if (!isValidDate(date)) {
        return "";
    }

    return format(date, "dd-MM", { locale: getLocale(culture) });
}

export function formattedDate(date: Date, culture: string) {
    if (!isValidDate(date)) {
        return "";
    }

    return format(date, "dd-MM-yyyy", { locale: getLocale(culture) });
}

export function formattedValue(value: string | number | boolean, translation: string): string {
    if (translation) {
        return processTemplate(translation, [value]);
    }
    return value.toString();
}

export function getDateValueFromDate(date: Date): CalendarValue {
    if (!date || !isValidDate(date)) {
        return null;
    }
    
    return {
        day: getDay(date),
        month: getMonth(date),
        year: getYear(date)
    } as CalendarValue;
}

export function getDateFromCalendarValue(value: CalendarValue) {
    if (!value) {
        return null;
    }
    return new Date(value.year, value.month - 1, value.day, 0, 0, 0, 0);
}

export function formattedAmount(amount: number, currency: string, culture: string) {
    try {
        return new Intl.NumberFormat(culture, {
            style: "currency",
            currency: currency,
        }).format(amount);
    } catch (e) {
        console.warn("Unable to use formatter: ", e);
        return amount.toString();
    }
}

/**
 * Coalesce is a method that returns the first of its parameters that is not null or undefined.
 * You can use it to have a list of fallbacks for a value
 * @param params
 */
export function coalesce<T>(...params: T[]): T {
    const len = params.length;
    for (let i = 0; i < len; i++) {
        if (params[i] !== null && params[i] !== undefined) {
            return params[i];
        }
    }
    return null;
}

export const defaultDateValue = (() => {
    let tmp = new Date();

    // move to next saturday
    switch (getISODay(tmp)) {
        case 1:
            tmp = addDays(tmp, 5);
            break;
        case 2:
            tmp = addDays(tmp, 4);
            break;
        case 3:
            tmp = addDays(tmp, 3);
            break;
        case 4:
            tmp = addDays(tmp, 2);
            break;
        case 5:
            tmp = addDays(tmp, 1);
            break;
        case 6:
            tmp = addDays(tmp, 0);
            break;
        case 7:
            tmp = addDays(tmp, 6);
            break;
    }

    let ensureLength = (str: string) => {
        return str.length == 1 ? "0" + str : str;
    };

    return (
        ensureLength((tmp.getMonth() + 1).toString()) +
        "-" +
        ensureLength(tmp.getDate().toString()) +
        "-" +
        tmp.getFullYear()
    );
})();

export const defaultValue = {
    adults: 2,
    duration: 7,
    pets: null,
    arrival: defaultDateValue,
};

export function removeEmptyProps(obj: any) {
    if (!obj) {
        return obj;
    }

    const result = {};
    Object.keys(obj).forEach(key => {
        if (obj[key]) {
            result[key] = obj[key];
        }
    });
    return result;
}

export function isSelectableItem(ip: ItemSelectorCheckoutPresentationFragment["itemLines"][0]) {
    return (
        ip.itemPrice.isMandatory === false &&
        ip.itemPrice.minQuantity !== ip.itemPrice.maxQuantity &&
        ip.itemPrice.maxQuantity > 0
    )
}