import { GenericDictionary, PluralizedTranslation } from "./types";
import { getPluralizedValue, pluralize } from "./helpers";

export type SimpleValue = string | number | boolean | Date;
export type SimpleValueDictionary = GenericDictionary<SimpleValue>;
export type SimpleValueArray = SimpleValue[];

export function isSimpleValueArray(
    values: SimpleValueArray | SimpleValueDictionary
): values is SimpleValueArray {
    return (<SimpleValueArray>values).length !== undefined;
}

export function processTemplate(
    template: string,
    values: SimpleValueArray | SimpleValueDictionary
): string {
    if (isSimpleValueArray(values)) {
        return processTemplateWithSimpleValues(template, values as SimpleValueArray);
    }
    return processTemplateWithValueDictionary(template, values as SimpleValueDictionary);
}

export function processTemplateWithSimpleValues(
    template: string,
    values: SimpleValueArray
): string {
    if (template === undefined || template === null) {
        return "";
    }

    let result: string = template;
    if (typeof values !== "undefined") {
        for (let index: number = 0; index < values.length; index++) {
            const value = values[index] !== undefined ? values[index].toString() : "";
            result = result.replace(new RegExp(`\\{${index}\\}`, "gi"), value);
        }
    }
    return result;
}

export function processTemplateWithValueDictionary(
    template: string,
    values: SimpleValueDictionary
): string {
    if (template === undefined || template === null) {
        return "";
    }

    let result: string = template;
    if (typeof values !== "undefined") {
        Object.keys(values).forEach((property) => {
            const value = values[property] !== undefined ? values[property].toString() : "";
            result = result.replace(new RegExp(`\\{${property}\\}`, "gi"), value);
        });
    }
    return result;
}

type TokenType = string | number | React.ReactElement<any>;

/**
 * A React Version of replaceStringTokens that supports React Components.
 * Replaces a set of tokens in the string str with the replacements specified in the tokens object.
 * Each token format is of type {hello} in the string
 * @param str The string to search for tokens in
 * @param tokens An object containing the token as key, and the replacement as value. The replacement can be a string, number or a React component. If the replacement is a React component, then remmember to specify the key prop.
 * @returns An array of the parts. This array can be sent directly to React to render
 */
export function processTemplateReact(str: string, tokens: { [s: string]: TokenType }) {
    let parts: any[] = str.split(/(\{.+?\})/gi);
    for (let i = 1; i < parts.length; i += 2) {
        let key = parts[i].substring(1, parts[i].length - 1);
        let token = tokens[key];

        parts[i] = token;
    }

    return parts;
}

export function processPluralizedTemplateReact(
    num: number,
    replacement: TokenType,
    translation: PluralizedTranslation
) {
    let str = getPluralizedValue(num, translation);
    let parts: any[] = str.split(/(\{.+?\})/gi);
    for (let i = 1; i < parts.length; i += 2) {
        let key = parts[i].substring(1, parts[i].length - 1);

        parts[i] = replacement;
    }

    return parts;
}
