import React, { CSSProperties, useEffect, useRef } from "react";

interface Props {
    width?: CSSProperties["width"];
    height?: CSSProperties["height"];
    alt?: HTMLImageElement["alt"];
    imageUrlFunc: (width: number, height: number) => string;
}

export default function Image({ imageUrlFunc, alt, width = "100%", height }: Props) {
    const ref = useRef<HTMLImageElement>();

    useEffect(() => {
        let width = ref.current.offsetWidth;
        let height = ref.current.offsetHeight;
        let pixelRatio = window.devicePixelRatio;
        ref.current.src = imageUrlFunc(width * pixelRatio, height * pixelRatio);
    }, [imageUrlFunc]);

    return <img ref={ref} alt={alt} style={{ width, height }} loading="lazy" />;
}
