import React, { useEffect, useState } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import {formatISO, parseISO} from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { useLastMinuteQuery } from "@bwp/operations.generated";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import {CleaningBannerType, DiscountBannerType, LodgingListSetting} from "@bwp/shared/types";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import DiscountLodgingList from "@bwp/DiscountLodgingList";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";
import { FavouritesTranslations } from "@bwp/translations/FavouritesTranslations";
import {getSearchContextPropsFromBookingOption, SearchContext} from "@bwp/shared/searchcontext";

export interface DiscountLodgingListWrapperProps {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    daysAhead: number;
    lodgingListSetting: LodgingListSetting;
    tagFilter: string;
    emptyResultHtmlContent: string;
    extraFacilities?: number[];
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

export default function DiscountLodgingListWrapper({
    translations,
    lodgingCardTranslations,
    favouritesTranslations,
    lodgingUrlPattern,
    includeLodgingIdInUrl,
    lodgingListSetting,
    daysAhead,
    tagFilter,
    emptyResultHtmlContent,
    extraFacilities,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: DiscountLodgingListWrapperProps) {
    const [sorting] = useQueryString("ord", null);

    const [searchContext] = useGlobalSearchContext({ defaultValues: {}, lodgingListSetting });
    const query = useLastMinuteQuery({
        variables: {
            query: searchContext.toServerQuery(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(new Date(), { representation: "date" }),
            sorting: sorting as any,
            daysAhead,
            tagFilter,
        },
    });

    const [numberOfSkeletons, setNumberOfSeletons] = useState(6);

    useEffect(() => {
        if (query.data) {
            setNumberOfSeletons(query.data.lastMinute.results.length);
        }
    }, [query.data]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return <SearchResultListSkeleton numberOfSkeletons={numberOfSkeletons} height={391} />;
    }

    const ignoredParams = includeLodgingIdInUrl
        ? []
        : ["lodgingId"];
    
    return (
        <>
            <DiscountLodgingList
                translations={translations}
                lodgingCardTranslations={lodgingCardTranslations}
                favouritesTranslations={favouritesTranslations}
                {...query.data}
                lodgingUrlBuilder={(result) => {
                    const baseUrl = lodgingUrl(lodgingUrlPattern, result.lodging);
                    const separator = baseUrl.indexOf('?') === -1 ? "?" : "&";
                    const boProps = getSearchContextPropsFromBookingOption({
                        lodgingId: result.lodging.id,
                        arrival: parseISO(result.selectedBookingOption.arrival),
                        duration: result.selectedBookingOption.duration,
                        adults: result.selectedBookingOption.adults,
                        children: result.selectedBookingOption.children,
                        infants: result.selectedBookingOption.infants,
                        pets: result.selectedBookingOption.pets
                    });
                    const qs = SearchContext.createFromSearchContextProps(boProps).toQueryString(ignoredParams);
                    return `${baseUrl}${separator}${qs}`;
                }}
                extraFacilities={extraFacilities}
                emptyResultHtmlContent={emptyResultHtmlContent}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                showDepositSeparately={showDepositSeparately}
                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                discountBanner={discountBanner}
                cleaningBanner={cleaningBanner}
                hideLodgingAddress={hideLodgingAddress}
                showItems={showItems}
                itemsToShow={itemsToShow}
                maxItemsToShow={maxItemsToShow}
            />
        </>
    );
}
