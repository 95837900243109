import React from "react";
import { useSelect } from "downshift";
import "./FieldWithLabel.scss";
import Callout from "./Callout";
import { usePositioned } from "./shared/usePositioned";

export interface SelectItem {
    text: React.ReactNode;
    value: string;
}

export interface SelectWithLabelProps {
    id: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    items?: SelectItem[];
    disabled?: boolean;
}

export default function SelectWithLabel({
    id,
    label,
    value,
    items,
    onChange,
    disabled,
}: SelectWithLabelProps) {
    const selectedItem = items.find((i) => i.value == value);
    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items,
        onSelectedItemChange: (item) => onChange(item.selectedItem.value),
        selectedItem: selectedItem,
        id,
    });

    const [container, rect] = usePositioned();

    const defaultText = selectedItem?.text;

    const classNames = ["bwp-field-with-label", "bwp-field-with-label--select"];

    if (disabled) {
        classNames.push("bwp-field-with-label--disabled");
    }

    return (
        <div className={classNames.join(" ")} ref={container}>
            <label {...getLabelProps()}>
                <span>{label}</span>
            </label>
            <button
                className="bwp-field-with-label__value-container"
                type="button"
                
                {...(Object.assign(disabled ? { disabled: true } : {}, getToggleButtonProps()))}
            >
                {(selectedItem && selectedItem.text) || defaultText || ""}
            </button>
            <Callout className="bwp-select-items" isOpen={isOpen} rect={rect} adjustments={{ width: -16, x: 8, y: -8 }}>
                <ul {...getMenuProps()}>
                    {items.map((item, index) => (
                        <li
                            style={
                                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
                            }
                            key={`${item.value}${index}`}
                            {...getItemProps({ item, index })}
                        >
                            {item.text}
                        </li>
                    ))}
                </ul>
            </Callout>
        </div>
    );
}
