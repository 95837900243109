import React, { useEffect, useMemo } from "react";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { LodgingListQuery } from "./operations.generated";
import LodgingCard2 from "./LodgingCard2";
import "./LodgingList.scss";
import useFavorites from "./shared/useFavorites";
import ReactDOM from "react-dom";
import SearchResultListHeader from "./SearchResultListHeader";
import {CleaningBannerType, DiscountBannerType, SearchResultListStyle} from "./shared/types";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import useQueryString from "./shared/useQueryString";
import SearchResultMap from "./SearchResultMap";
import { FavouritesTranslations } from "./translations/FavouritesTranslations";
import {isBrowser} from "./shared/helpers";
import {TrackLodgingListItem, trackViewList} from "./shared/tracking";

export function registerTranslations() {
    return {
        "lodgingCardTranslations": "LodgingCard",
        "favouritesTranslations": "Favourites",
        "searchResultListTranslations": "SearchResultList"
    };
}

export default function LodgingList({
    lodgingCardTranslations,
    favouritesTranslations,
    lodgingList: { results },
    lodgingUrlBuilder,
    extraFacilities,
    onlyShowFavorites,
    emptyResultHtmlContent,
    searchResultListTranslations,
    sorting,
    setSorting,
    headerElement,
    googleMapsApiKey,
    mapHeight,
    markerIconUrl,
    maxMapZoomLevel,
    showStars,
    starsExtraIcon,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    cleaningIncludedFacilityId,
    showProbabilityWithPriceAsBookingOption
}: {
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    searchResultListTranslations: SearchResultListTranslations;
    lodgingUrlBuilder: (l: LodgingListQuery["lodgingList"]["results"][0]["lodging"]) => string;
    extraFacilities: number[];
    onlyShowFavorites?: boolean;
    emptyResultHtmlContent?: string;
    sorting: string;
    setSorting: (sorting: string) => void;
    headerElement: HTMLElement;
    googleMapsApiKey: string;
    mapHeight: number;
    markerIconUrl: string;
    maxMapZoomLevel: number;
    showStars?: boolean;
    starsExtraIcon?: string;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    cleaningIncludedFacilityId?: number;
    showProbabilityWithPriceAsBookingOption?: boolean;
} & LodgingListQuery) {
    let [favorites, setFavorite] = useFavorites();
    let [listStyle, setListStyle] = useQueryString("listStyle", "Grid");

    let filteredResults = useMemo(() => {
        if (onlyShowFavorites) {
            return results.filter((r) => favorites.includes(r.lodging.id));
        } else {
            return results;
        }
    }, [favorites, onlyShowFavorites, results]);

    useEffect(() => {
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: filteredResults.length,
            })
        );
    }, [filteredResults.length]);
    
    useEffect(() => {
        if (isBrowser()) {
            const items = filteredResults.map(r => {
                return {
                    lodging: {
                        id: r.lodging.id,
                        name: r.lodging.name,
                        location: {
                            id: r.lodging.location.id,
                            name: r.lodging.location.name
                        }
                    },
                    value: r.seasonPrices?.length
                        ? {
                            amount: Math.min.apply(null, (r.seasonPrices.filter(sp => sp.price).map(sp => Math.round(sp.price * 7)))),
                            currency: r.seasonPrices[0].currency
                        }
                        : null
                } as TrackLodgingListItem;
            });
            
            trackViewList(items, { onlyOnce: false });
        }
    }, [filteredResults]);

    if (filteredResults.length == 0) {
        return <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />;
    }

    const withCleaning = (fv: LodgingListQuery["lodgingList"]["results"][0]["lodging"]["facilityValues"][0]) => {
        
        if (!cleaningIncludedFacilityId) {
            return false;
        }

        if (fv.facilityId === cleaningIncludedFacilityId) {            
            return fv.value === "True";
        }
        return false;
    }
    
    const filteredExtraFacilities = extraFacilities
        ? extraFacilities.filter(id => cleaningIncludedFacilityId != id)
        : [];
    
    return (
        <>
            {headerElement &&
                ReactDOM.createPortal(
                    <SearchResultListHeader
                        numberOfResults={filteredResults.length}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        translations={searchResultListTranslations}
                        listStyle={listStyle as SearchResultListStyle}
                        onListStyleChange={(val) => setListStyle(val)}
                    />,
                    headerElement
                )}
            {listStyle == "Grid" && (
                <div className="bwp-lodging-list">
                    {filteredResults.map((d) => (
                        <LodgingCard2
                            key={`list-lodging-${d.lodging.id}`}
                            lodging={d.lodging}
                            seasonPrices={d.seasonPrices}
                            translations={lodgingCardTranslations}
                            favouritesTranslations={favouritesTranslations}
                            extraFacilities={filteredExtraFacilities}
                            nextUrl={lodgingUrlBuilder(d.lodging)}
                            selectedBookingOption={d.selectedBookingOption}
                            isFavorite={favorites.includes(d.lodging.id)}
                            onSetFavorite={setFavorite}
                            showStars={showStars}
                            starsExtraIcon={starsExtraIcon}
                            discountBanner={discountBanner}
                            cleaningBanner={cleaningBanner}
                            hideAddress={hideLodgingAddress}
                            showCleaningBanner={d.lodging.facilityValues.some(withCleaning)}
                            showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                        />
                    ))}
                </div>
            )}
            {listStyle == "Map" && (
                <SearchResultMap
                    extraFacilities={extraFacilities}
                    lodgingCardTranslations={lodgingCardTranslations}
                    googleMapsApiKey={googleMapsApiKey}
                    searchResults={results.map((r) => ({
                        id: r.lodging.id,
                        latitude: r.lodging.latitude,
                        longitude: r.lodging.longitude,
                    }))}
                    mapHeight={mapHeight}
                    markerIconUrl={markerIconUrl}
                    maxMapZoomLevel={maxMapZoomLevel}
                    renderInfoWindow={(l) => {
                        let d = results.find((r) => r.lodging.id == l.id);

                        return (
                            <LodgingCard2
                                key={`map-lodging-${d.lodging.id}`}
                                lodging={d.lodging}
                                seasonPrices={d.seasonPrices}
                                translations={lodgingCardTranslations}
                                favouritesTranslations={favouritesTranslations}
                                extraFacilities={filteredExtraFacilities}
                                nextUrl={lodgingUrlBuilder(d.lodging)}
                                selectedBookingOption={d.selectedBookingOption}
                                isFavorite={favorites.includes(d.lodging.id)}
                                onSetFavorite={setFavorite}
                                showStars={showStars}
                                starsExtraIcon={starsExtraIcon}
                                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                                hideAddress={hideLodgingAddress}
                                disableSmartImage
                                disableImageSlider
                            />
                        );
                    }}
                />
            )}
        </>
    );
}
