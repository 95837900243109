import React, { useMemo } from "react";
import FieldWithLabel from "./FieldWithLabel";
import SelectWithLabel from "./SelectWithLabel";
import "./ContactDetailsForm.scss";
import "./Form.scss";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { CheckoutQuery } from "./operations.generated";
import { BookingRequestInput } from "./shared/graphql-types.generated";
import {FormFieldType} from "./shared/types";

export interface CountryProps {
    id: string;
    name: string;
}

type ValueType = Pick<
    BookingRequestInput["customer"],
    | "firstName"
    | "lastName"
    | "address"
    | "address2"
    | "postalCode"
    | "city"
    | "country"
    | "privateTelephone"
    | "privateMobilephone"
    | "workTelephone"
    | "email"
>;

export interface ContactDetailsFormProps {
    value: ValueType;
    countries: CountryProps[];
    address2FormFieldType?: FormFieldType;
    privateTelephoneFormFieldType?: FormFieldType;
    privateMobilephoneFormFieldType?: FormFieldType;
    workTelephoneFormFieldType?: FormFieldType;
    onChange: (customer: BookingRequestInput["customer"]) => void;
    translations: CheckoutTranslations;
}

export default function ContactDetailsForm({
    value,
    countries,
    address2FormFieldType,
    privateTelephoneFormFieldType,
    privateMobilephoneFormFieldType,
    workTelephoneFormFieldType,
    onChange,
    translations,
}: ContactDetailsFormProps) {
    const countryOptions = useMemo(() => countries.map((c) => ({ value: c.id, text: c.name })), [
        countries,
    ]);

    const postalCodeAndCityFields = translations.postalCodeAndCityLayout
        ? translations.postalCodeAndCityLayout.split(",")
        : ['postalcode', 'city'];
    
    return (
        <section className="bwp-form-section">
            <h2>{translations.contactDetailsFormTitle}</h2>
            <div className="bwp-form-section__list">
                <div className="bwp-form-section__col2">
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.firstName}
                            label={translations.firstname}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    firstName: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                    <div className="bwp-form-section__cell">
                        <FieldWithLabel
                            required
                            value={value.lastName}
                            label={translations.lastname}
                            onChange={(newValue) =>
                                onChange({
                                    ...value,
                                    lastName: newValue,
                                } as BookingRequestInput["customer"])
                            }
                        />
                    </div>
                </div>
                <FieldWithLabel
                    required
                    value={value.address}
                    label={translations.address}
                    onChange={(newValue) =>
                        onChange({ ...value, address: newValue } as BookingRequestInput["customer"])
                    }
                />
                {address2FormFieldType !== "hidden" && (
                    <FieldWithLabel
                        required={address2FormFieldType === "required"}
                        value={value.address2}
                        label={translations.address2}
                        onChange={(newValue) =>
                            onChange({ ...value, address2: newValue } as BookingRequestInput["customer"])
                        }
                    />                    
                )}
                <div className="bwp-form-section__col2">
                    <div className="bwp-form-section__cell">
                        {(postalCodeAndCityFields[0] === "city") && (
                            <FieldWithLabel
                                required
                                value={value.city}
                                label={translations.city}
                                onChange={(newValue) =>
                                    onChange({
                                        ...value,
                                        city: newValue,
                                    } as BookingRequestInput["customer"])
                                }
                            />
                        )}
                        {(postalCodeAndCityFields[0] != "city") && (
                            <FieldWithLabel
                                required
                                value={value.postalCode}
                                label={translations.postalCode}
                                onChange={(newValue) =>
                                    onChange({
                                        ...value,
                                        postalCode: newValue,
                                    } as BookingRequestInput["customer"])
                                }
                            />
                        )}
                    </div>
                    <div className="bwp-form-section__cell">
                        {(postalCodeAndCityFields[1] === "postalcode") && (
                            <FieldWithLabel
                                required
                                value={value.postalCode}
                                label={translations.postalCode}
                                onChange={(newValue) =>
                                    onChange({
                                        ...value,
                                        postalCode: newValue,
                                    } as BookingRequestInput["customer"])
                                }
                            />
                        )}
                        {(postalCodeAndCityFields[1] != "postalcode") && (
                            <FieldWithLabel
                                required
                                value={value.city}
                                label={translations.city}
                                onChange={(newValue) =>
                                    onChange({
                                        ...value,
                                        city: newValue,
                                    } as BookingRequestInput["customer"])
                                }
                            />
                        )}
                    </div>
                </div>
                <SelectWithLabel
                    value={value.country}
                    label={translations.country}
                    items={countryOptions}
                    onChange={(newValue) =>
                        onChange({ ...value, country: newValue } as BookingRequestInput["customer"])
                    }
                    id="country"
                />
                {privateTelephoneFormFieldType !== "hidden" && (
                    <FieldWithLabel
                        required={privateTelephoneFormFieldType === "required"}
                        value={value.privateTelephone}
                        label={translations.telephone}
                        onChange={(newValue) =>
                            onChange({
                                ...value,
                                privateTelephone: newValue,
                            } as BookingRequestInput["customer"])
                        }
                        type="tel"
                    />
                )}
                {privateMobilephoneFormFieldType !== "hidden" && (
                    <FieldWithLabel
                        required={privateMobilephoneFormFieldType === "required"}
                        value={value.privateMobilephone}
                        label={translations.cellphone}
                        onChange={(newValue) =>
                            onChange({
                                ...value,
                                privateMobilephone: newValue,
                            } as BookingRequestInput["customer"])
                        }
                        type="tel"
                    />
                )}
                {workTelephoneFormFieldType !== "hidden" && (
                    <FieldWithLabel
                        required={workTelephoneFormFieldType === "required"}
                        value={value.workTelephone}
                        label={translations.workPhone}
                        onChange={(newValue) =>
                            onChange({
                                ...value,
                                workTelephone: newValue,
                            } as BookingRequestInput["customer"])
                        }
                        type="tel"
                    />
                )}
                <FieldWithLabel
                    required
                    value={value.email}
                    label={translations.email}
                    onChange={(newValue) =>
                        onChange({ ...value, email: newValue } as BookingRequestInput["customer"])
                    }
                    type="email"
                />
            </div>
        </section>
    );
}
