import React from "react";
import SmartBackgroundImage from "./SmartBackgroundImage";
import "./BreakoutImage.scss";

export interface BreakoutImageProps {
    imageUrl: string;
    title?: string;
    link?: string;
    side: "left" | "right";
}

export default function BreakoutImage({ imageUrl, title, link, side }) {

    const getImageUrl = (width: number, height: number) => {
        return imageUrl;
    }

    return (
        <div className={`bwp-breakout-image bwp-breakout-image--${side}`}>
            <SmartBackgroundImage
                imageUrlFunc={getImageUrl}
                link={link}
                title={title}
                className="bwp-breakout-image__image"
            />
        </div>
    )
}
