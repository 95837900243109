import React from "react";
import { LodgingPresentationQuery } from "./operations.generated";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";

import "./LodgingDescription.scss";

interface Props {
    translations: LodgingPageTranslations;
}

export default function LodgingDescription({
    translations,
    activeDescription,
}: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]) {
    if (activeDescription?.body == null && activeDescription?.longHtmlDescription == null) {
        return null;
    }

    let hasLongHtmlDescription =
        activeDescription?.longHtmlDescription != null &&
        activeDescription?.longHtmlDescription.trim().length > 0;

    return (
        <div className="bwp-lodging-description">
            <h2>{translations.lodgingDescription}</h2>
            {hasLongHtmlDescription && (
                <div dangerouslySetInnerHTML={{ __html: activeDescription?.longHtmlDescription }} />
            )}
            {!hasLongHtmlDescription && activeDescription?.body && (
                <div style={{ whiteSpace: "pre-line" }}>{activeDescription?.body}</div>
            )}
        </div>
    );
}
