import * as React from "react";
import { LodgingPresentationQuery } from "./operations.generated";
import { ensureGoogleMapsIncluded } from "./shared/googleMapUtils";
import { fuzzyCoordinate } from "./shared/Coordinates";
import { Coordinate } from "./shared/types";
import "./LodgingMap.scss";
import { useEffect, useRef, useState } from "react";
import useLazyLoad from "./shared/useLazyLoad";

interface HouseMapProps {
    lodging: LodgingPresentationQuery["lodgingPresentation"]["lodging"];
    googleMapsApiKey: string;
    maxMapZoomLevel?: number;
    mapZoomLevel?: number;
    useCircle?: boolean;
    useFuzzyCoordinates?: boolean;
    fuzzyRangeInKm?: number;
    circleRadius?: number;
    circleFillColor?: string;
    circleFillOpacity?: number;
    circleStrokeColor?: string;
    circleStrokeOpacity?: number;
    circleStrokeWeight?: number;
    markerIconUrl?: string;
    mapHeight?: number;
}

const DefaultMapHeight = "500px";
const DefaultMapZoomLevel = 6;
const DefaultMaxMapZoomLevel = 11;
const DefaultCircleRadius = 10000;
const DefaultCircleFillColor = "#6666ff";
const DefaultCircleFillOpacity = 0.2;
const DefaultCircleStrokeColor = "#000000";
const DefaultCircleStrokeOpacity = 0;
const DefaultCircleStrokeWeight = 1;

export default function LodgingMap({
    mapZoomLevel = DefaultMapZoomLevel,
    useCircle,
    useFuzzyCoordinates,
    fuzzyRangeInKm,
    circleRadius,
    circleFillColor,
    circleFillOpacity,
    circleStrokeColor,
    circleStrokeOpacity,
    circleStrokeWeight,
    markerIconUrl,
    googleMapsApiKey,
    lodging,
    mapHeight,
    maxMapZoomLevel = DefaultMaxMapZoomLevel,
}: HouseMapProps) {
    const map = useRef<google.maps.Map>();
    const circle = useRef<google.maps.Circle>();
    const mapTarget = useRef<HTMLDivElement>();

    const [googleApiAvailable, setGoogleApiAvailable] = useState(false);

    useLazyLoad(mapTarget, () => {
        if (!googleMapsApiKey) {
            return;
        }
        ensureGoogleMapsIncluded(googleMapsApiKey).then(() => {
            setGoogleApiAvailable(true);
        });
    });

    useEffect(() => {
        if (map.current == null && lodging != null && googleApiAvailable) {
            const coords = useFuzzyCoordinates
                ? fuzzyCoordinate(
                      {
                          lat: parseFloat(lodging.latitude),
                          lng: parseFloat(lodging.longitude),
                      } as Coordinate,
                      fuzzyRangeInKm,
                      `lodging${lodging.id}`
                  )
                : ({
                      lat: parseFloat(lodging.latitude),
                      lng: parseFloat(lodging.longitude),
                  } as Coordinate);

            map.current = new google.maps.Map(mapTarget.current, {
                center: coords,
                zoom: mapZoomLevel,
                styles: [
                    {
                        featureType: "poi.business",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            });

            const startRadius = useCircle ? circleRadius : 1;
            const startZoomLevel = mapZoomLevel;
            const stopZoomLevel = maxMapZoomLevel;
            const stopRadius = (startZoomLevel / stopZoomLevel) * startRadius;
            const levelRatio = (stopRadius - startRadius) / (stopZoomLevel - startZoomLevel);
            const baseRadius = startRadius - levelRatio * startZoomLevel;

            map.current.addListener("bounds_changed", () => {
                const zoomLevel = map.current.getZoom();
                if (useCircle) {
                    const calculatedCircleRadius = zoomLevel * levelRatio + baseRadius;
                    circle.current.setRadius(calculatedCircleRadius);
                }
                if (0 < maxMapZoomLevel && maxMapZoomLevel < zoomLevel) {
                    map.current.setZoom(maxMapZoomLevel);
                }
            });

            if (useCircle !== undefined && useCircle !== null && useCircle) {
                circle.current = new google.maps.Circle({
                    center: coords,
                    radius:
                        circleRadius !== undefined && circleRadius !== null
                            ? circleRadius
                            : DefaultCircleRadius,
                    strokeColor:
                        circleStrokeColor !== undefined && circleStrokeColor !== null
                            ? circleStrokeColor
                            : DefaultCircleStrokeColor,
                    strokeOpacity:
                        circleStrokeOpacity !== undefined && circleStrokeOpacity !== null
                            ? circleStrokeOpacity
                            : DefaultCircleStrokeOpacity,
                    strokeWeight:
                        circleStrokeWeight !== undefined && circleStrokeWeight !== null
                            ? circleStrokeWeight
                            : DefaultCircleStrokeWeight,
                    fillColor:
                        circleFillColor !== undefined && circleFillColor !== null
                            ? circleFillColor
                            : DefaultCircleFillColor,
                    fillOpacity:
                        circleFillOpacity !== undefined && circleFillOpacity !== null
                            ? circleFillOpacity
                            : DefaultCircleFillOpacity,
                    map: map.current,
                });
            } else {
                let opts = {
                    position: coords,
                    map: map.current,
                };
                if (markerIconUrl !== undefined && markerIconUrl !== null && markerIconUrl !== "") {
                    opts = Object.assign(opts, { icon: markerIconUrl });
                }
                new google.maps.Marker(opts);
            }
        }
    }, [
        circleFillColor,
        circleFillOpacity,
        circleRadius,
        circleStrokeColor,
        circleStrokeOpacity,
        circleStrokeWeight,
        fuzzyRangeInKm,
        googleApiAvailable,
        lodging,
        mapZoomLevel,
        markerIconUrl,
        maxMapZoomLevel,
        useCircle,
        useFuzzyCoordinates,
    ]);
    
    if (!googleMapsApiKey) {
        return null;
    }

    return (
        <div className="bwp-lodging-map">
            <div
                ref={mapTarget}
                style={{
                    height:
                        mapHeight !== undefined && mapHeight !== null
                            ? mapHeight
                            : DefaultMapHeight,
                }}
            />
        </div>
    );
}
