import React, { useEffect, useRef, useState } from "react";
import "./LodgingBookingBox.scss";
import {
    LodgingPresentationQuery,
    useLodgingBookingOptionsQuery,
    useLodgingCalendarDataQuery
} from "./operations.generated";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import {isBrowser, stringFormat} from "./shared/helpers";
import Button from "./Button";
import { getImageUrl } from "./shared/images";
import Image from "./Image";
import DatePickerWithLabel, { DatePickerWithLabelRef } from "./DatePickerWithLabel";
import DatePicker, { SelectionMode } from "./DatePicker";
import { DurationSelectWithLabel } from "./DurationSelectWithLabel";
import { NumberSelectWithLabel } from "./NumberSelectWithLabel";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";
import {Dictionary, SearchBoxSettings} from "./shared/types";
import { DatePickerTranslations } from "./translations/DatePickerTranslations";
import {SearchContext} from "./shared/searchcontext";
import { CloseIcon } from "./CloseIcon";
import PeopleSelectWithLabel from "./PeopleSelectWithLabel";
import SelectWithLabel from "./SelectWithLabel";
import {TrackLodgingListItem, trackViewList, trackViewLodging} from "./shared/tracking";
import {ItemTypes} from "./shared/graphql-types.generated";
import {getDepositName} from "./shared/bookingoptions";

interface Props {
    culture: string;
    dayManipulator?: React.ComponentProps<typeof DatePicker>["dayManipulator"];
    lodgingPresentation: LodgingPresentationQuery["lodgingPresentation"];
    searchContext: SearchContext;
    searchBoxSettings: SearchBoxSettings;
    durationsAndPrices: Dictionary;
    onBook?: () => void;
    onSearchContextChange?: (searchContext: SearchContext) => void;
    showInfants?: boolean;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showTwoMonthsInDatePicker?: boolean;
    showDurationAboveDatePicker?: boolean;
    translations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    datePickerTranslations: DatePickerTranslations;
}

export function registerTranslations()
{
    return {
        "translations": "LodgingPage",
        "searchBoxTranslations": "SearchBox",
        "datePickerTranslations": "DatePicker"
    };
}

export default function LodgingBookingBox({
    culture,
    dayManipulator,
    lodgingPresentation: { lodging, selectedBookingOption },
    searchContext,
    searchBoxSettings,
    durationsAndPrices,
    onBook,
    onSearchContextChange,
    showInfants,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showTwoMonthsInDatePicker,
    showDurationAboveDatePicker,
    translations,
    searchBoxTranslations,
    datePickerTranslations
}: Props) {
    const [open, setOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>();
    const datePickerRef = useRef<DatePickerWithLabelRef>();
    
    const handleSearchContextChanged = (searchContext: SearchContext) => {
        if (typeof onSearchContextChange === "function") {
            onSearchContextChange(searchContext);
        }
    }

    useEffect(() => {
        if (open) {
            containerRef.current.scrollIntoView({ block: "start" });
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [open]);

    useEffect(() => {
        if (isBrowser() && lodging && selectedBookingOption) {
            trackViewLodging(
                {
                    id: lodging.id,
                    name: lodging.name,
                    location: {
                        id: lodging.location.id,
                        name: lodging.location.name
                    }
                },
                {
                    amount: selectedBookingOption.priceWithMandatoryItems,
                    currency: selectedBookingOption.currency
                },
                { onlyOnce: true }
            );
        }
    }, [lodging, selectedBookingOption]);
    
    const showMaxNumberOfPersonsWarning =
        (searchContext.adults + searchContext.children) > lodging.maxPeople;

    if (!lodging) {
        return null;
    }
    
    if (!searchBoxSettings) {
        return (
            <div className="bwp-widget-config-error">
                <p>
                    No search box settings were found for the <pre>LodgingBookingBox</pre>.
                </p>
                <p>
                    Please supply <pre>searchBoxSettings</pre> in props.
                </p>
            </div>
        );
    }
    
    const oneMonthDatePickerDisplay = showTwoMonthsInDatePicker
        ? "bwp-block bwp-md-hidden"
        : "";
    
    return (
        <div className={"bwp-lodging-booking-box"}>
            <div
                onClick={() => setOpen(true)}
                className="bwp-lodging-booking-box__open-booking-button"
                bwp-show={!open ? "" : undefined}
            >
                <Button type="primary">{translations.book}</Button>
            </div>
            <div
                ref={containerRef}
                className="bwp-lodging-booking-box__container"
                bwp-show={open ? "" : undefined}
            >
                <div className="bwp-lodging-booking-box__close-icon" onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                <div className="bwp-lodging-booking-box__lodging-summary">
                    <div className="bwp-lodging-booking-box__lodging-summary__image">
                        {lodging.images.length > 0 && (
                            <Image
                                imageUrlFunc={(width, height) =>
                                    getImageUrl(lodging.images[0], width, height)
                                }
                                alt={lodging.name}
                                width="56px"
                                height="50px"
                            />
                        )}
                    </div>
                    <div className="bwp-lodging-booking-box__lodging-summary__description">
                        <div className="bwp-lodging-booking-box__lodging-summary__name">
                            {translations.lodgingNamePrefix} {lodging.name}
                        </div>
                        <div className="bwp-lodging-booking-box__lodging-summary__address">
                            {lodging.location.name}
                        </div>
                    </div>
                </div>
                <div className="bwp-lodging-booking-box__parameters">
                    {showDurationAboveDatePicker && (
                        <DurationSelectWithLabel
                            id="duration"
                            label={searchBoxTranslations.durationLabel}
                            value={searchContext.duration}
                            onChange={(v) => handleSearchContextChanged(searchContext.changeDuration(v))}
                            translations={searchBoxTranslations}
                            durations={searchBoxSettings.availableDurations}
                            durationsAndPrices={durationsAndPrices}
                        />                        
                    )}
                    <div className={oneMonthDatePickerDisplay}>
                        <DatePickerWithLabel
                            ref={datePickerRef}
                            label={searchBoxTranslations.arrivalLabel}
                            value={searchContext.parsedArrival}
                            onChange={(v) => handleSearchContextChanged(searchContext.changeArrival(v))}
                            selectionMode={SelectionMode.Future}
                            culture={culture}
                            translations={datePickerTranslations}
                            dayManipulator={dayManipulator}
                            showTwoMonths={false}
                        />
                    </div>
                    {showTwoMonthsInDatePicker && (
                        <div className="bwp-hidden bwp-md-block">
                            <DatePickerWithLabel
                                ref={datePickerRef}
                                label={searchBoxTranslations.arrivalLabel}
                                value={searchContext.parsedArrival}
                                onChange={(v) => handleSearchContextChanged(searchContext.changeArrival(v))}
                                selectionMode={SelectionMode.Future}
                                culture={culture}
                                translations={datePickerTranslations}
                                dayManipulator={dayManipulator}
                                showTwoMonths={true}
                            />
                        </div>
                    )}
                    {!showDurationAboveDatePicker && (
                        <DurationSelectWithLabel
                            id="duration"
                            label={searchBoxTranslations.durationLabel}
                            value={searchContext.duration}
                            onChange={(v) => handleSearchContextChanged(searchContext.changeDuration(v))}
                            translations={searchBoxTranslations}
                            durations={searchBoxSettings.availableDurations}
                            durationsAndPrices={durationsAndPrices}
                        />
                    )}
                    <PeopleSelectWithLabel
                        label={searchBoxTranslations.personsLabel}
                        maxPersons={lodging?.maxPeople || searchBoxSettings.maxPeople}
                        value={{
                            adults: searchContext.adults ?? 0,
                            children: searchContext.children ?? 0,
                            infants: searchContext.infants ?? 0
                        }}
                        onChange={(v) =>
                            handleSearchContextChanged(
                                searchContext.changePersons(
                                    v.adults,
                                    v.children, 
                                    v.infants,
                                    searchContext.pets
                                )
                            )
                        }
                        translations={searchBoxTranslations}
                        warningMessage={
                            showMaxNumberOfPersonsWarning
                                ? translations.theHouseMayNotBeAvailableForTheSelectedNumberOfPeople
                                : undefined
                        }
                        showInfants={showInfants}
                    />
                    {lodging.petsAllowed && (
                        <NumberSelectWithLabel
                            id="pets"
                            label={searchBoxTranslations.petsLabel}
                            itemTranslation={searchBoxTranslations.pet}
                            value={searchContext.pets ?? 0}
                            min={0}
                            max={lodging.maxPets ?? 1}
                            onChange={(v) => handleSearchContextChanged(searchContext.changePets(v))}
                        />
                    )}
                    {!lodging.petsAllowed && (
                        <SelectWithLabel
                            id="pets"
                            label={searchBoxTranslations.petsLabel}
                            value={null}
                            onChange={() => { return; }}
                            items={[{ value: null, text: translations.petsNotAllowed }]}
                            disabled
                        />
                    )}
                </div>
                {selectedBookingOption && (
                    <PriceTable
                        translations={translations}
                        selectedBookingOption={selectedBookingOption}
                        showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                        showDepositSeparately={showDepositSeparately}
                        onBook={onBook}
                    />
                )}
                {selectedBookingOption == null && (
                    <div className="bwp-lodging-booking-box__no-bookingoption-available-container">
                        {showMaxNumberOfPersonsWarning && (
                            <p>
                                <strong>
                                    {
                                        translations.theHouseMayNotBeAvailableForTheSelectedNumberOfPeople
                                    }
                                </strong>
                            </p>
                        )}
                        <p>{translations.noAvailableBookingOption}</p>
                        <Button
                            type={"primary"}
                            onClick={() => datePickerRef.current.focusAndShowCalendar()}
                        >
                            {translations.chooseAnotherArrivalDate}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

interface PriceTableProps {
    onBook: () => void;
    selectedBookingOption: LodgingPresentationQuery["lodgingPresentation"]["selectedBookingOption"];
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    translations: LodgingPageTranslations;
}

function PriceTable({ selectedBookingOption, translations, onBook, showProbabilityWithPriceAsBookingOption, showDepositSeparately }: PriceTableProps) {
    const isProbability = (
        showProbabilityWithPriceAsBookingOption
            ? selectedBookingOption?.status === "Probability"
            : (selectedBookingOption?.status === "Probability" || selectedBookingOption?.status === "ProbabilityAsBooking")
    );
    
    const depositName = getDepositName(selectedBookingOption) || translations.deposit;
    
    return (
        <>
            {!isProbability && (
                <>
                    <div className="bwp-lodging-booking-box__item-row">
                        <div>
                            {stringFormat(
                                translations.periodPrice,
                                selectedBookingOption.arrivalDisplayValue,
                                selectedBookingOption.departureDisplayValue
                            )}
                        </div>
                        <div>{selectedBookingOption.normalPriceDisplayValue}</div>
                    </div>
                    {selectedBookingOption.hasDiscount && (
                        <div className="bwp-lodging-booking-box__item-row">
                            <div>{selectedBookingOption.discountName}</div>
                            <div>{selectedBookingOption.discountReductionDisplayValue}</div>
                        </div>
                    )}
                    {
                        selectedBookingOption.itemPrices
                            ?.filter((ip) => ip.isMandatory && (!showDepositSeparately || ip.item.itemType != ItemTypes.Deposit))
                            .map(
                                (ip, index) => (
                                    <div className="bwp-lodging-booking-box__item-row" key={index}>
                                        <div>{ip.item.name}</div>
                                        <div>{ip.priceWithQuantityDisplayValue}</div>
                                    </div>
                                )
                            )
                    }
                </>
            )}
            {!isProbability && (
                <div className="bwp-lodging-booking-box__total-row">
                    <div>{translations.price}</div>
                    <div>{showDepositSeparately
                        ? selectedBookingOption.priceWithMandatoryItemsWithoutDepositsDisplayValue
                        : selectedBookingOption.priceWithMandatoryItemsDisplayValue
                    }</div>
                    
                </div>
            )}
            {(!isProbability && showDepositSeparately && selectedBookingOption.priceDepositItems > 0) && (
                <div className="bwp-lodging-booking-box__deposit-row">
                    <div>{depositName}</div>
                    <div>{selectedBookingOption.priceDepositItemsDisplayValue}</div>
                </div>
            )}
            <div className="bwp-lodging-booking-box__book-row">
                <Button type="primary" onClick={() => onBook()}>
                    {isProbability ? translations.reserve : translations.book}
                </Button>
            </div>
        </>
    );
}

