import React from "react";
import "./Article.scss";

export default function Article() {
    return (
        <div style={{ margin: 16 }}>
            <article className="bwp-article">
                <h1>This our beautiful headline</h1>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat, eligendi
                    dolores assumenda consequuntur beatae eius sapiente! Eum animi aspernatur,
                    tenetur debitis dolor ab illo incidunt voluptatum laudantium laboriosam neque
                    suscipit?
                </p>
                <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsa magnam dolor
                    architecto asperiores inventore blanditiis iusto, molestias molestiae!
                    Repellendus obcaecati corporis vitae debitis aliquam ipsam hic, est laudantium
                    labore? Explicabo.
                </p>
                <ul>
                    <li>This is the first item</li>
                    <li>This is the second item</li>
                    <li>This is the third item</li>
                    <li>This is the forth item</li>
                </ul>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore modi
                    reprehenderit quisquam! Laboriosam accusantium corrupti incidunt illum sapiente!
                    Consequuntur iste laboriosam quod harum, quae impedit quas ut sequi modi nemo!
                </p>
                <ol>
                    <li>First we do this</li>
                    <li>Second we do this</li>
                    <li>Third we do this</li>
                    <li>Forth we do this</li>
                </ol>
                <h2>This is a second level headline</h2>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore officiis
                    autem, explicabo quidem quasi harum quas iusto architecto ad sunt, adipisci
                    accusantium qui maxime iste laudantium. Quisquam nihil corrupti doloremque.
                </p>
                <h3>This is a third level headline</h3>
                <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque pariatur
                    placeat provident, neque ex quibusdam iste, accusamus eum laboriosam cum
                    cupiditate, recusandae voluptas ad atque quia libero eveniet! Accusamus,
                    tempora.
                </p>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet at numquam
                    mollitia ab, ipsa possimus reprehenderit ad sapiente nulla modi perferendis
                    inventore repellat non. Reiciendis modi neque debitis vitae sed?
                </p>
                <h3>Another third level headline</h3>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel veniam nemo quos!
                    Sunt alias sed placeat quas dignissimos quo saepe voluptatibus, voluptates enim
                    in reiciendis libero laborum voluptatem, impedit nam?
                </p>
                <table>
                    <thead>
                        <tr>
                            <th>Days of the week</th>
                            <th>Opening hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Mon-Thur</td>
                            <td>08:00 - 16:00</td>
                        </tr>
                        <tr>
                            <td>Friday</td>
                            <td>08:00 - 18:00</td>
                        </tr>
                        <tr>
                            <td>Saturday and Sunday</td>
                            <td>07:30 - 20:00</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora hic iusto
                    neque nobis id pariatur deleniti non consequatur aperiam laborum? Accusantium,
                    asperiores nisi reiciendis aliquam nihil velit error. Unde, mollitia.
                </p>
                <h2>What our customers say about us</h2>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium in iure
                    nulla debitis dolor, aliquid fugit rerum facere, dolorem quos illo quaerat hic
                    doloremque, necessitatibus doloribus aspernatur corrupti sequi odit.
                </p>
                <blockquote>They really deliver on every promise!</blockquote>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis libero, culpa
                    suscipit explicabo laboriosam qui eaque provident adipisci repellendus,
                    quibusdam facere nostrum corporis iste illo aliquid sint magni. Dolores, dolor.
                </p>
                <blockquote>Everything worked like a charm. No complains from us.</blockquote>
            </article>
        </div>
    );
}
