import React from "react";
import "./BureauReviews.scss";
import { BureauReviewsQuery } from "./operations.generated";
import { CommentIcon } from "./shared/icons";

interface Props {
    reviews: BureauReviewsQuery["bureauReviews"];
}

export default function BureauReviews({ reviews }: Props) {
    return (
        <div className="bwp-bureau-reviews">
            {reviews.map((review, index) => (
                <div key={index} className="bwp-bureau-reviews__review">
                    <CommentIcon />
                    <div className="bwp-bureau-reviews__review-body">
                        <p>{review.reviewOfBureauText}</p>
                        {review.reviewerName && (
                            <div className="bwp-bureau-reviews__review-byline">
                                {review.reviewerName}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}


