let googleMapsCallbackListener = [];
let loadingGoogleMaps = false;

if (typeof window != "undefined") {
    window["CALLBACK_INCLUDEGOOGLEMAP"] = () => {
        googleMapsCallbackListener.forEach((fn) => {
            fn();
        });

        googleMapsCallbackListener = [];
    };
}

export function ensureGoogleMapsIncluded(apiKey: string): Promise<any> {
    if (window["google"] === undefined || window["google"]["maps"] === undefined) {
        if (loadingGoogleMaps === false) {
            loadingGoogleMaps = true;

            const googleScript = document.createElement("script");
            googleScript.src =
                "https://maps.googleapis.com/maps/api/js?callback=CALLBACK_INCLUDEGOOGLEMAP&key=" +
                apiKey;

            document.body.appendChild(googleScript);
        }

        return new Promise((resolve, _) => {
            addGoogleMapsCallbackListener(resolve);
        });
    } else {
        return Promise.resolve();
    }
}

function addGoogleMapsCallbackListener(fn: (value: any) => void) {
    googleMapsCallbackListener.push(fn);
}
