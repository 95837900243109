import { Favourite } from "./types";
import { isBrowser } from "./helpers";

class FavoriteHelper {
    private favouriteStorageKey = "BS_Favorites_key";
    private favourites: Favourite[];
    private subscribers: (() => void)[];

    constructor() {
        if (!isBrowser()) {
            return;
        }

        let jsonFavorites = localStorage.getItem(this.favouriteStorageKey);
        if (jsonFavorites) {
            this.favourites = JSON.parse(jsonFavorites);
        } else {
            this.favourites = [];
            window["localStorage"].setItem(
                this.favouriteStorageKey,
                JSON.stringify(this.favourites)
            );
        }
        this.subscribers = [];
    }

    getFavorites = (filterNonFavorites: boolean = true) => {
        if (filterNonFavorites) {
            return this.favourites.filter((fav) => fav.isFavourite);
        }
        return this.favourites;
    };

    isFavourite = (id: number) => {
        let item = this.favourites.find((fav) => fav.id == id);
        if (item) {
            return item.isFavourite;
        }
        return false;
    };

    handleFavouriteToggle = (id: number) => {
        let found = false;
        let newFavourites = this.favourites.map((fav) => {
            if (fav.id == id) {
                found = true;
                return { id: fav.id, isFavourite: !fav.isFavourite };
            } else {
                return { id: fav.id, isFavourite: fav.isFavourite };
            }
        });
        if (!found) {
            newFavourites.push({ id: id, isFavourite: true });
        }

        this.favourites = newFavourites;
        window["localStorage"].setItem(this.favouriteStorageKey, JSON.stringify(this.favourites));
        this.notifySubscribers();
    };

    subscribe = (notify: () => void) => {
        this.subscribers.push(notify);

        return () => {
            this.subscribers = this.subscribers.filter((sub) => sub != notify);
        };
    };

    private notifySubscribers = () => {
        this.subscribers.forEach((notificationFunction) => notificationFunction());
    };
}

const favoriteHelper = new FavoriteHelper();

export default favoriteHelper;
