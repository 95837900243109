import { SearchContext } from "./searchcontext";
import { useCallback, useEffect, useState } from "react";
import { isBrowser } from "./helpers";

/**
 * A hook that supplies access to a SearchContext instance.
 */
export default function usePassiveGlobalSearchContext() {
    let [searchContext, setSearchContext] = useState<SearchContext>();

    useEffect(() => {
        if (isBrowser()) {
            const listener = (e: { detail: SearchContext }) => {
                setSearchContext(e.detail);
            };

            window.addEventListener("searchContextUpdated", listener as any);

            return () => {
                window.removeEventListener("searchContextUpdated", listener as any);
            };
        }
    }, []);

    const handleSetSearchContext = useCallback((searchContext: SearchContext) => {
        if (isBrowser()) {
            window.dispatchEvent(
                new CustomEvent("passiveSearchContextUpdated", { detail: searchContext })
            );
        } else {
            throw "Global Search Context does not supporting setting the value outside of a browser context";
        }
    }, []);

    return [searchContext, handleSetSearchContext] as [
        SearchContext,
        (searchContext: SearchContext) => void
    ];
}
