// Ported from: https://github.com/BookingStudio/BookingStudio/blob/407cc1cf51ebe5a5aa391e6d31da0854c9c8ab7e/src/BookingStudioBricks/wwwroot/scripts/bricks/BookingStudio.Bricks.EmbedKit.js#L1
// Extended to add autoplay support

const urlParsers = [
    { regex: /(?:http[s]?:)?\/\/vimeo.com\/(\d*)/, type: "vimeo" },
    { regex: /(?:http[s]?:)?\/\/player.vimeo.com\/video\/(\d*)/, type: "vimeo" },
    { regex: /(?:http[s]?:)?\/\/youtu.be\/([-A-z0-9]*)/, type: "youtube" },
    { regex: /(?:http[s]?:)?\/\/www.youtube.com\/embed\/([^?]*)/, type: "youtube" },
    { regex: /(?:http[s]?:)?\/\/www.youtube.com\/watch\?v=([^?]*)/, type: "youtube" },
];

interface Movie {
    id: string;
    type: string;
}

export interface EmbedKitOptions {
    embedElementManipulator?: (
        embedElement: HTMLElement,
        movie: Movie,
        element: HTMLElement
    ) => void;
    autoPlay?: boolean;
}

export function embed(selector, options) {
    let elements = query(selector);

    let allEmbeds = [];
    elements.forEach((item) => {
        const embedElem = embedSingle(item, options);
        if (embedElem) {
            allEmbeds.push(embedElem);
        }
    });

    return allEmbeds;
}

export function embedSingle(element: HTMLElement, options: EmbedKitOptions) {
    options = options || {};

    let movie = resolveEmbedUrl(element.getAttribute("data-url"));
    if (!movie?.id || !movie.type) {
        return null;
    }

    let embedElement = getEmbedElement(movie, options);
    if (!embedElement) {
        return null;
    }

    if (options.embedElementManipulator) {
        options.embedElementManipulator(embedElement, movie, element);
    }

    element.appendChild(embedElement);

    return embedElement;
}

function getEmbedElement(movie: Movie, options: EmbedKitOptions) {
    let iframeElement = document.createElement("iframe");
    iframeElement.setAttribute("allowfullscreen", "allowfullscreen");
    iframeElement.setAttribute("allow", "autoplay; fullscreen");

    if (!movie?.id || !movie?.type) {
        return null;
    }
    
    switch (movie.type) {
        case "youtube": {
            let src = "https://www.youtube.com/embed/" + movie.id + "?enablejsapi=1";
            if (options.autoPlay) {
                src += "&autoplay=1";
            }
            iframeElement.setAttribute("src", src);
            return iframeElement;
        }
        case "vimeo": {
            let src = "https://player.vimeo.com/video/" + movie.id;
            if (options.autoPlay) {
                src += "?autoplay=1";
            }
            iframeElement.setAttribute("src", src);
            return iframeElement;
        }
    }
}

function query(selector: string) {
    let nodeList = document.querySelectorAll(selector);
    return Array.prototype.slice.call(nodeList, 0);
}
export function resolveEmbedUrl(url: string) {
    return urlParsers.reduce((result, current) => {
        if (result) {
            return result;
        }

        let matches = current.regex.exec(url);
        if (matches && matches.length > 0) {
            return {
                type: current.type,
                id: matches[1],
            };
        }
    }, null as Movie);
}

export function getEmbedController(element: HTMLIFrameElement) {
    let movie = resolveEmbedUrl(element.getAttribute("src"));

    if (movie) {
        switch (movie.type) {
            case "youtube":
                return youTubeController(element);
            case "vimeo":
                return vimeoController(element);
        }
    }

    warn("Could not find controller for element: " + element.outerHTML);
}

function youTubeController(element: HTMLIFrameElement) {
    if (element.src.indexOf("enablejsapi=1") < 0) {
        warn(
            "The youTubeController needs the enablejsapi=1 querystring parameter to be set in the src attribute, to be able to control the video"
        );
    }

    return {
        play: () => {
            element.contentWindow.postMessage(
                JSON.stringify({ event: "command", func: "playVideo" }),
                "https://www.youtube.com"
            );
        },
        pause: () => {
            element.contentWindow.postMessage(
                JSON.stringify({ event: "command", func: "pauseVideo" }),
                "https://www.youtube.com"
            );
        },
    };
}

function vimeoController(element) {
    return {
        play: () => {
            element.contentWindow.postMessage(
                JSON.stringify({ method: "play" }),
                "https://player.vimeo.com"
            );
        },
        pause: () => {
            element.contentWindow.postMessage(
                JSON.stringify({ method: "pause" }),
                "https://player.vimeo.com"
            );
        },
    };
}

function warn(message) {
    console && console.warn && console.warn(message);
}
