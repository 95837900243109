import React from "react";
import "./FieldWithLabel.scss";
import RangeSlider from "./RangeSlider";
import "./RangeSliderWithLabel.scss";
export interface RangeSliderWithLabelProps extends React.ComponentProps<typeof RangeSlider> {
    label: string;
}

export default function RangeSliderWithLabel(props: RangeSliderWithLabelProps) {
    const sliderProps = { ...props };
    delete sliderProps.label;

    return (
        <div className="bwp-range-slider-with-label">
            <label>
                <span>{props.label}</span>
                <RangeSlider {...sliderProps} />
            </label>
        </div>
    );
}
