import React from "react";
import "./NumberPicker.scss";

export interface NumberPickerProps {
    value: number;
    step?: number;
    min?: number;
    max?: number;
    onChange: (newValue: number) => void;
}

export default function NumberPicker({ value, step, min, max, onChange }: NumberPickerProps) {
    const handleMinusClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        const stepValue = typeof step !== "undefined" ? step : 1;
        onChange(value - stepValue);
    };

    const handlePlusClick = (e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        const stepValue = typeof step !== "undefined" ? step : 1;
        onChange(value + stepValue);
    };

    const canMinus = typeof min !== "undefined" ? value > min : true;

    const canPlus = typeof max !== "undefined" ? value < max : true;

    return (
        <div className="bwp-numberpicker">
            <button
                className="bwp-numberpicker__button"
                type="button"
                onClick={handleMinusClick}
                disabled={!canMinus}
            >
                -
            </button>
            <div className="bwp-numberpicker__label">{value}</div>
            <button
                className="bwp-numberpicker__button"
                type="button"
                onClick={handlePlusClick}
                disabled={!canPlus}
            >
                +
            </button>
        </div>
    );
}
