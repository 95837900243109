import React, {PropsWithChildren, useMemo, useState} from "react";
import {CalendarValue} from "./shared/types";
import {DatePickerTranslations} from "./translations/DatePickerTranslations";
import "./DatePickerCalendar.scss";
import {
    addDays,
    addMonths,
    format,
    getDaysInMonth,
    getISODay, getISOWeek,
    isAfter,
    isBefore,
    isEqual,
    parse,
    parseISO
} from "date-fns";

// DayCell component
type DayCellProps = React.HTMLProps<HTMLTableCellElement>;

const DayCell = (props: DayCellProps) => {
    let basicClassName = "bwp-calendar__day-cell";

    if (props.onClick) {
        basicClassName = basicClassName + " bwp-calendar__cell-clickable";
    } else {
        basicClassName = basicClassName + " bwp-calendar__cell-not-clickable";
    }

    const localProps = {
        ...props,
        className: [basicClassName, props.className].join(" "),
    };

    return <td {...localProps}>{localProps.children}</td>;
}

// WeekCell component
const WeekCell = (props: PropsWithChildren<unknown>) => {
    return <td className="bwp-calendar__week-cell">{props.children}</td>;
};

// DatePickerCalendar component
export interface DatePickerCalendarDay {
    overrideValue?: string;
    title?: string;
    suggestionRange?: { from: string; to: string };
    date: string;
    parsedDate: Date;
    selectable?: boolean;
    unSelectableReason?: string;
    classNames: string[];
}

export interface DatePickerCalendarProps {
    days: DatePickerCalendarDay[];
    onChange: (value: CalendarValue) => void;
    onActiveMonthChanged?: (year: number, month: number) => void;
    displayYear: number;
    displayMonth: number;
    value: CalendarValue;
    translations: DatePickerTranslations;
    showTwoMonths: boolean;
}

interface Cell {
    day: number;
    component: any;
}

interface DayInfo {
    day: DatePickerCalendarDay;
    parsedDate: Date;
    key: string;
}

const intoWeekRows = (result: Array<Array<Cell>>, cur) => {
    let curRow = result[result.length - 1];
    if (curRow.length === 7) {
        curRow = [];
        result.push(curRow);
    }
    curRow.push(cur);
    return result;
};

/**
 * Finds the weeknumber by looking at the first day in the week
 * We will out the cells that is duds
 */
const getRowWeekNumer = (date: Date, row: Cell[]) => {
    const cell = row.filter((d) => d.day > 0)[0];
    if (!cell?.day) {
        return -1;
    }
    const day = addDays(date, cell.day - 1);
    return getISOWeek(day);
}

export default function DatePickerCalendar({
    days,
    onChange,
    onActiveMonthChanged,
    displayYear,
    displayMonth,
    value,
    translations,
    showTwoMonths
}: DatePickerCalendarProps) {
    
    const [selectionMessage, setSelectionMessage] = useState<string>(null);
    const [hoveredDay, setHoveredDay] = useState<DayInfo>(null);

    const dayToValue = (day: number): CalendarValue => {
        return {
            year: displayYear,
            month: displayMonth + 1,
            day: day,
        };
    };
    
    const handleDayClicked = (day: number, dayInfo: DayInfo) => {
        if (dayInfo.day.selectable) {
            setSelectionMessage(null);
            if (dayInfo.day.overrideValue == null) {
                onChange(dayToValue(day));
            } else {
                let value = parse(dayInfo.day.overrideValue, "yyyy-MM-dd", new Date());
                onChange({
                    year: value.getFullYear(),
                    month: value.getMonth() + 1,
                    day: value.getDate(),
                });
            }
        } else {
            setSelectionMessage(dayInfo.day.unSelectableReason);
        }
    };
    
    const renderCell = (day: number, dayInfo: DayInfo) => {
        const cellProps: DayCellProps = {
            key: dayInfo.key,
            className: dayInfo.day.classNames.join(" "),
            onMouseOver: () => setHoveredDay(dayInfo),
            onMouseOut: () => {
                if (hoveredDay == dayInfo) {
                    setHoveredDay(null);
                }
            },
        };

        cellProps.title = dayInfo.day.title;

        if (dayInfo.day.selectable) {
            cellProps.onClick = () => handleDayClicked(day, dayInfo);
        } else {
            cellProps.title = dayInfo.day.unSelectableReason;
        }

        const suggestionRange = hoveredDay?.day?.suggestionRange;
        if (suggestionRange != null) {
            let from = parse(suggestionRange.from, "yyyy-MM-dd", new Date());
            let to = parse(suggestionRange.to, "yyyy-MM-dd", new Date());
            let parsedDate = dayInfo.parsedDate;
            if (isEqual(parsedDate, from)) {
                cellProps.className += " bwp-calendar__cell-suggestion-arrival-date";
            }
            if (isAfter(parsedDate, from) && isBefore(parsedDate, to)) {
                cellProps.className += " bwp-calendar__cell-suggestion";
            }
            if (isEqual(parsedDate, to)) {
                cellProps.className += " bwp-calendar__cell-suggestion-departure-date";
            }
        }

        if (value != null) {
            let date = new Date(
                value.year,
                value.month - 1,
                value.day,
                0,
                0,
                0
            );

            let suggestionRange = days.find((d) => isEqual(d.parsedDate, date))?.suggestionRange;

            if (suggestionRange != null) {
                let from = parse(suggestionRange.from, "yyyy-MM-dd", new Date());
                let to = parse(suggestionRange.to, "yyyy-MM-dd", new Date());
                let parsedDate = dayInfo.parsedDate;
                if (isEqual(parsedDate, from)) {
                    cellProps.className += " bwp-calendar__cell-selected-arrival-date";
                }
                if (isAfter(parsedDate, from) && isBefore(parsedDate, to)) {
                    cellProps.className += " bwp-calendar__cell-selected";
                }
                if (isEqual(parsedDate, to)) {
                    cellProps.className += " bwp-calendar__cell-selected-departure-date";
                }
            } else {
                if (isEqual(dayInfo.day.parsedDate, date)) {
                    cellProps.className += " bwp-calendar__cell-selected-single";
                }
            }
        }

        return <DayCell {...cellProps}>{day.toString()}</DayCell>;
    };
    
    const generateCells = (date: Date, dayInfos: DayInfo[]) => {
        const cells: Cell[] = [];

        // we need to add duds for days not in this month
        const numberOfDuds = getISODay(date) - 1;
        for (let x = 1; x <= numberOfDuds; x++) {
            cells.push({ day: null, component: <DayCell key={"dud" + x} /> });
        }

        for (let x = 1; x <= getDaysInMonth(date); x++) {
            const key = format(new Date(date.getFullYear(), date.getMonth(), x), "yyyy-MM-dd");
            const dayInfo = dayInfos.find((x) => x.key === key);
            if (dayInfo) {
                cells.push({ 
                    day: x,
                    component: renderCell(x, dayInfo)
                });
            }
        }

        return cells;
    };
    
    const handleNavigateBackward = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        const date = addMonths(
            new Date(
                displayYear, 
                displayMonth, 
                1, 
                0, 
                0, 
                0
            ),
-1);

        onActiveMonthChanged(date.getFullYear(), date.getMonth());
    };
    
    const handleNavigateForward = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        const date = addMonths(
            new Date(
                displayYear,
                displayMonth,
                1
            ),
        1
        );

        onActiveMonthChanged(date.getFullYear(), date.getMonth());
    };
    
    if (!days) {
        return;
    }
    
    const dayInfos = days.map(day => ({
        day,
        parsedDate: parseISO(day.date),
        key: day.date.substr(0, "yyyy-MM-dd".length)
    }));

    const monthNumber = displayMonth;
    const year = displayYear;

    const monthNames = [
        translations.january,
        translations.february,
        translations.march,
        translations.april,
        translations.may,
        translations.june,
        translations.july,
        translations.august,
        translations.september,
        translations.october,
        translations.november,
        translations.december
    ];

    const monthDate = new Date(year, monthNumber, 1, 0, 0, 0);
    const monthName = monthNames[monthDate.getMonth()];

    const nextMonthDate = new Date(
        monthNumber === 11 ? year + 1 : year,
        monthNumber === 11 ? 0 : monthNumber + 1,
        1, 0, 0, 0
    );
    const nextMonthName = monthNames[nextMonthDate.getMonth()];
    const nextMonthYear = nextMonthDate.getFullYear();

    const weekDayNames = [
        translations.sun,
        translations.mon,
        translations.tue,
        translations.wed,
        translations.thu,
        translations.fri,
        translations.sat
    ];
    
    const cells: Cell[] = useMemo(() => generateCells(monthDate, dayInfos), [monthDate, dayInfos]);
    const rows = cells.reduce(intoWeekRows, [[]]) as Array<Array<Cell>>;
    
    const nextMonthCells: Cell[] = useMemo(() => generateCells(nextMonthDate, dayInfos), [nextMonthDate, dayInfos]);
    const nextMonthRows = nextMonthCells.reduce(intoWeekRows, [[]]) as Array<Array<Cell>>;

    const showWeeks = window.innerWidth > 400;
    
    const headerStyles = showTwoMonths
        ? "bwp-calendar__two-months"
        : "";
    
    return (
        <div className="bwp-calendar-container">
            <div className="bwp-calendar">
                <div className="bwp-calendar__header">
                    <div className="bwp-calendar__navigator" onClick={handleNavigateBackward}>«</div>
                    <div className="bwp-calendar__month-year">{monthName}{" "}{year}</div>
                    {showTwoMonths && (
                        <div className="bwp-calendar__month-year">{nextMonthName}{" "}{nextMonthYear}</div>
                    )}
                    <div className="bwp-calendar__navigator" onClick={handleNavigateForward}>»</div>
                </div>
                <div style={{ display: "flex" }}>
                    <table className="bwp-calendar__table" style={{ flexGrow: 1 }}>
                        <thead>
                        <tr>
                            {showWeeks ? <WeekCell /> : null}
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[1]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[2]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[3]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[4]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[5]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[6]}
                            </th>
                            <th className="bwp-calendar__week-day-cell">
                                {weekDayNames[0]}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                {showWeeks ? (
                                    <WeekCell>{getRowWeekNumer(monthDate, row)}</WeekCell>
                                ) : null}
                                {row.map((r) => r.component)}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {showTwoMonths && (
                        <table className="table-condensed" style={{ flexGrow: 1 }}>
                            <thead>
                            <tr>
                                {showWeeks ? <WeekCell /> : null}
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[1]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[2]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[3]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[4]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[5]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[6]}
                                </th>
                                <th className="bwp-calendar__week-day-cell">
                                    {weekDayNames[0]}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {nextMonthRows.map((row, index) => (
                                <tr key={index}>
                                    {showWeeks ? (
                                        <WeekCell>
                                            {getRowWeekNumer(nextMonthDate, row)}
                                        </WeekCell>
                                    ) : null}
                                    {row.map((r) => r.component)}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                {selectionMessage && (
                    <span className="bwp-calendar__selection-message">
                        {selectionMessage}
                    </span>
                )}
            </div>
        </div>
    );
}