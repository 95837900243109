import React from "react";

export default function ClockIcon() {
    return (
        <svg
            width={21}
            height={22}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.5 0C4.6996 0 0 4.80089 0 10.7263C0 16.6517 4.6996 21.4526 10.5 21.4526C16.3004 21.4526 21 16.6517 21 10.7263C21 4.80089 16.3004 0 10.5 0ZM12.9175 15.1423L9.18327 12.3699C9.05202 12.2704 8.97581 12.1147 8.97581 11.9503V4.67114C8.97581 4.38568 9.20444 4.15212 9.48387 4.15212H11.5161C11.7956 4.15212 12.0242 4.38568 12.0242 4.67114V10.6268L14.7127 12.625C14.9413 12.7937 14.9879 13.1181 14.8228 13.3517L13.6288 15.0298C13.4637 15.2591 13.1462 15.311 12.9175 15.1423Z"
                fill="black"
            />
        </svg>
    );
}
