import seedrandom from "seedrandom";
import { Coordinate } from "./types";

export function fuzzyCoordinate(
    coord: Coordinate,
    fuzzinessInKm: number,
    seed: string
): Coordinate {
    const kmPerDegreeLatitude: number = (1 / 110.574) * (fuzzinessInKm || 1);
    const kmPerDegreeLongitude: number =
        (1 / (111.325 * Math.cos((coord.lat * Math.PI) / 180))) * (fuzzinessInKm || 1);

    const latitudeMinMax = {
        min: coord.lat - kmPerDegreeLatitude,
        max: coord.lat + kmPerDegreeLatitude,
    };

    const longitudeMinMax = {
        min: coord.lng - kmPerDegreeLongitude,
        max: coord.lng + kmPerDegreeLongitude,
    };

    let rng = seed !== null && seed !== "" ? seedrandom(seed) : seedrandom();

    return {
        lat: rng() * (latitudeMinMax.max - latitudeMinMax.min) + latitudeMinMax.min,
        lng: rng() * (longitudeMinMax.max - longitudeMinMax.min) + longitudeMinMax.min,
    } as Coordinate;
}
