import React from "react";
import BureauReviews from "@bwp/BureauReviews";
import { useBureauReviewsQuery } from "@bwp/operations.generated";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";

interface Props {
    headline?: string;
    buttonText?: string;
    buttonUrl?: string;
    animationDelay?: number;
}

export default function BureauReviewsWrapper() {
    const { data, loading, error } = useBureauReviewsQuery();

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }

    return (
        <BureauReviews
            reviews={data?.bureauReviews || []}
        />
    );
}
