import React from "react";
import "./AlertBox.scss";

export interface AlertBoxProps {
    children: React.ReactNode;
}

export default function AlertBox({ children }: AlertBoxProps) {
    return <div className="bwp-alert-box">{children}</div>;
}
