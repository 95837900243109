import React, { useEffect, useState } from "react";

import "./FilterPanel.scss";
import CheckBoxWithLabel from "./CheckBoxWithLabel";
import { SearchBoxSettings } from "./shared/types";
import usePassiveGlobalSearchContext from "./shared/usePassiveGlobalSearchContext";
import SearchBoxRangeFilter from "./SearchBoxRangeFilter";
import Button from "./Button";
import AnimatedCounter from "./AnimatedCounter";
import { CloseIcon } from "./CloseIcon";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";

interface LodgingListFilterProps {
    searchBoxSettings: SearchBoxSettings;
    translations: SearchBoxTranslations;
}

export default function LodgingListFilter({
    searchBoxSettings,
    translations,
}: LodgingListFilterProps) {
    const [searchContext, setSearchContext] = usePassiveGlobalSearchContext();
    const [open, setOpen] = useState(false);
    const [numberOfSearchResults, setNumberOfSearchResults] = useState<number>(null);

    useEffect(() => {
        const body = document.querySelector("body");
        const bounds = body.getBoundingClientRect();
        if (bounds.width < 768) {
            if (open) {
                body.classList.add("bwp-noscroll");
            } else {
                body.classList.remove("bwp-noscroll");
            }
        }
    }, [open]);

    useEffect(() => {
        let listener = (e: { detail: number }) => {
            setNumberOfSearchResults(e.detail);
        };

        window.addEventListener("numberOfSearchResultsUpdated", listener as any);

        return () => {
            window.removeEventListener("numberOfSearchResultsUpdated", listener as any);
        };
    });

    if (searchContext == null) {
        return <div />;
    }

    if (searchBoxSettings == null) {
        return (
            <div style={{ color: "red" }}>
                SearchBoxSettings was null. It must be supplied by choosing a SearchBoxSetting in
                the Administration.
            </div>
        );
    }

    return (
        <div className="bwp-filter-panel">
            <div bwp-show={!open ? "" : undefined} className="bwp-filter-panel__open-filter-button">
                <Button onClick={() => setOpen(true)}>{translations.setFilter}</Button>
            </div>
            <div className="bwp-filter-panel__container" bwp-show={open ? "" : undefined}>
                <div className="bwp-filter-panel__close-icon" onClick={() => setOpen(false)}>
                    <CloseIcon />
                </div>
                {searchBoxSettings.rangeFacilities.map((filter) => (
                    <SearchBoxRangeFilter
                        key={filter.facilityId}
                        filter={filter}
                        value={searchContext.getFacilityRange(filter.facilityId)}
                        onChange={(v) =>
                            setSearchContext(
                                searchContext.changeFacilityRange(filter.facilityId, v)
                            )
                        }
                        disabled={!searchContext.canChangeFacilityValue(filter.facilityId)}
                    />
                ))}
                {searchBoxSettings.booleanFacilities.map((facility) => (
                    <CheckBoxWithLabel
                        key={facility.facilityId}
                        label={facility.displayName}
                        value={searchContext.getFacilityEqualValue(facility.facilityId) == "true"}
                        onChange={(v) =>
                            setSearchContext(
                                searchContext.changeFacilityValue(
                                    facility.facilityId,
                                    v ? "true" : null
                                )
                            )
                        }
                        disabled={!searchContext.canChangeFacilityValue(facility.facilityId)}
                    />
                ))}
                <div className="bwp-filter-panel__modal-footer">
                    <Button type={"primary"} onClick={() => setOpen(false)}>
                        {translations.searchButtonText}{" "}
                        {numberOfSearchResults !== null && (
                            <span>
                                (<AnimatedCounter number={numberOfSearchResults} />
                            </span>
                        )}
                        )
                    </Button>
                </div>
            </div>
        </div>
    );
}
