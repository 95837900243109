import React from "react";

export default function getLodgingAutoComplete() {
    return {
        itemToString: item => (item?.name || item || null),
        itemToKey: item => {
            if (item?.id && item?.name) {
                return `${item.name}-${item.id}`;
            }
            if (item) {
                return item;
            }
            return "";
        },
        itemFilter: (item, inputValue) => {
            if (!inputValue) return false;
            const name = item.name?.toLowerCase();
            const area = item.location?.name?.toLowerCase();
            const address = item.address1?.toLowerCase();
            return (
                (name && name.startsWith(inputValue.toLowerCase())) ||
                (area && area.startsWith(inputValue.toLowerCase())) ||
                (address && address.startsWith(inputValue.toLowerCase()))
            );
        },
        // eslint-disable-next-line react/display-name
        itemListItem: (item) => (
            <>
                {(item.name && item.address1 && item.location.name) && (
                <>
                    <strong>{item.name}</strong><br />
                    {item.address1}, {item.location.name}
                </>
                )}
                {!(item.name && item.address1 && item.location.name) && (
                    <>
                        <em>{item}</em>
                    </>
                )}
            </>
        ),
        itemCompare: (a, b) => {
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        }
    };
}