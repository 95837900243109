import { useEffect } from "react";
// Source: https://usehooks.com/useOnClickOutside/
// JBJ: TypeScript Typings added

export default function useOnClickOutside(
    refs: React.MutableRefObject<any>[],
    handler: (event: Event) => void
) {
    useEffect(
        () => {
            const listener = (event: Event) => {
                let clickInside = false;

                for (const ref of refs) {
                    // Do nothing if clicking ref's element or descendent elements
                    if (!ref.current || ref.current.contains(event.target)) {
                        clickInside = true;
                    }
                }

                if (clickInside) {
                    return;
                }

                handler(event);
            };

            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);

            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },

        // Add refs and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...refs, handler] // we spread the array. ESLint can't figure that out
    );
}