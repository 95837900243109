import React, { useState } from "react";
import "./ItemsSummary.scss";
import { stringFormat } from "./shared/helpers";
import {
    ItemsSummaryCheckoutPresentationFragment,
    ItemsSummaryLodgingFragment,
} from "./operations.generated";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";

export interface ItemSelectionProps {
    itemId: number;
    quantity: number;
}

export interface ItemsSummaryProps {
    lodging: ItemsSummaryLodgingFragment;
    checkoutPresentation: ItemsSummaryCheckoutPresentationFragment;
    showBackToItems?: boolean;
    onChangeItemsClick?: () => void;
    openByDefault?: boolean;
    translations: CheckoutTranslations;
}

export default function ItemsSummary({
    showBackToItems,
    openByDefault,
    onChangeItemsClick,
    lodging,
    checkoutPresentation,
    translations,
}: ItemsSummaryProps) {
    const [isOpen, setIsOpen] = useState(openByDefault);

    const itemLabel = (itemName, quantity, maxQuantity) => {
        let label = "";
        if (maxQuantity > 1) {
            label += quantity.toString() + " x ";
        }
        label += itemName;
        return label;
    };

    const handleOpenToggleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleBackClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (onChangeItemsClick) {
            onChangeItemsClick();
        }
    };

    const bookingLine = stringFormat(translations.bookingLine, lodging.name);

    const buttonIcon = isOpen ? (
        <span className="bwp-icon bwp-icon--caret-up"></span>
    ) : (
        <span className="bwp-icon bwp-icon--caret-down"></span>
    );

    const listClassNames = ["bwp-items-summary__list"];
    if (isOpen) {
        listClassNames.push("bwp-open");
    }

    return (
        <div className="bwp-items-summary">
            <h2>{translations.priceInformation}</h2>
            <div className={listClassNames.join(" ")}>
                <ItemRow
                    label={bookingLine}
                    price={checkoutPresentation.normalBookingPriceDisplayValue}
                />
                {checkoutPresentation.hasDiscount && (
                    <ItemRow
                        label={checkoutPresentation.discountName}
                        price={checkoutPresentation.discountReductionDisplayValue}
                    />
                )}
                {checkoutPresentation.itemLines
                    .filter((i) => i.showInSummary)
                    .map((im) => (
                        <ItemRow
                            key={`itemRow${im.item.id}`}
                            label={itemLabel(im.item.name, im.quantity, im.itemPrice.maxQuantity)}
                            price={im.totalPriceDisplayValue}
                        />
                    ))}
            </div>
            <div className="bwp-items-summary__totals">
                <div className="bwp-items-summary__total-label">{translations.totalPrice}</div>
                <div className="bwp-items-summary__total-value">
                    {checkoutPresentation.totalPriceDisplayValue}
                </div>
            </div>
            {showBackToItems && (
                <div className="bwp-items-summary__nav">
                    <a href="#" onClick={handleBackClick}>
                        {translations.changeItems}
                    </a>
                </div>
            )}
            <div className="bwp-items-summary__footer">
                <button
                    className="bwp-pillbutton bwp-pillbutton--primary"
                    onClick={handleOpenToggleClick}
                    type="button"
                >
                    {translations.thePriceIncludes} {buttonIcon}
                </button>
            </div>
        </div>
    );
}

export interface ItemRowProps {
    label: string;
    price: string;
}

export function ItemRow({ label, price }: ItemRowProps) {
    return (
        <div className="bwp-items-summary__item">
            <div className="bwp-items-summary__item-label">{label}</div>
            <div className="bwp-items-summary__item-value">{price}</div>
        </div>
    );
}
