import {Money} from "./types";
import {BookingOption, ItemTypes} from "./graphql-types.generated";

export function priceWithMandatoryItems(bookingOption: {
    price: number;
    currency: string;
    itemPrices: { isMandatory: boolean; unitPrice: number; defaultQuantity: number }[];
}): Money {
    if (bookingOption === null) {
        throw new Error("Argument bookingOption cannot be null");
    }

    let amount = bookingOption.price;
    if (bookingOption.itemPrices && bookingOption.itemPrices.length) {
        bookingOption.itemPrices
            .filter((ip) => ip.isMandatory)
            .forEach((ip) => {
                amount += ip.unitPrice * ip.defaultQuantity;
            });
    }
    const currency = bookingOption.currency;

    return {
        amount,
        currency,
    };
}

export function getBookingOptionWithLowestPrice(
    ...bookingOptions: { price: number; currency: string }[]
) {
    if (typeof bookingOptions === "undefined") {
        return undefined;
    }

    if (bookingOptions === null) {
        return undefined;
    }

    let lowestPrice: { price: number; currency: string } = undefined;
    for (let i = 0; i < bookingOptions.length; i++) {
        if (bookingOptions[i]) {
            if (!lowestPrice || lowestPrice.price > bookingOptions[i].price) {
                lowestPrice = bookingOptions[i];
            }
        }
    }
    return lowestPrice;
}

export interface ItemPriceWithItem {
    item: {
        name: string,
        itemType?: ItemTypes
    },
    isMandatory: boolean,
    priceWithQuantityDisplayValue: string
}

export interface BookingOptionWithItemPrices {
    itemPrices?: ItemPriceWithItem[];
}

export function getDepositName(bookingOption: BookingOptionWithItemPrices) {
    if (!bookingOption) {
        return undefined;
    }
    
    if (!bookingOption.itemPrices) {
        return undefined;
    }
    
    const items = bookingOption.itemPrices.filter(ip => ip.item.itemType === ItemTypes.Deposit);
    if (!items) {
        return undefined;
    }
    
    if (items.length != 1) {
        return undefined;
    }
    
    return items[0].item.name;
}