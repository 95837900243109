import {Dictionary, PluralizedTranslation} from "./types";
import React, {useMemo} from "react";
import {SelectItem} from "../SelectWithLabel";
import {pluralize} from "./helpers";

/**
 * A helper hook that helps creating the options for a select list that lets you choose durations. It will automaticly use weeks if the durations is whole weeks
 * @param dayTranslation The pluralized translation for day
 * @param weekTranslation The pluralized translation for week
 * @param durations an array of durations that should be converted to select options
 */
export default function useDurationOptions(dayTranslation: PluralizedTranslation, weekTranslation: PluralizedTranslation, durations: number[], durationsWithPrice?: Dictionary) {
    return useMemo(() => {

        const result: SelectItem[] = [];

        durations.forEach(days => {
            let text: React.ReactNode;
            let label: string;

            if (days % 7 == 0) {
                let weeks = days / 7;
                label = pluralize(weeks, weekTranslation);
            } else {
                label = pluralize(days, dayTranslation);
            }
            
            if (durationsWithPrice && durationsWithPrice[`dur${days}`]) {
                text = (
                    <div className="bwp-option-with-price">
                        <div>{label}</div>
                        <div>{durationsWithPrice[`dur${days}`]}</div>
                    </div>
                );
            } else {
                text = label;
            }

            result.push({value: days.toString(), text})
        });

        return result;

    }, [dayTranslation, weekTranslation, durations, durationsWithPrice]);
}
