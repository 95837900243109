import * as React from "react";
import ImageViewer from "./ImageViewer";
import SmartBackgroundImage from "./SmartBackgroundImage";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import Button from "./Button";
import Video from "./Video";
import {resolveEmbedUrl} from "./shared/EmbedKit";
import {useMemo, useState} from "react";

export type LodgingImagesViewerDisplayMode = "single" | "collage";

interface LodgingImagesViewer2Props {
    disableViewerTools?: boolean;
    backgroundMode?: boolean;
    images: { url: string; tagIds: number[] }[];
    clusterImageTagId?: number;
    floorPlanImageTagId?: number;
    displayMode?: LodgingImagesViewerDisplayMode;
    isGrouped: boolean;
    translations: LodgingPageTranslations;
    embeddedVideoHtml?: string;
}

function imagesForCluster(
    images: { url: string; tagIds: number[] }[],
    clusterImageTagId: number,
    minNumberOfImages: number = null
) {
    if (!images || images.length === 0) {
        return [];
    }

    let clusteredImages = clusterImageTagId
        ? images.filter((img) => img.tagIds.some((id) => id === clusterImageTagId))
        : images;

    if (minNumberOfImages == null) {
        return clusteredImages;
    } else {
        if (clusteredImages.length < minNumberOfImages) {
            let remainingImages = images.filter((i) => clusteredImages.find((c) => c == i) == null);
            let missingImages = minNumberOfImages - clusteredImages.length;
            return [...clusteredImages, ...remainingImages.slice(0, missingImages)];
        } else {
            return clusteredImages;
        }
    }
}

function VideoIcon() {
    return (
        <svg
            width={21}
            height={22}
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0833 0C4.5131 0 0 4.92339 0 11C0 17.0766 4.5131 22 10.0833 22C15.6536 22 20.1667 17.0766 20.1667 11C20.1667 4.92339 15.6536 0 10.0833 0ZM14.7875 12.0645L7.63162 16.5444C6.98921 16.9347 6.18011 16.4335 6.18011 15.6129V6.3871C6.18011 5.57097 6.98515 5.06532 7.63162 5.45565L14.7875 10.2016C15.4543 10.6097 15.4543 11.6609 14.7875 12.0645Z"
                fill="black"
            />
        </svg>
    );
}

export default function LodgingImageViewer({
    disableViewerTools,
    backgroundMode,
    images,
    clusterImageTagId,
    floorPlanImageTagId,
    displayMode,
    isGrouped,
    translations,
    embeddedVideoHtml
}: LodgingImagesViewer2Props) {
    
    const [openingImageId, setOpeningImageId] = useState<number>(null);
    const [viewerOpen, setViewerOpen] = useState<boolean>(false);
    const [showVideo, setShowVideo] = useState<boolean>(false);

    const collageMode = useMemo(() => {
        return (displayMode && displayMode === "collage");
    }, [displayMode]);
    
    const containerClassName = useMemo(() => {
        if (collageMode) {
            return "bwp-lodging-image-grid1";
        }
        return "bwp-lodging-image-single";
    }, [collageMode]);

    const classNames = useMemo(() => {
        const names = ["bwp-lodging-images"];
        if (backgroundMode) {
            names.push("bwp-background-mode");
        }
        return names.join(" ");        
    }, [backgroundMode]);
    
    const viewerToolsEnabled = useMemo(() => {
        return !disableViewerTools;
    }, [disableViewerTools]);
    
    if (!images?.length) {
        const images = collageMode
            ? [0, 1, 2, 3, 4, 5, 6, 7]
            : [0];

        return (
            <div className={classNames}>
                <div className={containerClassName}>
                    {images.slice(0, Math.min(images.length, 6)).map((img, index) => (
                        <div
                            key={`lodgingImages_${index}`}
                            className={`bwp-image-cell${index + 1} bwp-image-cell`}
                        />
                    ))}
                </div>
            </div>
        );
    }

    const hasVideo = useMemo(() => {
        return !!resolveEmbedUrl(embeddedVideoHtml);
    }, [embeddedVideoHtml]);

    const floorPlanImageIndex = useMemo(() => {
        if (!images?.length){
            return -1;
        }
        return images.findIndex((i) =>
            i.tagIds.includes(floorPlanImageTagId)
        );
    }, [images, floorPlanImageTagId]);

    const imagesForViewer: { url: string }[] = useMemo(() => {
        if (isGrouped) {
            return imagesForCluster(images, clusterImageTagId);
        }
        return images;
    }, [images, isGrouped, clusterImageTagId]);
    
    const clusteredImages: { url: string }[] = useMemo(() => {
        if (isGrouped) {
            return imagesForCluster(images, clusterImageTagId);
        }
        return imagesForCluster(images, clusterImageTagId, 6);
    }, [images, isGrouped, clusterImageTagId]);
    
    const displayedImages = useMemo(() => {
        return collageMode
            ? clusteredImages.slice(0, 6)
            : [clusteredImages[0]];
    }, [collageMode, clusteredImages]);

    const getImageUrl = (image: { url: string }, width: number, height: number) => {
        if (!(image?.url)) {
            return null;
        }
        let url = image.url.replace("/default", "");
        url = url.replace("/cl/", "/v2/");
        url = url + "?width=" + width + "&height=" + height + "&mode=crop";
        return url;
    };

    const handleViewerOpen = (imageId: number | null) => {
        if (viewerToolsEnabled) {
            setViewerOpen(true);
            setOpeningImageId(imageId);
        }
    };
    
    const handleViewerClose = () => {
        setViewerOpen(false);
        setOpeningImageId(null);
    };

    const handlePlayVideo = () => {
        setShowVideo(true);
    };

    const handleHideVideo = () => {
        setShowVideo(false);
    };
    
    return (
        <React.Fragment>
            <div className={classNames}>
                <div className={containerClassName}>
                    {displayedImages.map((img, index) => {
                        return (
                            <SmartBackgroundImage
                                key={`lodgingImages_${index}`}
                                className={`bwp-image-cell${index + 1} bwp-image-cell`}
                                imageUrlFunc={(width, height) =>
                                    getImageUrl(img, width, height)
                                }
                                onClick={() => handleViewerOpen(index)}
                            />
                        );
                    })}
                    {hasVideo && (
                        <div className="bwp-lodging-image-tools-left">
                            {showVideo == false && (
                                <Button type="secondary" onClick={() => handlePlayVideo()}>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridGap: "12px",
                                            gridAutoFlow: "column",
                                        }}
                                    >
                                        <VideoIcon />
                                        {translations.video}
                                    </div>
                                </Button>
                            )}
                        </div>
                    )}
                    {showVideo && (
                        <div className="bwp-lodging-image__video">
                            <div className="bwp-lodging-image__video__close">
                                <Button type="secondary" onClick={() => handleHideVideo()}>
                                    {translations.closeVideo}
                                </Button>
                            </div>
                            <div className="bwp-lodging-image__video__container">
                                <Video
                                    autoPlay
                                    url={embeddedVideoHtml}
                                    embedElementManipulator={(el) => {
                                        if (el) {
                                            el.style.height = "100%";
                                            el.style.width = "100%";
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {viewerToolsEnabled && (
                        <div className="bwp-lodging-image-tools">
                            {floorPlanImageIndex != -1 && (
                                <Button
                                    type="secondary"
                                    className="bwp-lodging-image-tools__floorplan"
                                    onClick={() => handleViewerOpen(floorPlanImageIndex)}
                                >
                                    {translations.showFloorPlan}
                                </Button>
                            )}
                            <Button
                                type="secondary"
                                onClick={() => handleViewerOpen(null)}
                            >
                                {translations.showImages}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {viewerOpen && (
                <ImageViewer
                    images={imagesForViewer}
                    onClose={handleViewerClose}
                    translations={translations}
                    openingImageIndex={openingImageId}
                />
            )}
        </React.Fragment>
    );
}