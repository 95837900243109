import * as React from "react";

import "./ImageViewer.scss";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { useEffect, useRef } from "react";
import SmartImage from "./SmartImage";

// Converts a rem value to a pixel value
export function rem(value: number, rootSize: number = 16) {
    return value / 0.0625;
}

export interface ImageViewerState {
    current: number;
    lastPan: "left" | "right" | "";
    orientation: "landscape" | "portrait" | "";
}

const DefaultImageMaxWidth = 1600;
const DefaultImageMaxHeight = 1200;

interface ImageViewerProps {
    openingImageIndex: number;
    images: {
        url: string;
        originalWidth?: number;
        originalHeight?: number;
    }[];
    onClose: () => void;
    translations: LodgingPageTranslations;
}

function getImageWithMaxUrl(image: { url: string }, maxWidth: number, maxHeight: number) {
    let url = image.url.replace("/default", "");
    url = url.replace("/cl/", "/v2/");
    url = url + "?maxwidth=" + maxWidth + "&maxheight=" + maxHeight;
    return url;
}

export function getImageUrl(image: { url: string }, width: number, height: number) {
    let url = image.url.replace("/default", "");
    url = url.replace("/cl/", "/v2/");
    url = url + "?width=" + width + "&height=" + height + "&mode=crop";
    return url;
}

class ImageViewer extends React.Component<ImageViewerProps, ImageViewerState> {
    private activeThumbnailRef;
    private thumbnailStripRef;
    private containerRef: React.RefObject<HTMLDivElement>;
    private resizeTimer;
    private stickies;

    constructor(props: ImageViewerProps) {
        super(props);
        this.state = {
            current: props.openingImageIndex || 0,
            lastPan: "",
            orientation: "",
        };
        this.activeThumbnailRef = React.createRef<HTMLDivElement>();
        this.thumbnailStripRef = React.createRef<HTMLDivElement>();
        this.containerRef = React.createRef<HTMLDivElement>();
    }

    handlePrevImage = () => {
        const { current } = this.state;
        if (current > 0) {
            this.setState({ current: current - 1, lastPan: "left" });
        }
    };

    handleNextImage = () => {
        const { current } = this.state;
        const { images } = this.props;
        if (current < images.length - 1) {
            this.setState({ current: current + 1, lastPan: "right" });
        }
    };

    handleGotoImage = (e, imageId) => {
        this.setState({ current: imageId });
    };

    handleClose = () => {
        const { onClose } = this.props;
        if (typeof onClose === "function") {
            onClose();
        }
    };

    thumbnailClassnames(isActive) {
        let classnames = ["bwp-thumbnail"];
        if (isActive) {
            classnames.push("bwp-active");
        }
        return classnames.join(" ");
    }

    classNames() {
        const { orientation } = this.state;
        let names = ["bwp-image-viewer"];
        names.push("bwp-image-viewer--" + orientation);
        return names.join(" ");
    }

    getComputedMarginLeft = (elem) => {
        return parseFloat(getComputedStyle(elem).marginLeft.slice(0, -2));
    };

    adjustStripPositionOnMove() {
        if (!this.activeThumbnailRef || !this.activeThumbnailRef.current) {
            return;
        }

        // Viewport halfway (width)
        let halfway = Math.round(window.innerWidth / 2);
        // Active thumbnail rectangle info
        let activeThumbnailRect = this.activeThumbnailRef.current.getBoundingClientRect();
        // If we move, what is the new correct active thumbnail left position?
        let newLeft = halfway - activeThumbnailRect.width / 2;
        // Thumbnail strip rectangle info
        let stripRect = this.thumbnailStripRef.current.getBoundingClientRect();
        // Thumbnail strip current margin left (in pixels)
        let stripCurrentLeft = this.getComputedMarginLeft(this.thumbnailStripRef.current);
        // What is the maximum we will want to move the strip offscreen to the left?
        let maxStripOffscreenWidth = window.innerWidth - stripRect.width - 32;

        // Is the active thumbnail more to right in the viewport?
        let activeIsOnTheRight =
            halfway - activeThumbnailRect.right < 0 &&
            Math.abs(halfway - activeThumbnailRect.left) <
                Math.abs(halfway - activeThumbnailRect.right);

        let stripAtBeginningActiveOnRight = stripCurrentLeft === 0 && activeIsOnTheRight;
        let stripAtEndActiveOnLeft =
            stripCurrentLeft <= maxStripOffscreenWidth && !activeIsOnTheRight;
        let stripInTheMiddle = maxStripOffscreenWidth < stripCurrentLeft && stripCurrentLeft < 0;

        if (stripAtBeginningActiveOnRight || stripAtEndActiveOnLeft || stripInTheMiddle) {
            let diffLeft = newLeft - activeThumbnailRect.left;

            // Move the strip to make active thumbnail
            // But don't move the strip to the right (left margin is positive)
            // and don't move the strup to far to the left (respect max strip offscreen width)
            let stripNextLeft = Math.min(
                0,
                Math.max(maxStripOffscreenWidth, stripCurrentLeft + diffLeft)
            );

            this.thumbnailStripRef.current.style.marginLeft = `${stripNextLeft}px`;
        }
    }

    getOrientation = () => {
        return window.innerWidth > window.innerHeight ? "landscape" : "portrait";
    };

    disableStickies() {
        this.stickies = document.querySelectorAll(".bwp-sticky");
        this.stickies.forEach((s) => s.classList.remove("bwp-sticky"));
    }

    reenableStickies() {
        this.stickies.forEach((s) => s.classList.add("bwp-sticky"));
    }

    componentDidMount() {
        this.disableStickies();
        this.adjustStripPositionOnMove();
        this.setState({ orientation: this.getOrientation() });
        window.addEventListener(
            "resize",
            () => {
                const { orientation } = this.state;
                if (this.resizeTimer) {
                    clearTimeout(this.resizeTimer);
                }
                this.resizeTimer = setTimeout(() => {
                    const newOrientation = this.getOrientation();
                    if (orientation !== newOrientation) {
                        this.setState({ orientation: newOrientation });
                    }
                }, 400);
            },
            false
        );
        this.containerRef.current.focus();
        document.body.style.overflowY = "hidden";
    }

    componentDidUpdate() {
        this.adjustStripPositionOnMove();
    }

    componentWillUnmount() {
        this.reenableStickies();
        this.stickies = [];
        document.body.style.overflowY = "auto";
    }

    getContainerRect = () => {
        const rect = this.containerRef.current && this.containerRef.current.getBoundingClientRect();

        return rect;
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key == "ArrowLeft") {
            this.handlePrevImage();
        }
        if (e.key == "ArrowRight") {
            this.handleNextImage();
        }
        if (e.key == "Escape") {
            this.handleClose();
        }
    };

    render() {
        const { images } = this.props;
        const { current } = this.state;

        return (
            <div
                className={this.classNames()}
                onKeyDown={this.handleKeyDown}
                ref={this.containerRef}
                tabIndex={0}
            >
                <div className="bwp-image-viewer__tools">
                    <button className="btn" onClick={this.handleClose} aria-label="Close window">
                        <svg
                            width="34"
                            height="35"
                            viewBox="0 0 34 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <line
                                x1="3.06164"
                                y1="3.23323"
                                x2="31.0656"
                                y2="31.2372"
                                stroke="#999999"
                                strokeWidth="4"
                                strokeLinecap="round"
                            />
                            <line
                                x1="3.06775"
                                y1="31.1039"
                                x2="31.0874"
                                y2="3.08417"
                                stroke="#999999"
                                strokeWidth="4"
                                strokeLinecap="round"
                            />
                        </svg>
                    </button>
                </div>
                <div className="bwp-image-viewer__main" style={{ outline: "none" }}>
                    <button
                        className="btn"
                        onClick={this.handlePrevImage}
                        aria-label="Previous image"
                    >
                        <svg
                            width="19"
                            height="33"
                            viewBox="0 0 19 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.4919 3.65506C18.273 2.87401 18.273 1.60768 17.4919 0.826631C16.7109 0.0455826 15.4445 0.0455826 14.6635 0.826631L0.783541 14.7066C0.122612 15.3675 0.0209655 16.3759 0.478601 17.1437C0.573319 17.3382 0.701445 17.5205 0.862978 17.682L14.6696 31.4886C15.4506 32.2697 16.7169 32.2697 17.498 31.4886C18.279 30.7076 18.279 29.4412 17.498 28.6602L4.99239 16.1546L17.4919 3.65506Z"
                                fill="#999999"
                            />
                        </svg>
                    </button>
                    <ContainedImage image={images[current]} />
                    <button className="btn" onClick={this.handleNextImage} aria-label="Next image">
                        <svg
                            width="19"
                            height="33"
                            viewBox="0 0 19 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.679713 3.57173C-0.101337 2.79068 -0.101337 1.52435 0.679713 0.743303C1.46076 -0.0377457 2.72709 -0.0377457 3.50814 0.743303L17.3881 14.6233C18.049 15.2842 18.1507 16.2926 17.693 17.0604C17.5983 17.2549 17.4702 17.4372 17.3087 17.5987L3.50206 31.4053C2.72101 32.1863 1.45469 32.1863 0.673636 31.4053C-0.107412 30.6242 -0.107412 29.3579 0.673636 28.5769L13.1792 16.0713L0.679713 3.57173Z"
                                fill="#999999"
                            />
                        </svg>
                    </button>
                </div>
                <div className="bwp-image-viewer__thumbnails">
                    <div
                        ref={this.thumbnailStripRef}
                        className="bwp-image-viewer__image-strip"
                        style={{
                            width: `calc(8rem * ${images.length} + 0.5rem * ${images.length - 1}`,
                        }}
                    >
                        {images.map(
                            (img, index) =>
                                (index === current && (
                                    <div
                                        key={`imageViewer_Thumbnail_${index}`}
                                        className={this.thumbnailClassnames(true)}
                                        style={{
                                            backgroundImage: `url(${getImageUrl(
                                                img,
                                                rem(8),
                                                rem(5)
                                            )})`,
                                        }}
                                        ref={this.activeThumbnailRef}
                                        onClick={(e) => this.handleGotoImage(e, index)}
                                    />
                                )) ||
                                (index !== current && (
                                    <div
                                        key={`imageViewer_Thumbnail_${index}`}
                                        className={this.thumbnailClassnames(false)}
                                        style={{
                                            backgroundImage: `url(${getImageUrl(
                                                img,
                                                rem(8),
                                                rem(5)
                                            )})`,
                                        }}
                                        onClick={(e) => this.handleGotoImage(e, index)}
                                    />
                                ))
                        )}
                    </div>
                </div>
                <div className="bwp-image-viewer__stacked-view">
                    {images.map((img, index) => (
                        <SmartImage
                            key={index}
                            imageUrlFunc={(width, height) => getImageUrl(img, width, 0)}
                            originalWidth={img.originalWidth}
                            originalHeight={img.originalHeight}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

function ContainedImage({ image }: { image: { url: string } }) {
    const ref = useRef<HTMLDivElement>();

    const rect = ref.current?.getBoundingClientRect();

    let width = Math.ceil((rect && rect.width) || DefaultImageMaxWidth);
    let height = Math.ceil((rect && rect.height) || DefaultImageMaxHeight);

    // Adjust for aspect ratio 16:9
    const size = width > height ? getLandscape(width) : getPortrait(width);

    let currentImageUrl = "";
    if (image?.url) {
        currentImageUrl = getImageWithMaxUrl(image, size.width, size.height);
    }

    return (
        <div
            ref={ref}
            style={{ backgroundImage: `url(${currentImageUrl})` }}
            className="bwp-image-viewer__large-image"
        />
    );
}

const getLandscape = (width: number) => {
    let pixelRatio = window.devicePixelRatio;

    let sizes = [
        { width: 320, height: 180 },
        { width: 640, height: 360 },
        { width: 764, height: 432 },
        { width: 1024, height: 576 },
        { width: 1280, height: 720 },
        { width: 1440, height: 810 },
        { width: 1600, height: 900 },
        { width: 2000, height: 1125 },
    ];

    let pixelWidth = pixelRatio * width;

    for (let i = 0; i < sizes.length; i++) {
        if (sizes[i].width > pixelWidth) {
            return sizes[i];
        }
    }

    return sizes[sizes.length - 1];
};

const getPortrait = (width: number) => {
    let pixelRatio = window.devicePixelRatio;

    let sizes = [
        { width: 180, height: 320 },
        { width: 360, height: 640 },
        { width: 432, height: 764 },
        { width: 576, height: 1024 },
        { width: 720, height: 1280 },
        { width: 810, height: 1440 },
        { width: 900, height: 1600 },
        { width: 1125, height: 2000 },
    ];

    let pixelWidth = pixelRatio * width;

    for (let i = 0; i < sizes.length; i++) {
        if (sizes[i].width > pixelWidth) {
            return sizes[i];
        }
    }

    return sizes[sizes.length - 1];
};
export default ImageViewer;
