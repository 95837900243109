import React, {useMemo} from "react";
import LodgingBookingBox from "@bwp/LodgingBookingBox";
import {LodgingPageTranslations} from "@bwp/translations/LodgingPageTranslations";
import {SearchBoxTranslations} from "@bwp/translations/SearchBoxTranslations";
import {DatePickerTranslations} from "@bwp/translations/DatePickerTranslations";
import {SearchBoxSettings} from "@bwp/shared/types";
import {SearchContext, SearchContextProps, StandardDefaultSearchContext} from "@bwp/shared/searchcontext";
import {
    LodgingPresentationQuery,
    useLodgingBookingOptionsQuery
} from "@bwp/operations.generated";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import useCalendarDayManipulator from "@bwp/shared/useCalendarDayManipulator";

export interface LodgingBookingBoxWrapperProps {
    culture: string;
    searchBoxSettings: SearchBoxSettings;
    presets: SearchContextProps;
    defaultValues: SearchContextProps;
    lodgingPresentation: LodgingPresentationQuery["lodgingPresentation"];
    onBook?: () => void;
    showInfants?: boolean;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showTwoMonthsInDatePicker?: boolean;
    showDurationAboveDatePicker?: boolean;
    translations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    datePickerTranslations: DatePickerTranslations;
}

export default function LodgingBookingBoxWrapper({
    culture,
    searchBoxSettings,
    presets,
    defaultValues,
    lodgingPresentation,
    onBook,
    showInfants,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showTwoMonthsInDatePicker,
    showDurationAboveDatePicker,
    translations,
    searchBoxTranslations,
    datePickerTranslations
}: LodgingBookingBoxWrapperProps) {

    // Add lodgingId to defaultValues if known via lodgingPresentation.lodging
    // This is because of rewritten lodging urls the lodging id might not 
    // be set by the search context factory method.
    // TODO: This should be handled in the searchContext factory method
    const defaultValuesWithLodgingId = lodgingPresentation?.lodging && !defaultValues?.lodgingId
        ? {
            ...(defaultValues || StandardDefaultSearchContext),
            lodgingId: lodgingPresentation.lodging.id
        }
        : defaultValues;

    const [searchContext, setSearchContext] = useGlobalSearchContext({ presets: presets, defaultValues: defaultValuesWithLodgingId });

    const lodgingQuery = useLodgingBookingOptionsQuery( {
        variables: {
            query: searchContext?.changeDuration(null).changeDurations(searchBoxSettings.availableDurations).toQueryString()
        },
        skip: !searchContext || !searchContext.canSearchBookingOptions()
    });
    
    const durationsAndPrices = useMemo(() => {
        const result = {};
        if (lodgingQuery && !lodgingQuery.loading && lodgingQuery.data) {
            lodgingQuery.data.lodgingBookingOptions.bookingOptions.forEach(bo => {
                if (bo.priceWithMandatoryItems) {
                    result[`dur${bo.duration}`] = showDepositSeparately
                        ? bo.priceWithMandatoryItemsWithoutDepositsDisplayValue
                        : bo.priceWithMandatoryItemsDisplayValue;
                }
            });
        }
        return result;
    }, [lodgingQuery]);
    
    const dayManipulator = useCalendarDayManipulator({ searchContext });
    
    const handleSearchContextChanged = (searchContext: SearchContext) => {
        setSearchContext(searchContext);
    };
    
    return (
        <LodgingBookingBox
            culture={culture}
            lodgingPresentation={lodgingPresentation}
            searchContext={searchContext}
            searchBoxSettings={searchBoxSettings}
            dayManipulator={dayManipulator}
            durationsAndPrices={durationsAndPrices}
            onBook={onBook}
            onSearchContextChange={handleSearchContextChanged}
            showInfants={showInfants}
            showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
            showDepositSeparately={showDepositSeparately}
            showTwoMonthsInDatePicker={showTwoMonthsInDatePicker}
            showDurationAboveDatePicker={showDurationAboveDatePicker}
            translations={translations}
            searchBoxTranslations={searchBoxTranslations}
            datePickerTranslations={datePickerTranslations}
        />
    )
}

