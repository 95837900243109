import React from "react";
import "./LodgingGeneralInfo.scss";
import ClockIcon from "./ClockIcon";
import HouseIcon from "./HouseIcon";
import KeyIcon from "./KeyIcon";

interface Props {
    title: string;
    checkInOutTitle: string;
    checkInOutContentHtml: string;
    houseInformationTitle: string;
    houseInformationHtml: string;
    keyInformationTitle: string;
    keyInformationHtml: string;
}

export default function LodgingGeneralInfo({
    title,
    checkInOutTitle,
    checkInOutContentHtml,
    houseInformationTitle,
    houseInformationHtml,
    keyInformationTitle,
    keyInformationHtml,
}: Props) {
    return (
        <div className="bwp-lodging-general-info">
            <h2>{title}</h2>
            <div>
                <ClockIcon />
                <h3>{checkInOutTitle}</h3>
                <div dangerouslySetInnerHTML={{ __html: checkInOutContentHtml }} />
            </div>
            <div>
                <HouseIcon />
                <h3>{houseInformationTitle}</h3>
                <div dangerouslySetInnerHTML={{ __html: houseInformationHtml }} />
            </div>
            <div>
                <KeyIcon />
                <h3>{keyInformationTitle}</h3>
                <div dangerouslySetInnerHTML={{ __html: keyInformationHtml }} />
            </div>
        </div>
    );
}
