import React, {useState} from "react";

import "./BookingSummary.scss";
import "./global/shared.scss";
import { pluralize, stringFormat } from "./shared/helpers";
import useModal from "./shared/useModal";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import { SearchBoxTranslations} from "./translations/SearchBoxTranslations";
import { getImageUrl } from "./shared/images";
import {
    BookingSummaryLodgingFragment,
    BookingSummaryBookingOptionFragment,
} from "./operations.generated";
import PeopleSelectWithLabel, {PeopleValue} from "./PeopleSelectWithLabel";
import {NumberSelectWithLabel} from "./NumberSelectWithLabel";
import Button from "./Button";
import {LodgingPageTranslations} from "./translations/LodgingPageTranslations";

export interface BookingSummaryProps {
    lodging: BookingSummaryLodgingFragment;
    bookingOption: BookingSummaryBookingOptionFragment;
    onPeopleChange: (value: PeopleValue) => void;
    translations: CheckoutTranslations;
    lodgingTranslations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    showInfants?: boolean;
    maxPeople?: number;
    maxPets?: number;
    hideLodgingAddress?: boolean;
}

type ModalValue = PeopleValue & { pets: number };

export default function BookingSummary({
    lodging,
    bookingOption,
    onPeopleChange,
    translations,
    lodgingTranslations,
    searchBoxTranslations,
    showInfants,
    maxPeople,
    maxPets,
    hideLodgingAddress
}: BookingSummaryProps) {
    
    const [ modalValues, setModalValues ] = useState<ModalValue>(null);
    
    const lodgingName = stringFormat(translations.lodgingPrefix, lodging.name);
    const lodgingStreetAndArea = hideLodgingAddress
        ? lodging.location.name
        : stringFormat(
            translations.streetAndArea,
            lodging.address1,
            lodging.location.name
        );
    
    const dateRange = stringFormat(
        translations.dateRange,
        bookingOption.arrivalDisplayValue,
        bookingOption.departureDisplayValue
    );
    const adults = pluralize(bookingOption.adults, searchBoxTranslations.adult);
    const children = pluralize(bookingOption.children, searchBoxTranslations.child);
    const infants = pluralize(bookingOption.infants, searchBoxTranslations.infant);
    const pets = pluralize(bookingOption.pets, translations.pet);
    const adultsAndChildren = stringFormat(translations.adultsAndChildren, adults, children);
    const allPeople = stringFormat(translations.allPeople, adults, children, infants);

    const width = 262;
    const height = 190;
    const imageUrl = lodging.primaryImage
        ? getImageUrl(
              lodging.primaryImage,
              width * window.devicePixelRatio,
              height * window.devicePixelRatio
          )
        : null;
    
    const { Modal, showModal, hideModal } = useModal();
    
    const handleClick = (e) => {
        e.preventDefault();
        showModal();
    };

    const modalValuesOrDefault = modalValues == null
        ? {
            adults: bookingOption.adults,
            children: bookingOption.children,
            infants: bookingOption.infants,
            pets: bookingOption.pets
        } as ModalValue
        : modalValues;
    
    const handlePeopleChange = (value: PeopleValue) => {
        setModalValues({
            adults: value.adults, 
            children: value.children, 
            infants: value.infants, 
            pets: modalValuesOrDefault.pets   
        });
    };
    
    const handlePets = (pets) => {
        setModalValues({
            adults: modalValuesOrDefault.adults,
            children: modalValuesOrDefault.children,
            infants: modalValuesOrDefault.infants,
            pets: pets
        });    
    };

    const tooManyPeopleSelected = () => {
        if (!modalValues) {
            return false;
        }
        if (!maxPeople) {
            return false;
        }
        const numberOfPeople = modalValues.adults + modalValues.children;
        return (maxPeople < numberOfPeople);
    };

    const handleClickConfirm = () => {
        if (modalValues) {
            if (!tooManyPeopleSelected()) {
                onPeopleChange({
                    adults: modalValues.adults,
                    children: modalValues.children,
                    infants: modalValues.infants,
                    pets: modalValues.pets
                });
                hideModal();
                setModalValues(null);
            }
        } else {
            hideModal();
        }
    };
    
    const handleClickCancel = () => {
        hideModal();
        setModalValues(null);
    };
    
    const maxPetsForLodging = typeof maxPets === "number"
        ? maxPets
        : 0;    /* The default fallback is pets not allowed */
    
    return (
        <>
            <div className="bwp-bookingsummary">
                <div
                    className="bwp-bookingsummary__image"
                    style={{
                        backgroundImage: lodging.primaryImage != null ? `url(${imageUrl})` : null,
                    }}
                    title={lodgingName}
                />
                <div className="bwp-bookingsummary__text">
                    <h2>{lodgingName}</h2>
                    <p className="bwp-only-large-screen">{lodgingStreetAndArea}</p>
                    <h2 className="bwp-only-large-screen">{translations.yourStay}</h2>
                    <p>{dateRange}</p>
                    {showInfants ? (
                        <p>
                            {allPeople}
                        </p>
                    ) : (
                        <p>
                            {adultsAndChildren}
                        </p>
                    )}
                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <p>
                            {pets}
                        </p>
                        <p>
                            <button onClick={handleClick} className="bwp-bookingsummary__change">{translations.changePeopleButton}</button>
                        </p>
                    </div>
                </div>
            </div>

            <Modal
                title={translations.changePeopleTitle}
                translations={{ close: translations.changePeopleClose}}
            >
                <PeopleSelectWithLabel 
                    label={searchBoxTranslations.personsLabel}  
                    maxPersons={maxPeople} 
                    translations={searchBoxTranslations} 
                    value={{
                        adults: modalValuesOrDefault.adults || 0,
                        children: modalValuesOrDefault.children || 0,
                        infants: modalValuesOrDefault.infants || 0
                    } as PeopleValue}
                    onChange={handlePeopleChange}
                    showInfants={showInfants}
                    warningMessage={
                        tooManyPeopleSelected()
                            ? lodgingTranslations.theHouseMayNotBeAvailableForTheSelectedNumberOfPeople
                            : undefined
                    }
                />
                <NumberSelectWithLabel
                    id="pets"
                    label={searchBoxTranslations.petsLabel}
                    itemTranslation={translations.pet}
                    value={typeof modalValuesOrDefault.pets === "number" ? modalValuesOrDefault.pets : 0}
                    min={0}
                    max={maxPetsForLodging}
                    onChange={handlePets}
                    disabled={!maxPets}
                />
                <div className="bwp-bookingsummary__modal-buttons">
                    <Button
                        onClick={handleClickCancel}
                        type={"secondary"}>{translations.changePeopleCancel}</Button>
                    <Button
                        disabled={tooManyPeopleSelected()}
                        onClick={handleClickConfirm}
                        type={"primary"}>{translations.changePeopleConfirm}</Button>
                </div>
            </Modal>
        </>
    );
}