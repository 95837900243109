import React, { useCallback, useMemo } from "react";
import "./LodgingCard2.scss";
import CardImageSlider from "./CardImageSlider";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import { FavouritesTranslations } from "./translations/FavouritesTranslations";
import { pluralize } from "./shared/helpers";
import { processTemplate } from "./shared/templates";
import { getImageUrl } from "./shared/images";
import { IconStar20, IconHalfStar20, IconPlus14 } from "./shared/icons";
import { LodgingListQuery, SearchResultsPageQuery } from "./operations.generated";
import { CleaningBannerType, DiscountBannerType } from "./shared/types";

type LodgingForLodgingCard = SearchResultsPageQuery["search"]["results"][0]["lodging"]
    | LodgingListQuery["lodgingList"]["results"][0]["lodging"]
    | {
        id: number;
        activeDescription?: {
            title: string | null;
        };
        name: string;
        address1: string;
        petsAllowed?: boolean;
        maxPeople?: number;
        images: {
            url: string;
        }[];
        location: {
            name: string;
        };
        facilityValues: {
            facilityId: number;
            displayValueAndName: string;
        }[];
        stars?: number;
        starsPostText: string;
    };

type BookingOptionForLodgingCard = SearchResultsPageQuery["search"]["results"][0]["selectedBookingOption"] | LodgingListQuery["lodgingList"]["results"][0]["selectedBookingOption"];

type SeasonPricesForLodgingCard = {
    price: number;
    weeklyPriceDisplayValue: string;
    seasonCode: string;
}[]; 

interface LodgingCard2Props {
    lodging: LodgingForLodgingCard;
    selectedBookingOption?: BookingOptionForLodgingCard;
    seasonPrices?: SeasonPricesForLodgingCard;
    extraFacilities?: number[];
    nextUrl: string;
    translations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    isFavorite?: boolean;
    onSetFavorite?: (id: number) => void;
    disableSmartImage?: boolean;
    disableImageSlider?: boolean;
    showStars?: boolean;
    starsExtraIcon?: string;
    hideAddress?: boolean;
    showCleaningBanner?: boolean;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

export default function LodgingCard2({
    lodging,
    extraFacilities = [],
    nextUrl,
    translations,
    favouritesTranslations,
    selectedBookingOption,
    seasonPrices,
    isFavorite,
    onSetFavorite,
    disableSmartImage,
    disableImageSlider,
    showStars,
    starsExtraIcon,
    hideAddress,
    showCleaningBanner,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    showItems,
    itemsToShow,
    maxItemsToShow
}: LodgingCard2Props) {
    const handleClick = useCallback(() => {
        window.location.href = nextUrl;
    }, [nextUrl]);

    let petsAllowedLabel = null;
    let personLabel = null;
    if (lodging.maxPeople !== null) {
        personLabel = pluralize(lodging.maxPeople, translations.person);
    }
    if (lodging.petsAllowed !== null) {
        petsAllowedLabel = lodging.petsAllowed
            ? translations.petsAllowed
            : translations.petsNotAllowed;
    }

    let extraFacilityRows = useMemo(
        () =>
            extraFacilities.reduce((result, cur, index) => {
                if (index % 2 == 0) {
                    result.push([]);
                }

                let arr = result[result.length - 1];
                let val = lodging.facilityValues.find((f) => f.facilityId == cur);
                if (val) {
                    arr.push(val.displayValueAndName);
                }

                return result;
            }, [] as string[][]),
        [extraFacilities, lodging.facilityValues]
    );

    let {
        discountName = null
    }: Partial<LodgingCard2Props["selectedBookingOption"]> = selectedBookingOption || {};
    
    const hasTitle =
        lodging.activeDescription?.title != null && lodging.activeDescription?.title.length > 0;

    const lodgingTitle = hasTitle ? lodging.activeDescription?.title : lodging.name;
    const isTitleTheAddress = lodgingTitle.toLowerCase() == lodging.address1?.toLowerCase();
    const displayCleaningBanner = showCleaningBanner || selectedBookingOption?.cleaningIncludedInPriceWithMandatoryItems;

    const activeDiscountBannerMode = useMemo(() => {
        if (!discountName) {
            return "hidden";
        }
        return discountBanner;
    }, [discountName, discountBanner]);

    const activeCleaningBannerMode = useMemo(() => {
        if (!displayCleaningBanner) {
            return "hidden";
        }
        return cleaningBanner;
    }, [displayCleaningBanner, cleaningBanner]);
    
    return (
        <div className="bwp-lodging-card-2">
            <div className="bwp-lodging-card-2__body">
                <CardImageSlider
                    images={lodging.images}
                    favourite={isFavorite}
                    enableFavorite={onSetFavorite != null}
                    enableImageSlider={!disableImageSlider}
                    nextUrl={nextUrl}
                    title={lodging.activeDescription?.title ?? lodging.name}
                    translations={{ ...translations, ...favouritesTranslations }}
                    onFavouriteClick={() => onSetFavorite(lodging.id)}
                    disableSmartImage={disableSmartImage}
                    getImageUrl={(url, width, height) => getImageUrl({ url }, width, height)}
                >
                    <LodgingCardBannerContainer
                        selectedBookingOption={selectedBookingOption}
                        showItems={showItems}
                        itemsToShow={itemsToShow}
                        maxItemsToShow={maxItemsToShow}
                        discountBanner={activeDiscountBannerMode}
                        discountName={discountName}
                        cleaningBanner={activeCleaningBannerMode}
                        translations={translations}
                        onClick={handleClick}
                    />
                </CardImageSlider>
                {(activeDiscountBannerMode === "custom") && (
                    <CustomBanner type="discount" label={discountName} onClick={handleClick} />
                )}
                {(activeCleaningBannerMode === "custom") && (
                    <CustomBanner type="cleaning" label={translations.cleaningIncluded} onClick={handleClick} />
                )}
                <div className="bwp-lodging-card-2__content" onClick={handleClick}>
                    {showStars && (<LodgingCardTitleWithStars lodging={lodging} starsExtraIcon={starsExtraIcon} />)}
                    {!showStars && (<LodgingCardTitle lodging={lodging} />)}
                    {!isTitleTheAddress && (
                        <LodgingSubTitle
                            lodging={lodging}
                            translations={translations}
                            hideAddress={hideAddress}
                        />
                    )}
                    <LodgingCardFacilityRow facilities={[personLabel, petsAllowedLabel]} />
                    {extraFacilityRows.map((row, index) => (
                        <LodgingCardFacilityRow key={`lodgingCardFacilityRow${index}`} facilities={row} />
                    ))}
                </div>
            </div>
            <div className="bwp-lodging-card-2__footer">
                {(activeDiscountBannerMode === "pricebox") && (
                    <StandardBanner type="discount" label={discountName} empty="blank" />
                )}
                <LodgingCardPriceRow
                    bookingOption={selectedBookingOption}
                    seasonPrices={seasonPrices}
                    showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                    showDepositSeparately={showDepositSeparately && !showPriceWithoutMandatoryItems}
                    showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                    onClick={handleClick}
                    translations={translations}
                />
            </div>
        </div>
    );
}

interface LodgingCardBannerContainerProps {
    selectedBookingOption?: BookingOptionForLodgingCard;
    showItems: boolean;
    itemsToShow: number[];
    maxItemsToShow: number;
    discountBanner: DiscountBannerType;
    discountName: string;
    cleaningBanner: CleaningBannerType;
    translations: LodgingCardTranslations;
    onClick: () => void;
}

function LodgingCardBannerContainer({ selectedBookingOption, showItems, itemsToShow, maxItemsToShow, discountBanner, discountName, cleaningBanner, translations, onClick }: LodgingCardBannerContainerProps) {
    
    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
    }
    
    if (showItems) {
        return (
            <ItemBox
                bookingOption={selectedBookingOption}
                itemsToShow={itemsToShow}
                maxItemsToShow={maxItemsToShow}
            />
        );
    }
    
    return (
        <div className="bwp-lodging-card-2__banner-container">
            {(discountBanner === "image") && (
                <StandardBanner type="discount" label={discountName} onClick={handleClick} empty="hide" />
            )}
            {(cleaningBanner === "image") && (
                <StandardBanner type="cleaning" label={translations.cleaningIncluded} onClick={handleClick} empty="hide" />
            )}
        </div>
    );
}

function LodgingCardTitleWithStars({ lodging, starsExtraIcon }: { lodging: LodgingForLodgingCard, starsExtraIcon?: string }) {
    const hasTitle =
        lodging.activeDescription?.title != null && lodging.activeDescription?.title.length > 0;

    const lodgingTitle = hasTitle ? lodging.activeDescription?.title : lodging.name;

    const stars = lodging.stars
        ? [1, 2, 3, 4, 5].filter(s => s <= lodging.stars)
        : [];

    return (
        <div className="bwp-lodging-card-2__title-row">
            <div className="bwp-lodging-card-2__location">{lodging.location.name}</div>
            <div className="bwp-lodging-card-2__title">
                {lodgingTitle}
            </div>
            <div className="bwp-lodging-card-2__stars">
                {stars.map(s => <LodgingCardStar key={`star${s}`} />)}
                {lodging.starsPostText && (<LodgingCardStarExtraIcon starsExtraIcon={starsExtraIcon} />)}
            </div>
        </div>
    );
}

function LodgingCardTitle({ lodging }: { lodging: LodgingForLodgingCard }) {
    const hasTitle =
        lodging.activeDescription?.title != null && lodging.activeDescription?.title.length > 0;

    const lodgingTitle = hasTitle ? lodging.activeDescription?.title : lodging.name;

    return (
        <div className="bwp-lodging-card-2__title-row">
            <div className="bwp-lodging-card-2__location">{lodging.location.name}</div>
            <div className="bwp-lodging-card-2__title">
                {lodgingTitle}
            </div>
        </div>
    );
}

function LodgingCardStar() {
    return (
        <>
            {IconStar20}
        </>
    )
}

function LodgingCardStarExtraIcon({ starsExtraIcon }: { starsExtraIcon?: string }) {

    let icon: React.ReactNode = null;
    if (starsExtraIcon === "Half star") {
        icon = IconHalfStar20;
    } else if (starsExtraIcon === "Plus") {
        icon = IconPlus14;
    }

    if (!icon) {
        return null;
    }

    return (
        <>
            {icon}
        </>
    );
}

interface BannerProps {
    type: "discount" | "cleaning";
    label: string;
    onClick?: () => void;
    empty?: "blank" | "hide"
}

function StandardBanner({ type, label, onClick, empty }: BannerProps) {
    
    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
    }
    
    if (!label && empty === "hide") {
        return null;
    }
    
    if (!label && empty === "blank") {
        return (
            <div className={`bwp-lodging-card-2__blank-${type}-banner`} />
        );
    }
    
    return (
        <div className={`bwp-lodging-card-2__${type}-banner`} onClick={handleClick}>
            {label}
        </div>
    );
}

function CustomBanner({ type, label, onClick }: BannerProps) {
    const cultureClassName = `bwp-culture__${window["BookingStudio_culture"]}`;

    const handleClick = () => {
        if (typeof onClick === "function") {
            onClick();
        }
    }
    
    return (
        <div className={`bwp-lodging-card-2__custom-${type}-banner ${cultureClassName}`} onClick={handleClick}>
            <span>{label}</span>
        </div>
    );
}

interface ItemBoxProps {
    bookingOption: BookingOptionForLodgingCard;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

function ItemBox({ bookingOption, itemsToShow, maxItemsToShow }: ItemBoxProps) {
    
    if (!bookingOption) {
        return null;
    }
    
    if (!bookingOption.itemPrices) {
        return null;
    }
    
    const itemIsShown = (itemPrice: BookingOptionForLodgingCard["itemPrices"][0]) => {
        if (!itemsToShow?.length) {
            return false;
        }
        return itemsToShow.findIndex(itemId => itemPrice.item.id === itemId) > -1;
    };
    
    const items = useMemo(() => 
        bookingOption.itemPrices
            .filter(ip => (
                ip.isMandatory &&
                itemIsShown(ip) 
            ))
        , [bookingOption.itemPrices, itemsToShow]);
    
    return (
        <div className="bwp-lodging-card-2__items-container">
            {items.filter((itemPrice, index) => index < (maxItemsToShow || 3)).map(itemPrice => (
                <div key={`itemPrice${itemPrice.item.id}`} className="bwp-lodging-card-2__item-label">{itemPrice.item.name}</div>
            ))}
        </div>
    );
}

interface LodgingSubTitleProps {
    lodging: LodgingForLodgingCard;
    translations: LodgingCardTranslations;
    hideAddress: boolean;
}

function LodgingSubTitle({ lodging, translations, hideAddress }: LodgingSubTitleProps) {
    const showLodgingName = (lodging.name?.toLowerCase() != lodging.address1?.toLowerCase());
    
    if (!showLodgingName && hideAddress) {
        return null;
    }
    
    let label = showLodgingName
        ? hideAddress
            ? `${translations.houseNumber} ${lodging.name}`
            : `${translations.houseNumber} ${lodging.name} - ${lodging.address1}`
        : lodging.address1;
        
    return (
        <div className="bwp-lodging-card-2__subtitle">
            {label}
        </div>
    );
}

interface LodgingCardFacilityRowProps {
    facilities: string[];
}

function LodgingCardFacilityRow({ facilities }: LodgingCardFacilityRowProps) {
    
    const visibleFacilities = facilities.filter(f => f);
    if (!visibleFacilities.length) {
        return null;
    }
    
    return (
        <div className="bwp-lodging-card-2__facility-row">
            {visibleFacilities.filter(l => l).join(" - ")}
        </div>
    );
}

interface LodgingCardPriceRowProps {
    bookingOption?: BookingOptionForLodgingCard;
    seasonPrices?: SeasonPricesForLodgingCard;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    onClick: () => void;
    translations: LodgingCardTranslations;
}

function LodgingCardPriceRow({ 
    bookingOption,
    seasonPrices,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    onClick,
    translations }: LodgingCardPriceRowProps) {
    
    const handleClick = (e) => {
        e.preventDefault();
        if (typeof onClick === "function") {
            onClick();
        }
    };

    let {
        arrivalDisplayValue = null,
        arrivalWeekDayName = null,
        departureDisplayValue = null,
        priceDisplayValue = null,
        priceWithMandatoryItemsDisplayValue = null,
        hasDiscount = false,
        normalPriceDisplayValue = null,
        normalPriceWithMandatoryItemsDisplayValue = null,
        normalPriceWithMandatoryItemsWithoutDepositsDisplayValue = null,
    }: Partial<LodgingCard2Props["selectedBookingOption"]> = bookingOption || {};

    const isProbability = showProbabilityWithPriceAsBookingOption
        ? bookingOption?.status === "Probability"
        : bookingOption?.status === "Probability" || bookingOption?.status === "ProbabilityAsBooking";

    const hasBookingOption = bookingOption != null;
    const lowestSeasonPrice = (seasonPrices ?? []).reduce(
        (prev, cur) => (prev == null || prev.price > cur.price ? cur : prev),
        null
    );
    
    const priceWithMandatoryItemsWithoutDepositsDisplayValue = bookingOption?.priceWithMandatoryItemsWithoutDepositsDisplayValue || null;
    
    const beforePrice = useMemo(() => {
        if (!hasDiscount) {
            return null;
        }
        if (showPriceWithoutMandatoryItems) {
            return normalPriceDisplayValue;
        }
        if (showDepositSeparately) {
            return normalPriceWithMandatoryItemsWithoutDepositsDisplayValue;
        }
        return normalPriceWithMandatoryItemsDisplayValue;
    }, [hasDiscount, showPriceWithoutMandatoryItems, showDepositSeparately, normalPriceDisplayValue, normalPriceWithMandatoryItemsWithoutDepositsDisplayValue, normalPriceWithMandatoryItemsDisplayValue]);
    
    const price = useMemo(() => {
        if (isProbability) {
            return translations.canBeReserved;
        }
        if (showPriceWithoutMandatoryItems) {
            return priceDisplayValue;
        }
        if (showDepositSeparately) {
            return priceWithMandatoryItemsWithoutDepositsDisplayValue;
        }
        return priceWithMandatoryItemsDisplayValue;
    }, [isProbability, showPriceWithoutMandatoryItems, priceDisplayValue, showDepositSeparately, priceWithMandatoryItemsWithoutDepositsDisplayValue, priceWithMandatoryItemsDisplayValue]);
    
    return (
        <div
            className="bwp-lodging-card-2__price-row"
            data-has-discount={hasDiscount}
            onClick={handleClick}
        >
            {hasBookingOption && (
                <>
                    <div className="bwp-lodging-card-2__price-row__first-row">
                        <div>
                            {translations.arrival}: {arrivalWeekDayName}
                        </div>
                        {hasDiscount && (
                            <div>
                                <del>
                                    {beforePrice}
                                </del>
                            </div>
                        )}
                    </div>
                    <div className="bwp-lodging-card-2__price-row__second-row">
                        <div>
                            {processTemplate(translations.period, {
                                from: arrivalDisplayValue,
                                to: departureDisplayValue,
                            })}
                        </div>
                        <div>
                            {price}
                        </div>
                    </div>
                </>
            )}
            {hasBookingOption == false && lowestSeasonPrice != null && (
                <div className="bwp-lodging-card-2__price-row__second-row">
                    {processTemplate(translations.fromPricePerPeriod, {
                        price: lowestSeasonPrice.weeklyPriceDisplayValue,
                        period: translations.perWeek,
                    })}
                </div>
            )}
        </div>
    );
}