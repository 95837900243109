import React, {useEffect, useRef, useState} from 'react';
import "./HeroSearch.scss";
import HeroSearchSearchBox from "./HeroSearchSearchBox";
import { LodgingsQuery } from './operations.generated';
import {SearchBoxProps} from "./shared/types";

export interface Props {
    title: string;
    paragraph: string;
    backgroundImageUrl: string;
    searchBox: SearchBoxProps;
    requestLodgings: () => LodgingsQuery;
    lodgings?: LodgingsQuery["lodgings"];
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    culture: string;
}

export function registerTranslations() : { [propName: string]: string }
{
    return {
        "searchBox.translations": "SearchBox",
        "searchBox.datePickerTranslations": "DatePicker"
    }
}

export default function HeroSearch({title, paragraph, backgroundImageUrl, searchBox, requestLodgings, lodgings, lodgingUrlPattern, includeLodgingIdInUrl, culture }: Props) {
    
    const containerRef = useRef<HTMLDivElement>();
    
    const [mounted, setIsMounted] = useState<boolean>(false);
    useEffect(() => {
        setIsMounted(true)
    }, [mounted]);
    
    function handleScrollDown() {
        window.scroll({
           behavior: "smooth",
           top: containerRef.current.offsetTop + containerRef.current.offsetHeight 
        });
    }
    
    return (
        <div ref={containerRef} className="bwp-hero-search" style={{ "--hero-search-background": `url(${backgroundImageUrl}) center center` } as any}>
            <div className="bwp-hero-search__body">
                <div className="bwp-hero-search__text">
                    <h1>{title}</h1>
                    <p>{paragraph}</p>
                </div>
                <HeroSearchSearchBox
                    searchBox={searchBox}
                    lodgings={lodgings}
                    requestLodgings={requestLodgings}
                    lodgingUrlPattern={lodgingUrlPattern}
                    includeLodgingIdInUrl={includeLodgingIdInUrl}
                    culture={culture}
                />
            </div>

            <div className="bwp-hero-search__arrow">
                <svg onClick={handleScrollDown} width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.75">
                        <path d="M9.83469 9.35391L0.484687 2.97891C-0.161562 2.53828 -0.161562 1.82578 0.484687 1.38984L2.03844 0.330469C2.68469 -0.110156 3.72969 -0.110156 4.36906 0.330469L10.9966 4.84922L17.6241 0.330469C18.2703 -0.110156 19.3153 -0.110156 19.9547 0.330469L21.5222 1.38516C22.1684 1.82578 22.1684 2.53828 21.5222 2.97422L12.1722 9.34922C11.5259 9.79453 10.4809 9.79453 9.83469 9.35391ZM12.1722 18.3539L21.5222 11.9789C22.1684 11.5383 22.1684 10.8258 21.5222 10.3898L19.9684 9.33047C19.3222 8.88984 18.2772 8.88984 17.6378 9.33047L11.0034 13.8445L4.37594 9.32578C3.72969 8.88516 2.68469 8.88516 2.04531 9.32578L0.484687 10.3852C-0.161562 10.8258 -0.161562 11.5383 0.484687 11.9742L9.83469 18.3492C10.4809 18.7945 11.5259 18.7945 12.1722 18.3539Z" fill="white" fillOpacity="0.85" />
                    </g>
                </svg>
            </div>
        </div>
    );
}

