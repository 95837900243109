import React, { useCallback } from "react";
import ReactSlider from "react-slider";

import "./RangeSlider.scss";
import { coalesce } from "./shared/helpers";

export type RangeValue = { min: number; max: number };

interface Props {
    value: RangeValue;
    onChange: (value: RangeValue) => void;
    onAfterChange?: (value: RangeValue) => void;
    minText?: string;
    maxText?: string;
    min: number;
    max: number;
    /**
     * If this is true when it will set the min or max value to null, if the current selected value is the same as the minimum and maximum value.
     * For example. Say that you have a max of 4 bathrooms in the RangeSlider.
     * Now there is a house with 5 bathrooms. If you set 'replaceEdgeValuesWithNull' to false it will add both the min (0) and max (4) values to the SearchContext.
     * If 'replaceEdgeValuesWithNull' is true it will not add any of the parameters as both will be null that there value is on the "edge" of the slider
     **/
    replaceEdgeValuesWithNull?: boolean;
    disabled?: boolean;
}

export default function RangeSlider({
    value,
    onChange,
    onAfterChange,
    minText,
    maxText,
    min,
    max,
    replaceEdgeValuesWithNull = true,
    disabled,
}: Props) {
    let displayValue = [coalesce(value?.min, min), coalesce(value?.max, max)];

    function getThumbText(value: number) {
        if (value == min && minText) {
            return minText;
        }

        if (value == max && maxText) {
            return maxText;
        }

        return value.toString();
    }

    const convertValue = useCallback(
        (value: number[]) => {
            let val = { min: value[0], max: value[1] };
            if (replaceEdgeValuesWithNull && value[0] == min) {
                val.min = null;
            }
            if (replaceEdgeValuesWithNull && value[1] == max) {
                val.max = null;
            }
            return val;
        },
        [replaceEdgeValuesWithNull, min, max]
    );

    return (
        <ReactSlider
            className="bwp-range-slider__control"
            thumbClassName="bwp-range-slider__thumb"
            trackClassName="bwp-range-slider__track"
            value={displayValue}
            min={min}
            max={max}
            ariaLabel={["Lower thumb", "Upper thumb"]}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            onAfterChange={(v) => onAfterChange && onAfterChange(convertValue(v as number[]))}
            onChange={(v) => onChange(convertValue(v as number[]))}
            renderThumb={(props, state) => (
                <div {...props}>
                    <span>{getThumbText(state.valueNow)}</span>
                </div>
            )}
            pearling
            disabled={disabled}
        />
    );
}
