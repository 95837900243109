import React from "react";
import "./PayRates.scss";
import "./Form.scss";
import { stringFormat } from "./shared/helpers";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import PaymentOptions from "./PaymentOptions";
import {PaymentOption} from "./shared/types";
import {allowBankTransfer, allowCreditCard, hasTwoCreditCardPaymentOptions} from "./shared/paymentoptions";

export interface PayRateProps {
    amountDisplayValue: string;
    payDateDisplayValue: string;
}

export interface PayRatesProps {
    payRates: PayRateProps[];
    paymentOptions: number[];
    isLastMinute?: boolean;
    allowCreditCardFullAmountWhenTwoRates: boolean;
    lateBookingCreditCardRequired: boolean;
    payMethod: PaymentOption | null;
    onChange: (payMethod: PaymentOption) => void;
    translations: CheckoutTranslations;
}

export default function PayRates({ payRates, paymentOptions, isLastMinute, allowCreditCardFullAmountWhenTwoRates, lateBookingCreditCardRequired, payMethod, onChange, translations }: PayRatesProps) {

    const handlePayMethodChange = (value) => {
        onChange(value);
    }
    
    const hasTwoRates = () => {
        return payRates && Array.isArray(payRates) && payRates.length === 2;
    }
    
    const getDescription = () => {
        if (allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) && allowCreditCard(paymentOptions)) {
            if (isLastMinute) {
                return translations.paymentBankCreditCardLastMinute;
            }
            
            return hasTwoRates()
                ? translations.paymentBankCreditCardTwoRates
                : translations.paymentBankCreditCardOneRate;
        }
        
        if (allowCreditCard(paymentOptions)) {
            if (isLastMinute) {
                return translations.paymentCreditCardLastMinute;
            }
            
            return hasTwoRates()
                ? translations.paymentCreditCardTwoRates
                : translations.paymentCreditCardOneRate;
        }
        
        if (isLastMinute) {
            return translations.descriptionLastMinute;
        }
        
        return hasTwoRates()
            ? translations.descriptionWithTwoRates
            : translations.descriptionWithOneRate;
    }
    
    const filteredPaymentOptions = (
        (paymentOptions?.indexOf(PaymentOption.BankTransfer) > -1) && 
        (isLastMinute && lateBookingCreditCardRequired)
    )
        ? paymentOptions.filter(po => po != PaymentOption.BankTransfer)
        : paymentOptions;

    const showPaymentOptions = (
        (allowBankTransfer(paymentOptions, isLastMinute, lateBookingCreditCardRequired) && allowCreditCard(paymentOptions)) ||
        (
            allowCreditCard(paymentOptions) && 
            hasTwoRates() &&
            hasTwoCreditCardPaymentOptions(filteredPaymentOptions, allowCreditCardFullAmountWhenTwoRates)
        )
    );
    
    return (
        <section className="bwp-form-section">
            <h2>{translations.payRatesTitle}</h2>
            <p>{getDescription()}</p>
            <div className="bwp-form-section__list">
                {payRates.map((payRate, index) => (
                    <PayRateRow
                        key={`payrate${index}`}
                        payRate={payRate}
                        label={stringFormat(translations.rateLabel, (index + 1).toString())}
                        translations={translations}
                    />
                ))}
                {showPaymentOptions && (
                    <PaymentOptions
                        payRates={payRates}
                        payMethod={payMethod}
                        onPayMethodChange={handlePayMethodChange}
                        paymentOptions={filteredPaymentOptions}
                        allowCreditCardFullAmountWhenTwoRates={allowCreditCardFullAmountWhenTwoRates}
                        translations={translations}
                    />
                )}
            </div>
        </section>
    );
}

export interface PayRateRowProps {
    payRate: PayRateProps;
    label: string;
    translations: CheckoutTranslations;
}

function PayRateRow({ payRate, label, translations }: PayRateRowProps) {
    return (
        <div className="bwp-payrate-row">
            <div className="bwp-payrate-row__label">{label}</div>
            <div className="bwp-payrate-row__value">{payRate.amountDisplayValue}</div>
            <div className="bwp-payrate-row__details">
                {stringFormat(translations.isDueOn, payRate.payDateDisplayValue)}
            </div>
        </div>
    );
}
