import {PaymentOption} from "./types";
import {PayRate} from "./graphql-types.generated";
import {isToday, parseISO} from "date-fns";

export function allowCreditCard(paymentOptions: PaymentOption[])
{
    if (!paymentOptions || paymentOptions.length === 0) {
        return false;
    }

    return paymentOptions.indexOf(PaymentOption.CreditCardOneRate) > -1 ||
        paymentOptions.indexOf(PaymentOption.CreditCardTwoRates) > -1;
}

export function allowBankTransfer(paymentOptions: PaymentOption[], isLastMinute: boolean, lateBookingCreditCardRequired: boolean)
{
    /* If no payment options are defined we fallback to
     * bank transfer.
     */
    if (!paymentOptions || paymentOptions.length === 0) {
        return true;
    }

    if (allowCreditCard(paymentOptions)) {
        if (isLastMinute && lateBookingCreditCardRequired) {
            return false;
        }
    }

    return paymentOptions.indexOf(PaymentOption.BankTransfer) > -1;
}

export function hasTwoCreditCardPaymentOptions(paymentOptions: PaymentOption[], allowCreditCardFullAmountWhenTwoRates: boolean)
{
    if (!paymentOptions || paymentOptions.length < 2) {
        return false;
    }

    return (
        paymentOptions.indexOf(PaymentOption.CreditCardOneRate) > -1 &&
        paymentOptions.indexOf(PaymentOption.CreditCardTwoRates) > -1 &&
        allowCreditCardFullAmountWhenTwoRates
    );
}

export function isLateMinute(payRate: PayRate | Pick<PayRate, "payDate" | "amountDisplayValue" | "payDateDisplayValue">)
{
    if (!payRate?.payDate) {
        return false;
    }
    
    return isToday(parseISO(payRate.payDate));
}