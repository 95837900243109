import React, { useCallback, useState } from "react";

import "./NewsletterSpot.scss";
import FieldWithLabel from "./FieldWithLabel";
import Button from "./Button";
import { NewsletterFormTranslations } from "./translations/NewsletterFormTranslations";
import useModal from "./shared/useModal";
import CheckBoxWithLabel from "./CheckBoxWithLabel";

export interface Props {
    onSubmit: (value: SubmitValue) => void;
    headline: React.ReactNode;
    intro: React.ReactNode;
    translations: NewsletterFormTranslations;
    imageUrl?: string;
    termsHtml: string;
    successMessage: string;
    showSuccessMessage?: boolean;
}

export interface SubmitValue {
    firstName: string;
    lastName: string;
    email: string;
}

export default function NewsletterSpot({
    onSubmit,
    headline,
    intro,
    translations,
    imageUrl,
    termsHtml,
    showSuccessMessage,
    successMessage,
}: Props) {
    const [firstName, setFirstname] = useState();
    const [lastName, setLastname] = useState();
    const [email, setEmail] = useState();
    const [accepted, setAccepted] = useState(false);
    const { Modal, showModal, hideModal } = useModal();

    const handleSubmit = useCallback(
        (e: React.SyntheticEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit({
                firstName,
                lastName,
                email,
            });
        },
        [email, firstName, lastName, onSubmit]
    );

    const handleLinkClick = useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault();
            showModal();
        },
        [showModal]
    );

    const handleAccept = useCallback(() => {
        setAccepted(true);
        hideModal();
    }, [hideModal]);

    const newsletterCheckboxLabel = (
        <a href="#newsletter-terms" onClick={handleLinkClick}>
            {translations.termsCheckBoxText}
        </a>
    );

    return (
        <div className="bwp-newsletter-spot">
            <div className="bwp-newsletter-spot__container">
                <div className="bwp-newsletter-spot__headline">{headline}</div>
                <div className="bwp-newsletter-spot__intro">
                    <p>{intro}</p>
                    {showSuccessMessage && (
                        <p className="bwp-newsletter-spot__success">{successMessage}</p>
                    )}
                </div>

                <form onSubmit={handleSubmit}>
                    <FieldWithLabel
                        required
                        label={translations.firstName}
                        value={firstName ?? ""}
                        autoComplete="given-name"
                        onChange={setFirstname}
                    />
                    <FieldWithLabel
                        required
                        label={translations.lastName}
                        value={lastName ?? ""}
                        onChange={setLastname}
                        autoComplete="family-name"
                    />
                    <FieldWithLabel
                        required
                        type="email"
                        label={translations.email}
                        value={email ?? ""}
                        onChange={setEmail}
                        autoComplete="email"
                    />
                    <CheckBoxWithLabel
                        label={newsletterCheckboxLabel}
                        value={accepted}
                        onChange={(newValue) => setAccepted(newValue)}
                        required={true}
                        className="bwp-newsletter-spot__terms-checkbox"
                    />
                    <Button type="primary" submitButton>
                        {translations.subscribe}
                    </Button>
                </form>
                {imageUrl && <img src={imageUrl} role="presentation" loading="lazy" />}
            </div>
            <Modal title={translations.conditionsModalTitle} translations={{ close: "Close" }}>
                <div dangerouslySetInnerHTML={{ __html: termsHtml }} />
                <div className="bwp-newsletter-spot__modal-actions">
                    <Button type="primary" onClick={() => handleAccept()}>
                        {translations.acceptTerms}
                    </Button>
                    <Button type="secondary" onClick={() => hideModal()}>
                        {translations.closeTerms}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
