import React, {useEffect} from "react";
import {isBrowser} from "./shared/helpers";
import { BookingQueryResult} from "./operations.generated";
import {TrackBooking, trackBooking} from "./shared/tracking";
import {processTemplate} from "./shared/templates";
import "./PurchaseTracker.scss";

export interface PurchaseTrackerProps {
    description?: string;
    booking: BookingQueryResult["data"]["booking"];
}

export default function PurchaseTracker({ description, booking } : PurchaseTrackerProps) {
    
    useEffect(() => {
        if (isBrowser() && booking) {
            const bookingLine = booking.bookingLines.length > 0
                ? booking.bookingLines[0]
                : null;
            
            if (bookingLine) {
                trackBooking({
                    reservationId: booking.reservationId,
                    currency: booking.currency,
                    totalPrice: booking.totalPrice,
                    bookingsTotalPrice: booking.bookingsTotalPrice,
                    discountBookingsTotalPrice: booking.bookingsTotalDiscount,
                    lodging: {
                        id: bookingLine.lodgingId,
                        name: bookingLine.lodging.name
                    },
                    location: {
                        ...bookingLine.lodging.location
                    },
                    customer: {
                        postalCode: booking.customer.postalCode,
                        city: booking.customer.city,
                        country: booking.customer.country
                    },
                    itemLines: booking.itemLines.map(il => ({
                        itemId: il.itemId,
                        quantity: il.quantity,
                        description: il.description,
                        unitPrice: il.calculatedUnitPrice,
                        lineTotalPrice: il.calculatedLineTotal
                    }))
                } as TrackBooking, {onlyOnce: true});
            }
        }
    }, [booking]);
    
    if (!description) {
        return (<></>);
    }
    
    return (
        <>
            <div
                dangerouslySetInnerHTML={{
                    __html: processTemplate(
                        description, {
                            reservationId: booking.reservationId,
                            customerEmail: booking.customer.email
                        }
                    ),
                }}
            />
        </>    
    );
}