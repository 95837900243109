import React from "react";
import {NavGroup, Brand, FooterPanel, isNavGroup, isFooterBrand, isColumnList, isImages, Image} from "./shared/types";

import "./Footer.scss";
import { telLinkHref, emailLinkHref } from "./shared/helpers";
import { FooterTranslations } from "./translations/FooterTranslations";

export interface FooterProps {
    brand: Brand;
    panels: FooterPanel[];
    backgroundStyle?: string;
    translations: FooterTranslations;
}

// Component Footer
// - component for rendering a website footer
export default function Footer({ brand, panels, backgroundStyle, translations }: FooterProps) {

    const renderPanel = (panel: FooterPanel, key: string) => {
        if (!panel || !panel.data) {
            return null;
        }

        if (isNavGroup(panel.data)) {
            return (
                <FooterMenu
                    key={key}
                    menu={panel.data}
                />
            );
        } else if (isFooterBrand(panel.data)) {
            return (
                <FooterContactDetails
                    key={key}
                    name={panel.data.legalName || panel.data.name}
                    address={panel.data.address}
                    email={panel.data.email}
                    telephone={panel.data.telephone}
                    brandLogoUrl={panel.data.footerBrandLogoUrl}
                    brandLogoWidth={panel.data.footerBrandLogoWidth}
                    brandLogoHeight={panel.data.footerBrandLogoHeight}
                    translations={translations}
                />
            );
        } else if (isImages(panel.data)) {
            return (
                <FooterImages
                    images={panel.data.images}
                />
            );
        } else if (isColumnList(panel.data)) {
            return (
                <div className="bwp-footer-columnlist" key={key}>
                    {panel.data.panels.map((subPanel, i) => (
                        <div key={`${key}_subPanel${i}`}>
                            {isNavGroup(subPanel.data) && (
                                <FooterMenu
                                    menu={subPanel.data}
                                />
                            )}
                            {isFooterBrand(subPanel.data) && (
                                <FooterContactDetails
                                    name={subPanel.data.legalName || subPanel.data.name}
                                    address={subPanel.data.address}
                                    email={subPanel.data.email}
                                    telephone={subPanel.data.telephone}
                                    brandLogoUrl={subPanel.data.footerBrandLogoUrl}
                                    brandLogoWidth={subPanel.data.footerBrandLogoWidth}
                                    brandLogoHeight={subPanel.data.footerBrandLogoHeight}
                                    translations={translations}
                                />
                            )}
                            {isImages(subPanel.data) && (
                                <FooterImages
                                    images={subPanel.data.images}
                                />
                            )}
                        </div>
                    ))}
                </div>
            )
        }
        return null;
    }

    const bodyClassNames = ['bwp-footer__body-center'];
    if (panels.length) {
        bodyClassNames.push(`bwp-footer__body-col-${panels.length}`);
    }

    return (
        <footer className="bwp-footer" bwp-color-preset={backgroundStyle}>
            <div className="bwp-footer__header">
                <div className="bwp-footer__header-center"></div>
            </div>
            <div className="bwp-footer__body">
                <div className={bodyClassNames.join(' ')}>
                    {panels.map((panel, index) => renderPanel(panel, `footerPanel${index}`))}
                </div>
            </div>
            <div className="bwp-footer__bar">
                <div className="bwp-footer__bar-center">
                    &copy;{new Date().getFullYear()} - {brand.legalName}
                </div>
            </div>
        </footer>
    );
}

interface FooterMenuProps {
    menu: NavGroup;
}

// Component FooterMenu
// - component for showing a list of links in the footer
function FooterMenu({ menu }: FooterMenuProps) {
    const isExternalUrl = (url) => {
        return (url && url.toLowerCase().startsWith("https://"));
    }
    
    const makeLink = (node) => {
        const target = isExternalUrl(node.url)
            ? "_blank"
            : null;
        
        return <a href={node.url} target={target}>{node.name}</a>
    }
    
    return (
        <div className="bwp-footer__group">
            <h2>{menu.headline}</h2>
            {menu.nodes && (
                <nav>
                    <ul>
                        {menu.nodes.map((node) => (
                            <li
                                key={`${node.name}_${node.url}`}
                                className={node.icon && `bwp-icon--${node.icon}`}
                            >
                                {makeLink(node)}
                            </li>
                        ))}
                    </ul>
                </nav>
            )}
        </div>
    );
}

interface FooterContactDetailsProps {
    name: string;
    address: string;
    telephone?: string;
    email?: string;
    brandLogoUrl?: string;
    brandLogoWidth?: string;
    brandLogoHeight?: string;
    translations: FooterTranslations;
}

// Component FooterContactDetails
// - for showing a box with contact details and optionally a logo also.
function FooterContactDetails({ name, address, telephone, email, brandLogoUrl, brandLogoWidth, brandLogoHeight, translations }: FooterContactDetailsProps) {
    const brand = brandLogoUrl
        ? <div className="bwp-footer__logo"><img src={brandLogoUrl} width={brandLogoWidth || null} height={brandLogoHeight || null} alt={name} /></div>
        : <h2>{name}</h2>;

    return (
        <div className="bwp-footer__group">
            <address>
                {brand}
                {(address || "").split("\n").map((line, index) => (
                    <React.Fragment key={`addressLine${index}`}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
                <ul>
                    {telephone && (
                        <li>
                            {`${translations.telephone} `}
                            <a href={telLinkHref(telephone)}>{telephone}</a>
                        </li>
                    )}
                    {email && (
                        <li>
                            {`${translations.email} `}
                            <a href={emailLinkHref(email)}>{email}</a>
                        </li>
                    )}
                </ul>
            </address>
        </div>
    );
}

// Component FooterImages
// - a component for showing images in the footer (i.e. credit card logos)
function FooterImages({ images }: { images: Image[]}) {
    
    const ImageTag = ({ image } : { image: Image }) => {
        const tagProps = Object.assign({
            src: image.url
        }, image);
        delete tagProps.url;
        return <img {...tagProps} />;
    }
    
    return (
        <div className="bwp-footer-images">
            <div className="bwp-footer-images__body">
                {images.map((img, i) => (
                    <div key={`footer_images_${i}`}><ImageTag image={img} /></div>
                ))}
            </div>
        </div>
    );
}