import { SearchBoxSettings } from "./shared/types";
import { RangeValue } from "./RangeSlider";
import React, { useEffect, useState } from "react";
import RangeSliderWithLabel from "./RangeSliderWithLabel";

export default function SearchBoxRangeFilter({
    filter,
    value,
    onChange,
    disabled,
}: {
    filter: SearchBoxSettings["rangeFacilities"][0];
    value: RangeValue;
    onChange: (value: RangeValue) => void;
    disabled?: boolean;
}) {
    const [intermediateValue, setIntermediateValue] = useState(value);

    useEffect(() => {
        setIntermediateValue(value);
    }, [value]);

    return (
        <RangeSliderWithLabel
            key={filter.facilityId}
            label={filter.displayName}
            min={filter.min}
            max={filter.max}
            maxText={filter.maxName}
            minText={filter.minName}
            value={intermediateValue}
            onChange={(value) => setIntermediateValue(value)}
            onAfterChange={onChange}
            disabled={disabled}
        />
    );
}
