import * as React from "react";
import { useCallback, useRef, useState } from "react";
import useLazyLoad from "./shared/useLazyLoad";
import Skeleton from "react-loading-skeleton";

export interface SmartImageProps {
    imageUrlFunc: ImageUrlFunction;
    className?: string;
    onClick?: (e) => void;
    altText?: string;
    originalWidth?: number;
    originalHeight?: number;
}

export interface SmartImageState {
    imageUrl: string;
    srcSet: string;
    visible: boolean;
}

type ImageUrlFunction = (width: number, height: number) => string;

function generateSrcSet(
    url: string,
    imageUrlFunc: ImageUrlFunction,
    { offsetWidth, offsetHeight }: { offsetWidth: number; offsetHeight: number }
) {
    let size1 = imageUrlFunc(offsetWidth, offsetHeight);
    let size1point5 = imageUrlFunc(Math.ceil(offsetWidth * 1.5), Math.ceil(offsetHeight * 1.5));
    let size2 = imageUrlFunc(Math.ceil(offsetWidth * 2), Math.ceil(offsetHeight * 2));
    return `${size1} 1x, ${size1point5} 1.5x, ${size2} 2x`;
}

export default function SmartImage({
    imageUrlFunc,
    className,
    onClick,
    altText,
    originalWidth,
    originalHeight,
}: SmartImageProps) {
    const [imageUrl, setImageUrl] = useState(null);
    const [srcSet, setSrcSet] = useState(null);
    const [visible, setVisible] = useState(false);
    const containerRef = useRef<HTMLDivElement>();

    const handleLoaded = useCallback(() => {
        setVisible(true);
        setImageUrl(
            imageUrlFunc(containerRef.current.offsetWidth, containerRef.current.offsetHeight)
        );
        setSrcSet(generateSrcSet(imageUrl, imageUrlFunc, containerRef.current));
    }, [imageUrl, imageUrlFunc]);

    useLazyLoad(containerRef, handleLoaded);

    let fixedHeight =
        originalWidth && originalHeight && containerRef.current?.offsetWidth
            ? (containerRef.current.offsetWidth / originalWidth) * originalHeight + "px"
            : undefined;

    return (
        <div ref={containerRef} className={className} onClick={onClick}>
            {imageUrl && visible && (
                <img
                    src={imageUrl}
                    srcSet={srcSet}
                    className="bwp-smartimage-img"
                    style={{ height: fixedHeight }}
                    alt={altText}
                    loading="lazy"
                />
            )}
        </div>
    );
}
