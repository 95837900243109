import { useMemo } from "react";
import { PluralizedTranslation, SelectItem } from "./types";
import { pluralize } from "./helpers";

/**
 * A helper hook that helps making select list options where you need to choose from ex. 1 adult og 2 adults
 * @param translation the pluralized translation to use
 * @param from the minimum number to start from
 * @param to the maximum number to end with
 * @param nullText Add a null item text
 * @param fromText show a special text for the minimum value
 * @param toText show a special text for the maximum value
 */
export default function useArrayPluralizeOptions(
    translation: PluralizedTranslation,
    from: number,
    to: number,
    nullText: string = null,
    fromText: string = null,
    toText: string = null
) {
    return useMemo(() => {
        const result: SelectItem[] = [];

        if (nullText) {
            result.push({ value: "", text: nullText });
        }

        for (let x = from; x <= to; x++) {
            let text = null;
            if (x == from && fromText) {
                text = fromText;
            } else if (x == to && toText) {
                text = toText;
            } else {
                text = pluralize(x, translation);
            }
            result.push({ value: x.toString(), text });
        }

        return result;
    }, [nullText, from, to, fromText, toText, translation]);
}
