import * as React from "react";
import { useCallback, useEffect, useState } from "react";

function calculateVisible(containerRef: React.RefObject<HTMLElement>) {
    let maxHeight = document.body.scrollTop + window.innerHeight;
    let buffer = window.innerHeight;
    let loadHeight = maxHeight + buffer;
    let clientRect = containerRef.current?.getClientRects()[0];
    if (clientRect) {
        return clientRect.top <= loadHeight;
    } else {
        return false;
    }
}

export default function useLazyLoad(
    containerRef: React.RefObject<HTMLElement>,
    onVisible: () => void
) {
    const [initialRender, setInitialRender] = useState(true);
    const [registerLazyLoading, setRegisterLazyLoading] = useState(false);

    const onLoaded = useCallback(() => {
        onVisible();
    }, [onVisible]);

    useEffect(() => {
        if (initialRender == true) {
            setInitialRender(false);
            if (calculateVisible(containerRef)) {
                onLoaded();
            } else {
                if (containerRef?.current) {
                    setRegisterLazyLoading(true);
                }
            }
        }
    }, [containerRef, initialRender, onLoaded]);

    useEffect(() => {
        if (registerLazyLoading) {
            if (window["IntersectionObserver"] != null) {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.intersectionRatio > 0) {
                                onLoaded();
                                observer.disconnect();
                            }
                        });
                    },
                    {
                        // If the image gets within 50px in the Y axis, start the download.
                        rootMargin: "50px 0px",
                        threshold: 0.01,
                    }
                );
                observer.observe(containerRef.current);
            } else {
                // The browser does not support IntersectionObserver - so we will just show the image
                onLoaded();
            }
        }
    }, [containerRef, onLoaded, registerLazyLoading]);
}
