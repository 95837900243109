import React, {useCallback, useEffect, useMemo, useState} from "react";
import BookingSummary from "./BookingSummary";
import ItemsSummary from "./ItemsSummary";
import {BookingRequestInput, CreateBookingResultType, CustomerInput} from "./shared/graphql-types.generated";
import {CheckoutQuery, CreateBookingMutation, PayRatesQuery, TermsQuery,} from "./operations.generated";
import {CheckoutTranslations} from "./translations/CheckoutTranslations";
import LodgingFacilitySummary from "./LodgingFacilitySummary";
import ItemsSelector from "./ItemsSelector";
import ContactDetailsForm from "./ContactDetailsForm";
import Button from "./Button";
import InfoBox from "./InfoBox";
import {Step, StepName} from "./StepVisualizer";
import GuestsForm from "./GuestsForm";
import NotesForm from "./NotesForm";
import CommunicationForm from "./CommunicationForm";
import PayRates from "./PayRates";
import {isToday, parseISO} from "date-fns";
import TermsForm from "./TermsForm";
import "./Checkout.scss";
import "./global/shared.scss";
import Spinner from "./Spinner";
import {isBrowser, isSelectableItem } from "./shared/helpers";
import {trackDynamicUrlChange} from "./shared/googleAnalyticsUtils";
import {Redirect, Route, Switch, useHistory, useRouteMatch} from "react-router";
import {FormFieldType, PaymentOption} from "./shared/types";
import {isLateMinute} from "./shared/paymentoptions";
import {SearchBoxTranslations} from "./translations/SearchBoxTranslations";
import {PeopleValue} from "./PeopleSelectWithLabel";
import {SearchContext} from "./shared/searchcontext";
import {LodgingPageTranslations} from "./translations/LodgingPageTranslations";
import {TrackBooking, trackBooking} from "./shared/tracking";
import {processTemplate} from "./shared/templates";

type StepWithLocalizedUrl = Step & { routeParam: string };

interface Props {
    value: BookingRequestInput;
    onChange: (value: BookingRequestInput) => void;
    onPayMethodChange: (payMethod: PaymentOption) => void;
    onPeopleChange: (value: PeopleValue) => void;
    searchContext: SearchContext;
    data: CheckoutQuery;
    translations: CheckoutTranslations;
    lodgingTranslations: LodgingPageTranslations;
    searchBoxTranslations: SearchBoxTranslations;
    languageId: number;
    onBook: () => void;
    defaultCountry?: string;
    infoBoxContent?: string;
    guestsFormAlertMessage?: string;
    catalogueLabel?: string;
    payRates: PayRatesQuery;
    guestsRequired?: boolean;
    hideGuests?: boolean;
    terms: TermsQuery;
    createBookingResult: CreateBookingMutation;
    doneText: string;
    doneContactInformation: string;
    address2FormFieldType?: FormFieldType;
    privateTelephoneFormFieldType?: FormFieldType;
    privateMobilephoneFormFieldType?: FormFieldType;
    workTelephoneFormFieldType?: FormFieldType;
    payMethod: PaymentOption | null;
    paymentOptions?: PaymentOption[];
    allowCreditCardFullAmountWhenTwoRates: boolean;
    lateBookingCreditCardRequired: boolean;
    showInfants?: boolean;
    showProbabilityWithPriceAsBookingOption?: boolean;
    hideLodgingAddress?: boolean;
}

function Checkout({
    languageId,
    data,
    value,
    onChange,
    onPayMethodChange,
    onPeopleChange,
    translations,
    lodgingTranslations,
    searchBoxTranslations,
    searchContext,
    onBook,
    defaultCountry,
    infoBoxContent,
    guestsFormAlertMessage,
    catalogueLabel,
    payRates,
    guestsRequired,
    hideGuests,
    terms,
    createBookingResult,
    doneText,
    doneContactInformation,
    address2FormFieldType,
    privateTelephoneFormFieldType,
    privateMobilephoneFormFieldType,
    workTelephoneFormFieldType,
    payMethod,
    paymentOptions,
    allowCreditCardFullAmountWhenTwoRates,
    lateBookingCreditCardRequired,
    showInfants,
    showProbabilityWithPriceAsBookingOption,
    hideLodgingAddress
}: Props) {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const isProbability = showProbabilityWithPriceAsBookingOption
        ? data?.lodgingPresentation?.selectedBookingOption?.status === "Probability"
        : (data?.lodgingPresentation?.selectedBookingOption?.status === "Probability" || data?.lodgingPresentation?.selectedBookingOption?.status === "ProbabilityAsBooking");
    
    const [maxStep, setMaxStep] = useState<StepName>(
        isProbability ? "yourDetails" : "extras"
    );
    const match = useRouteMatch<{ step: StepName }>();
    const history = useHistory();

    const steps: StepWithLocalizedUrl[] = useMemo(
        () => [
            isProbability === false
                ? {
                    id: "extras" as StepName,
                    name: translations.stepExtras,
                    routeParam: translations.stepExtrasUrlName,
                }
                : null,
            {
                id: "yourDetails" as StepName,
                name: translations.stepYourDetails,
                routeParam: translations.stepYourDetailsUrlName,
            },
            {
                id: "payment" as StepName,
                name: "",
                routeParam: "payment"
            },
            {
                id: "done" as StepName,
                name: translations.stepDone,
                routeParam: translations.stepDoneUrlName,
            },
        ].filter((x) => x !== null),
        [isProbability, translations]
    );

    const setStep = useCallback(
        (stepName: StepName) => {
            window.scrollTo(0, 0);
            let step = steps.find((s) => s.id == stepName);
            history.push("/" + step.routeParam + "/" + window.location.search);
        },
        [steps, history]
    );
    
    const noItems = data.checkoutPresentation.itemLines 
        ? data.checkoutPresentation.itemLines.filter(isSelectableItem).length === 0
        : true;
    
    let step = steps.find((s) => s.routeParam == match.params.step);
    if (step?.id === "extras" && noItems) {
        step = steps.find(s => s.id === "yourDetails");
        // TODO: How to redirect to your details?
    }
    
    useEffect(() => {
        if (firstRender == true) {
            setFirstRender(false);
        }
    }, [firstRender]);

    useEffect(() => {
        if (!firstRender) {
            trackDynamicUrlChange();
        }
        // We disable ESLint here as we don't want to trigger on firstRender change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (steps.indexOf(step) > steps.findIndex((s) => s.id == maxStep)) {
            setStep(maxStep);
        }
    }, [step, maxStep, setStep, steps]);

    const blankCustomer: BookingRequestInput["customer"] = {
        firstName: "",
        lastName: "",
        address: "",
        postalCode: "",
        city: "",
        country: defaultCountry,
        privateTelephone: "",
        privateMobilephone: "",
        workTelephone: "",
        email: "",
        subscribeToCatalogue: false,
        customerId: null,
        customFieldValues: [],
        languageId: languageId,
        subscribeToNewsletters: null,
    };

    const blankGuest: BookingRequestInput["guests"][0] = {
        firstName: "",
        lastName: "",
        birthYear: null,
    };

    const blankGuests = [];
    const numberOfGuests =
        data.lodgingPresentation.selectedBookingOption.adults +
        data.lodgingPresentation.selectedBookingOption.children;
    for (let x = 1; x <= numberOfGuests; x++) {
        blankGuests.push({ ...blankGuest });
    }

    const handleItemQuantityChange = useCallback(
        (itemId: number, quantity: number) => {
            const items = [...(value.items || [])];

            let item = items.find((i) => i.itemId == itemId);
            if (item != null) {
                const index = items.indexOf(item);
                items[index] = { ...item, quantity };
            } else {
                items.push({ itemId, quantity });
            }

            onChange({
                ...value,
                items,
            });
        },
        [onChange, value]
    );

    const onChangeCustomer = useCallback(
        (customer: Partial<CustomerInput>) => {
            onChange({
                ...value,
                customer: { ...blankCustomer, ...value.customer, ...customer },
            });
        },
        [blankCustomer, onChange, value]
    );

    const handleFormSubmit = useCallback(
        (e: React.SyntheticEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (step.id === "yourDetails") {
                onBook();
                if (payMethod == PaymentOption.CreditCardOneRate || payMethod == PaymentOption.CreditCardTwoRates) {
                    setMaxStep("payment");
                    setStep("payment");
                } else {
                    setMaxStep("done");
                    setStep("done");                    
                }
            } else if (step.id === "extras") {
                setMaxStep("yourDetails");
                setStep("yourDetails");
            } else {
                return;
            }
        },
        [onBook, setStep, step]
    );
    
    if (!step) {
        const firstStep = steps.find(s => s.routeParam);
        return <Redirect to={"/" + firstStep.routeParam + "/" + window.location.search} />;
    }
    
    const showCommunicationSection = (data?.newsletters?.length) || catalogueLabel;
    
    return (
        <FormOrContainer isForm={step.id !== "payment"} onFormSubmit={handleFormSubmit} className="bwp-checkout">
            <aside>
                <div>
                    <div style={{ position: "relative", zIndex: 1 }}>
                        <BookingSummary
                            translations={translations}
                            lodgingTranslations={lodgingTranslations}
                            searchBoxTranslations={searchBoxTranslations}
                            onPeopleChange={onPeopleChange}
                            bookingOption={data?.lodgingPresentation?.selectedBookingOption}
                            lodging={data?.lodgingPresentation?.lodging}
                            showInfants={showInfants}
                            maxPeople={data?.lodgingPresentation?.lodging.maxPeople}
                            maxPets={data?.lodgingPresentation?.lodging.maxPets}
                            hideLodgingAddress={hideLodgingAddress}
                        />
                    </div>
                    {isProbability == false && (
                        <ItemsSummary
                            lodging={data.lodgingPresentation.lodging}
                            checkoutPresentation={data.checkoutPresentation}
                            translations={translations}
                            showBackToItems={step.id === "yourDetails" && !noItems}
                            onChangeItemsClick={() => setStep("extras")}
                        />
                    )}
                </div>
                <div className="bwp-only-large-screen">
                    <LodgingFacilitySummary
                        lodging={data.lodgingPresentation.lodging}
                        translations={translations}
                    />
                </div>
                <div className="bwp-only-large-screen">
                    {doneContactInformation && step.id == "done" && (
                        <InfoBox>
                            <div dangerouslySetInnerHTML={{ __html: doneContactInformation }} />
                        </InfoBox>
                    )}
                </div>
            </aside>
            {step.id === "extras" && (
                <main>
                    <ItemsSelector
                        data={data.checkoutPresentation}
                        translations={translations}
                        onItemQuantityChange={handleItemQuantityChange}
                    />
                    <div className="bwp-checkout__next-button">
                        <Button submitButton type="primary">
                            {translations.nextStep}
                        </Button>
                    </div>
                </main>
            )}
            {step.id === "yourDetails" && (
                <main>
                    <ContactDetailsForm
                        value={value?.customer ?? blankCustomer}
                        countries={data.countries}
                        address2FormFieldType={address2FormFieldType}
                        privateTelephoneFormFieldType={privateTelephoneFormFieldType}
                        privateMobilephoneFormFieldType={privateMobilephoneFormFieldType}
                        workTelephoneFormFieldType={workTelephoneFormFieldType}
                        onChange={(customer) => onChange({ ...value, customer })}
                        translations={translations}
                    />
                    {!hideGuests && (
                        <GuestsForm
                            customer={value?.customer}
                            guests={value?.guests ?? blankGuests}
                            onChange={(guests) => onChange({ ...value, guests })}
                            translations={translations}
                            alertMessage={guestsFormAlertMessage}
                            required={guestsRequired}
                        />
                    )}
                    <NotesForm
                        value={value?.note ?? ""}
                        onChange={(note) => onChange({ ...value, note: note })}
                        translations={translations}
                    />
                    {showCommunicationSection && (
                        <CommunicationForm
                            catalogue={value?.customer?.subscribeToCatalogue ?? false}
                            newsletters={data.newsletters}
                            onCatalogueChange={(subscribeToCatalogue) =>
                                onChangeCustomer({ subscribeToCatalogue })
                            }
                            onNewslettersChange={(subscribeToNewsletters) =>
                                onChangeCustomer({
                                    subscribeToNewsletters: subscribeToNewsletters.join(","),
                                })
                            }
                            translations={translations}
                            selectedNewsletters={
                                value?.customer?.subscribeToNewsletters
                                    ?.split(",")
                                    .map((x) => parseInt(x)) ?? []
                            }
                            catalogueLabel={catalogueLabel}
                        />
                    )}
                    {payRates && (
                        <PayRates
                            payRates={[payRates.payRates.rate1, payRates.payRates.rate2].filter(
                                (p) => p != null
                            )}
                            isLastMinute={isLateMinute(payRates.payRates.rate1)}
                            translations={translations}
                            paymentOptions={paymentOptions}
                            allowCreditCardFullAmountWhenTwoRates={allowCreditCardFullAmountWhenTwoRates}
                            lateBookingCreditCardRequired={lateBookingCreditCardRequired}
                            payMethod={payMethod}
                            onChange={onPayMethodChange}
                        />
                    )}
                    {terms && (
                        <TermsForm
                            termsAccepted={termsAccepted}
                            onTermsAcceptedChange={setTermsAccepted}
                            terms={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: terms.terms.description,
                                    }}
                                />
                            }
                            translations={translations}
                        />
                    )}
    
                    <div className="bwp-checkout__next-button">
                        <Button submitButton type="primary">
                            {isProbability ? translations.reserve : translations.book}
                        </Button>
                        {!isProbability && !noItems && (
                            <a
                                href="#"
                                className="bwp-checkout__back-link bwp-only-large-screen"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setStep("extras");
                                }}
                            >
                                {translations.backToExtras}
                            </a>
                        )}
                    </div>
                </main>
            )}
            {step.id === "payment" && (
                <main>
                    {createBookingResult?.createBooking == null && (
                        <WaitSpinner showDelay={0} text={translations.pleaseWait} />
                    )}
                    {createBookingResult?.createBooking && (
                        <PaymentView
                            {...{ createBookingResult, translations }}
                        />
                    )}
                </main>
            )}
            {step.id === "done" && (
                <main>
                    {createBookingResult?.createBooking == null && (
                        <WaitSpinner showDelay={0} text={translations.pleaseWait} />
                    )}
                    {createBookingResult?.createBooking && (
                        <DoneView
                            {...{ doneText, createBookingResult, translations }}
                            checkoutPresentation={data.checkoutPresentation}
                            lodgingPresentation={data.lodgingPresentation}
                            lodgingName={data.lodgingPresentation.lodging.name}
                            lodgingId={data.lodgingPresentation.lodging.id}
                            value={value}
                            payRates={isProbability ? null : payRates}
                        />
                    )}
                </main>
            )}
        </FormOrContainer>
    );
}

function WaitSpinner({ showDelay, text } : { showDelay: number, text?: string })
{
    return (
        <div className="bwp-checkout__spinner">
            {text && (
                <h2>{text}</h2>
            )}
            <Spinner showDelay={showDelay} />
        </div>
    )
}

function FormOrContainer({ isForm, onFormSubmit, className, children } : { 
    isForm: boolean, 
    onFormSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void,
    className: string,
    children: React.ReactNode
}) 
{
    if (isForm) {
        return (
            <form className={className} onSubmit={onFormSubmit}>
                <DisableEnterSubmitOfForm />
                {children}
            </form>
        );
    }
    
    return (
        <div className={className}>
            {children}
        </div>
    );
}

function DoneView({
    doneText,
    createBookingResult,
    value,
    payRates,
    checkoutPresentation,
    lodgingPresentation,
    translations
}: Pick<Props, "doneText" | "createBookingResult" | "payRates" | "translations"> & {
        value: BookingRequestInput;
        checkoutPresentation: CheckoutQuery["checkoutPresentation"];
        lodgingPresentation: CheckoutQuery["lodgingPresentation"];
        lodgingName: string;
        lodgingId: number;
}) {

    useEffect(() => {
        if (isBrowser() && createBookingResult) {
            if (createBookingResult.createBooking.success) {
                if (createBookingResult.createBooking.result.resultType === CreateBookingResultType.OrderItem) {
                    trackBooking(
                        {
                            reservationId: createBookingResult.createBooking.result.orderItem.reservationId,
                            currency: checkoutPresentation.currency,
                            totalPrice: checkoutPresentation.totalPrice,
                            bookingsTotalPrice: checkoutPresentation.bookingPrice,
                            discountBookingsTotalPrice: checkoutPresentation.discountReduction
                                ? checkoutPresentation.discountReduction * -1     /* The discount for tracking is positive number */
                                : 0,
                            lodging: {
                                id: lodgingPresentation.lodging.id,
                                name: lodgingPresentation.lodging.name
                            },
                            location: {
                                ...lodgingPresentation.lodging.location
                            },
                            customer: {
                                postalCode: value.customer.postalCode,
                                city: value.customer.city,
                                country: value.customer.country
                            },
                            itemLines: checkoutPresentation.itemLines
                                ? checkoutPresentation.itemLines.filter(il => il.showInSummary).map(il => ({
                                    itemId: il.item.id,
                                    description: il.item.name,
                                    quantity: il.quantity,
                                    unitPrice: il.itemPrice.unitPrice,
                                    lineTotalPrice: il.totalPrice
                                }))
                                : []
                        } as TrackBooking,
                        { onlyOnce: true }
                    );
                }
            }
        }
    }, [createBookingResult]);
    
    if (createBookingResult.createBooking.success && createBookingResult.createBooking.result.resultType === CreateBookingResultType.OrderItem) {
        return (
            <>
                <InfoBox>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: processTemplate(
                                doneText, {
                                    reservationId: createBookingResult.createBooking.result.orderItem.reservationId,
                                    customerEmail: value.customer.email
                                }
                            ),
                        }}
                    />
                </InfoBox>
                {payRates && (
                    <PayRates
                        payRates={[payRates.payRates.rate1, payRates.payRates.rate2].filter(
                            (p) => p != null
                        )}
                        isLastMinute={isToday(parseISO(payRates.payRates.rate1.payDate))}
                        translations={translations}
                        paymentOptions={[]}
                        allowCreditCardFullAmountWhenTwoRates={false}
                        lateBookingCreditCardRequired={false}
                        payMethod={null}
                        onChange={(v) => { return; }}
                    />
                )}
            </>
        );
    } else {
        return <div>{createBookingResult.createBooking.errorMessage}</div>;
    }
}

function PaymentView({
    createBookingResult
}: Pick<Props, "createBookingResult">) {
    
    const formForGateway = createBookingResult?.createBooking?.result?.formForGateway;
    
    useEffect(() => {
        if (formForGateway) {
            const form = document.querySelector<HTMLFormElement>(".bwp-payment-form-container form");
            if (form) {
                form.submit();
            }
        }
    }, [formForGateway]);
    
    if (!createBookingResult?.createBooking?.success || createBookingResult.createBooking.result.resultType != CreateBookingResultType.FormForGateway) {
        return (
            <div className="bwp-widget-uncaught-error">
                <p>
                    An error occurred while processing the request on the server.
                </p>
                <p>
                    Please contact us for further assistance.
                </p>
            </div>
        );
    }
    
    return (
        <div className="bwp-payment-form-container">
            <div dangerouslySetInnerHTML={{
                __html: createBookingResult.createBooking.result.formForGateway 
            }}></div>
        </div>
    )
}

// Taken from: https://stackoverflow.com/a/51507806
function DisableEnterSubmitOfForm() {
    return <button type="submit" disabled style={{ display: "none" }} aria-hidden="true" />;
}

export default function CheckoutRouteWrapper(props: Props) {
    return (
        <Switch>
            <Route path={"/:step?/"} render={() => <Checkout {...props} />} />
        </Switch>
    );
}
