import React from "react";
import ReactDOM from "react-dom";
import Skeleton from "react-loading-skeleton";

export default function SearchResultListSkeleton({
    headerElement,
    numberOfSkeletons = 6,
    height = 400,
}: {
    headerElement?: HTMLElement;
    numberOfSkeletons?: number;
    height?: number;
}) {
    let arr = [];
    for (let x = 0; x < numberOfSkeletons; x++) {
        arr.push(x);
    }

    return (
        <>
            {headerElement &&
                ReactDOM.createPortal(
                    <div className="bwp-search-result-list-header" />,
                    headerElement
                )}
            <div className="bwp-lodging-list">
                {arr.map((a) => (
                    <Skeleton key={a} height={height} />
                ))}
            </div>
        </>
    );
}
