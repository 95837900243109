import * as React from 'react'
import {useEffect, useState} from "react";

interface AnimatedCounterProps {
    number: number;
}

export default function AnimatedCounter({ number }: AnimatedCounterProps) {

    const [currentNumber, setCurrentNumber] = useState(0);
    const [timer, setTimer] = useState(null);
    
    useEffect(() => {
        if (number != currentNumber) {
            // After each render we set the timer to null.
            // If we have a timer at this point we clear it
            // as we are about to schedule a new render.
            if (timer) {
                clearTimeout(timer);
            }
            const newTimer = setTimeout(() => {
                if (currentNumber != number) {
                    const change = (currentNumber < number) ? 1 : -1;
                    // Setting the currentNumber in state here
                    // triggers useEffect(...) to run again 
                    // as currentNumber is listed as a dependency.
                    setCurrentNumber(currentNumber + change);
                }
                setTimer(null);
            }, 5);
            // Schedule the next frame to run in 5 milliseconds
            setTimer(newTimer);
        }
    }, [number, currentNumber]);
    
    return (
        <span>{currentNumber}</span>
    );


    

}