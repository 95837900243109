import * as React from "react";
import { renderToString } from "react-dom/server";
import { ensureGoogleMapsIncluded } from "./shared/googleMapUtils";
import LodgingCard2 from "./LodgingCard2";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";

interface Props {
    googleMapsApiKey: string;
    maxMapZoomLevel?: number;
    searchResults: SearchResultMapLodging[];
    markerIconUrl?: string;
    lodgingCardTranslations: LodgingCardTranslations;
    extraFacilities: number[];
    mapHeight?: number;
    renderInfoWindow: (lodging: SearchResultMapLodging) => React.ReactNode;
}

interface SearchResultMapLodging {
    id: number;
    latitude: string;
    longitude: string;
}

const DefaultMapHeight = "500px";

export default class SearchResultMap extends React.Component<Props> {
    private map: google.maps.Map;
    private infoWindow: google.maps.InfoWindow;
    private mapTarget = React.createRef<HTMLDivElement>();
    private markers: google.maps.Marker[] = [];

    state = {
        googleApiAvailable: false,
    };

    componentDidMount() {
        if (this.props.googleMapsApiKey) {
            ensureGoogleMapsIncluded(this.props.googleMapsApiKey).then(() => {
                this.setState({googleApiAvailable: true});
            });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.state.googleApiAvailable) {
            const maxZoomLevel = this.props.maxMapZoomLevel || 0;

            if (this.map == null) {
                this.map = new google.maps.Map(this.mapTarget.current, {
                    styles: [
                        {
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }],
                        },
                    ],
                });
                this.map.addListener("bounds_changed", () => {
                    if (0 < maxZoomLevel && maxZoomLevel < this.map.getZoom()) {
                        this.map.setZoom(maxZoomLevel);
                    }
                });
                this.infoWindow = new google.maps.InfoWindow();
            }

            let validSearchResults = this.props.searchResults.filter(
                (x) => x.latitude != "0" && x.longitude != "0"
            );

            let bounds = new google.maps.LatLngBounds();
            validSearchResults.forEach((searchResult) =>
                bounds.extend(
                    new google.maps.LatLng(
                        parseFloat(searchResult.latitude),
                        parseFloat(searchResult.longitude)
                    )
                )
            );
            this.map.fitBounds(bounds);

            this.markers.forEach((m) => {
                m.unbindAll();
            });
            this.markers = validSearchResults.map(this.createMapMarker);
        }
    }

    createMapMarker = (searchResult: SearchResultMapLodging): google.maps.Marker => {
        let markerOpts = {
            position: new google.maps.LatLng(
                parseFloat(searchResult.latitude),
                parseFloat(searchResult.longitude)
            ),
            map: this.map,
        };
        if (this.props.markerIconUrl) {
            markerOpts = Object.assign(markerOpts, { icon: this.props.markerIconUrl });
        }

        const marker = new google.maps.Marker(markerOpts);
        marker.addListener("click", (e) => {
            this.infoWindow.setContent(this.createInfoWindowContent(searchResult));
            this.infoWindow.open(this.map, marker);
        });
        return marker;
    };

    createInfoWindowContent = (d: SearchResultMapLodging): string => {
        return renderToString(
            <div className="bwp-info-window">{this.props.renderInfoWindow(d)}</div>
        );
    };

    render() {
        const mapHeight = this.props.mapHeight || DefaultMapHeight;

        if (!this.props.googleMapsApiKey) {
            return null;
        }
        
        return (
            <div className="bwp-lodging-list-map">
                <div ref={this.mapTarget} style={{ height: mapHeight }} />
            </div>
        );
    }
}
