import {
    ApolloClient, 
    ApolloClientOptions,
    InMemoryCache,
    NormalizedCacheObject,
} from "@apollo/client";
import { isBrowser } from "@bwp/shared/helpers";

let client: ApolloClient<NormalizedCacheObject> = null;

export function configureClient(options?: ApolloClientOptions<NormalizedCacheObject>) {
    return new ApolloClient(
        Object.assign({
            uri: "/umbraco/bookingstudio/api/graphql?culture=" + window["BookingStudio_culture"],
            cache: new InMemoryCache(),
        }, options || {})
    );
}

export function getClient() {
    if (client == null && isBrowser()) {
        client = configureClient();
    }
    return client;
}