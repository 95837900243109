import React, {useEffect, useState} from "react";
import PurchaseTracker from "@bwp/PurchaseTracker";
import {useBookingQuery} from "@bwp/operations.generated";
import { parse } from "query-string";
import Spinner from "@bwp/Spinner";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";
import {CheckoutTranslations} from "@bwp/translations/CheckoutTranslations";

export interface PurchaseTrackerWrapperProps {
    description?: string;
    dateFormat: string;
    translations?: CheckoutTranslations;
}

const DelayBetweenRetries: number[] = [500, 1000, 2000, 4000, 8000, 16000];
const MaxRetries: number = DelayBetweenRetries.length - 1;

export default function PurchaseTrackerWrapper({ description, dateFormat, translations } : PurchaseTrackerWrapperProps) {
    const qs = parse(window.location.search);
    
    const [refetching, setRefetching] = useState(false);
    const [loadRetries, setLoadRetries] = useState(0);

    const basketId = Array.isArray(qs.basketId)
        ? qs.basketId[0]
        : qs.basketId;
    
    const { loading, error, data, refetch } = useBookingQuery({
        variables: {
            basketId: basketId,
            dateFormat: dateFormat
        },
        skip: !basketId,
    });
    
    useEffect(() => {
        if (basketId && !loading && !error) {
            if (!data?.booking?.reservationId) {
                if (!refetching && loadRetries < MaxRetries) {
                    const delay = DelayBetweenRetries[loadRetries];
                    setRefetching(true);
                    setTimeout(() => {
                        setLoadRetries(loadRetries + 1);
                        refetch().then(() => {
                            setRefetching(false);
                        });
                    }, delay);
                }
            }
        }
    }, [basketId, loading, error, data, refetch, loadRetries, refetching]);

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }
    
    if (loading || (!data?.booking?.reservationId && loadRetries < MaxRetries)) {
        return (
            <div style={{ display: "grid", justifyContent: "center" }}>
                <Spinner />
            </div>
        );
    }
    
    if (!data?.booking?.reservationId) {
        return (
            <div className="bwp-purchase-error">
                <h2>{translations.anErrorOccurred}</h2>
                <p>{translations.unableToFindBookingInShoppingBasket}</p>
            </div>
        );
    }
    
    return (
        <PurchaseTracker booking={data.booking} description={description} />
    )    
    
}