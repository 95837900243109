import React from "react";
import "./LodgingTitle.scss";
import { LodgingPageTranslations } from "./translations/LodgingPageTranslations";
import { LodgingPresentationQuery } from "./operations.generated";
import { IconHalfStar32, IconPlus22, IconStar32 } from "./shared/icons";

interface Props {
    showStars?: boolean;
    starsExtraIcon?: string;
    translations: LodgingPageTranslations;
}

export default function LodgingTitle({
    id,
    name,
    activeDescription,
    location,
    translations,
    stars,
    starsPostText,
    showStars,
    starsExtraIcon
}: Props & LodgingPresentationQuery["lodgingPresentation"]["lodging"]) {

    const hasTitle = activeDescription?.title != null && activeDescription?.title.length > 0;

    const lodgingTitle = hasTitle ? activeDescription.title : name;
    
    const starsToShow = stars
        ? [1, 2, 3, 4, 5].filter(s => s <= stars)
        : [];

    return (
        <div className="bwp-lodging-title">
            {(name.toLowerCase() != lodgingTitle.toLowerCase()) && (
                <div className="bwp-lodging-title__name">
                    {translations.lodgingNamePrefix} {name}
                </div>
            )}
            <h1 className="bwp-lodging-title__title">{lodgingTitle}</h1>
            <div className="bwp-lodging-title__location-name">{location.name}</div>
            {showStars && (
                <div className="bwp-lodging-title__stars">
                    {starsToShow.map(s => <LodgingStar key={`star${s}`} />)}
                    {starsPostText && (<LodgingStarsExtraIcon starsExtraIcon={starsExtraIcon} />)}
                </div>
            )}
        </div>
    );
}

function LodgingStar() {
    return (
        <>
            {IconStar32}
        </>
    )
}

function LodgingStarsExtraIcon({ starsExtraIcon }: { starsExtraIcon?: string })
{
    let icon: React.ReactNode = null;
    if (starsExtraIcon === "Half star") {
        icon = IconHalfStar32;
    } else if (starsExtraIcon === "Plus") {
        icon = IconPlus22;
    }

    if (!icon) {
        return null;
    }

    return (
        <>
            {icon}
        </>
    );
}