import React from "react";
import "./TermsForm.scss";
import "./Form.scss";
import CheckBoxWithLabel from "./CheckBoxWithLabel";
import useModal from "./shared/useModal";
import Button from "./Button";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";

export interface TermsFormProps {
    termsAccepted: boolean;
    onTermsAcceptedChange: (termsAccepted: boolean) => void;
    terms: React.ReactNode;
    translations: CheckoutTranslations;
}

export default function TermsForm({
    termsAccepted,
    onTermsAcceptedChange,
    terms,
    translations,
}: TermsFormProps) {
    const { Modal, showModal } = useModal();

    const handleClick = () => {
        showModal();
    };

    return (
        <>
            <section className="bwp-form-section bwp-terms-form">
                <h2>{translations.termsFormTitle}</h2>
                <p>{translations.termsFormDescription}</p>
                <Button onClick={handleClick}>{translations.termsFormReadTheTerms}</Button>
                <CheckBoxWithLabel
                    required
                    label={translations.termsFormYesIAcceptTheTerms}
                    onChange={onTermsAcceptedChange}
                    value={termsAccepted}
                />
            </section>
            <Modal
                title={translations.termsFormModelTitle}
                translations={{ close: translations.termsFormClose }}
            >
                {terms}
            </Modal>
        </>
    );
}
