import React, { useMemo } from "react";
import { LodgingCardTranslations } from "@bwp/translations/LodgingCardTranslations";
import useGlobalSearchContext from "@bwp/shared/useGlobalSearchContext";
import { getSearchSorting } from "@bwp/shared/helpers";
import { formatISO, parseISO } from "date-fns";
import useQueryString from "@bwp/shared/useQueryString";
import { SearchResultListTranslations } from "@bwp/translations/SearchResultListTranslations";
import { useSearchResultsPageQuery } from "@bwp/operations.generated";
import SearchResultListSkeleton from "@bwp/SearchResultListSkeleton";
import SearchResultList from "@bwp/SearchResultList";
import { lodgingUrl } from "@bwp/shared/lodgingurl";
import { FavouritesTranslations } from "@bwp/translations/FavouritesTranslations";
import {getSearchContextPropsFromBookingOption, SearchContext} from "@bwp/shared/searchcontext";
import {CleaningBannerType, DiscountBannerType} from "@bwp/shared/types";

export interface SearchResultListWrapperProps {
    translations: SearchResultListTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingUrlPattern: string;
    includeLodgingIdInUrl?: boolean;
    markerIconUrl: string;
    mapHeight: number;
    googleMapsApiKey: string;
    maxMapZoomLevel: number;
    emptyResultHtmlContent: string;
    defaultOrderBy?: string;
    showStars?: boolean;
    starsExtraIcon?: string;
    extraFacilities?: number[];
    headlessMode?: boolean;
    headlessDataFormat?: "full" | "standard" | "simple";
    showProbabilityWithPriceAsBookingOption?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    showDepositSeparately?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

export default function SearchResultListWrapper({
    translations,
    lodgingCardTranslations,
    favouritesTranslations,
    lodgingUrlPattern,
    includeLodgingIdInUrl,
    markerIconUrl,
    mapHeight,
    googleMapsApiKey,
    maxMapZoomLevel,
    emptyResultHtmlContent,
    defaultOrderBy,
    showStars,
    starsExtraIcon,
    extraFacilities,
    headlessMode,
    headlessDataFormat,
    showProbabilityWithPriceAsBookingOption,
    showPriceWithoutMandatoryItems,
    showDepositSeparately,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: SearchResultListWrapperProps) {
    const [headerElement] = React.useState(document.getElementById("bwp-search-result-header"));
    const [sorting, setSorting] = useQueryString("ord", null);
    const [searchContext] = useGlobalSearchContext({});

    const query = useSearchResultsPageQuery({
        variables: {
            query: searchContext.changeArrivalDensity(3).toServerQuery(),
            onlyMasterFacilities: [],
            onlyFacilities: extraFacilities,
            displayDate: formatISO(searchContext.arrival, {representation: "date"}),
            sorting: (sorting as any) ?? getSearchSorting(defaultOrderBy),
        },
    });

    const queryString = useMemo(() => {
        return searchContext.toQueryString([
            "context:lodging-location",
            "context:lodging-address1",
            "context:lodging-name"
        ]);
    }, [searchContext]);

    if (query.error) {
        return <div>{query.error}</div>;
    }

    if (query.loading) {
        return <SearchResultListSkeleton headerElement={headerElement} height={405}/>;
    }
    
    const ignoredParams = includeLodgingIdInUrl
        ? []
        : ["lodgingId"];

    return (
        <>
            <SearchResultList
                headerElement={headerElement}
                translations={translations}
                lodgingCardTranslations={lodgingCardTranslations}
                favouritesTranslations={favouritesTranslations}
                {...query.data}
                lodgingUrlBuilder={(result) => {
                    const baseUrl = lodgingUrl(lodgingUrlPattern, result.lodging);
                    const separator = baseUrl.indexOf('?') === -1 ? "?" : "&";
                    const boProps = getSearchContextPropsFromBookingOption({
                        lodgingId: result.lodging.id,
                        arrival: parseISO(result.selectedBookingOption.arrival),
                        duration: result.selectedBookingOption.duration,
                        adults: result.selectedBookingOption.adults,
                        children: result.selectedBookingOption.children,
                        infants: result.selectedBookingOption.infants,
                        pets: result.selectedBookingOption.pets
                    });
                    const qs = SearchContext.createFromSearchContextProps(boProps).toQueryString(ignoredParams);
                    return `${baseUrl}${separator}${qs}`;
                }}
                extraFacilities={extraFacilities}
                sorting={sorting}
                setSorting={setSorting}
                markerIconUrl={markerIconUrl}
                mapHeight={mapHeight}
                googleMapsApiKey={googleMapsApiKey}
                maxMapZoomLevel={maxMapZoomLevel}
                emptyResultHtmlContent={emptyResultHtmlContent}
                showStars={showStars}
                starsExtraIcon={starsExtraIcon}
                arrivalDate={searchContext.arrival}
                headlessMode={headlessMode}
                headlessDataFormat={headlessDataFormat}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                showDepositSeparately={showDepositSeparately}
                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                discountBanner={discountBanner}
                cleaningBanner={cleaningBanner}
                hideLodgingAddress={hideLodgingAddress}
                showItems={showItems}
                itemsToShow={itemsToShow}
                maxItemsToShow={maxItemsToShow}
            />
        </>
    );
}
