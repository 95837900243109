import React, { useEffect } from "react";

import "./Modal.scss";
import Overlay from "./Overlay";
import Button from "./Button";
import { useCallback } from "react";

export interface ModalTranslations {
    close: string;
}

export interface ModalProps {
    title: React.ReactNode;
    className?: string;
    children: React.ReactNode;
    translations: ModalTranslations;
}

interface ModalPrivateProps {
    onClose: () => void;
}

export default function Modal({
    title,
    className,
    children,
    translations,
    onClose,
}: ModalPrivateProps & ModalProps) {
    useEffect(() => {
        document.querySelector("body").classList.add("bwp-noscroll");
        return () => {
            document.querySelector("body").classList.remove("bwp-noscroll");
        };
    }, []);

    const Esc = 27;
    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === Esc) {
                onClose();
            }
        },
        [onClose]
    );

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const handleOutsideClick = () => onClose();

    const handleInsideClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const classNames = ["bwp-modal"];
    if (className) {
        classNames.push(className);
    }

    return (
        <Overlay>
            <div className={classNames.join(" ")} onClick={handleOutsideClick}>
                <div className="bwp-modal__frame" onClick={handleInsideClick}>
                    <div className="bwp-modal__body">
                        <div className="bwp-modal__header">
                            <h2>{title}</h2>
                            <button
                                className="bwp-close-button"
                                type="button"
                                onClick={() => onClose()}
                                aria-label={translations.close}
                            ></button>
                        </div>
                        <div className="bwp-modal__content">{children}</div>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}
