import React, { useEffect, useState, useRef } from "react";
import "./FieldWithLabel.scss";
import { SearchBoxTranslations } from "./translations/SearchBoxTranslations";
import { isBrowser, pluralize } from "./shared/helpers";
import NumberPicker from "./NumberPicker";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import useOnClickOutside from "./shared/useOnClickOutside";

export interface PeopleValue {
    adults: number;
    children: number;
    infants?: number;
    pets?: number;
}

export interface PeopleWithLabelProps {
    label: string;
    value: PeopleValue;
    onChange: (value: PeopleValue) => void;
    maxPersons: number;
    translations: SearchBoxTranslations;
    showLabelAsPersons?: boolean;
    warningMessage?: string;
    showInfants?: boolean;
    peopleSummaryMaxWidth?: number;
}

export default function PeopleSelectWithLabel({
    label,
    value = { adults: 0, children: 0, infants: 0, pets: 0 },
    onChange,
    translations,
    maxPersons,
    showLabelAsPersons,
    warningMessage,
    showInfants,
    peopleSummaryMaxWidth
}: PeopleWithLabelProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [div, setDiv] = useState(null);
    
    const [inputWidth, setInputWidth] = useState(null);
    const inputContainerRef = useRef<HTMLDivElement>(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start'        
    });    

    useOnClickOutside([{ current: referenceElement }, { current: popperElement }], (event) => {
        setIsOpen(false);
    })

    // Setup the portal container
    useEffect(() => {
        if (!div && isBrowser()) {
            const newDiv = document.createElement("div");
            document.body.appendChild(newDiv);
            setDiv(newDiv);
        }

        return () => {
            if (div && isBrowser()) {
                document.body.removeChild(div);
                setDiv(null);
            }
        };
    }, [div]);

    // Setup close on Esc key and tab key
    useEffect(() => {
        const listener = (event: KeyboardEvent): any => {
            if (event.key === "Escape" || event.key === "Tab") {
                setIsOpen(false);
            }
        };

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        }
    });
    
    // Setup the text value so that it changes in accordance to the screen size
    useEffect(() => {
        let resizeObserver = null;
        if (inputContainerRef.current) {
            if (typeof ResizeObserver === "function") {
                resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        if (entry.contentBoxSize[0]) {
                            setInputWidth(entry.contentBoxSize[0].inlineSize);

                        }
                    }
                });
                resizeObserver.observe(inputContainerRef.current);
            } else {
                let width = inputContainerRef.current.getBoundingClientRect().width;
                setInputWidth(width);
            }
        }
        
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [inputContainerRef]);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            setIsOpen(true);
        } else if (event.key === "ArrowUp") {
            event.preventDefault();
            setIsOpen(false);
        }
    };
    
    const defaultThreshold = showInfants 
        ? 650 
        : 500;

    const threshold = peopleSummaryMaxWidth || defaultThreshold;
    const labelAsPersons = showLabelAsPersons || (typeof inputWidth === "number" && inputWidth < threshold) 
        ? (
            <>{pluralize(value.adults + value.children + (value.infants || 0), translations.person)}</>
        )
        : (
            <>
                {pluralize(value.adults, translations.adult)}
                {", "}
                {pluralize(value.children, translations.child)}
                {(showInfants && value.infants > 0) && (
                    <>
                        {", "}
                        {pluralize(value.infants, translations.infant)}
                    </>
                )}
            </>
        );
    
    return (
        <div ref={inputContainerRef}>
            <div className="bwp-field-with-label bwp-field-with-label--people-select">
                <label>
                    <span>{label}</span>
                </label>
                <div>
                    <button
                        className="bwp-field-with-label__value-container"
                        type="button"
                        ref={setReferenceElement}
                        onClick={() => { setIsOpen(!isOpen) }}
                        onKeyDown={handleKeyDown}
                    >
                        {labelAsPersons}
                    </button>
            </div>
        </div>
            {isOpen && (isBrowser() && div) && ReactDOM.createPortal(
                <div className="bwp-select-people" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                    <div className="bwp-select-people__selector">
                        <div>{translations.adults}</div>
                        <NumberPicker
                            min={0}
                            max={maxPersons}
                            value={value.adults}
                            onChange={(adults) =>
                                onChange({ adults, children: value.children, infants: value.infants})
                            }
                        />
                    </div>
                    <div className="bwp-select-people__selector">
                        <div>{translations.children}</div>
                        <NumberPicker
                            min={0}
                            max={maxPersons}
                            value={value.children}
                            onChange={(children) =>
                                onChange({ adults: value.adults, children, infants: value.infants})
                            }
                        />
                    </div>
                {showInfants && (  
                    <div className="bwp-select-people__selector">
                        <div>{translations.infants}</div>
                        <NumberPicker
                            min={0}
                            max={maxPersons}
                            value={value.infants}
                            onChange={(infants) =>
                                onChange({adults: value.adults, children: value.children, infants})
                            }
                        />
                    </div>
                )}
                    <div className="bwp-select-people__warning-message">
                        <p>{warningMessage}</p>
                    </div>
                </div>
            , div)}
        </div>
    );
}
