import React from "react";

export function setBoundsFromRef(ref: React.RefObject<HTMLElement>, bounds, setBounds) {
    if (ref && ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (!bounds) {
            setBounds(rect);
        } else if (rect !== bounds) {
            setBounds(rect);
        }
    }
};
