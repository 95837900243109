import { useEffect, useState } from "react";
import { isBrowser } from "./helpers";
import { parse, stringify } from "query-string";

export default function useQueryString(name: string, defaultValue: string | null = null) {
    const value = getQueryString(name);
    const [internalValue, setInternalValue] = useState(value); // used to trigger updates of react
    const eventName = "onQueryStringChanged_" + name;

    let parsedValue = value;

    if (value == null) {
        parsedValue = defaultValue;
    }

    function setValue(value: string) {
        const url = updateQueryStringParameter(window.location.search, name, value);
        window.history.replaceState(null, null, url);
        setInternalValue(value);
        window.dispatchEvent(new CustomEvent(eventName));
    }

    useEffect(() => {
        const handler = () => {
            const value = getQueryString(name);
            if (value != internalValue) {
                setInternalValue(value);
            }
        };

        window.addEventListener(eventName, handler);

        return () => {
            window.removeEventListener(eventName, handler);
        };
    }, [eventName, internalValue, name]);

    return [parsedValue, setValue] as [string | null, (value: string | null) => void];
}

export function getQueryString(name: string): string {
    let location = isBrowser() ? window.location.href : globalUrl;

    let match = RegExp("[?&]" + name + "=([^&]*)", "i").exec(location);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export function updateQueryStringParameter(uri, key, value) {
    let qs = parse(uri);

    if (value !== null && value !== undefined) {
        qs[key] = value.toString();
    } else {
        delete qs[key];
    }
    let stringQs = stringify(qs);
    if (stringQs.length > 0) {
        return uri.split("?")[0] + "?" + stringify(qs);
    } else {
        return uri.split("?")[0];
    }
}
