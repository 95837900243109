import React from "react";
import "./FieldWithLabel.scss";

export interface FieldWithLabelProps {
    id?: string;
    label: string;
    value: string;
    onChange: (newValue) => void;
    pattern?: string;
    type?: string;
    required?: boolean;
    autoComplete?: string;
    placeholder?: string;
    min?: number;
    max?: number;
}

export default function FieldWithLabel({
    id,
    label,
    value,
    onChange,
    pattern,
    type = "text",
    required,
    autoComplete,
    placeholder,
    min,
    max
}: FieldWithLabelProps) {
    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e.currentTarget.value);
        }
    };

    let attr = {
        type: type,
        value: value,
        onChange: handleOnChange
    };
    
    if (id) {
        attr = Object.assign(attr, { id });
    }
    
    if (pattern) {
        attr = Object.assign(attr, { pattern });
    }
    
    if (required) {
        attr = Object.assign(attr, { required });
    }
    
    if (placeholder) {
        attr = Object.assign(attr, { placeholder });
    }
    
    if (autoComplete) {
        attr = Object.assign(attr, { autoComplete });
    }
    
    if (type === "number") {
        if (typeof min === "number") {
            attr = Object.assign(attr, {min});
        }
        if (typeof max === "number") {
            attr = Object.assign(attr, {max});
        }
    }
    
    return (
        <div className="bwp-field-with-label">
            <label>
                <span>{required ? label + " *" : label}</span>
                <input
                    className="bwp-field-with-label__value-container"
                    {...attr}
                />
            </label>
        </div>
    );
}
