import React from "react";
import "./PaymentOptions.scss";
import "./Form.scss";
import { CheckoutTranslations } from "./translations/CheckoutTranslations";
import {PayRateProps} from "./PayRates";
import {PaymentOption} from "./shared/types";

export type PaymentOptionsProps = {
    payRates: PayRateProps[];
    paymentOptions: number[];
    payMethod: number;
    onPayMethodChange: (payMethod: number) => void;
    allowCreditCardFullAmountWhenTwoRates: boolean;
    translations: CheckoutTranslations;
} 

export default function PaymentOptions({ payRates, payMethod, onPayMethodChange, paymentOptions, allowCreditCardFullAmountWhenTwoRates, translations }: PaymentOptionsProps) {
    const handleOptionClick = (value) => {
        onPayMethodChange(value);
    }

    const showCreditCard = paymentOptions && 
        paymentOptions.length && 
        (
            paymentOptions.indexOf(PaymentOption.CreditCardOneRate) > -1 ||
            paymentOptions.indexOf(PaymentOption.CreditCardTwoRates) > -1
        );

    let showBankTransfer = (
        !paymentOptions || 
        paymentOptions.length === 0 || 
        paymentOptions.indexOf(PaymentOption.BankTransfer) > -1
    );
    
    const numberOfPayRates = payRates.length;
    
    return (
        <div className="bwp-payment-options">
            {translations.pleaseChoosePaymentMethod}
            <div className="bwp-payment-options__list">
                {showBankTransfer && (
                    <>
                        <h3>{translations.paymentBankTransfer}</h3>
                        <PaymentOptionRadio
                            label={translations.paymentTransferMoneyViaYourBank}
                            value={PaymentOption.BankTransfer}
                            checked={payMethod == PaymentOption.BankTransfer}
                            onClick={handleOptionClick}
                        />
                    </>
                )}
                {(showCreditCard && numberOfPayRates === 1) && (
                    <>
                        <h3>{translations.paymentCreditCard}</h3>
                        <PaymentOptionRadio
                            label={translations.paymentPayOnlineWithYourCreditCard}
                            value={PaymentOption.CreditCardOneRate}
                            checked={payMethod == PaymentOption.CreditCardOneRate}
                            onClick={handleOptionClick}
                        />
                    </>
                )}
                {(showCreditCard && numberOfPayRates === 2) && (
                    <>
                        <h3>{translations.paymentCreditCard}</h3>
                        <PaymentOptionRadio
                            label={allowCreditCardFullAmountWhenTwoRates ? translations.paymentPayFirstRateNowAndRestLater : translations.paymentPayOnlineWithYourCreditCard}
                            value={PaymentOption.CreditCardTwoRates}
                            checked={payMethod == PaymentOption.CreditCardTwoRates}
                            onClick={handleOptionClick}
                        />
                        {allowCreditCardFullAmountWhenTwoRates && (
                            <PaymentOptionRadio
                                label={translations.paymentPayFullAmountNow}
                                value={PaymentOption.CreditCardOneRate}
                                checked={payMethod == PaymentOption.CreditCardOneRate}
                                onClick={handleOptionClick}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

function PaymentOptionRadio({ label, value, checked, onClick })
{
    const handleOptionClick = (e) => {
        onClick(value);
    }
    
    const handleOnChange = (e) => {
        onClick(value);
    }
    
    const classNames = checked 
        ? ["bwp-payment-options__item", "bwp-selected"]
        : ["bwp-payment-options__item"];
    
    const id = `bwpPayMethod${value}`;
    
    return (
        <div className={classNames.join(" ")} onClick={handleOptionClick}>
            <label htmlFor={id}>{label}</label>
            <input type="radio" name="paymethod" value={value} checked={checked} id={id} onChange={handleOnChange} required={true} />
        </div>
    );
}