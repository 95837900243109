import React from "react";

export default function ListIcon() {
    return (
        <svg
            width={19}
            height={12}
            viewBox="0 0 19 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={5} height={5} fill="#333333" />
            <rect y={7} width={5} height={5} fill="#333333" />
            <rect x={7} width={5} height={5} fill="#333333" />
            <rect x={7} y={7} width={5} height={5} fill="#333333" />
            <rect x={14} width={5} height={5} fill="#333333" />
            <rect x={14} y={7} width={5} height={5} fill="#333333" />
        </svg>
    );
}
