import React from "react";
import ReviewSpot from "@bwp/ReviewSpot";
import { useBureauReviewsQuery } from "@bwp/operations.generated";
import GraphQLErrorPanel from "@bwp/GraphQLErrorPanel";

interface Props {
    headline?: string;
    buttonText?: string;
    buttonUrl?: string;
    animationDelay?: number;
}

export default function ReviewSpotWrapper({ headline, buttonText, buttonUrl, animationDelay }: Props) {
    const { data, loading, error } = useBureauReviewsQuery();

    if (error) {
        return <GraphQLErrorPanel error={error} />;
    }

    return (
        <ReviewSpot
            headline={headline}
            data={data || { bureauReviews: [] }}
            buttonText={buttonText}
            buttonUrl={buttonUrl}
            animationDelay={animationDelay}
        />
    );
}
