import React, { ComponentProps, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { SearchResultListTranslations } from "./translations/SearchResultListTranslations";
import { LodgingCardTranslations } from "./translations/LodgingCardTranslations";
import useQueryString from "./shared/useQueryString";
import { SearchResultsPageQuery } from "./operations.generated";
import SearchResultListHeader from "./SearchResultListHeader";
import LodgingCard2 from "./LodgingCard2";

import useFavorites from "./shared/useFavorites";
import {CleaningBannerType, DiscountBannerType, SearchResultListStyle} from "./shared/types";
import SearchResultMap from "./SearchResultMap";
import { isSameDay, parseISO } from "date-fns";

import "./LodgingList.scss";
import "./SearchResultList.scss";
import { FavouritesTranslations } from "./translations/FavouritesTranslations";
import {WidgetSearchResult} from "./widgets/types";
import {isBrowser} from "./shared/helpers";
import {TrackLodgingListItem, trackViewList} from "./shared/tracking";

export function registerTranslations() {
    return {
        "translations": "SearchResultList",
        "favouritesTranslations": "Favourites",
        "lodgingCardTranslations": "LodgingCard"
    };
}

export default function SearchResultList({
    translations,
    favouritesTranslations,
    lodgingCardTranslations,
    search: { results, numberOfResults },
    headerElement,
    lodgingUrlBuilder,
    extraFacilities,
    sorting,
    setSorting,
    googleMapsApiKey,
    mapHeight,
    markerIconUrl,
    maxMapZoomLevel,
    emptyResultHtmlContent,
    showStars,
    starsExtraIcon,
    arrivalDate,
    headlessMode,
    headlessDataFormat,
    showProbabilityWithPriceAsBookingOption,
    showPriceWithoutMandatoryItems,
    showDepositSeparately,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: {
    translations: SearchResultListTranslations;
    favouritesTranslations: FavouritesTranslations;
    lodgingCardTranslations: LodgingCardTranslations;
    headerElement: HTMLElement;
    lodgingUrlBuilder: (result: SearchResultsPageQuery["search"]["results"][0]) => string;
    extraFacilities: number[];
    sorting: string;
    setSorting: (sorting: string) => void;
    googleMapsApiKey: string;
    mapHeight: number;
    markerIconUrl: string;
    maxMapZoomLevel: number;
    emptyResultHtmlContent?: string;
    showStars?: boolean,
    starsExtraIcon?: string;
    arrivalDate: Date;
    headlessMode?: boolean;
    headlessDataFormat?: "full" | "standard" | "simple";
    showProbabilityWithPriceAsBookingOption?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    showDepositSeparately?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
} & SearchResultsPageQuery) {
    let [favorites, setFavorite] = useFavorites();
    let [listStyle, setListStyle] = useQueryString("listStyle", "Grid");

    useEffect(() => {
        // Legacy event - to be removed
        window.dispatchEvent(
            new CustomEvent("numberOfSearchResultsUpdated", {
                detail: results.length
            })
        );
        
        window.dispatchEvent(
            new CustomEvent("BookingStudio:numberOfSearchResultsUpdated", {
                detail: results.length
            })
        );
        
        if (headlessMode) {
            const eventResults = results.map(result => toWidgetSearchResult(result, lodgingUrlBuilder(result), (headlessDataFormat ?? "simple")));
            window.dispatchEvent(
                new CustomEvent("BookingStudio:searchResultsUpdated", {
                    detail: eventResults
                })
            );

        }
    }, [results]);
    
    useEffect(() => {
       if (isBrowser()) {
           const items = results.map(r => {
               return {
                   lodging: {
                       id: r.lodging.id,
                       name: r.lodging.name,
                       location: {
                           id: r.lodging.location.id,
                           name: r.lodging.location.name
                       }
                   },
                   value: r.selectedBookingOption 
                       ? {
                           amount: r.selectedBookingOption.priceWithMandatoryItems,
                           currency: r.selectedBookingOption.currency
                       }
                       : null
               } as TrackLodgingListItem;
           });
           trackViewList(items, { onlyOnce: false });
       }
    }, [results]);
    
    if (headlessMode) {
        return null;
    }

    return (
        <>
            {headerElement &&
                ReactDOM.createPortal(
                    <SearchResultListHeader
                        numberOfResults={numberOfResults}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        translations={translations}
                        listStyle={listStyle as SearchResultListStyle}
                        mapUnavailable={!googleMapsApiKey}
                        onListStyleChange={(val) => setListStyle(val)}
                    />,
                    headerElement
                )}
            {results.length == 0 && (
                <div dangerouslySetInnerHTML={{ __html: emptyResultHtmlContent }} />
            )}
            {results.length != 0 && listStyle == "Grid" && (
                <SearchResultListGrid
                    {...{
                        translations,
                        lodgingCardTranslations,
                        favouritesTranslations,
                        extraFacilities,
                        lodgingUrlBuilder,
                        results,
                        setFavorite,
                        favorites,
                        arrivalDate,
                        showStars,
                        starsExtraIcon,
                        showProbabilityWithPriceAsBookingOption,
                        showDepositSeparately,
                        showPriceWithoutMandatoryItems,
                        discountBanner,
                        cleaningBanner,
                        hideLodgingAddress,
                        showItems,
                        itemsToShow,
                        maxItemsToShow
                    }}
                />
            )}
            {results.length != 0 && listStyle == "Map" && (
                <SearchResultMap
                    extraFacilities={extraFacilities}
                    lodgingCardTranslations={lodgingCardTranslations}
                    googleMapsApiKey={googleMapsApiKey}
                    searchResults={results.map((r) => ({
                        id: r.lodging.id,
                        longitude: r.lodging.longitude,
                        latitude: r.lodging.latitude,
                    }))}
                    mapHeight={mapHeight}
                    markerIconUrl={markerIconUrl}
                    maxMapZoomLevel={maxMapZoomLevel}
                    renderInfoWindow={(lodging) => {
                        let result = results.find((r) => r.lodging.id == lodging.id);
                        return (
                            <LodgingCard2
                                key={
                                    result.lodging.id +
                                    " " +
                                    result.selectedBookingOption.arrivalDisplayValue +
                                    " " +
                                    result.selectedBookingOption.departureDisplayValue +
                                    " " +
                                    result.selectedBookingOption.priceWithMandatoryItemsDisplayValue
                                }
                                lodging={result.lodging}
                                showStars={showStars}
                                starsExtraIcon={starsExtraIcon}
                                translations={lodgingCardTranslations}
                                favouritesTranslations={favouritesTranslations}
                                extraFacilities={extraFacilities}
                                nextUrl={lodgingUrlBuilder(result)}
                                selectedBookingOption={result.selectedBookingOption}
                                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                                showCleaningBanner={result.selectedBookingOption.cleaningIncludedInPriceWithMandatoryItems}
                                showDepositSeparately={showDepositSeparately}
                                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                                discountBanner={discountBanner}
                                cleaningBanner={cleaningBanner}
                                hideAddress={hideLodgingAddress || false}
                                showItems={showItems}
                                itemsToShow={itemsToShow}
                                maxItemsToShow={maxItemsToShow}
                                disableSmartImage
                                disableImageSlider
                            />
                        );
                    }}
                />
            )}
        </>
    );
}

interface SearchResultListGridProps {
    results: SearchResultsPageQuery["search"]["results"];
    translations: ComponentProps<typeof SearchResultList>["translations"];
    lodgingCardTranslations: ComponentProps<typeof SearchResultList>["lodgingCardTranslations"];
    favouritesTranslations: FavouritesTranslations;
    extraFacilities: ComponentProps<typeof SearchResultList>["extraFacilities"];
    lodgingUrlBuilder: ComponentProps<typeof SearchResultList>["lodgingUrlBuilder"];
    arrivalDate: ComponentProps<typeof SearchResultList>["arrivalDate"];
    favorites: number[];
    setFavorite: (id: number) => void;
    showStars?: boolean;
    starsExtraIcon?: string;
    showProbabilityWithPriceAsBookingOption?: boolean;
    showDepositSeparately?: boolean;
    showPriceWithoutMandatoryItems?: boolean;
    discountBanner?: DiscountBannerType;
    cleaningBanner?: CleaningBannerType;
    hideLodgingAddress?: boolean;
    showItems?: boolean;
    itemsToShow?: number[];
    maxItemsToShow?: number;
}

function SearchResultListGrid({
    results,
    lodgingCardTranslations,
    favouritesTranslations,
    extraFacilities,
    favorites,
    lodgingUrlBuilder,
    setFavorite,
    arrivalDate,
    translations,
    showStars,
    starsExtraIcon,
    showProbabilityWithPriceAsBookingOption,
    showDepositSeparately,
    showPriceWithoutMandatoryItems,
    discountBanner,
    cleaningBanner,
    hideLodgingAddress,
    showItems,
    itemsToShow,
    maxItemsToShow
}: SearchResultListGridProps) {
    const resultsOnArrivalDate = useMemo(
        () =>
            results?.filter((r) =>
                isSameDay(parseISO(r.selectedBookingOption.arrival), arrivalDate)
            ),
        [results, arrivalDate]
    );
    
    const resultsOnOtherDaysSorted = useMemo(
        () =>
            results
                ?.filter((r) => !isSameDay(parseISO(r.selectedBookingOption.arrival), arrivalDate))
                .sort(
                    (a, b) =>
                        parseISO(a.selectedBookingOption.arrival).getTime() -
                        parseISO(b.selectedBookingOption.arrival).getTime()
                ),
        [results, arrivalDate]
    );

    function renderLodging(result: typeof results[0]) {

        return (
            <LodgingCard2
                key={
                    result.lodging.id +
                    " " +
                    result.selectedBookingOption.arrivalDisplayValue +
                    " " +
                    result.selectedBookingOption.departureDisplayValue +
                    " " +
                    result.selectedBookingOption.priceWithMandatoryItemsDisplayValue
                }
                lodging={result.lodging}
                translations={lodgingCardTranslations}
                favouritesTranslations={favouritesTranslations}
                extraFacilities={extraFacilities}
                nextUrl={lodgingUrlBuilder(result)}
                selectedBookingOption={result.selectedBookingOption}
                isFavorite={favorites.includes(result.lodging.id)}
                onSetFavorite={setFavorite}
                showStars={showStars}
                starsExtraIcon={starsExtraIcon}
                showCleaningBanner={result.selectedBookingOption.cleaningIncludedInPriceWithMandatoryItems}
                showProbabilityWithPriceAsBookingOption={showProbabilityWithPriceAsBookingOption}
                showDepositSeparately={showDepositSeparately && !showPriceWithoutMandatoryItems}
                showPriceWithoutMandatoryItems={showPriceWithoutMandatoryItems}
                discountBanner={discountBanner}
                cleaningBanner={cleaningBanner}
                hideAddress={hideLodgingAddress}
                showItems={showItems}
                itemsToShow={itemsToShow}
                maxItemsToShow={maxItemsToShow}
            />
        );
    }

    return (
        <div className="bwp-lodging-list-grouped-container">
            <div className="bwp-lodging-list">
                {resultsOnArrivalDate.map((d) => renderLodging(d))}
            </div>
            {resultsOnArrivalDate.length == 0 && (
                <h2 className="bwp-lodging-list__group-heading">
                    {translations.noArrivalsOnDateShowsOtherDates}
                </h2>
            )}

            {resultsOnArrivalDate.length > 0 && <h2>{translations.resultsOnOtherArrivalDates}</h2>}
            <div className="bwp-lodging-list">
                {resultsOnOtherDaysSorted.map((d) => renderLodging(d))}
            </div>
        </div>
    );
}

function toWidgetSearchResult(result: any, url: string, mode: "full" | "standard" | "simple") : WidgetSearchResult {
    return {
        lodging: (mode === "full" || mode === "standard")
            ? {
                id: result.lodging.id,
                name: result.lodging.name,
                nameSlug: result.lodging.nameSlug,
                longitude: mode === "full" ? result.lodging.longitude : 0,
                latitude: mode === "full" ? result.lodging.latitude : 0,
                address1: mode === "full" ? result.lodging.address1 : "",
                address1slug: mode === "full" ? result.lodging.address1slug : "",
                location: {
                    name: result.lodging.location?.name,
                    slug: result.lodging.location?.slug
                },
                maxPeople: result.lodging.maxPeople,
                petsAllowed: result.lodging.petsAllowed,
                images: result.lodging.images?.map(img => { return { url: img.url }}),
                facilityValues: result.lodging.facilityValues.map(fv => {
                    return {
                        facilityId: fv.facilityId,
                        facility: {
                            name: fv.facility.name
                        },
                        value: fv.value,
                        displayValueAndName: fv.displayValueAndName,
                        displayValue: fv.displayValue
                    };
                }),
                description: {
                    title: result.lodging.activeDescription.title,
                    summary: result.lodging.activeDescription.summary
                },
                stars: result.lodging.stars,
                starsPostText: result.lodging.starsPostText
            }
            : {
                id: result.lodging.id,
                name: result.lodging.name
            },
        selectedBookingOption: {
            arrival: result.selectedBookingOption.arrival,
            arrivalDisplayValue: result.selectedBookingOption.arrivalDisplayValue,
            arrivalWeekDayName: result.selectedBookingOption.arrivalWeekDayName,
            duration: result.selectedBookingOption.duration,
            departureDisplayValue: result.selectedBookingOption.departureDisplayValue,
            adults: result.selectedBookingOption.adults,
            children: result.selectedBookingOption.children,
            infants: result.selectedBookingOption.infants,
            pets: result.selectedBookingOption.pets,
            priceWithMandatoryItemsDisplayValue: result.selectedBookingOption.priceWithMandatoryItemsDisplayValue,
            status: result.selectedBookingOption.status,
            normalPriceWithMandatoryItems: result.selectedBookingOption.normalPriceWithMandatoryItems,
            cleaningIncludedInPriceWithMandatoryItems: result.selectedBookingOption.cleaningIncludedInPriceWithMandatoryItems,
            hasDiscount: result.selectedBookingOption.hasDiscount,
            discountName: result.selectedBookingOption.discountName
        },
        url: url
    } as WidgetSearchResult;
}