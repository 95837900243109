import { SearchContext } from "./searchcontext";
import {SearchBoxTranslations} from "../translations/SearchBoxTranslations";
import {DatePickerTranslations} from "../translations/DatePickerTranslations";

export type ButtonType = "default" | "primary" | "secondary" | "filter";

export type SearchResultListStyle = "Grid" | "Map";

export type FormFieldType = "optional" | "required" | "hidden";

export interface SelectItem {
    value: string;
    text: string;
}

export interface Image {
    url: string;
    width?: string;
    height?: string;
    alt?: string;
    title?: string;
}

export interface NavNode {
    name: string;
    url?: string;
    icon?: "facebook" | "instagram";
    nodes?: NavNode[];
}

export interface NavGroup {
    headline: string;
    icon?: "info-circle" | "comment";
    nodes: NavNode[];
}

export interface VirtualElement {
    getBoundingClientRect: () => DOMRect;
}

export function isNavGroup(panel: FooterPanelType | Brand): panel is NavGroup {
    return (panel as NavGroup).headline !== undefined;
}

export interface FooterColumnList {
    panels: FooterPanel[];
}

export interface FooterImages {
    images: Image[];
}

type FooterPanelType = NavGroup | FooterBrand | FooterColumnList | FooterImages; 

export interface FooterPanel {
    data: FooterPanelType;
}

export interface Language {
    isoCode: string;
    name: string;
    url: string;
    isCurrent: boolean;
}

export interface Brand {
    name: string;
    logoLightBgUrl?: string;
    logoDarkBgUrl?: string;
    logoUrl: string;
    logoWidth?: string;
    logoHeight?: string;
    legalName?: string;
    url?: string;
    address?: string;
    telephone?: string;
    email?: string;
}

export function isBrand(panel: NavGroup | Brand | FooterBrand): panel is Brand {
    return (
        (panel as Brand).name !== undefined &&
        (panel as FooterBrand).footerBrandLogoUrl === undefined
    );
}

export type FooterBrand = Brand & {
    footerBrandLogoUrl?: string;
    footerBrandLogoWidth?: string;
    footerBrandLogoHeight?: string;
};

export function isFooterBrand(panel: FooterPanelType | Brand): panel is FooterBrand {
    return (panel as FooterBrand).footerBrandLogoUrl !== undefined;
}

export function isColumnList(panel: FooterPanelType): panel is FooterColumnList {
    return (panel as FooterColumnList).panels !== undefined;
}

export function isImages(panel: FooterPanelType): panel is FooterImages {
    return (panel as FooterImages).images !== undefined;
}

export type FacilityValueComparison = "equal" | "min" | "max";

export enum MasterFacilities {
    MaxPeople = 1,
    Bedrooms = 2,
    LodgingArea = 3,
    LandArea = 4,
    BuiltYear = 5,
    ReconstructedYear = 6,
    PetsAllowed = 7,
    PetsNotAllowed = 8,
    NumberOfPets = 9,
    Internet = 10,
    Swimmingpool = 11,
    Spa = 12,
    Sauna = 13,
    WashingMachine = 14,
    Dishwasher = 15,
    WoodStove = 16,
    SmokingAllowed = 17,
    SmokingNotAllowed = 18
}

export interface Money {
    amount: number;
    currency: string;
}

export interface PluralizedTranslation {
    zero?: string;
    one?: string;
    // According to the spec (https://lingohub.com/blog/2019/02/pluralization) - then theese can be included as well - but we don't support them yet
    // two?: string;
    // few?: string;
    // many?: string;
    other: string;
}

export type UrlWriter = (searchContext: SearchContext) => string;
export type UrlReader = (location: Location) => SearchContext;

export interface CalendarValue {
    year: number;
    month: number;
    day: number;
}

export type Dictionary = { [key: string]: string };

export interface GenericDictionary<T> {
    [key: string]: T;
}

export interface CultureInfo {
    isoName: string;
    languageCode: string;
    bsLanguageId: number;
}

export type SearchBoxProps = {
    translations: SearchBoxTranslations;
    datePickerTranslations: DatePickerTranslations;
    locations: SelectItem[];
    durations: number[];
    maxPeople: number;
    maxPets: number;
    onSubmit?: (value: SearchContext) => void;
    urlWriter?: UrlWriter;
    urlReader?: UrlReader;
    highlightDays?: number[];
    booleanFacilities?: BooleanFacility[];
    rangeFacilities?: RangeFacility[];
};

export type BooleanFacility = {
    facilityId: number;
    displayName: string;
}

export type RangeFacility = {
    facilityId: number;
    displayName: string;
    min: number;
    max: number;
    minName?: string;
    maxName?: string;
}

export interface SearchBoxSettings {
    id: number;
    maxPeople: number;
    maxPets: number;
    name: string;
    version: number;
    availableDurations: number[];
    booleanFacilities: {
        facilityId: number;
        displayName: string;
    }[];
    rangeFacilities: {
        facilityId: number;
        displayName: string;
        min: number;
        max: number;
        minName?: string;
        maxName?: string;
    }[];
}

export interface Coordinate {
    lat: number;
    lng: number;
}

export interface LodgingListSetting {
    id: number;
    name: string;
    locations: number[];
    facilities: { facilityId: number; value: string; minValue: string; maxValue: string }[];
}

export interface Favourite {
    id: number;
    isFavourite: boolean;
}

export interface CommonStyles {
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    paddingTop?: string;
    paddingBottom?: string;
    paddingLeft?: string;
    paddingRight?: string;
}

export interface Position {
    width: number;
    height: number;
    x: number;
    y: number;
}

export enum PaymentOption {
    BankTransfer,
    CreditCardOneRate,
    CreditCardTwoRates
}

export interface LodgingCalendarDay {
    date: Date;
    isAvailable: boolean;
    isChangeDay: boolean;
}

export enum Weekday {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6
}

export interface OverrideChangeDays {
    lodgingId: number;
    changeDays: Weekday[];
}

export type DiscountBannerType = "image" | "pricebox" | "custom" | "hidden";
export type CleaningBannerType = "image" | "custom" | "hidden";