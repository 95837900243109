import React from "react";
import { LocationDescriptionFragment } from "./operations.generated";
import "./LocationDescription.scss";

interface Props {
    location: LocationDescriptionFragment;
}

export default function LocationDescription({ location }: Props) {
    return (
        <div className="bwp-location-description">
            <h3>{location.name}</h3>
            <p>{location.shortDescription}</p>
        </div>
    );
}
