import "normalize.css";
import "@bwp/global/reset.scss";
import "@bwp/global/colors.scss";
import "@bwp/global/non-components.scss";
import "@bwp/global/fonts.scss";

import "./styles.scss";
import { setExports } from "./manifest";
import { getClient } from "./apolloClient";

setExports(window, getClient());
